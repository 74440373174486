import React, { createRef, useEffect, useRef, useState } from "react";
import Icon from "app/assets/icon/icon";
import {
  MDBRow,
  MDBCol,
  MDBBox,
  MDBIcon,
  MDBTypography,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Button from "app/core/components/button/button";
import themeConfiguration from "config/themeConfiguration";
import { useSelector } from "react-redux";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Wishlist from "app/layout/wishlist";
import { useReactToPrint } from "react-to-print";

import { ArkansasSpecSheet } from "./ArkansasSpecSheet";

const DownloadSection = ({
  image,
  files,
  closeBox,
  save3DImage,
  data,
  selectedOptionsMap,
  skusAndPrices,
  answers,
  lengthOfSelectedOptions,
  lengthOfQuestions,
  questionsNew,
  catalogNoMap,
  activeCatalogNo,
  colorPickerValue,
  colorPickerRalValue,
  custitem_pdf_attachment_1 = false,
  custitem_closeout,
  additionalDocuments,
}) => {
  let options = [];
  if (selectedOptionsMap[0] === "Arkansas") {
    options = [
      {
        question: selectedOptionsMap[1]?.question,
        name: selectedOptionsMap[1]?.name,
        generated_sku: selectedOptionsMap[1]?.generated_sku,
        catalogNumber: selectedOptionsMap[1]?.catalogNumber,
      },
    ];
  } else {
    options = Object.keys(selectedOptionsMap)
      .slice(1)
      .map((sek) => {
        let se = selectedOptionsMap[sek];
        let question = questionsNew.filter((q) => {
          return q.code === sek;
        })[0];
        if (question === undefined) return null;

        let questionName = question.name;

        return {
          question: questionName,
          name: se.name,
        };
      })
      .filter((x) => x !== null);
  }

  let stringifiedOptions = '"' + JSON.stringify(options) + '"';

  let title = data.name;

  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
    checkEmail,
  ] = useWishlist();

  const { isLoggedIn, customer } = useCustomer();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radioVal, setRadioVal] = useState("hidde");
  const [markupvalue, setMarkupvalue] = useState("");
  const [configuratorImg, setConfiguratorImg] = useState("");
  const [notes, setNotes] = useState("");
  const [project, setProject] = useState("");
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [wishlisItemsSort, setwishlisItemsSort] = useState(null);
  const [modal, setModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [systemCode, setSystemcode] = useState(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  const {
    data: { wishlistItems },
  } = wishlist;

  useEffect(() => {
    if (isLoggedIn && customerGroupId === 4) {
      if (selectedOptionsMap?.SYS?.name === "Multipoint Canopies System") {
        setSystemcode("Multipoint Canopies System");
        setRadioVal("hidde");
      }
      if (selectedOptionsMap?.SYS?.name !== "Multipoint Canopies System") {
        setSystemcode(null);
        setRadioVal("hide");
      }
      if (systemCode != null) {
        setRadioVal("hidde");
      }

      // if(customerGroupId === 1) {
      //     setRadioVal("hidde");
      // }
    }
  }, [skusAndPrices]);

  useEffect(() => {
    if (wishlistItems) {
      setwishlisItemsSort(wishlistItems.sort((a, b) => a.id - b.id));
    }
  }, [wishlistItems]);

  useEffect(() => {
    const isFavorite =
      wishlistItems !== undefined &&
      wishlistItems?.length > 0 &&
      wishlistItems[0] !== undefined &&
      wishlistItems[0]?.items.some(
        (favorite) => favorite?.product?.sku === data.sku
      );
    setIsFavorite(isFavorite);
  }, [loading]);

  const [activeItem, setActiveItem] = useState("1");

  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };
  const customerGroupId = useSelector(
    (state) => state.customer.data.customer_group
  );

  //const { questions } = data;
  //console.log("Ljubica colorPickerValue", colorPickerValue);

  const toggle = () => {
    setModalShow(!modalShow);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeBox(false);
        }
      }
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // const pdf1 =
  //   files.custom_item_pdf_1 !== null
  //     ? files?.custom_item_pdf_1.replace('["', "")
  //     : files.custom_item_pdf_1;

  //console.log("here the fuck I am", data);
  const pdf1 = data.attribute_set_id !== 9 ? true : false;
  const iesFile = files?.custom_item_ies_file;

  let downloadItems = [
    { name: "PNG Image", downloadFunc: (e) => save3DImage(e, image) },
  ];
  let productName = data.name;
  // console.log("product name", productName);
  let productId = data.id;
  let skusAndPricesHalf = skusAndPrices[0].price / 2;
  /*needed for url start*/
  let custitem_closeout_value = custitem_closeout === 0 ? false : true;
  /*needed for url end*/
  //
  // THIS IS BAD!
  //
  const getUrl = (confgImage) => {
    return `${
      themeConfiguration.magento_url
    }/productpdf/download/file/1?id=${productId}&name=${productName}&catalogno=${
      catalogNoMap[activeCatalogNo]?.name
    }&price_select=${radioVal}&mark_up_value${
      markupvalue ? "=" + markupvalue : "&"
    }&configurator_pdf_image=${confgImage ? confgImage : ""}
    &split_sku_SKU=${skusAndPrices[0].sku}
    ${
      skusAndPrices.length === 3
        ? `
    &lamp_sku_LAMP${
      skusAndPrices[1]
        ? Object.keys(skusAndPrices[1])[0] === "Lamp"
          ? "=" + skusAndPrices[1].Lamp + "&"
          : "&"
        : "&"
    }
    driver_sku_DRIVER${
      skusAndPrices !== undefined &&
      skusAndPrices[2] !== undefined &&
      skusAndPrices[2].Driver !== undefined &&
      skusAndPrices.length > 1 &&
      Object.keys(skusAndPrices[2])[0] === "Driver"
        ? "=" + skusAndPrices[2].Driver + "&"
        : "&"
    }
    sku_price_SKU=${skusAndPricesHalf}
    &driver_price_Driver${
      skusAndPrices.length > 1 && Object.keys(skusAndPrices[2])[0] === "Driver"
        ? "=" + skusAndPrices[2].price + "&"
        : "&"
    }lamp_price_Lamp${
            skusAndPrices.length > 1 &&
            Object.keys(skusAndPrices[1])[0] === "Lamp"
              ? "=" + skusAndPrices[1].price + "&"
              : "&"
          }
    `
        : `&
        driver_sku_DRIVER${
          skusAndPrices !== undefined &&
          skusAndPrices[1] !== undefined &&
          skusAndPrices[1].Driver !== undefined &&
          skusAndPrices.length > 1 &&
          Object.keys(skusAndPrices[1])[0] === "Driver"
            ? "=" + skusAndPrices[1].Driver + "&"
            : "&"
        }
        sku_price_SKU=${skusAndPricesHalf}
        &driver_price_Driver${
          skusAndPrices !== undefined &&
          skusAndPrices[1] !== undefined &&
          skusAndPrices[1].price !== undefined &&
          Object.keys(skusAndPrices[1])[0] === "Driver"
            ? "=" + skusAndPrices[1].price + "&"
            : "&"
        }`
    }
 ${
   skusAndPrices.length === 2 &&
   Object.keys(skusAndPrices[1])[0] === "Lamp" &&
   `&lamp_sku_LAMP${
     skusAndPrices !== undefined &&
     skusAndPrices[1] !== undefined &&
     skusAndPrices[1].Lamp !== undefined &&
     skusAndPrices.length > 1 &&
     Object.keys(skusAndPrices[1])[0] === "Lamp"
       ? "=" + skusAndPrices[1].Lamp + "&"
       : "&"
   }
 sku_price_SKU=${skusAndPricesHalf}
&lamp_price_Lamp${
     skusAndPrices !== undefined &&
     skusAndPrices[1] !== undefined &&
     skusAndPrices[1].price !== undefined &&
     skusAndPrices.length > 1 &&
     Object.keys(skusAndPrices[1])[0] === "Lamp"
       ? "=" + skusAndPrices[1].price + "&"
       : "&"
   }
 `
 }
 ${
   skusAndPrices.length === 3 && Object.keys(skusAndPrices[2])[0] === "ESP"
     ? `
&lamp_sku_LAMP${
         skusAndPrices[1]
           ? Object.keys(skusAndPrices[1])[0] === "Lamp"
             ? "=" + Object.keys(skusAndPrices[1])[1] + "&"
             : "&"
           : "&"
       }
       driver_sku_DRIVER${
         skusAndPrices !== undefined &&
         skusAndPrices[1] !== undefined &&
         skusAndPrices[1].Driver !== undefined &&
         skusAndPrices.length > 1 &&
         Object.keys(skusAndPrices[1])[0] === "Driver"
           ? "=" + skusAndPrices[1].Driver + "&"
           : "&"
       }
esp_sku_ESP${
         skusAndPrices[1][0] === "ESP"
           ? skusAndPrices.length > 1
             ? Object.keys(skusAndPrices[1])[0] === "ESP"
               ? "=" + skusAndPrices[1].ESP + "&"
               : "&"
             : "&"
           : Object.keys(skusAndPrices[2])[0] === "ESP"
           ? "=" + skusAndPrices[2].ESP + "&"
           : "&"
       }
sku_price_SKU=${skusAndPricesHalf}
&driver_price_Driver${
         skusAndPrices !== undefined &&
         skusAndPrices[1] !== undefined &&
         skusAndPrices[1].price !== undefined &&
         Object.keys(skusAndPrices[1])[0] === "Driver"
           ? "=" + skusAndPrices[1].price + "&"
           : "&"
       }
&esp_price_ESP${
         skusAndPrices.length > 1 && Object.keys(skusAndPrices[2])[0] === "ESP"
           ? "=" + skusAndPrices[2].price + "&"
           : "&"
       }lamp_price_Lamp${
         skusAndPrices.length > 1 && Object.keys(skusAndPrices[1])[0] === "Lamp"
           ? "=" + skusAndPrices[1].price + "&"
           : "&"
       }
`
     : `&
    esp_sku_ESP${
      skusAndPrices !== undefined &&
      skusAndPrices[1] !== undefined &&
      skusAndPrices[1].ESP !== undefined &&
      skusAndPrices.length > 1 &&
      Object.keys(skusAndPrices[1])[0] === "ESP"
        ? "=" + skusAndPrices[1].ESP + "&"
        : "&"
    }
    sku_price_SKU=${skusAndPricesHalf}
    &esp_price_ESP${
      skusAndPrices !== undefined &&
      skusAndPrices[1] !== undefined &&
      skusAndPrices[1].price !== undefined &&
      Object.keys(skusAndPrices[1])[0] === "ESP"
        ? "=" + skusAndPrices[1].price + "&"
        : "&"
    }`
 }
 ${
   //this code only adds true / false to url no query param
   //  skusAndPrices.length === 2 &&
   //  Object.keys(skusAndPrices[1])[0] === "Breaker" &&
   `
  breaker_sku_BREAKER${
    skusAndPrices !== undefined &&
    skusAndPrices[1] !== undefined &&
    skusAndPrices[1].Breaker !== undefined &&
    skusAndPrices.length > 1 &&
    Object.keys(skusAndPrices[1])[0] === "Breaker"
      ? "=" + skusAndPrices[1].Breaker + "&"
      : "&"
  }
  sku_price_SKU=${skusAndPricesHalf}
  &breaker_price_BREAKER${
    skusAndPrices !== undefined &&
    skusAndPrices[1] !== undefined &&
    skusAndPrices[1].price !== undefined &&
    Object.keys(skusAndPrices[1])[0] === "Breaker"
      ? "=" + skusAndPrices[1].price + "&"
      : "&"
  }`
 }
    ${
      options[0] &&
      options[0] !== undefined &&
      `customizable_options_0_${encodeURIComponent(options[0]?.question)}${
        "=" + encodeURIComponent(options[0]?.name)
      }&`
    }
    ${
      options[1] &&
      options[1] !== undefined &&
      `customizable_options_1_${encodeURIComponent(options[1]?.question)}${
        "=" + encodeURIComponent(options[1]?.name)
      }&`
    }
    ${
      options[2] &&
      options[2] !== undefined &&
      `customizable_options_2_${encodeURIComponent(options[2]?.question)}${
        "=" + encodeURIComponent(options[2]?.name)
      }&`
    }
    ${
      options[3] &&
      options[3] !== undefined &&
      `customizable_options_3_${encodeURIComponent(options[3]?.question)}${
        "=" + encodeURIComponent(options[3]?.name)
      }&`
    }
    ${
      options[4] &&
      options[4] !== undefined &&
      `customizable_options_4_${encodeURIComponent(options[4]?.question)}${
        "=" + encodeURIComponent(options[4]?.name)
      }&`
    }
    ${
      options[5] &&
      options[5] !== undefined &&
      `customizable_options_5_${encodeURIComponent(options[5]?.question)}${
        "=" + encodeURIComponent(options[5]?.name)
      }&`
    }
    ${
      options[6] &&
      options[6] !== undefined &&
      `customizable_options_6_${encodeURIComponent(options[6]?.question)}${
        "=" + encodeURIComponent(options[6]?.name)
      }&`
    }
    ${
      options[7] &&
      options[7] !== undefined &&
      `customizable_options_7_${encodeURIComponent(options[7]?.question)}${
        "=" + encodeURIComponent(options[7]?.name)
      }&`
    }
    ${
      options[8] &&
      options[8] !== undefined &&
      `customizable_options_8_${encodeURIComponent(options[8]?.question)}${
        "=" + encodeURIComponent(options[8]?.name)
      }&`
    }
      ${
        options[9] &&
        options[9] !== undefined &&
        `customizable_options_9_${encodeURIComponent(options[8]?.question)}${
          "=" + encodeURIComponent(options[9]?.name)
        }&`
      }
     ${
       options[9] &&
       options[9] !== undefined &&
       `customizable_options_9_${encodeURIComponent(options[8]?.question)}${
         "=" + encodeURIComponent(options[9]?.name)
       }&`
     }
  ${
    colorPickerValue
      ? "&color_picker_selected_color=" + encodeURIComponent(colorPickerValue)
      : ""
  }
  ${"&custitem_closeout=" + custitem_closeout_value}
  ${
    colorPickerRalValue
      ? "&color_picker_selected_ral_color=" +
        encodeURIComponent(colorPickerRalValue)
      : ""
  }
  ${notes ? "&notes=" + notes : ""}&sku=${catalogNoMap[activeCatalogNo]?.sku}`;
  };
  //
  //
  const pdfComponentArkansas = React.useRef();
  const saveArkansasPDf = useReactToPrint({
    content: () => pdfComponentArkansas.current,
  });

  const getConfiguratorImage = async (e) => {
    await save3DImage(e, image, true, false);
    try {
      const blob = window.localStorage.getItem("blob");
      window.localStorage.removeItem("blob");
      const configurator_image = {
        name: data.skuState.CATA,
        type: "image/png",
        base64_encoded_data: blob.replace("data:image/png;base64,", ""),
        // this is bad solution THIS IS CHANGED
        // lengthOfQuestions <= 2
        //   ? "data:,"
        //   : blob.replace("data:image/png;base64,", ""),
      };
      const { data: newData } = await apolloClient.mutate({
        mutation: queryLoader("uploadConfiguratorImage"),
        variables: { input: { configurator_image } },
      });
      try {
        const {
          uploadConfiguratorImage: { configurator_image },
        } = newData;
        return configurator_image;
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (pdf1) {
    downloadItems.push({
      name: "Spec sheet",
      downloadFunc: async (e) => {
        if (lengthOfSelectedOptions === lengthOfQuestions) {
          const confgImage = await getConfiguratorImage(e);
          setConfiguratorImg(confgImage);
          if (isLoggedIn && customerGroupId === 4 && systemCode == null) {
            //isLoggedIn && customerGroupId === 4 && systemCode !== "Multipoint Canopies System"
            // setModalShow(false);
            // setRadioVal("hidde");
            window.location.href = encodeURI(getUrl(confgImage));
          } else if (systemCode) {
            setRadioVal("hidde");
            window.location.href = encodeURI(getUrl(confgImage));
          } else {
            window.location.href = encodeURI(getUrl(confgImage));
          }
        } else {
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 2000);
        }
      },
    });
  }

  if (iesFile) {
    downloadItems.push({
      name: "IES",
      downloadFunc: (e) => window.open(iesFile, "_blank"),
    });
  }

  if (custitem_pdf_attachment_1)
    downloadItems.push({
      name: "Save Overview Spec Sheet",
      downloadFunc: (e) => window.open(custitem_pdf_attachment_1, "_blank"),
    });

  return (
    <>
      <MDBRow className="py-3 justify-content-between" style={{ zIndex: "0" }}>
        <div className="downloads-wrapper">
          <div className="d-flex align-items-center justify-content-between">
            <DownloadIcon
              data={data}
              items={downloadItems}
              isLoggedIn={isLoggedIn}
              additionalDocuments={additionalDocuments}
            />
            <div className={`downloads-text text-center cursor-pointer`}>
              <span>DOWNLOADS</span>
            </div>
          </div>
          {showPopup && (
            <div className="question-box-tooltip-wrapper">
              <p>Please configure the product.</p>
            </div>
          )}
        </div>
        <MDBCol size="3">
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            className="wrapper-favorite"
          >
            <Wishlist
              customer={customer}
              login={isLoggedIn}
              product={data}
              fetchArr={wishlisItemsSort}
              isFavorite={isFavorite}
              setIsFavorite={setIsFavorite}
            >
              <Icon
                icon={isFavorite ? "heartFull" : "heartPdp"}
                width={33}
                height={45}
                className={`mr-2 heart`}
                style={{
                  minWidth: "33px",
                  zIndex: "5",
                  cursor: "pointer",
                  order: "1",
                }}
              />
            </Wishlist>
            <div
              className={`span-logo text-center cursor-pointer wishlist-icon-container`}
              style={{ order: "2" }}
            >
              <span>FAVORITE</span>
            </div>
            {/* {tooltipVisible && ( // This is temporary so ignore the bad code
              <div
                style={{
                  position: "absolute",
                  top: "-5.5rem",
                  zIndex: "99",
                  border: "1px solid #1f1e3f",
                  borderRadius: "0.5rem",
                  fontSize: "1.4rem",
                  padding: "1rem 1.2rem",
                  color: "black",
                  backgroundColor: "#ffffff",
                  height: "5rem",
                  width: "16rem",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontFamily: "Montserrat !important" }}>
                  Coming soon
                </p>
              </div>
            )} */}
          </div>
        </MDBCol>
      </MDBRow>
      <MDBModal
        className="modal-wrapper tear-sheet"
        isOpen={modalShow}
        // centered
        toggle={(e) => {
          toggle();
        }}
        size="sm"
      >
        <MDBModalHeader>
          <div>
            <p className="text-center">Download Tearsheet</p>
            <Icon
              style={{
                cursor: "pointer",
                fill: "white",
                position: "absolute",
                top: "21px",
                right: "15px",
              }}
              icon="closeSearch"
              onClick={() => {
                toggle();
              }}
            />
          </div>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="modal-body-inner mt-5">
            <MDBInput
              type="checkbox"
              label="Show pricing"
              value="show"
              checked={radioVal === "show"}
              id="show"
              onChange={() => setRadioVal("show")}
            />
            <MDBInput
              type="checkbox"
              label="Hide pricing"
              value="hidde"
              checked={radioVal === "hidde"}
              id="hidde"
              onChange={() => setRadioVal("hidde")}
            />
            <div className="d-flex align-items-center mb-5">
              <MDBInput
                type="checkbox"
                label="Markup pricing"
                value="hide"
                checked={radioVal === "mark_up"}
                id="mark_up"
                onChange={() => setRadioVal("mark_up")}
              />
              <div
                className="d-flex align-items-center"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBInput
                  disabled={radioVal !== "mark_up" ? true : false}
                  containerClass="enter-value-container"
                  className="enter-value"
                  type="number"
                  group
                  validate
                  error="wrong"
                  success="right"
                  value={markupvalue !== undefined ? markupvalue : ""}
                  onChange={(e) => setMarkupvalue(e.target.value)}
                />
                {error && (
                  <span className="error">This is a required field.</span>
                )}
              </div>
            </div>
            <MDBInput
              type="textarea"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={`register-input`}
              placeholder="Enter remarks"
              label="Remarks"
              labelClass={`register-label`}
            />
          </div>
          <div className="modal-actions mt-5">
            <Button
              onClick={() => {
                setError(false);
                if (radioVal === "mark_up" && markupvalue === "") {
                  setError(true);
                  return;
                } else {
                  window.location.href = encodeURI(getUrl(configuratorImg));
                  setModalShow(false);
                }
              }}
            >
              Create pdf
            </Button>
          </div>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export const DownloadIcon = ({
  items,
  data,
  isLoggedIn,
  additionalDocuments,
}) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  const arkansasRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => arkansasRef.current,
  });

  return (
    <>
      {show && (
        <MDBBox
          className="downloadWindow"
          onMouseLeave={() => {
            setShow(false);
          }}
        >
          {data.attribute_set_id === 9 && (
            <MDBRow className="item-wrapper mx-0 px-2">
              <MDBCol
                className={`item py-3 d-flex align-items-center `}
                onClick={(e) => handlePrint()}
              >
                <MDBIcon
                  className="downloadIcon-icon d-inline-block mr-3 pb-1"
                  icon="arrow-down"
                  size="1x"
                />
                <div style={{ display: "none" }}>
                  <div ref={arkansasRef}>
                    <ArkansasSpecSheet data={data} isLoggedIn={isLoggedIn} />
                  </div>
                </div>
                <MDBTypography tag="p" className="d-inline-block">
                  Spec sheet
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          )}
          {additionalDocuments?.map((el) => (
            <MDBRow key={el.value} className="item-wrapper mx-0 px-2">
              <MDBCol
                className={`item py-3 d-flex align-items-center `}
                onClick={() => window.open(el?.value)}
              >
                <MDBIcon
                  className="downloadIcon-icon d-inline-block mr-3 pb-1"
                  icon="arrow-down"
                  size="1x"
                />
                <MDBTypography tag="p" className="d-inline-block">
                  {el?.label}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          ))}
          {items.map((item, i) => {
            return (
              <MDBRow
                className="item-wrapper mx-0 px-2"
                onClick={(e) => {
                  item.downloadFunc(e);
                }}
              >
                <MDBCol
                  className={`item py-3 d-flex align-items-center ${
                    i + 1 < items.length ? "border-bottom" : ""
                  }`.trim()}
                >
                  <MDBIcon
                    className="downloadIcon-icon d-inline-block mr-3 pb-1"
                    icon="arrow-down"
                    size="1x"
                  />
                  <MDBTypography tag="p" className="d-inline-block">
                    {item.name}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            );
          })}
        </MDBBox>
      )}
      <MDBBox
        className={`downloadIcon flex-center p-1 ${
          show ? "active" : ""
        }`.trim()}
        onClick={toggleShow}
      >
        <MDBBox className="downloadIcon-wrapper border-bottom-1-black pb-1">
          <MDBIcon className="downloadIcon-icon" icon="arrow-down" size="2x" />
        </MDBBox>
      </MDBBox>
    </>
  );
};

export default DownloadSection;
