import React from "react";
import { MDBBox, MDBTypography } from "mdbreact";
import { Link } from "react-router-dom";
import { customerActions } from "core/state/redux/data/customer";
import { useDispatch } from "react-redux";

const MobileMenuBottom = ({
  isLoggedIn,
  toggleModal,
  setActiveItem,
  toggleMobileMenu,
}) => {
  const logOut = () => {
    dispatch(customerActions.logoutCustomer());
    if (window.innerWidth < 991) {
      document.getElementById("hamburger1").click();
    }
  };
  const dispatch = useDispatch();

  return (
    <MDBBox className="mobile-menu-bottom">
      <MDBBox className={`p-0`}>
        <li>
          {isLoggedIn ? (
            <React.Fragment>
              <span className="account-mobile d-flex align-items-center">
                <Link onClick={toggleMobileMenu} to="/customer">
                  my account
                </Link>
              </span>
              <span className="sign-out d-flex align-items-center">
                <Link onClick={logOut} to="/">
                  Sign Out
                </Link>
              </span>
            </React.Fragment>
          ) : (
            <MDBTypography
              className="mobile-bottom-text"
              onClick={() => {
                toggleModal();
                setActiveItem("1");
              }}
            >
              Login
            </MDBTypography>
          )}
        </li>

        <li>
          {isLoggedIn ? (
            <></>
          ) : (
            // <span className="wishlist-mobile d-flex align-items-center">
            // {/* <Link to="/customer/wishlist"> my whishlist </Link> */}
            // </span>
            <MDBTypography
              className="mobile-bottom-text"
              onClick={() => {
                toggleModal();
                setActiveItem("2");
              }}
            >
              Register
            </MDBTypography>
          )}
        </li>
        {/* <li className="temp-message-mobile-menu">Experiencing issues?</li>
        <li className="temp-message-mobile-menu">
          Click{" "}
          <a
            href={"https://www.primalighting.com/"}
            target="__blank"
            rel="noreferrer noopener"
          >
            here
          </a>{" "}
          to access the original website.
        </li> */}
      </MDBBox>
    </MDBBox>
  );
};

export default MobileMenuBottom;
