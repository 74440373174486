import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import VideoDataContainer from "./data-container";

const HOME_PAGE_VIDEO = gql`
  query cmsBlock($id: String!) {
    cmsBlocks(identifiers: [$id]) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

const VideoQueryContainer = (props) => {
  return (
    <GraphQlWrapper
      query={HOME_PAGE_VIDEO}
      variables={{ id: "homepage_prima_video" }}
    >
      <VideoDataContainer {...props} />
    </GraphQlWrapper>
  );
};

export default VideoQueryContainer;
