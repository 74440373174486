import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBox,
  MDBTypography,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import "./style.scss";
import {
  FirstNameErrorMessage,
  LastNameErrorMessage,
  AddressErrorMessage,
  PhoneErrorMessage,
  PostcodeErrorMessage,
  CityErrorMessage,
} from "../accountInformation/errorMessages";
import Select2 from "core/components/select/select";
import { AddressEmailErrorMessage } from "../accountInformation/errorMessages";

const AddNewAddressForm = ({
  register,
  errors,
  countries,
  country,
  state,
  availableStates,
  onCountryChangeHandler,
  onStateChangeHandler,
  handleCheckedShipping,
  handleCheckedBilling,
  loading,
  setNonUsState,
  nonUsState,
  stateError,
  setStateError,
}) => {
  let modifiedStates = [
    {
      label: `${country.value === "US" ? "Select State *" : "Select Province"}`,
      value: "",
    },
    ...availableStates,
  ];

  return (
    <MDBRow className="m-0 d-flex align-items-stretch justify-content-center customer-information-main-row address-book-form">
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <MDBBox tag="div">
          <MDBTypography
            variant="h6"
            tag="h6"
            className="pt-4 pb-4 main-title-account"
          >
            Contact Information
          </MDBTypography>
          <MDBInput
            name="firstname"
            type="text"
            inputRef={register({ required: true })}
            className={`mb-5 account-input ${
              errors.firstname && `account-input-error`
            }`}
            placeholder="Enter Name *"
            label="First Name"
            labelClass={`account-input-label ${
              errors.firstname && `account-error-label`
            }`}
          />
          <FirstNameErrorMessage errors={errors} />
          <MDBInput
            name="lastname"
            type="text"
            inputRef={register({ required: true })}
            className={`mb-5 account-input ${
              errors.lastname && `account-input-error`
            }`}
            placeholder="Enter Last Name *"
            label="Last Name"
            labelClass={`account-input-label ${
              errors.lastname && `account-error-label`
            }`}
          />
          <LastNameErrorMessage errors={errors} />
          <MDBInput
            name="telephone"
            type="number"
            inputRef={register({ required: true })}
            className={`mb-5 account-input ${
              errors.phone && `account-input-error`
            }`}
            placeholder="Enter Phone Number *"
            label="Phone"
            labelClass={`account-input-label ${
              errors.phone && `account-error-label`
            }`}
          />
          <PhoneErrorMessage errors={errors} />
          <MDBInput
            name="address_email"
            type="email"
            inputRef={register({
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            className={`mb-5 account-input ${
              errors.address_email && `account-input-error`
            }`}
            placeholder="Enter Email *"
            label="Email"
            labelClass={`account-input-label ${
              errors.address_email && `account-error-label`
            }`}
          />
          <AddressEmailErrorMessage errors={errors} />
        </MDBBox>
      </MDBCol>
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <MDBBox tag="div" className="pb-5">
          <MDBTypography
            variant="h6"
            tag="h6"
            className="pt-4 pb-4 main-title-account"
          >
            Address
          </MDBTypography>
          <MDBInput
            name="address1"
            type="text"
            inputRef={register({ required: true })}
            className={`mb-5 account-input ${
              errors.address1 && `account-input-error`
            }`}
            placeholder="Enter Address 1 *"
            label="Address 1"
            labelClass={`account-input-label ${
              errors.address1 && `account-error-label`
            }`}
          />
          <AddressErrorMessage errors={errors} />
          <MDBInput
            name="address2"
            type="text"
            inputRef={register({ required: false })}
            className="mb-5 account-input"
            placeholder="Enter Address 2"
            label="Address 2"
            labelClass="account-input-label"
          />
          <MDBCol
            className={`select2-container`}
            style={{
              padding: 0,
              display: "block",
            }}
          >
            <span className="select2-label">Country</span>
            <Select2
              icon="address"
              required={true}
              value={country}
              onChange={(event) => {
                onCountryChangeHandler(event);
              }}
              wrapperClass="small w-100"
              placeholder={"Country *"}
              options={countries.map((country) => ({
                label: country.full_name_english,
                value: country.id,
                available_regions: country.available_regions,
              }))}
              isSearchable={true}
              className={`w-100`}
              rules={{ required: true }}
            />
          </MDBCol>

          <MDBInput
            name="city"
            type="text"
            inputRef={register({ required: true })}
            className={`mb-5 mt-5 account-input ${
              errors.city && `account-input-error`
            }`}
            placeholder="Enter City *"
            label="City"
            labelClass={`account-input-label ${
              errors.city && `account-error-label`
            }`}
          />
          <CityErrorMessage errors={errors} />
          {availableStates && availableStates.length > 0 ? (
            <MDBCol
              className={`select2-container`}
              style={{
                padding: 0,
                display: "block",
              }}
            >
              <span className="select2-label">
                {country.value === "US"
                  ? "State"
                  : country.value === "CA"
                  ? "Province"
                  : "State/Region"}
              </span>
              <Select2
                // disabled={loader}
                name={"country"}
                type={"text"}
                icon="address"
                required={true}
                value={state}
                onChange={(event) => {
                  onStateChangeHandler(event);
                  setStateError(false);
                }}
                className={`w-100 ${stateError && `error-quote`}`}
                wrapperClass="small w-100"
                placeholder={
                  country.value === "US"
                    ? "State *"
                    : country.value === "CA"
                    ? "Province *"
                    : "State/Region"
                }
                options={modifiedStates}
                isSearchable={true}
              />
            </MDBCol>
          ) : (
            <MDBCol className="pl-0 pr-0 mt-4">
              <MDBInput
                className="account-input"
                value={nonUsState}
                onChange={(e) => {
                  setNonUsState(e.target.value);
                }}
                name="NonUsState"
                type="text"
                placeholder="State / Region "
                label="State / Region"
                labelClass={`account-input-label`}
              />
            </MDBCol>
          )}
          <MDBInput
            name="postcode"
            type="text"
            inputRef={register({ required: true })}
            className={`mb-5 mt-5 account-input ${
              errors.postcode && `account-input-error`
            }`}
            placeholder="Enter Zip / Postal Code *"
            label="Zip / Postal Code"
            labelClass={`account-input-label ${
              errors.postcode && `account-error-label`
            }`}
          />
          <PostcodeErrorMessage errors={errors} />

          <MDBCol className="ml-0 pl-0 mt-5 mb-4">
            <MDBInput
              label="use as my default billing address"
              type="checkbox"
              id="billing"
              onChange={handleCheckedBilling}
            />
          </MDBCol>
          <MDBCol className="ml-0 pl-0 mb-3">
            <MDBInput
              label="Use as my default shipping address"
              type="checkbox"
              id="shipping"
              onChange={handleCheckedShipping}
            />
          </MDBCol>
          <MDBBtn
            disabled={loading}
            className="mt-5 new-address-button"
            style={{ float: "right" }}
            type="submit"
          >
            Submit
          </MDBBtn>
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};

export default AddNewAddressForm;
