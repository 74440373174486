import React, { memo } from "react";
import { MDBBox, MDBContainer, MDBRow } from "mdbreact";
import SingleProduct from "./singleProduct";

const Listing = memo(({ items }) => {
  return (
    <MDBBox>
      <MDBContainer fluid>
        <MDBRow className={`products-list`}>
          {items.map((item) => (
            <SingleProduct
              image={item.small_image}
              name={item.name}
              sku={item.sku}
              url={item.url_key}
              categories={item.categories}
              imageUrl={item?.imageUrl}
              id={item?.id}
              custitem_closeout={
                item?.custitem_closeout?.toLowerCase() === "yes"
              }
              ark_closeout={item?.ark_closeout === "1"}
            />
          ))}
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
});

export default Listing;
