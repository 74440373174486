import React from "react";
import gql from "graphql-tag";
import { About } from "./about";
import GraphQlQueryWrapper from "core/components/graphQlWrapper";

const ids = [
  "about_us_product_image",
  "about_us_banner_desktop",
  "about_us_banner_mobile",
  "about_us_banner_tablet",
  "about_us_title",
  "about_us_first_heading",
  "about_us_text_left_first",
  "about_us_text_right_first",
  "about_us_highlighted_first",
  "about_us_product_name",
  "about_us_product_sku",
  "about_us_tabs_image",
  "about_us_restaurants_image",
  "about_us_restaurants_title",
  "about_us_restaurants_text",
  "about_us_restaurants_bottom_left",
  "about_us_restaurants_bottom_image_right",
  "about_us_public_spaces_image",
  "about_us_public_spaces_text",
  "about_us_public_spaces_title",
  "about_us_public_spaces_bottom_left",
  "about_us_public_spaces_bottom_image_right",
  "about_us_retail_image",
  "about_us_retail_title",
  "about_us_retail_text",
  "about_us_retail_bottom_left",
  "about_us_retail_bottom_image_right",
  "about_us_residential_image",
  "about_us_residential_title",
  "about_us_residential_text",
  "about_us_residential_bottom_left",
  "about_us_residential_bottom_image_right",
  "about_us_government_image",
  "about_us_government_title",
  "about_us_government_text",
  "about_us_government_bottom_left",
  "about_us_government_bottom_image_right",
  "about_us_adult_image",
  "about_us_adult_title",
  "about_us_adult_text",
  "about_us_adult_bottom_left",
  "about_us_adult_bottom_image_right",
  "about_us_catalog_image",
  "about_us_catalog_text",
  "about_us_catalog_button",
  "about_us_second_heading",
  "about_us_text_left_second",
  "about_us_text_right_second",
  "about_us_highlighted_second",
  "about_us_large_image",
  "about_us_link",
];

const ABOUT_US = gql`
  query cmsBlock($ids: [String!]!) {
    cmsBlocks(identifiers: $ids) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const AboutUsContainer = () => {
  return (
    <GraphQlQueryWrapper
      query={ABOUT_US}
      variables={{ id: "about-us-page-cms-blocks", ids: ids }}
    >
      <About contentMap={ids} />
    </GraphQlQueryWrapper>
  );
};
