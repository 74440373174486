import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBBox,
} from "mdbreact";
import "./modal.scss";
import Select2 from "core/components/select/select";
import MaskedInput from "react-text-mask";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import PrimaSpinner from "../primaSpinner";

const RegisterFormContent = ({
  errors,
  register,
  countries,
  onCountryChangeHandler,
  onStateChangeHandler,
  setSubscribed,
  getValues,
  hasError,
  errorMessage,
  loading,
  country,
  state,
  availableStates,
  telephoneMask,
  zipMask,
  control,
  businessTypes,
  businessType,
  setBusinessType,
  setNonUsState,
  nonUsState,
}) => {
  const RequiredFieldMsg = () => (
    <MDBTypography tag="p" className="register-error-msg">
      This is a required field.
    </MDBTypography>
  );

  let modifiedStates = [
    {
      label: `${country.value === "US" ? "Select State *" : "Select Province"}`,
      value: "",
    },
    ...availableStates,
  ];

  return (
    <>
      <MDBRow className="register">
        <MDBCol md="6" sm="12">
          <MDBCol
            className={`select2-container`}
            style={{
              display: "block",
            }}
          >
            <span className={`select2-label register-label ${
              errors.businessType && `register-error-label`
            } `}>Business type</span>
            <Select2
              required={true}
              wrapperClass="small w-100"
              placeholder={"Select Business Type *"}
              options={businessTypes}
              isSearchable={true}
              className={`w-100 ${
                errors.businessType && `register-error-input`
              }`}
              rules={{ required: true }}
              onChange={(e) => {
                setBusinessType(e.value);
              }}
            />
           <div style={{ position: 'relative', top: '23px'}}>
            {errors.businessType && errors.businessType.type === "required" && (
              <RequiredFieldMsg />
              )}
           </div>
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="email"
              name="email"
              placeholder="Enter Email *"
              label="Email"
              labelClass={`register-label ${
                errors.email && `register-error-label`
              } `}
              className={`register-input ${
                errors.email && `register-error-input`
              }`}
              inputRef={register({
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <RequiredFieldMsg />
            )}
            {errors.email && errors.email.type === "pattern" && (
              <MDBTypography tag="p">Invalid email</MDBTypography>
            )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="password"
              name="password"
              placeholder="Enter Password *"
              label="Password"
              labelClass={`register-label ${
                errors.password && `register-error-label`
              } `}
              className={`register-input ${
                errors.password && `register-error-input`
              }`}
              inputRef={register({
                required: true,
                min: 8,
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
              })}
            />
            {errors.password && errors.password.type === "required" && (
              <RequiredFieldMsg />
            )}
            {errors.password && errors.password.type === "pattern" && (
              <MDBTypography tag="p" className="register-error-msg">
                Password must include 3 different classes of characters: lower
                case, upper case, digits, and/or special characters
              </MDBTypography>
            )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="password"
              name="confirm_password"
              placeholder="Confirm Password *"
              label="Confirm Password"
              labelClass={`register-label ${
                errors.confirm_password && `register-error-label`
              } `}
              className={`register-input ${
                errors.confirm_password && `register-error-input`
              }`}
              inputRef={register({
                required: true,
                min: 8,
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
                validate: (value) =>
                  value === getValues("password") ||
                  "The passwords do not match",
              })}
            />
            {errors.confirm_password &&
              errors.confirm_password.type === "required" && (
                <RequiredFieldMsg />
              )}
            {errors.confirm_password &&
              errors.confirm_password.type === "pattern" && (
                <MDBTypography tag="p" className="register-error-msg">
                  Password must contain at least 8 characters, including
                  upper/lowercase, digits and/or special characters
                </MDBTypography>
              )}
            {errors.confirm_password &&
              errors.confirm_password.type === "validate" && (
                <MDBTypography tag="p" className="register-error-msg">
                  The passwords do not match
                </MDBTypography>
              )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="text"
              name="firstName"
              placeholder="Enter Name *"
              label="First Name"
              labelClass={`register-label ${
                errors.firstName && `register-error-label`
              } `}
              className={`register-input ${
                errors.firstName && `register-error-input`
              }`}
              inputRef={register({ required: true })}
            />
            {errors.firstName && errors.firstName.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="text"
              name="lastName"
              placeholder="Enter Last Name *"
              label="Last Name"
              labelClass={`register-label ${
                errors.lastName && `register-error-label`
              } `}
              className={`register-input ${
                errors.lastName && `register-error-input`
              }`}
              inputRef={register({ required: true })}
            />
            {errors.lastName && errors.lastName.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="text"
              name="company"
              placeholder="Enter Company Name *"
              label="Company"
              labelClass={`register-label ${
                errors.company && `register-error-label`
              } `}
              className={`register-input ${
                errors.company && `register-error-input`
              }`}
              inputRef={register({ required: true })}
            />
            {errors.company && errors.company.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
        </MDBCol>
        <MDBCol md="6" sm="12">
          <MDBCol>
            <MDBBox className={`md-form`}>
              <Controller
                as={
                  <MaskedInput
                    className={`form-control register-input ${
                      errors.phone && `register-error-input`
                    }`}
                  />
                }
                control={control}
                name={`phone`}
                type="text"
                hint="Phone*"
                error="wrong"
                success="right"
                mask={telephoneMask}
                guide={false}
                placeholder="Enter Phone Number *"
                label="Phone"
                rules={{ required: true }}
              />
              <label
                for={`phone`}
                className={`register-label active ${
                  errors.phone && `register-error-label`
                } `}
              >
                Phone
              </label>
            </MDBBox>
            {errors.phone && errors.phone.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="text"
              name="address"
              placeholder="Enter Address *"
              label="Address"
              labelClass={`register-label ${
                errors.address && `register-error-label`
              } `}
              className={`register-input ${
                errors.address && `register-error-input`
              }`}
              inputRef={register({ required: true })}
            />
            {errors.address && errors.address.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
          <MDBCol
            className={`select2-container`}
            style={{
              display: "block",
            }}
          >
            <span className="select2-label">Country</span>
            <Select2
              icon="address"
              required={true}
              value={country}
              onChange={(event) => {
                onCountryChangeHandler(event);
              }}
              wrapperClass="small w-100"
              placeholder={"Country *"}
              options={countries.map((country) => ({
                label: country.full_name_english,
                value: country.id,
                available_regions: country.available_regions,
              }))}
              isSearchable={true}
              className={`w-100`}
              rules={{ required: true }}
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              type="text"
              name="city"
              placeholder="Enter City *"
              label="City"
              labelClass={`register-label ${
                errors.city && `register-error-label`
              } `}
              className={`register-input ${
                errors.city && `register-error-input`
              }`}
              inputRef={register({ required: true })}
            />
            {errors.city && errors.city.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>

          {availableStates && availableStates.length > 0 ? (
            <MDBCol
              className={`select2-container`}
              style={{
                display: "block",
              }}
            >
              <span className={`select2-label register-label ${
                errors.state && `register-error-label`
              }`}>
                {country.value === "US"
                  ? "State"
                  : country.value === "CA"
                  ? "Province"
                  : "State/Region"}
              </span>

              <Select2
                // disabled={loader}
                name={"country"}
                type={"text"}
                icon="address"
                required={true}
                value={state}
                onChange={(event) => {
                  onStateChangeHandler(event);
                }}
                wrapperClass="small w-100"
                placeholder={"State *"}
                options={modifiedStates}
                isSearchable={true}
                className={`w-100 ${
                  errors.state && `register-error-input`
                }`}
              />
              <div style={{ position: 'relative', top: '23px'}}>
                {errors.state && errors.state.type === "required" && (
                  <RequiredFieldMsg />
                )}
              </div>
            </MDBCol>
          ) : (
            <MDBCol>
              <MDBInput
                type="text"
                value={nonUsState}
                onChange={(e) => {
                  setNonUsState(e.target.value);
                }}
                className={`register-input`}
                placeholder="State / Region"
                label="State / Region"
                labelClass={`register-label`}
              />
            </MDBCol>
          )}
          <MDBCol>
            <MDBBox className={`md-form`}>
              <Controller
                as={
                  zipMask ? (
                    <MaskedInput
                      className={`form-control register-input ${
                        errors.postalCode && `register-error-input`
                      }`}
                    />
                  ) : (
                    <MDBInput
                      type="text"
                      name="postalCode"
                      placeholder="Enter Zip / Postal Code *"
                      label="Zip / Postal Code"
                      labelClass={`register-label ${
                        errors.postalCode && `register-error-label`
                      } `}
                      className={`register-input ${
                        errors.postalCode && `register-error-input`
                      }`}
                    />
                  )
                }
                name={`postalCode`}
                control={control}
                type="text"
                hint={`${country.value === "US" ? "Zip Code" : "Postal Code"}`}
                error="wrong"
                success="right"
                mask={zipMask}
                guide={false}
                placeholder={`${
                  country.value === "US" ? "Zip Code" : "Postal Code"
                }`}
                rules={{ required: true }}
              />
              {zipMask ? (
                <label
                  for={`postalCode`}
                  className={`register-label active ${
                    errors.postalCode && `register-error-label`
                  } `}
                >
                  {country.value === "US" ? "Zip Code" : "Postal Code"}
                </label>
              ) : (
                <></>
              )}
            </MDBBox>

            {errors.postalCode && errors.postalCode.type === "required" && (
              <RequiredFieldMsg />
            )}
          </MDBCol>
          <MDBCol style={{ marginTop: "20px", paddingLeft: 0 }}>
            <MDBInput
              name="isSubscribed"
              type="checkbox"
              label="join our mailing list"
              id="isSubscribed"
              inputRef={register({ required: false })}
              onChange={() => setSubscribed(getValues("isSubscribed"))}
            />
          </MDBCol>
          <MDBCol
            style={{ marginTop: "10px", paddingLeft: 0 }}
            className="d-flex flex-col"
          >
            <MDBInput
              name="termsConditions"
              type="checkbox"
              required
              inputRef={register({ required: true })}
              id="termsConditions"
            />
            <Link
              className="terms-and-conditions-link"
              target="_blank"
              to="/terms-and-conditions"
              style={{
                fontSize: "1.2rem",
                letterSpacing: "0.75px",
                textTransform: "uppercase",
                color: "#1f1e3f",
                fontWeight: "500",
                fontFamily: "SangBleuSunriseRegular",
              }}
            >
              I have read the <span>terms + conditions</span>
            </Link>
            {errors.termsConditions &&
              errors.termsConditions.type === "required" && (
                <MDBTypography>
                  You have to agree with terms and conditions.
                </MDBTypography>
              )}
          </MDBCol>
          <MDBCol
            style={{
              marginTop: "4rem",
              textAlign: "right",
            }}
          >
            {loading ? (
              <PrimaSpinner />
            ) : (
              <MDBBtn type="submit" style={{ marginBottom: "2rem" }}>
                Register
              </MDBBtn>
            )}
          </MDBCol>
        </MDBCol>
      </MDBRow>
      {hasError && (
        <MDBCol
          style={{ margin: "auto", textAlign: "center", marginTop: "20px" }}
        >
          <MDBTypography
            tag="p"
            style={{
              color: "#f83535",
              fontSize: "1.5rem",
              fontFamily: "Montserrat",
            }}
          >
            {errorMessage}
          </MDBTypography>
        </MDBCol>
      )}
    </>
  );
};

export default RegisterFormContent;
