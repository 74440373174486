/* eslint-disable react-hooks/rules-of-hooks */
import { GET_PAYMENT_METHODS } from "./queries";
import { useQuery } from "react-apollo-hooks";

export const getPaymentMethods = (token, cart_id) => {
  const { data, loading, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: {
      cart_id: cart_id,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
  });

  if (loading || error) return null;
  return data;
};
