import React from "react";
import Alert from "./alert";

const SessionMessagesItem = (props) => {
  let closeTime = 4000;
  if (props.closeTime) closeTime = props.closeTime;

  if (props.autoClose) {
    setTimeout(() => {
      props.onClose();
    }, closeTime);
  }
  let message = "";
  if (props.text) {
    message = props.text.replace("Error: GraphQL error: ", "");
  }

  return (
    <>
      {/* <ScrollToTopOnMount /> */}
      <Alert
        variant={props.type}
        closeLabel="Remove message"
        dismissible={props.dismissible}
        onClose={props.onClose}
        successIcon={props.successIcon}
        errorIcon={props.errorIcon}
      >
        {message}
        {props.options}
      </Alert>
    </>
  );
};

export default SessionMessagesItem;
