export const klevuFilters = async (
  value,
  klevuConfig,
  limit = 30,
  offset = 0,
  sort
) => {
  let paginationStartsFrom = offset <= 1 ? 0 : (offset - 1) * limit;
  let filters = {};
  let filtersToReturn = {
    enabled: true,
    include: [],
    options: {
      order: "INDEX",
      limit: 40,
    },
    rangeFilterSettings: [
      {
        key: "klevu_price",
        minMax: false,
        rangeInterval: 50,
      },
      {
        key: "CustomRangeFilter",
        minMax: true,
      },
    ],
  };
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      value[i]["settings"] = { singleSelect: "false" };
    }

    value = value.filter((v) => v.values.length !== 0);

    filters =
      value.length > 0
        ? {
            applyFilters: {
              filters: value,
            },
            filtersToReturn: filtersToReturn,
          }
        : { filtersToReturn: filtersToReturn };
  } else {
    filters = {
      filtersToReturn: filtersToReturn,
    };
  }
  const { cloud_search_url, js_api_key } = klevuConfig;
  const body = JSON.stringify({
    recordQueries: [
      {
        filters: filters,
        id: "productSearch",
        typeOfRequest: "SEARCH",
        settings: {
          searchPrefs: ["includeCategoryFilterInCatNav"],
          query: {
            term: Array.isArray(value)
              ? document.location.search.split("=")[1]
              : value,
          },
          offset: paginationStartsFrom,
          limit: !limit ? 30 : limit,
          sort,
          typeOfRecords: ["KLEVU_PRODUCT"],
        },
      },
    ],
    context: {
      apiKeys: [`${js_api_key}`],
    },
  });

  const data = await fetch(`https://${cloud_search_url}/cs/v2/search/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err));
  return data;
};
