import React, { useState } from "react";
import {
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavLink,
  MDBNavItem,
} from "mdbreact";
import unescape from "lodash/unescape";

const ProductDimensions = (props) => {
  const [activeItem, setActiveItem] = useState({ activeItem: "1" });
  const toggle = (tab) => (e) => {
    e.preventDefault();
    if (activeItem !== tab) {
      setActiveItem({
        activeItem: tab,
      });
    }
  };
  return (
    <>
      <MDBNav className="nav-tabs">
        <MDBNavItem>
          <MDBNavLink
            link
            to="#"
            exact
            active={activeItem.activeItem === "1"}
            onClick={toggle("1")}
            role="tab"
          >
            DESCRIPTION
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink
            link
            to="#"
            active={activeItem.activeItem === "2"}
            onClick={toggle("2")}
            role="tab"
          >
            SIZE + DIMENSIONS
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
      <MDBTabContent activeItem={activeItem.activeItem}>
        <MDBTabPane tabId="1" role="tabpanel">
          <div>
            {props?.description?.html ? (
              <ul
                dangerouslySetInnerHTML={{
                  __html: unescape(props.description.html.replace("?", "")),
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </MDBTabPane>
        <MDBTabPane tabId="2" role="tabpanel">
          <div className="custom-item-prima-web-drawing-wrapper">
            <img
              src={props?.custom_item_prima_web_drawing}
              alt="custom_item_prima_web_drawing"
            />
          </div>
        </MDBTabPane>
      </MDBTabContent>
    </>
  );
};

export default ProductDimensions;
