import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import DefaultBilling from "../accountDashboard/defaultBilling";
import DefaultShipping from "../accountDashboard/defaultShipping";
import AdditionalEntries from "./additionalEntries";

const AddressBookContent = () => {
  const { getCountryById, customer } = useCustomer();
  const country = getCountryById(
    typeof customer.addresses !== "undefined"
      ? customer?.addresses[0]?.country_code
      : "US"
  );

  let additionalEntries = customer?.addresses?.filter((address) => {
    if (!address.default_shipping && !address.default_billing) {
      return address;
    }
  });
  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol size={12} className="dashboard-title d-flex align-items-center">
        <h3 className="main-title-account address-main-title-margins h3">
          Address Book
        </h3>
      </MDBCol>
      <MDBRow className="m-0  d-flex align-items-stretch justify-content-center customer-information-main-row">
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
          <div className="mb-5 mt-5 description-text-address">
            <h6 className="mb-1 h6">Default Billing Address</h6>
            <DefaultBilling customer={customer} country={country} />
          </div>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
          <div className="mb-5 mt-5 description-text-address">
            <h6 className="mb-1 h6">Default Shipping Address</h6>
            <DefaultShipping customer={customer} country={country} />
          </div>

          <AdditionalEntries
            customer={customer}
            additionalEntries={additionalEntries}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AddressBookContent;
