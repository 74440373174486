import React from "react";
import { withRouter } from "react-router-dom";
import ProductQueryContainer from "./query-container";

const ProductStateContainer = (props) => {
  let productSku = props?.sku

  let productUrl = props.url;

  return (
    <ProductQueryContainer
      {...props}
      productSku={productSku}
      productUrl={productUrl}
    />
  );
};

export default withRouter(ProductStateContainer);
