import React, { useState } from "react";
import { fragmentContentById } from "utils/fragmentContentById";
import { CmsDataFragment } from "./privacy-policy-query-container";
import {
  MDBBox,
  MDBTypography,
  MDBContainer,
  MDBCard,
  MDBIcon,
  MDBCollapseHeader,
  MDBCollapse,
  MDBCardBody,
} from "mdbreact";
import Back from "app/assets/images/privacy-back.png";
import SeoHelmet from "app/layout/seoHelmet";

const PrivacyPolicy = ({ data, contentMap }) => {
  let [activeCollapse, setActiveCollapse] = useState(0);
  const content = {
    title: fragmentContentById("privacy_title", data, contentMap),
    background: Back,
    accordion: [
      {
        heading: fragmentContentById("privacy_first_heading", data, contentMap),
        text: fragmentContentById("privacy_first_text", data, contentMap),
        visible: true,
      },
      {
        heading: fragmentContentById(
          "privacy_second_heading",
          data,
          contentMap
        ),
        text: fragmentContentById("privacy_second_text", data, contentMap),
        visible: false,
      },
      {
        heading: fragmentContentById("privacy_third_heading", data, contentMap),
        text: fragmentContentById("privacy_third_text", data, contentMap),
        visible: false,
      },
      {
        heading: fragmentContentById(
          "privacy_fourth_heading",
          data,
          contentMap
        ),
        text: fragmentContentById("privacy_fourth_text", data, contentMap),
        visible: false,
      },
      {
        heading: fragmentContentById("privacy_fifth_heading", data, contentMap),
        text: fragmentContentById("privacy_fifth_text", data, contentMap),
        visible: false,
      },
      {
        heading: fragmentContentById("privacy_sixth_heading", data, contentMap),
        text: fragmentContentById("privacy_sixth_text", data, contentMap),
        visible: false,
      },
    ],
  };
  return (
    <MDBBox className="privacy-policy-outer">
      <SeoHelmet title={"Privacy Policy"} url={"/privacy-policy"} />
      <MDBBox
        className="privacy-policy"
        style={{
          background: "url(" + content.background + ") left top no-repeat",
        }}
      >
        <MDBContainer>
          <MDBTypography tag="h1" variant="h1">
            <CmsDataFragment content={content.title} />
          </MDBTypography>
          <MDBBox className="md-accordion">
            {content.accordion.map((card, index) => {
              let isOpen = activeCollapse === index;
              return (
                <MDBCard key={index}>
                  <MDBCollapseHeader
                    onClick={() => {
                      setActiveCollapse(index);
                      if (isOpen) {
                        setActiveCollapse(false);
                      }
                    }}
                    tagClassName="d-flex justify-content-between"
                  >
                    <CmsDataFragment content={card.heading} />
                    <MDBIcon icon={isOpen ? "minus" : "plus"} />
                  </MDBCollapseHeader>
                  <MDBCollapse isOpen={isOpen}>
                    <MDBCardBody>
                      <CmsDataFragment content={card.text} />
                    </MDBCardBody>
                  </MDBCollapse>
                </MDBCard>
              );
            })}
          </MDBBox>
        </MDBContainer>
      </MDBBox>
    </MDBBox>
  );
};

export default PrivacyPolicy;
