import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";

import themeConfiguration from "config/themeConfiguration";
import Price from "core/components/price";
import { isUndefined } from "lodash";
import Icon from "app/assets/icon/icon";
// import uuid from "react-uuid";
import PrimaLogo from "app/assets/images/prima-logo.png";
class Pdf extends React.Component {
  constructor(props) {
    super(props);

    this.state = { images: [] };
  }
  componentDidMount() {
    this.props.products.map((item) => {
      let image = new Image();
      image.src = item.product.image.url;
      this.setState((state) => {
        const images = [...state.images, image];

        return {
          images: images,
        };
      });

      return item;
    });
  }

  render() {
    const showPdfPersonal = this.props.showPdfPersonal;
    const contactName = this.props.contactName;
    const contactEmail = this.props.contactEmail;
    const contactCompany = this.props.contactCompany;
    const contactPhone = this.props.contactPhone;
    const pdfMessage = this.props.pdfMessage;
    const pdfFormat = this.props.pdfFormat;
    const pdfPricing = this.props.pdfPricing;
    const pdfPricingMarkup = this.props.pdfPricingMarkup;
    const products = this.props.products;
    const attributeOptions = this.props.attributeOptions;

    let hasDimensions = false;
    let colSize = 12;
    let prodDisplay = "column";
    let heading = 2;
    let imageHeight = "810px";
    let layoutClass = "one";
    let descriptionBullets = true;
    let smallFont = "22px";
    let largeFont = "32px";

    switch (pdfFormat) {
      case 2:
        colSize = 12;
        prodDisplay = "row";
        imageHeight = "initial";
        imageHeight = "510px";
        heading = 3;
        layoutClass = "two";
        descriptionBullets = true;
        break;
      case 4:
        colSize = 6;
        imageHeight = "300px";
        heading = 3;
        layoutClass = "four";
        break;
      case 9:
        colSize = 4;
        heading = 4;
        imageHeight = "200px";
        layoutClass = "nine";
        // smallFont = "1rem";
        // largeFont = "1.2rem";
        break;
      default:
        colSize = 12;
        descriptionBullets = true;
    }
    let pages = chunkArray(products, pdfFormat);

    // Mapping products per page
    let sizeOptions = [];
    if (typeof attributeOptions !== "undefined") {
      sizeOptions = attributeOptions.attribute_options;
    }

    return (
      <MDBContainer fluid className="pdf-one-container bg-white">
        {pages.map((page) => {
          return (
            <MDBRow
              style={{
                height: "100%",
                pageBreakAfter: "always",
                backgroundColor: "white",
              }}
              //   key={uuid()}
            >
              <MDBCol className="d-flex h-100 mh-100 flex-column">
                <MDBRow className="pdf-one-header">
                  <MDBCol className="py-5 d-flex border-bottom justify-content-center align-content-center mb-4">
                    <img src={PrimaLogo} alt="logo" />
                  </MDBCol>
                </MDBRow>
                {showPdfPersonal === true && (
                  <MDBRow className="pdf-contact border-bottom py-3 px-5 text-center">
                    <MDBCol size="3">
                      <p>{contactName}</p>
                    </MDBCol>
                    <MDBCol size="3">
                      <p>{contactCompany}</p>
                    </MDBCol>
                    <MDBCol size="3">
                      <p>{contactEmail}</p>
                    </MDBCol>
                    <MDBCol size="3">
                      <p>{contactPhone}</p>
                    </MDBCol>
                  </MDBRow>
                )}
                <MDBRow className="pdf-one-main flex-grow-1 w-90 mx-auto">
                  <MDBCol size="12">
                    <MDBRow>
                      {page.map((product, index) => {
                        if (
                          typeof product.product !== "undefined" &&
                          typeof product.product.height !== "undefined" &&
                          typeof product.product.depth !== "undefined" &&
                          typeof product.product.width !== "undefined"
                        ) {
                          hasDimensions = true;
                        }
                        return (
                          <MDBCol
                            size={`${colSize}`}
                            className="mb-5"
                            //   key={uuid()}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                              }}
                              href={`${themeConfiguration.app_url}${
                                "/" + product.product.url_key
                              }`}
                            >
                              <MDBRow
                                style={{
                                  marginBottom:
                                    pdfFormat === 9 ? "100px" : "150px",
                                }}
                              >
                                {prodDisplay === "column" && (
                                  <MDBCol
                                    className={
                                      "d-flex mb-1 flex-column justify-content-center align-content-center"
                                    }
                                  >
                                    {heading === 2 && (
                                      <h2
                                        className="pdf-product-name text-center"
                                        style={{
                                          height: "5rem",
                                          minHeight: "5rem",
                                          fontWeight: "bold",
                                          fontSize: "2.5rem",
                                        }}
                                      >
                                        {product.product.name}
                                      </h2>
                                    )}
                                    {heading === 3 && (
                                      <h3
                                        className="pdf-product-name text-center"
                                        style={{
                                          height: "5rem",
                                          minHeight: "5rem",
                                          fontWeight: "bold",
                                          fontSize: "2.5rem",
                                        }}
                                      >
                                        {product.product.name}
                                      </h3>
                                    )}
                                    {heading === 4 && (
                                      <h4
                                        className="pdf-product-name text-center"
                                        style={{
                                          height: "5rem",
                                          minHeight: "5rem",
                                          fontWeight: "bold",
                                          fontSize: "2.5rem",
                                        }}
                                      >
                                        {product.product.name}
                                      </h4>
                                    )}
                                    {/* <Lines className={"line w-90 mx-auto"} /> */}
                                  </MDBCol>
                                )}
                                <MDBCol
                                  size={prodDisplay === "column" ? "12" : "6"}
                                  className={`pdf-print-product-image d-flex justify-content-center align-content-center ${layoutClass}`}
                                >
                                  <img
                                    style={{
                                      height: imageHeight,
                                      width: "auto",
                                    }}
                                    // className="img-fluid"
                                    src={product.product.image.url}
                                    alt={"product"}
                                  />
                                  {/* {typeof this.state.images[index] !==
                                    "undefined" && this.state.images[index]} */}
                                </MDBCol>
                                {prodDisplay === "column" && (
                                  <MDBCol size="12" className="px-0 my-3">
                                    <MDBBox
                                      tag="div"
                                      className="separator mw-90 w-90 mx-auto"
                                    ></MDBBox>
                                  </MDBCol>
                                )}
                                <MDBCol className="w-100 px-0 d-flex flex-column justify-content-center">
                                  {prodDisplay === "row" && (
                                    <MDBRow className="w-90">
                                      <MDBCol
                                        size="12"
                                        className={"d-flex my-0 flex-column"}
                                      >
                                        <h2
                                          className="pb-0 pdf-productname"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <b>{product.product.name}</b>
                                        </h2>
                                      </MDBCol>
                                    </MDBRow>
                                  )}
                                  {/* {hasDimensions === true && (
                                    <MDBRow className="w-90 py-3 mx-auto">
                                      <MDBCol
                                        size="12"
                                        className={`my-0 px-0 align-content-center`}
                                      >
                                        <span>{product.product.width}"W</span>
                                        {" x "}
                                        <span>{product.product.depth}"D</span>
                                        {" x "}
                                        <span>{product.product.height}"H</span>
                                      </MDBCol>
                                    </MDBRow>
                                  )} */}
                                  {!isUndefined(
                                    product.product.description
                                  ) && (
                                    <MDBRow>
                                      <MDBCol
                                        size="12"
                                        className={
                                          pdfFormat === 4 || pdfFormat === 9
                                            ? "d-flex justify-content-center"
                                            : ""
                                        }
                                      >
                                        <div
                                          className={`pdf-product-specification ${layoutClass} ${
                                            pdfPricing !== "hide" &&
                                            "price-shown"
                                          }`}
                                        >
                                          {/* {pdfPricing === "show" && (
                                            <div className="pricepdf">
                                              <p
                                                className="mr-5"
                                                style={{
                                                  width: "250px",
                                                  display: "inline",
                                                  fontSize: largeFont
                                                }}
                                              >
                                                <b>PRICE</b>
                                              </p>
                                              <span>
                                                <Price
                                                  price={
                                                    product.product.price_range
                                                      .minimum_price
                                                      .regular_price.value
                                                  }
                                                  onlyValues={true}
                                                  type={"fixed"}
                                                />
                                              </span>
                                            </div>
                                          )} */}
                                          {/* {pdfPricing === "markup" && (
                                            <div className="pricepdf">
                                              <p
                                                className="mr-5"
                                                style={{
                                                  width: "250px",
                                                  display: "inline",
                                                  fontSize: largeFont
                                                }}
                                              >
                                                <b>PRICE</b>
                                              </p>
                                              <span>
                                                <Price
                                                  price={
                                                    product.product.price_range
                                                      .minimum_price
                                                      .regular_price.value *
                                                    pdfPricingMarkup.value
                                                  }
                                                  onlyValues={true}
                                                  type={"fixed"}
                                                />
                                              </span>
                                            </div>
                                          )} */}
                                          <div
                                            style={{
                                              marginLeft:
                                                pdfFormat === 1 && "180px",
                                            }}
                                          >
                                            <p
                                              className="mr-5"
                                              style={{
                                                display: "inline",
                                                width: "250px",
                                                fontSize: smallFont,
                                              }}
                                            >
                                              <b>SKU</b>
                                            </p>
                                            <span
                                              style={{ fontSize: smallFont }}
                                            >
                                              {product.product.sku}
                                            </span>
                                          </div>
                                          {product.product.super_attributes &&
                                            product.product.super_attributes.map(
                                              (sp, index) => (
                                                <div key={index}>
                                                  <strong>{sp.label}</strong>
                                                  <span className="ml-3">
                                                    {sp.value}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        {/* {((colSize === 6 || colSize === 4) &&
                                          product.product.description.html
                                            .length > 100 && (
                                            // <MDBBox
                                            //   tag="p"

                                            // >
                                            <div
                                              className={`pdf-product-description ${layoutClass}`}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  product.product.description
                                                    .html,
                                              }}
                                            ></div>
                                            // </MDBBox>
                                          )) || (
                                          // <MDBBox
                                          //   tag="p"
                                          //   className={`pdf-product-description ${layoutClass}`}
                                          // >
                                          <div
                                            className={`pdf-product-description ${layoutClass}`}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                product.product.description
                                                  .html,
                                            }}
                                          ></div>
                                          // </MDBBox>
                                        )} */}
                                      </MDBCol>
                                    </MDBRow>
                                  )}
                                  {/*<MDBRow>
                                    <MDBCol>
                                      <li>
                                          <span>Size</span>
                                          <span>
                                            {typeof sizeOptions !==
                                              "undefined" &&
                                              sizeOptions.map(
                                                (option, index) => (
                                                  <div
                                                    key={`details-information-${index}`}
                                                  >
                                                    {sizeOptions[index]
                                                      .value ===
                                                      product.product.size && (
                                                      <div>
                                                        {
                                                          sizeOptions[index]
                                                            .label
                                                        }
                                                      </div>
                                                    )}
                                                  </div>
                                                )
                                              )}
                                          </span>
                                        </li> 
                                      {product.product.catalog_page !==
                                          "undefined" && (
                                          <li>
                                            <span>Catalog Page</span>
                                            <span>
                                              {product.product.catalog_page !==
                                                "undefined" &&
                                                product.product.catalog_page}
                                            </span>
                                          </li>
                                        )} 
                                    </MDBCol>
                                  </MDBRow>*/}
                                </MDBCol>
                              </MDBRow>
                            </a>
                          </MDBCol>
                        );
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                {pdfMessage && (
                  <MDBRow>
                    <MDBCol
                      size="12"
                      className="border-top footer text-center px-5 py-3"
                    >
                      <p>{pdfMessage}</p>
                    </MDBCol>
                  </MDBRow>
                )}
              </MDBCol>
            </MDBRow>
          );
        })}
      </MDBContainer>
    );

    function chunkArray(myArray, chunk_size) {
      const arrayLength = myArray.length;
      let tempArray = [];

      for (let index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
      }

      return tempArray;
    }
  }
}

export default Pdf;
