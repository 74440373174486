import {
  SET_EMAIL,
  SET_NAME,
  SET_COMPANY,
  SET_PHONE,
  SET_MESSAGE,
  SET_EMAIL_ERROR,
  SET_NAME_ERROR,
  SET_EMAIL_ERROR_MSG,
  SET_NAME_ERROR_MSG,
} from "./actions";

export const initialState = {
  email: "",
  name: "",
  company: "",
  phone: "",
  message: "",

  emailError: false,
  nameError: false,

  emailErrorMsg: "",
  nameErrorMsg: "",
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL:
      return { ...state, email: payload };
    case SET_NAME:
      return { ...state, name: payload };
    case SET_COMPANY:
      return { ...state, company: payload };
    case SET_PHONE:
      return { ...state, phone: payload };
    case SET_MESSAGE:
      return { ...state, message: payload };

    case SET_EMAIL_ERROR:
      return { ...state, emailError: payload };
    case SET_NAME_ERROR:
      return { ...state, nameError: payload };

    case SET_EMAIL_ERROR_MSG:
      return { ...state, emailErrorMsg: payload };
    case SET_NAME_ERROR_MSG:
      return { ...state, nameErrorMsg: payload };

    default:
      return state;
  }
};
