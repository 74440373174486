import React, { memo } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import _ from "lodash";
import ProductWidget from "app/layout/productWidget";
import { Link } from "react-router-dom";
import PrimaSpinner from "app/layout/primaSpinner";

const SearchResults = memo(
  ({ data, loading, setDisplaySearch, tmpSearch }) => {

    if (!data) return <></>;
    if (loading) {
      return (
        <MDBContainer className="text-center mt-5">
          <PrimaSpinner />
        </MDBContainer>
      );
    }
    if (data?.length === 0 && !loading)

      return (
        <MDBContainer
          fluid
          id={`search-results-container`}
          className={`no-results text-center mt-5`}
        >
          <MDBRow>
            <MDBCol className="no-results-found-search">
              <MDBTypography tag="p">No Results Found</MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );

    return (

      <MDBContainer fluid id={`search-results-container`}>
        <MDBRow>
          <MDBCol>
            <MDBTypography tag="h1">Suggested Results</MDBTypography>
          </MDBCol>
        </MDBRow>
        <MDBRow className={`search-widget-wrapper`}>
          <MDBCol
            className={`search-product-widget ${
              data?.length === 1
                ? "one"
                : data?.length === 2
                ? "two"
                : data?.length === 3
                ? "three"
                : ""
            }`}
          >
            <ProductWidget
              view={"grid"}
              parentSize="2"
              items={data?.slice(0, 4)}
              // onClick={(e) => {
              //   setDisplaySearch(false);
              // }}
              labels={false}
              showWishlist={false}
              setDisplaySearch={setDisplaySearch}
            />
          </MDBCol>
        </MDBRow>
        {data?.length > 4 || (data?.length >= 3 && window.innerWidth <= 991) ? (
          <MDBRow className={"view-all-wrapper"}>
            <MDBCol className={`mt-5 ml-0`}>
              <Link
                onClick={(e) => {
                  setDisplaySearch(false);
                }}
                to={`/search-results?search=` + encodeURI(tmpSearch)}
                className={"view-all text-uppercase"}
              >
                View All
              </Link>
            </MDBCol>
          </MDBRow>
        ) : null}
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchResults;
