import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Logo from "app/layout/logo/logo";

const Header = () => {
  return (
    <MDBRow>
      <MDBCol>
        <Logo mobileLogo={false} />
      </MDBCol>
    </MDBRow>
  );
};

export default Header;
