import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { Link, useHistory } from "react-router-dom";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import DashboardNav from "../../dashboard/dashboardNav";
import WishlistContent from "./wishlistContent";

const WishlistPage = () => {
  const history = useHistory();
  const { customer } = useCustomer();
  const [activeItem, setActiveItem] = useState(5);
  const [navHeight, setNavHeight] = useState(5);
  const onClickHandler = () => setActiveItem(5);

  useEffect(() => {
    let dashboard = document.querySelectorAll("#navigation-no-mobile > li > a");
    dashboard.forEach((link) => {
      if (link.classList.contains("active-link")) {
        link.classList.remove("active-link");
      }
    });

    dashboard[dashboard?.length - 1].classList.add("active-link");
    return () => {
      dashboard[dashboard?.length - 1].classList.remove("active-link");
    };
  }, [history.location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      const navbarHeight = document.querySelector(
        ".wishlist-container .products-list"
      );
      const projectsDivHeight = document.querySelector(
        ".wishlist-container > .wishlist-projects"
      );
      setNavHeight(navbarHeight ? navbarHeight : projectsDivHeight);
    }, 100);
  }, [activeItem]);

  return (
    <MDBContainer
      id={`account-dashboard-container`}
      fluid
      className="m-0 dashboard-container"
    >
      <MDBRow className="m-0 p-0 justify-content-between align-items-stretch">
        <MDBCol
          xl="3"
          lg="3"
          md="4"
          sm="12"
          xs="12"
          className="m-0 p-0 main-navbar-account"
          style={{
            height:
              activeItem === 5 && navHeight?.clientHeight >= 1139
                ? `${navHeight?.clientHeight + 350}px`
                : "1139px",
          }}
        >
          <DashboardNav
            activeItem={activeItem}
            onClickHandler={onClickHandler}
          />
        </MDBCol>
        <MDBCol xl="9" lg="9" md="8" sm="12" xs="12" className="m-0 p-0">
          <WishlistContent />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default WishlistPage;
