import React from "react";
import { MDBContainer } from "mdbreact";
import BannerSectionQueryContainer from "./banner-section-query-container";

const BannerSection = () => {
  return (
    <MDBContainer fluid className="p-0">
      <BannerSectionQueryContainer />
    </MDBContainer>
  );
};

export default BannerSection;
