import React from "react";

const Video = ({ videoUrl }) => {
  return (
    <>
      <div className="home-page-video-wrapper">
        <video
          loop
          autoPlay={true}
          type="video/mp4"
          playsInline
          muted
          src={videoUrl}
        ></video>
      </div>
    </>
  );
};

export default Video;
