import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import CustomerInformation from "./customerInformation";
import DefaultBilling from "./defaultBilling";
import Newsletter from "./newsletter";
import DefaultShipping from "./defaultShipping";
import { Link } from "react-router-dom";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const AccountDashboardContent = () => {
  const { customer } = useCustomer();

  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol
        size={12}
        className="pl-sm-5 dashboard-title d-flex align-items-center"
      >
        <h3 className="pl-sm-4 h3">Account Dashboard</h3>
      </MDBCol>
      <MDBRow className="m-0 p-0 pl-sm-5 d-flex align-items-stretch justify-content-around account-dashboard-content">
        <MDBCol md="6">
          <CustomerInformation customer={customer} />
        </MDBCol>
        <MDBCol md="6">
          <Newsletter customer={customer} />
        </MDBCol>
      </MDBRow>
      <MDBRow className="m-0 p-0 pl-sm-5 d-flex align-items-stretch justify-content-around account-dashboard-content">
        <MDBCol xl="6" lg="6" md="6" sm="12" xs="12">
          <div className="d-flex flex-direction-row">
            <h5 className="mb-5 h5">Address Book</h5>
            <Link className="ml-4 mt-2" to={"/customer/address-book"}>
              <b>
                <u style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                  MANAGE ADDRESSES
                </u>
              </b>
            </Link>
          </div>

          <h6 className="mb-1 h6">Default Billing Address</h6>
          <DefaultBilling customer={customer} />
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="12" xs="12" >
          <div className="fill-gap"></div>
          <h6 className="mb-5 h6">Default Shipping Address</h6>
          <DefaultShipping customer={customer} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AccountDashboardContent;
