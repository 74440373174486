import React, {useEffect} from "react";
import Header from "./header";
import Footer from "./footer";
// import Misc from "./misc";
import Modal from "core/components/modal";
import { default as DynamicRouter } from "app/overrides/router";
import { ModalProvider } from "core/components/modal/ModalContext";
import ModalWrapper from "app/layout/modal/ModalWrapper";

// Global application style
import "@fortawesome/fontawesome-free/css/all.min.css";

// Configuration loader
import ConfigurationLoader from "app/layout/configurationLoader";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withApollo } from "react-apollo";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SessionMessages from "core/components/sessionMessages";
import { MiniCartProvider } from "app/state/hooks/cartHooks/MinicartContext";
import {Switch, useHistory} from "react-router-dom";

library.add(fab, fas);

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for every page on the website, it's content controls how any page will be rendered.
// It is used to bootstrap the header, footer, modals, and misc components, as well as provide a point of insertion for the content of any page based on
// the current URL.
// This file should not be edited unless you are making a change that should effect the layout of all pages
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Layout = (props) => {

  return (
    <ModalProvider {...props}>
      <MiniCartProvider>
        <ConfigurationLoader />
        <Header />
        <div className={`session-container`}>
          <SessionMessages target="global" /> {/* SESSION MESSAGES */}
        </div>
        <div className={"main"}>

            <DynamicRouter /> {/* CONTENT */}

        </div>
        <Modal ModalWrapper={ModalWrapper} />
        <Footer />
      </MiniCartProvider>
    </ModalProvider>
  );
};

export default withApollo(Layout);
