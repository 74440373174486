import React from "react";
import { useSelector } from "react-redux";
import {
  MDBBox,
  MDBTypography,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBListGroup,
  MDBLink,
} from "mdbreact";
import "./sitemap.scss";
import background from "app/assets/images/site-map-back.jpg";
import SeoHelmet from "app/layout/seoHelmet";

const Sitemap = ({ customerService }) => {
  const storedData = useSelector((state) => {
    return state?.queries?.data;
  });
  if (Object.keys(storedData).length === 0) {
    return null;
  }
// console.log("ljubica.sitemap");
//   console.log(customerService?.category["children"]);
  const headerData =
    storedData[1]?.data && storedData[1].data?.category["children"];
  const footerData =
    storedData[15]?.data && storedData[15].data.categoryList[0]?.children;
  const customerServiceData = customerService?.category["children"] ;

  return (
    <MDBBox className="site-map">
      <SeoHelmet title={"Site Map"} url={"/site-map"} />
      <MDBBox
        className="site-map-container"
        style={{
          background: "url(" + background + ") center top no-repeat",
        }}
      >
        <MDBContainer>
          <MDBTypography tag="h1" variant="h1" className="ml-sm-4">
            Site Map
          </MDBTypography>
          <MDBRow className="justify-content-between row-container" between>
            {headerData &&
              headerData
                .filter((item) => item.include_in_menu && item.is_explore)
                .map((group, index) => (
                  <MDBCol key={index}>
                    <MDBTypography tag="h2" variant="h4">
                      {group.name}
                    </MDBTypography>
                    <MDBListGroup className="vertical-list">
                      {group.children.map((link, index) => (
                        <li key={index}>
                          <MDBLink to={`/${link.url_path}`}>
                            {link.name}
                          </MDBLink>
                        </li>
                      ))}
                    </MDBListGroup>
                  </MDBCol>
                ))}
            <MDBCol className="justify-content-center">
              <MDBTypography tag="h2" variant="h4">
                Replacement Parts
              </MDBTypography>
              <MDBListGroup className="vertical-list">
                {headerData &&
                  headerData
                    .filter((item) => !item.include_in_menu && item.is_explore)
                    .slice(1)
                    .map((item, index) => (
                      <MDBCol key={index}>
                        <MDBTypography className="second-heading">
                          {item.name}
                        </MDBTypography>
                        <MDBListGroup className="vertical-list">
                          {item.children.map((link, index) => (
                            <li key={index}>
                              <MDBLink to={`/${link.url_path}`}>
                                {link.name}
                              </MDBLink>
                            </li>
                          ))}
                        </MDBListGroup>
                      </MDBCol>
                    ))}
              </MDBListGroup>
            </MDBCol>
          </MDBRow>
          <MDBRow className="justify-content-center ml-sm-5 pl-5 row-container">
            <MDBCol>
              <MDBLink to="/portfolio" style={{ textTransform: "capitalize" }}>
                <MDBTypography tag="h2" variant="h4">
                  Portfolio
                </MDBTypography>
              </MDBLink>
            </MDBCol>
            <MDBCol lg="4" md="6">
              <MDBTypography tag="h2" variant="h4">
                {customerServiceData?.name}
              </MDBTypography>
              <MDBListGroup className="vertical-list">
                {customerServiceData &&
                  customerServiceData.map((group, index) => (
                    <li key={index}>
                      <MDBLink to={`/${group.url_path}`}>{group.name}</MDBLink>
                    </li>
                  ))}
              </MDBListGroup>
            </MDBCol>
            <MDBCol lg="4" md="6">
              <MDBTypography tag="h2" variant="h4">
                Footer Links
              </MDBTypography>
              <MDBListGroup className="vertical-list">
                {footerData &&
                  footerData.map((group, index) => (
                    <li key={index}>
                      <MDBLink to={`/${group.url_path}`}>{group.name}</MDBLink>
                    </li>
                  ))}
              </MDBListGroup>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBBox>
    </MDBBox>
  );
};

export default Sitemap;
