import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";
import Wishlist from "app/layout/wishlist";

const ProductItemSliderView = (props) => {
  let { item } = props;
  return (
    <MDBContainer className="product-slider-view-container">
      <MDBRow className="product-item-header-row">
        <MDBCol className={`product-new-label`}></MDBCol>
        <MDBCol className={`wishlist-product-widget`}>
          <Wishlist product={{ ...item }}>
            <Icon icon="wishlist_empty_heart" />
          </Wishlist>
        </MDBCol>
      </MDBRow>
      <MDBRow className="product-item-image-row">
        <MDBCol lg="12" className="product-item-image-column">
          <Link onClick={props.onClick} to={"/" + item.url_key}>
            <img
              alt="product-slider-item"
              className={
                item.image &&
                item.image.url &&
                item.image.url.includes("placeholder") &&
                "placeholder"
              }
              src={
                item.image &&
                item.image.url &&
                item.image.url.includes("placeholder")
                  ? "/placeholder.png"
                  : item.image && item.image.url
              }
            />
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="product-item-info-row">
        {/* <label className="product-item-info-triangle"></label> */}
        <MDBCol lg="12">
          <h5>{item.name}</h5>
        </MDBCol>
        <MDBRow lg="12">
          <MDBCol>
            <p>SKU {item.sku}</p>
          </MDBCol>
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProductItemSliderView;
