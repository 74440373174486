import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const ExploreSectionImage = () => {
  return <CmsBlock id={"explore_section_image"} />;
};

export const ExploreSectionFindRepText = () => {
  return <CmsBlock id={"explore_section_find_rep_text"} />;
};

export const ExploreSectionFindRepLink = () => {
  return <CmsBlock id={"explore_section_find_rep_link"} />;
};

export const ExploreSectionFindRepImage = () => {
  return <CmsBlock id={"explore_section_find_rep_image"} />;
};

export const ExploreSectionText = () => {
  return <CmsBlock id={"explore_section_text"} />;
};

export const ExploreSectionLink = () => {
  return <CmsBlock id={"explore_section_link"} />;
};

export const ExploreSectionCatalogText = () => {
  return <CmsBlock id={"explore_section_catalog_text"} />;
};

export const ExploreSectionCatalogImage = () => {
  return <CmsBlock id={"explore_section_catalog_image"} />;
};

export const ExploreSectionCatalogLink = () => {
  return <CmsBlock id={"explore_section_catalog_link"} />;
};
