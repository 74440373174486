import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import FooterDataContainer from "./data-container";
import {useSelector} from "react-redux";
import {SalesRepNewsModal} from "../login/salesRepNewsModal";

const MENU_QUERY = gql`
  query Category($id: Int) {
    category(id: $id) {
      id
      name
      include_in_menu
      url_path
      position
      description
      image
      products {
        total_count
      }
      children {
        id
        name
        image
        url_key
        url_path
        include_in_menu
        position
        description
        products {
          total_count
        }
        children {
          id
          name
          image
          url_key
          url_path
          include_in_menu
          position
          description
          products {
            total_count
          }
          children {
            id
            name
            image
            url_key
            url_path
            include_in_menu
            position
            description
            products {
              total_count
            }
          }
        }
      }
    }
  }
`;

const FooterQueryContainer = (props) => {



  return (
      <>


        <GraphQlWrapper
            query={MENU_QUERY}
            variables={{ id: "1" }}
            saveQuery={true}
        >

          <FooterDataContainer toggleSearch={props.toggleSearch} />
        </GraphQlWrapper>
      </>

  );
};

export default FooterQueryContainer;
