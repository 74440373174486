import gql from "graphql-tag";

export const GET_CART = gql`
  query getCart($cart_id: String!) {
    cart(cart_id: $cart_id) {
      id
      items {
        id
        quantity
        product {
          id
          name
          sku
          image {
            url
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER_CART = gql`
  {
    customerCart {
      id
    }
  }
`;

export const COUNTRIES = gql`
  {
    countries {
      available_regions {
        code
        id
        name
      }
      full_name_english
      full_name_locale
      id
      three_letter_abbreviation
      two_letter_abbreviation
    }
  }
`;

export const STATES = gql`
  {
    getAllRepLocators {
      country
      email
      fax
      firstname
      image
      industry
      lastname
      notes
      phone
      position
      show_in_grid
      state
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods($cart_id: String!) {
    cart(cart_id: $cart_id) {
      available_payment_methods {
        code
        title
      }
    }
  }
`;

export const COUNTRY = gql`
  query getCountryById($id: String) {
    country(id: $id) {
      available_regions {
        code
        id
        name
      }
      full_name_english
      full_name_locale
      id
      three_letter_abbreviation
      two_letter_abbreviation
    }
  }
`;

export const CUSTOMER = gql`
  {
    customer {
      addresses {
        city
        company
        country_code

        default_billing
        default_shipping
        extension_attributes {
          attribute_code
          value
        }
        fax
        firstname
        id
        lastname
        middlename
        postcode
        prefix
        region {
          region
          region_code
        }
        street
        suffix
        telephone
        vat_id
      }
      created_at
      date_of_birth
      default_billing
      default_shipping
      email
      firstname
      gender
      is_subscribed
      lastname
      middlename
      prefix
      suffix
      taxvat
      wishlist {
        cartId
        id
        items {
          added_at
          cart_ready
          description
          id
          message
          qty
        }
        items_count
        name
        sharing_code
        updated_at
      }
      wishlistByName {
        cartId
        id
        items {
          added_at
          cart_ready
          description
          id
          message
          qty
        }
        items_count
        name
        sharing_code
        updated_at
      }
    }
  }
`;

export const LIFESTYLE_IMAGES = gql`
  query getLifestyleImages($ids: [String]) {
    cmsBlocks(identifiers: $ids) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const RELATED_PRODUCTS = gql`
  query getRelatedProducts($category_ids: [String]) {
    products(filter: { category_id: { in: $category_ids } }) {
      items {
        name
        sku
        url_key
        url_path
        categories {
          name
          id
        }
        pi_product_category
        image {
          url
        }
        price_range {
          maximum_price {
            regular_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;
