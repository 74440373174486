import React, { useEffect, useCallback, useState } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { useHistory } from "react-router-dom";
import placeholder from "app/assets/images/placeholder.png";
import SingleProduct from "app/pages/catalog/category/listing/singleProduct";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";

const WishlistProjects = ({ projects }) => {
  const history = useHistory();
  const [project, setProject] = useState(null);

  const [, , , , , , wishlist, , , , ,] = useWishlist();
  const {
    data: { wishlistItems },
  } = wishlist;

  useEffect(() => {
    if (history.location.search === "") {
      projects = projects.map((project) => {
        if (project.items.length === 1) {
          let items = [{}, {}, {}];
          project.items = project.items.concat(items);
        } else if (project.items.length === 2) {
          let items = [{}, {}];
          project.items = project.items.concat(items);
        } else if (project.items.length === 3) {
          let items = [{}];
          project.items = project.items.concat(items);
        }
      });
    }
  });

  const gotToSingleProjectPage = (projectId) => {
    history.push({ search: `projectId=${projectId}` });
  };

  useEffect(() => {
    const project = projects?.find(
      (project) =>
        project?.id === history.location.search.split("projectId=")[1]
    );
    setProject(project);
  }, [history.location.search]);

  const getItemsByProjectId = useCallback(() => {
    const project = projects?.find(
      (project) =>
        project?.id === history.location.search.split("projectId=")[1]
    );
    let items = [];
    if (project) {
      project.items.map((item) => {
        if (Object.keys(item).length > 0) {
          items.push(item);
        }
      });
    }
    return items;
  }, [history.location.search, wishlistItems]);

  return (
    <MDBContainer className="wishlist-projects">
      {history.location.search === "" ? (
        <div className="projects">
          {projects.map((project, i) => (
            <MDBRow
              className="project-wrapper cursor-pointer"
              onClick={() => gotToSingleProjectPage(project.id)}
            >
              {project?.items.length > 0 ? (
                project?.items.map((item) => (
                  <MDBCol size="6" className="col-box">
                    <div className="box-img">
                      {item?.product?.image.url ? (
                        <img
                          src={item?.product?.image.url || placeholder}
                          className={`img-fluid`}
                        />
                      ) : null}
                    </div>
                  </MDBCol>
                ))
              ) : (
                <MDBCol
                  size="12"
                  style={{ height: "100%", minHeight: "314px" }}
                >
                  <p className="mt-4">
                    There are no products currently in this project.
                  </p>
                </MDBCol>
              )}
              <div className="d-flex w-100 justify-content-between mt-3 mb-3 align-items-center">
                <h5 className="mb-0">{project.name}</h5>
                <p>
                  {project.items_count}
                  <span className="ml-3">
                    {project.items_count == 1 && "item"}
                    {project.items_count > 1 && "items"}
                  </span>
                </p>
              </div>
            </MDBRow>
          ))}
        </div>
      ) : (
        <div className="project-list">
          <MDBContainer id={`listing`}>
            <MDBRow className={`products-list`}>
              {getItemsByProjectId().length > 0 &&
                getItemsByProjectId().map(({ product }) => (
                  <SingleProduct
                    image={product?.small_image || product?.image}
                    name={product?.name}
                    sku={product?.sku}
                    url={product?.url_key}
                    product={product}
                    isWishlist={true}
                    projectName={project?.name}
                  />
                ))}
              {getItemsByProjectId().length == 0 && (
                <p className="mt-4 mb-4">
                  There are no products currently in this project.
                </p>
              )}
            </MDBRow>
          </MDBContainer>
        </div>
      )}
    </MDBContainer>
  );
};

export default WishlistProjects;
