import gql from "graphql-tag";

export const GET_CATEGORY_SECTION = gql`
  query {
    getCategoryExplore {
      title
      url
      image
      position
      widget_image
      is_textual_block
    }
  }
`;
