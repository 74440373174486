import React, { useEffect } from "react";
import { MDBCol } from "mdbreact";
import ProductItemGridView from "./productItemViewTemplates/productItemGridView";
import ProductItemSliderView from "./productItemViewTemplates/productItemSliderView";

export const useProductLayout = (props) => {
  function getListItemView(props) {
    let { parentSize } = props;
    return (
      <MDBCol size={parentSize} className={`product-list-item product-item`}>
        product item list view
      </MDBCol>
    );
  }

  function GetGridItemView(props) {
    let { item } = props;
    preloadImagesForAllItems(item);

    return (
      <React.Fragment key={props.keys}>
        <ProductItemGridView {...props} />
      </React.Fragment>
    );
  }

  function GetSliderItemView(props) {
    let { item } = props;
    preloadImagesForAllItems(item);

    return (
      <React.Fragment key={props.keys}>
        <ProductItemSliderView {...props} />
      </React.Fragment>
    );
  }

  return [GetGridItemView, getListItemView, GetSliderItemView];
};

function PreloadImages(urls) {
  useEffect(() => {
    urls.map((url) => {
      new Image().src = url;
    });
  }, []);
}

const preloadImagesForAllItems = (item) => {
  let allUpsellImages = [];
  if (typeof item.upsell_products !== "undefined") {
    allUpsellImages = item.upsell_products.map((upsell) => {
      return upsell.image.url;
    });

    PreloadImages(allUpsellImages);
  }
};
