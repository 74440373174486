import apolloClient from "core/graphql/apolloClient";
import commonActions from "../../common/actions";
import queryActions from "core/state/redux/data/queries/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";

export default async (store, action) => {
  if (!action.email) {
    store.dispatch(
      messagesActions.addMessage("Please enter email address.", "danger")
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("generateResetPasswordToken"),
      variables: { email: action.email },
    });

    if (data) {
      store.dispatch(
        messagesActions.addMessage(
          data.generateResetPasswordToken.message,
          data.generateResetPasswordToken.code
        )
      );
      store.dispatch(commonActions.unlock());
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
