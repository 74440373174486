import React, { useState } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import PortfolioCarousel from "./portfolioCarousel";

const PortfolioModal = ({ activeImage, index, images, hideModal }) => {
  const [toggleModal, setToggleModal] = useState(true);
  const toggle = () => {
    setToggleModal(!toggleModal);
  };
  return (
    <MDBContainer className="portfolio-gallery-modal">
      <MDBModal
        isOpen={toggleModal}
        centered
        toggle={(e) => {
          toggle();
        }}
        size="lg"
        contentClassName="portfolio-content"
        modalClassName="portfolio-dialog"
        wrapClassName="portfolio-wrap"
        backdropClassName="portfolio-backdrop"
      >
        <MDBModalHeader toggle={toggle} className="mb-0 pb-0">
          {/* <MDBTypography className="download-text">Save Image</MDBTypography> */}
          {/* <a href={imgUrl} download target="_blank" rel="noopener noreferrer">
            <MDBBox
              style={{ background: `url(${download})` }}
              className="download modal-download"
            />
          </a> */}
        </MDBModalHeader>
        <MDBModalBody>
          <PortfolioCarousel
            activeImage={activeImage}
            images={images}
            index={index}
            hideModal={hideModal}
          />
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default PortfolioModal;
