import { useDispatch } from "react-redux";
import { customerActions } from "app/state/redux/data/customer";

function useCart() {
  const dispatch = useDispatch();
  const getCartFromMagento = () => {
    dispatch(customerActions.getCartInformation());
  };

  return { getCartFromMagento };
}
export { useCart };
