import React, { useEffect, useState } from "react";
import "./lightedMirrors.scss";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTooltip,
  MDBTypography,
  toast,
  ToastContainer,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import SeoHelmet from "../../../layout/seoHelmet";
import ProductNameSection from "../product-name-section";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import RelatedProducts from "../related-products/relatedProducts-query-container";
import useCustomer from "../../../state/hooks/customerHooks/useCustomer";
import { Msg } from "../messages";

import QuoteRequestWishlistButton from "../quote-request-button";
import DownloadSection from "../downloadSection";
import Icon from "../../../assets/icon/icon";

const LightedMirrors = ({ customerGroupId, ...props }) => {
  let colorPickerValue = "";
  let questions = ["Arkansas"];
  const { isLoggedIn } = useCustomer();
  const [addToWishlist, , , , , , wishlist, , , , , ,] = useWishlist();
  const [activeCatalogNoPossition, setActiveCatalogNoPossition] = useState(0);
  const [activeCatalogNo, setActiveCatalogNo] = useState("");
  let chosenProduct = Array.isArray(props?.items)
    ? props?.items?.find((el) => el?.product?.sku === activeCatalogNo)
        ?.product || props?.items[0]
    : props;

  useEffect(
    () => chosenProduct && setActiveCatalogNo(chosenProduct?.sku),
    [chosenProduct]
  );

  const [fullscreen, setFullscreen] = useState(false);
  const [activeTab, setActiveTab] = useState("custom_sizes");
  const [customHeight, setCustomHeight] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeightError, setCustomHeightError] = useState(false);
  const [customWidthError, setCustomWidthError] = useState(false);
  const [enteredCustomDimensions, setEnteredCustomDimensions] = useState(false);

  const selectedCustomizableOptionId = [
    {
      option_id: chosenProduct?.id,
      required: false,
      sort_order: 1,
      title: "selected_options",
      __typename: "CustomizableFieldOption",
    },
  ];

  const customProd = () => {
    if (props.__typename.includes("GroupedProduct")) {
      var simpleProducts = props?.items;
      var configurableProductSku = props?.sku.includes("X")
        ? props?.sku.substring(0, props?.sku.length - 1)
        : props?.sku.split("-")[0];
      return simpleProducts.find(
        (item) => item.product?.sku === configurableProductSku
      );
    }
    return props;
  };

  const [customizableProduct, setCustomizablePoduct] = useState(customProd());

  const catalogNoMap = (
    Array.isArray(props?.items) && props?.items
      ? props?.items?.map((a) => {
          return a;
        })
      : [props]
  )?.sort((a, b) => {
    const skuDimensionA = a?.product?.sku.split("-")[1];
    const skuDimensionB = b?.product?.sku.split("-")[1];
    return +skuDimensionA - +skuDimensionB;
  });

  let productToAddToWishlist = {
    products: [{ sku: props.sku }],
    wishlists: [{ name: "My Favorites" }],
  };

  const wishlistItems =
    wishlist?.data?.wishlistItems && wishlist?.data?.wishlistItems[0]?.items;

  const isInWishlist = wishlistItems?.filter(
    (item) => item.product?.sku === props?.sku
  );

  const handleAddToWishlist = () => {
    addToWishlist(productToAddToWishlist);
    toast(<Msg />);
  };

  let skusAndPrices = [
    {
      sku: activeTab === "custom_sizes" ? props.sku : chosenProduct?.sku,
      price: chosenProduct?.price_range?.minimum_price?.regular_price?.value,
    },
  ];

  let selectedOptionsMap = [
    "Arkansas",
    {
      question: "Catalog No.",
      name: chosenProduct?.name,
      generated_sku: skusAndPrices,
      catalogNumber: chosenProduct?.name,
      arkansasSalesDescription: chosenProduct?.ark_custitem_prima_sales_desc,
    },
  ];

  const CreateImageLink = (image) => {
    var a = document.createElement("a");
    a.href = image;
    a.rel = "noopener noreferrer";
    a.target = "_blank";
    a.download = chosenProduct?.image?.url;
    a.click();
  };

  const save3DImage = (e) => {
    CreateImageLink(chosenProduct?.image?.url);
  };

  const [specificationEmpty, setspecificationEmpty] = useState(true);

  useEffect(() => {
    if (
      !chosenProduct?.ark_main_color &&
      !chosenProduct?.ark_shade_dimensions &&
      !chosenProduct?.ark_material_type &&
      !chosenProduct?.ark_shade_material &&
      !chosenProduct?.ark_primary_finish &&
      !chosenProduct?.ark_power_cord &&
      !chosenProduct?.ark_voltage &&
      !chosenProduct?.ark_bulb_wattage &&
      !chosenProduct?.ark_base &&
      !chosenProduct?.ark_custitem_ul_rating
    ) {
      setspecificationEmpty(false);
    }
  }, []);

  const DimensionBox = ({ sku, inStock, index }) => {
    if (inStock === 0) return null;
    if (props.sku == sku + "X") return null;
    var skuDimension = sku.split("-")[1];
    var width = skuDimension.substr(0, 2);
    var height = skuDimension.substr(2, 2);

    return (
      <button
        className={`dimension-box ${activeCatalogNo === sku ? "selected" : ""}`}
        onClick={() => {
          setActiveCatalogNo(sku);
          const idx = catalogNoMap?.findIndex((el) => el?.product?.sku === sku);
          setActiveCatalogNoPossition(idx);
        }}
      >
        <p className="dimension-value">
          W {width}' x H {height}'
        </p>
        <p className="inStock-value">In Stock: {inStock}</p>
      </button>
    );
  };

  const SectionWrapper = ({ label, value }) => {
    if (value !== null && value?.trim() !== "") {
      return (
        <>
          <MDBCol md="6" sm="6" xs="6">
            <p>
              <b>{label}</b>
            </p>
          </MDBCol>
          <MDBCol md="6" sm="6" xs="6">
            <p>{value}</p>
          </MDBCol>
        </>
      );
    }
    return "";
  };

  const setActiveItem = (product) => {
    var productInCatalogNoMap = catalogNoMap.find((item) =>
      item.product ? item.product == product : item == product
    );
    var indexOfCustomSizeItem = catalogNoMap.indexOf(productInCatalogNoMap);
    setActiveCatalogNo(product.sku);
    setActiveCatalogNoPossition(indexOfCustomSizeItem);
  };

  useEffect(() => {
    if (activeTab === "custom_sizes") {
      setActiveItem(
        customizableProduct.product
          ? customizableProduct.product
          : customizableProduct
      );
    } else {
      var firstAvailableItem = customizableProduct.product
        ? catalogNoMap.find(
            (item) => Math.floor(item.product.ark_quantityavailable) !== 0
          )
        : catalogNoMap[0];
      setActiveItem(
        firstAvailableItem.product
          ? firstAvailableItem.product
          : firstAvailableItem
      );
    }
  }, [activeTab]);

  var applyHandler = () => {
    if (customHeight.trim().length === 0) {
      setCustomHeightError(true);
    }
    if (customWidth.trim().length === 0) {
      setCustomWidthError(true);
    }
    if (customHeight.trim().length !== 0 && customWidth.trim().length !== 0) {
      setCustomHeightError(false);
      setCustomWidthError(false);
      return setEnteredCustomDimensions(true);
    }
  };

  return (
    <MDBContainer
      style={{ scrollBehavior: "smooth" }}
      fluid
      className="text-center text-md-left typography-wrapper w-100 ml-0 mr-0 pl-0 pr-0"
      id="product-page-container"
    >
      <SeoHelmet />
      <ToastContainer autoClose={300} hideProgressBar />
      <MDBRow className="breadcrumbs-row w-100 no-gutters">
        <MDBCol size="12" lg="12">
          <ProductNameSection
            categories={props?.categories}
            name={props?.name}
            // catalogNoMap={catalogNoMap}
          />
        </MDBCol>
        <MDBCol size="12" className="product-name-and-3D-icon">
          <MDBTypography tag="h1" className="h2 mb-2">
            {props?.name}
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow className="no-gutters main-row-pdp ml-0 mr-0 pl-0 pr-0 w-100">
        <MDBCol
          size="13"
          lg="4"
          md="12"
          sm="12"
          className="top-left-section p-0 ml-0 mr-0 pl-0 pr-0"
        >
          <MDBRow className="m-0">
            <button
              className={`pdp-button-tab ${
                activeTab === "custom_sizes" ? "active" : ""
              }`}
              onClick={() => setActiveTab("custom_sizes")}
            >
              CUSTOM SIZES
            </button>
            <button
              className={`pdp-button-tab ${
                activeTab === "stock_sizes" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTab("stock_sizes");
                setEnteredCustomDimensions(false);
                setCustomHeight("");
                setCustomWidth("");
              }}
            >
              IN STOCK SIZES
            </button>
          </MDBRow>
          {activeTab === "stock_sizes" ? (
            <div className="dimension-box-container">
              {!props?.items &&
              Math.floor(props.ark_quantityavailable) === 0 ? (
                <p>No available sizes.</p>
              ) : props?.items ? (
                catalogNoMap
                  ?.filter((el) => el?.product?.sku)
                  .map((item, index) => {
                    if (!item?.product?.ark_custitem_prima_sales_desc) return;
                    return (
                      <DimensionBox
                        sku={item.product.sku}
                        inStock={Math.floor(item.product.ark_quantityavailable)}
                        index={index}
                      />
                    );
                  })
              ) : (
                <DimensionBox
                  sku={props.sku}
                  inStock={Math.floor(props.ark_quantityavailable)}
                  index={0}
                />
              )}
            </div>
          ) : !enteredCustomDimensions ? (
            <MDBCol
              className="mx-0 px-0 custom-dimensions-form"
              style={{ paddingBottom: "2.2rem" }}
            >
              <MDBInput
                type="text"
                value={customWidth}
                name="custom-width"
                placeholder="Enter Width"
                label="WIDTH"
                labelClass="custom-size-input-label"
                className={`custom-size-input ${customWidthError ? "err" : ""}`}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setCustomWidth(e.target.value);
                    setCustomWidthError(false);
                  }
                }}
              />
              <MDBInput
                type="text"
                value={customHeight}
                name="custom-height"
                placeholder="Enter Height"
                label="HEIGHT"
                labelClass="custom-size-input-label"
                className={`custom-size-input ${
                  customHeightError ? "err" : ""
                }`}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setCustomHeight(e.target.value);
                    setCustomHeightError(false);
                  }
                }}
              />
              <MDBRow className="mx-0 pdp-buttons-row">
                <MDBBtn
                  className="pdp-custom-size-btn apply"
                  onClick={() => {
                    applyHandler();
                  }}
                >
                  APPLY
                </MDBBtn>
                <MDBBtn
                  className="pdp-custom-size-btn cancel"
                  onClick={() => {
                    setEnteredCustomDimensions(false);
                    setCustomHeight("");
                    setCustomWidth("");
                  }}
                >
                  CANCEL
                </MDBBtn>
              </MDBRow>
            </MDBCol>
          ) : (
            <MDBRow className="mx-0 px-0 custom-dimensions-entered">
              <p className="custom-size-txt">CUSTOM SIZE:</p>
              <p className="custom-size-values">
                {" "}
                W {customWidth}' x H {customHeight}'
              </p>
              <button
                className="edit-custom-dimensions"
                onClick={() => {
                  setEnteredCustomDimensions(false);
                  setCustomWidth(customWidth);
                  setCustomHeight(customHeight);
                }}
              >
                EDIT
              </button>
            </MDBRow>
          )}
          {/*Item Number*/}
          <MDBRow
            className="sku-price-wrapper no-gutters m-0 pl-0 w-100 pr-0"
            style={{
              borderTop: "1px solid #e4e4e7",
              paddingTop: "2.2rem",
              paddingBottom: "2.2rem",
            }}
          >
            <span className="catalog text-bold">ITEM NUMBER: </span>
            <span className="catalog ml-3"> {activeCatalogNo}</span>
          </MDBRow>
          {/*Description*/}
          <MDBRow
            className="sku-price-wrapper no-gutters m-0 pl-0 w-100 pr-0"
            style={{
              borderBottom: "1px solid #e4e4e7",
              paddingBottom: "2.2rem",
            }}
          >
            <span className="pb-3 w-100 catalog text-bold">DESCRIPTION: </span>
            {activeTab !== "stock_sizes" &&
            !enteredCustomDimensions ? null : enteredCustomDimensions ? (
              <p className="custom-size-values">
                {" "}
                {customWidth}"W x {customHeight}"H Prima Lighting’s custom
                mirrors are tailored to your preferences. Specifications will
                vary.
              </p>
            ) : (
              <span className="catalog">
                {chosenProduct?.ark_custitem_prima_sales_desc}
              </span>
            )}
          </MDBRow>
          {chosenProduct?.ark_turnaround_code === "S" ? (
            <MDBRow
              className="sku-price-wrapper no-gutters m-0 pl-0 w-100 pr-0"
              style={{ paddingTop: "2.2rem" }}
            >
              <span className="catalog text-bold">SHIPPING CODE: </span>
              <span className="catalog ml-3 mr-1"> SPECIAL ORDER </span>
              <MDBTooltip
                placement={"right"}
                domElement
                tag="span"
                clickable={true}
                isVisible={true}
              >
                <div>
                  <Icon role={`top`} icon={`info`} />
                </div>
                <div>
                  <span>
                    {/*<CmsBlock id={"tooltip_special_code"} />*/}
                    Special Order, lead times are 90-120 days with ocean freight
                    included.
                  </span>
                </div>
              </MDBTooltip>
            </MDBRow>
          ) : (
            <></>
          )}

          {/* <MDBRow className="sku-price-wrapper no-gutters mx-0 mb-0 px-0 w-100"  style={{ borderBottom: "1px solid #e4e4e7", paddingBottom: "2.2rem", paddingTop: "1.2rem"}}>
                        <span className="catalog text-bold">MINIMUM ORDER QTY: </span>
                        <span className="catalog  ml-3"> {chosenProduct?.ark_min_order_qty}</span>
                    </MDBRow> */}
          <MDBRow
            className="sku-price-wrapper no-gutters m-0 pl-0 w-100 pr-0"
            style={{ paddingTop: "2.2rem", paddingBottom: "0.7rem" }}
          >
            <MDBCol className="item-number">
              <span className="catalog text-bold">ITEM NUMBER: </span>
              <span className="catalog"> {activeCatalogNo}</span>
            </MDBCol>
            <MDBCol>
              {isLoggedIn && customerGroupId === 4 ? (
                <>
                  {chosenProduct?.ark_closeout_price !== null &&
                  chosenProduct?.ark_closeout &&
                  chosenProduct?.ark_closeout === 1 ? (
                    <p style={{ textAlign: "right", fontSize: "1.9rem" }}>
                      <span style={{ color: "#f29822" }} className="mr-2">
                        Clearance!
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontSize: "1.4rem",
                          textDecorationLine: "line-through",
                        }}
                      >
                        $
                        {
                          chosenProduct?.price_range?.minimum_price
                            ?.regular_price?.value
                        }
                      </span>{" "}
                      $
                      {parseFloat(chosenProduct?.ark_closeout_price).toFixed(2)}
                    </p>
                  ) : (
                    <></>
                  )}
                  {activeTab !== "custom_sizes" &&
                    chosenProduct?.price_tiers &&
                    Object.keys(chosenProduct?.price_tiers).length === 0 &&
                    chosenProduct?.ark_closeout_price === null &&
                    !chosenProduct?.ark_closeout &&
                    chosenProduct?.ark_closeout === 0 && (
                      <p style={{ textAlign: "right", fontSize: "2.6rem" }}>
                        $
                        {chosenProduct?.price_range?.minimum_price?.regular_price?.value.toFixed(
                          2
                        )}
                      </p>
                    )}
                </>
              ) : (
                <></>
              )}
            </MDBCol>
            {activeTab !== "custom_sizes" &&
              isLoggedIn &&
              customerGroupId === 4 &&
              chosenProduct?.ark_closeout_price === null &&
              !chosenProduct?.ark_closeout &&
              chosenProduct?.ark_closeout === 0 &&
              chosenProduct?.price_tiers.length !== 0 && (
                <>
                  <MDBTable className="availability-table-ark" responsive fixed>
                    <MDBTableHead>
                      <tr>
                        {chosenProduct?.price_tiers &&
                          chosenProduct?.price_tiers?.map((tiers) => {
                            return (
                              <th
                                className="text-bold"
                                style={{ fontSize: "1.2rem" }}
                              >
                                <b>QTY {tiers?.quantity}+</b>
                              </th>
                            );
                          })}
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        {chosenProduct?.price_tiers &&
                          chosenProduct?.price_tiers?.map((tiersP) => {
                            return (
                              <th style={{ fontSize: "1.2rem" }}>
                                ${tiersP?.final_price?.value.toFixed(2)}
                              </th>
                            );
                          })}
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                  <span
                    className="catalog"
                    style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  >
                    *Freight allowance not included. Please ask for a freight
                    quote.{" "}
                  </span>
                </>
              )}
          </MDBRow>

          <QuoteRequestWishlistButton
            arkansasDescrption={
              chosenProduct?.ark_custitem_prima_sales_desc
                ? chosenProduct?.ark_custitem_prima_sales_desc
                : false
            }
            selectedOptionsMap={selectedOptionsMap}
            questions={questions}
            isLoggedIn={isLoggedIn}
            handleAddToWishlist={handleAddToWishlist}
            isInWishlist={isInWishlist}
            customerGroupId={customerGroupId}
            sku={activeTab === "custom_sizes" ? props.sku : chosenProduct?.sku}
            catalogNumbers={
              activeTab === "custom_sizes" ? props.sku : chosenProduct?.sku
            }
            selectedCustomizableOptionId={selectedCustomizableOptionId}
            genratedSku={skusAndPrices}
            catalogNumber={
              activeTab === "custom_sizes" ? props.sku : chosenProduct?.sku
            }
            // lengthOfQuestions={lengthOfQuestions}
            // lengthOfSelectedOptions={lengthOfSelectedOptions}
            skusAndPrices={skusAndPrices}
            // getCanopyPrice={props.getCanopyPrice}
            colorPickerValue={colorPickerValue}
            // colorPickerRALValue={colorPickerRALValue}
            customHeight={activeTab === "custom_sizes" ? customHeight : null}
            customWidth={activeTab === "custom_sizes" ? customWidth : null}
            customProductOptions={
              customHeight && customWidth
                ? customizableProduct.product
                  ? customizableProduct.product.options[0]
                  : customizableProduct.options[0]
                : null
            }
          />
          <div
            style={{
              borderBottom: "1px solid #1F1E3F",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          ></div>

          <DownloadSection
            activeCatalogNo={chosenProduct?.sku}
            selectedOptionsMap={selectedOptionsMap}
            skusAndPrices={skusAndPrices}
            data={chosenProduct}
            save3DImage={save3DImage}
          />
        </MDBCol>
        {/*HERE STARTS MAIN IMAGE DISPLAY*/}

        <MDBCol
          size="12"
          lg="7"
          md="12"
          sm="12"
          className="main-image custom-shadow top-middle-section ml-0 mr-0 pl-md-4 pr-md-4"
        >
          {chosenProduct?.ark_closeout && chosenProduct?.ark_closeout === 1 ? (
            <div className={"closeout-item"}>Clearance</div>
          ) : null}
          <img
            onClick={() => {
              setFullscreen(!fullscreen);
            }}
            className={`product-image ${fullscreen ? "fullscreen-canvas" : ""}`}
            src={chosenProduct?.image?.url}
            alt=""
          />
          {fullscreen ? (
            <MDBBox
              className="close-fullscreen-img"
              onClick={() => {
                setFullscreen(!fullscreen);
              }}
            >
              <Icon icon={"minimize"} width="30px" height="30px" />
            </MDBBox>
          ) : (
            <MDBBox
              className="enlarge-pdp"
              onClick={() => {
                setFullscreen(!fullscreen);
              }}
            >
              <Icon icon={"enlarge"} />
            </MDBBox>
          )}
        </MDBCol>
      </MDBRow>
      {specificationEmpty && (
        <div className="description-wrapper description-wrapper-arkansas px-md-5 px-sm-3">
          <MDBRow
            className="data-row no-gutters"
            id="identitySpefificationSeciton"
          >
            <MDBCol
              md="12"
              lg="12"
              className="m-0 parameters-section-arkansas p-0 w-100"
            >
              <h3 style={{ color: "#211F44" }}>Specification</h3>
            </MDBCol>

            <MDBCol className="description-and-size-wrapper" md="12" lg="6">
              <MDBRow className="no-gutters mt-5 ">
                <MDBCol md="12" sm="12">
                  <MDBRow
                    className="no-gutters parameters-section-wrapper"
                    style={{ textAlign: "left" }}
                  >
                    <SectionWrapper
                      label={"Main Color"}
                      value={chosenProduct?.ark_main_color}
                    />
                    <SectionWrapper
                      label={"Shade Dimension"}
                      value={chosenProduct?.ark_shade_dimensions}
                    />
                    <SectionWrapper
                      label={"Material Type"}
                      value={chosenProduct?.ark_material_type}
                    />
                    <SectionWrapper
                      label={"Shade Material"}
                      value={chosenProduct?.ark_shade_material}
                    />
                    <SectionWrapper
                      label={"Finish"}
                      value={chosenProduct?.ark_primary_finish}
                    />
                    <SectionWrapper
                      label={"UL Rating"}
                      value={chosenProduct?.ark_custitem_ul_rating}
                    />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol className="description-and-size-wrapper " md="12" lg="6">
              <MDBRow className="no-gutters mt-5">
                <MDBCol md="12" sm="12" className="">
                  <MDBRow
                    className="no-gutters parameters-section-wrapper"
                    style={{ textAlign: "left" }}
                  >
                    <SectionWrapper
                      label={"Power cord"}
                      value={chosenProduct?.ark_power_cord}
                    />
                    <SectionWrapper
                      label={"Voltage"}
                      value={chosenProduct?.ark_voltage}
                    />
                    <SectionWrapper
                      label={"Bulb wattage"}
                      value={chosenProduct?.ark_bulb_wattage}
                    />
                    <SectionWrapper
                      label={"Base"}
                      value={chosenProduct?.ark_base}
                    />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      )}
      {props.categories ? (
        <RelatedProducts currentCategory={props.categories} />
      ) : (
        <></>
      )}
    </MDBContainer>
  );
};

export default LightedMirrors;
