import React from "react";
import gql from "graphql-tag";
import GraphQlQueryWrapper from "core/components/graphQlWrapper";
import VideoTutorial from "./videoTutorial";

const VIDEO_TUTORIAL = gql`
  query cmsBlock($id: String!) {
    cmsBlocks(identifiers: [$id]) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

const VideoTutorialQueryContainer = () => {
  return (
    <GraphQlQueryWrapper
      query={VIDEO_TUTORIAL}
      variables={{ id: "pdp_video_tutorial" }}
    >
      <VideoTutorial />
    </GraphQlQueryWrapper>
  );
};

export default VideoTutorialQueryContainer;
