import React from "react";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";

const RepCard = (props) => {
  let locators = props.repLocators;
  let firstname = locators?.firstname === null ? "" : locators?.firstname;
  let lastname = locators?.lastname === null ? "" : locators?.lastname;

  return (
    <>
      <MDBRow className={`find-rep-card d-flex justify-content-center py-3`}>
        <MDBCol md="12">
          <MDBBox tag="h2" className="icon-wrapper">
            {locators?.image.length === 0 ? (
              <></>
            ) : (
              // <Icon icon={"repLocatorUser"} />
              <img src={locators?.image} alt="rep-icon" />
            )}
          </MDBBox>
        </MDBCol>
        <MDBCol
          md="4"
          className={`first-col ${
            locators?.image.length === 0 &&
            "no-company-logo d-flex flex-column justify-content-center"
          }`}
        >
          <MDBBox tag="h2">
            {locators?.company === null ? "" : locators?.company}
          </MDBBox>
          <MDBBox tag="h2" style={{ fontSize: "1.4rem" }}>
            {firstname + " " + lastname}
          </MDBBox>
        </MDBCol>
        <MDBCol md="4" className="second-col">
          <MDBBox tag="h6">
            {locators?.address === null ? "" : locators?.address}
          </MDBBox>
          <MDBBox tag="h6">
            {}
            {locators?.city === null
              ? ""
              : locators?.city + ", " + locators.state_code}
          </MDBBox>
          <MDBBox tag="h6">{locators.postcode}</MDBBox>
        </MDBCol>
        <MDBCol md="4" className="third-col d-flex flex-column">
          <a href={locators?.website} target="__blank">
            {locators?.website
              ?.replace("https://", "")
              .replace("http://", "")
              .replace("www.", "")}
          </a>
          <a target="__blank" href={`tel:${locators.phone}`}>
            {locators.phone}
          </a>
          <a target="__blank" href={`mailto:${locators?.email}`}>
            {locators?.email}
          </a>
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default RepCard;
