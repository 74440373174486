import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import QuoteRequestDataContainer from "./data-container";
import { messagesActions } from "core/state/redux/data/messages";
import EmptyQuoteRequest from "./emptyQuoteRequest/emptyQuoteRequest";

const QuoteRequestReduxContainer = (props) => {
  if (
    props.cart === undefined ||
    (props.customer.data === "undefined" &&
      props.customer.data.cart === "undefined") ||
    (typeof props.customer.data.cart !== "undefined" &&
      typeof props.customer.data.cart.items !== "undefined" &&
      props.customer.data.cart.items.length === 0)
  ) {
    return <EmptyQuoteRequest />;
  }
  return (
    <>
      <QuoteRequestDataContainer {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cartToken !== "undefined"
      ? state.customer.data
      : undefined,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    removeItem: (id, callback = () => {}) => {
      dispatch(customerActions.removeProductFromCart(id)).then(() => {
        callback();
      });
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
    clearCart: () => {
      dispatch(customerActions.clearCart());
    },
    setShippingAddress: (shipping_address) => {
      dispatch(customerActions.setShippingAddressOnCart(shipping_address));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
    logoutCustomer: () => {
      dispatch(customerActions.logoutCustomer());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestReduxContainer);
