import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import Slider from "react-slick";
import { MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";

const SLIDER_QUERY = gql`
  {
    slider(name: "Slider 2") {
      autoplay
      slide_animation
      slide_list
      slides {
        has_overlay
        length
        media
        product_media
        name
        online_video
        overlay_cta_label
        overlay_cta_link
        overlay_text
        overlay_title
        video
        product_description
        product_url
      }
    }
  }
`;

const Slides = (props) => {
  const sliderData = props && props.data && props.data.slider;
  if (!sliderData) return null;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: sliderData.autoplay,
    autoplaySpeed: 7000,
    speed: 2800,
    // cssEase: "linear",
    // slidesToShow: 1,
    variableWidth: false,
    draggable: false,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    easing: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          swipe: false,
        },
      },
    ],
  };

  return (
    <Slider {...sliderSettings} className="home-banner-slider">
      {sliderData.slides.map((slide, index) => {
        return (
          <MDBRow key={index} className="mobile-reverse">
            <MDBCol lg="3" md="6" className="p-0 slider-left">
              <MDBBox className="product-part">
                <MDBBox className="d-flex justify-content-center align-items-center image-part">
                  <img src={slide.media} alt="" className="img-fluid" />
                </MDBBox>
                <MDBBox className="text-part d-flex justify-content-center">
                  <MDBTypography tag="h6" variant="h6">
                    {slide.overlay_text}
                  </MDBTypography>
                  <p className="product-description">
                    {slide.product_description}
                  </p>
                  <a
                    className="view-product-link"
                    href={slide.product_url}
                    rel="noreferrer noopener"
                    target={`${
                      slide?.product_url?.includes("https://www")
                        ? "_blank"
                        : ""
                    }`}
                  >
                    VIEW PRODUCT
                  </a>
                </MDBBox>
              </MDBBox>
            </MDBCol>
            <MDBCol lg="3" md="6" className="p-0 slider-middle">
              <MDBBox className="title-part d-flex justify-content-center">
                <MDBTypography tag="h1" variant="h1">
                  {slide.overlay_title}
                </MDBTypography>
                <a
                  className=" d-flex align-items-center justify-content-center view-more-btn"
                  href={slide.overlay_cta_link}
                  target={`${
                    slide.overlay_cta_link.includes("https://www")
                      ? "_blank"
                      : ""
                  }`}
                >
                  {slide.overlay_cta_label}
                </a>
              </MDBBox>
            </MDBCol>
            <MDBCol lg="6" className="p-0 slider-right">
              <MDBBox className="banner-image">
                <img src={slide.product_media} alt="" className="img-fluid" />
              </MDBBox>
            </MDBCol>
          </MDBRow>
        );
      })}
    </Slider>
  );
};

const BannerSectionQueryContainer = (props) => {
  return (
    <GraphQlWrapper
      query={SLIDER_QUERY}
      variables={{ id: "slider", name: "slider" }}
      saveQuery={true}
    >
      <Slides />
    </GraphQlWrapper>
  );
};

export default BannerSectionQueryContainer;
