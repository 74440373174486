export default (state, action) => {
  let { data } = action;
  let customerState = state;
  customerState.data.addresses.map((address, index) => {
    if (address.id === data.id) {
      return (customerState.data.addresses[index] = data);
    }
    return address;
  });
  return Object.assign({}, state, {
    ...customerState,
  });
};
