import React from "react";
import { useQuery } from "react-apollo-hooks";

import gql from "graphql-tag";
import Sitemap from "./site-map";
import { TemporaryPage } from "app/config/routing/pages/globalPages";

const SITE_MAP = gql`
  query Category($id: Int) {
    category(id: $id) {
      id
      name
      include_in_menu
      url_path
      position
      description
      image
      products {
        total_count
      }
      children {
        id
        name
        image
        url_key
        url_path
        include_in_menu
        position
        description
        products {
          total_count
        }
        children {
          id
          name
          image
          url_key
          url_path
          include_in_menu
          position
          description
          products {
            total_count
          }
          children {
            id
            name
            image
            url_key
            url_path
            include_in_menu
            position
            description
            products {
              total_count
            }
          }
        }
      }
    }
  }
`;

export const SiteMapContainer = () => {
  const { data, loading } = useQuery(SITE_MAP, {
    variables: { id: "33" },
  });
  if (loading) {
    return <TemporaryPage />;
  }
  return <Sitemap customerService={data} />;
};
