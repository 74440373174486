import React from "react";
import { MDBRow, MDBCol, MDBTypography } from "mdbreact";
import { currentStyleFunction } from "./currentStyle";
import { Link } from "react-router-dom";
// import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";

const CategorySection = ({ categorySectionData }) => {
  const isMobile = useMediaQuery({ maxWidth: 991.98 });
  return (
    <MDBRow className="all-categories">
      {isMobile && (
        <MDBCol xl="4" lg="6" sm="12" className="textual-block">
          <MDBTypography tag="h6" variant="h6">
            Explore By Category
          </MDBTypography>
        </MDBCol>
      )}
      {categorySectionData?.map((category) => {
        let isExternalLink;
        if (category.url !== null && category.url.includes("www")) {
          isExternalLink = true;
        }
        let currentPosition = currentStyleFunction(category.position);
        if (category.is_textual_block === "true") {
          return (
            <>
              {!isMobile && (
                <MDBCol xl="4" lg="4" sm="12" className="textual-block">
                  <MDBTypography tag="h6" variant="h6">
                    {/* <Icon icon={"exploreLine"} /> */}
                    {category.title}
                  </MDBTypography>
                </MDBCol>
              )}
            </>
          );
        } else
          return (
            <>
              <MDBCol
                xl="4"
                lg="4"
                sm="12"
                key={category.url}
                className="p-0 single-category"
              >
                <Link
                  fluid
                  to={isExternalLink ? "//" + category.url : category.url}
                  className={`images-holder  ${currentPosition.currentPosition}`}
                  target={`${isExternalLink ? "_blank" : ""}`}
                  rel="noreferrer noopener"
                >
                  <img
                    className="category-main-image"
                    src={category.image}
                    alt=""
                  />
                  <div className="widget-and-title-wrapper">
                    <MDBTypography tag="h6" variant="h6">
                      {category.title}
                    </MDBTypography>
                    {/* originally client wanted this section to have widgets, uncomment this if they change their minds again, also style for this is in _category-section and different_positions    */}
                    {/* <img
                      className={`widget-image`}
                      src={category.widget_image}
                      alt=""
                    /> */}
                  </div>
                </Link>
              </MDBCol>
            </>
          );
      })}
    </MDBRow>
  );
};

export default CategorySection;
