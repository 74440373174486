import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import ProductStateContainer from "./state-container";

const ProductReduxContainer = (props) => {
  return <ProductStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer:
    typeof state.customer.data !== "undefined"
      ? state.customer.data.token
      : false,
  items:
    state.customer.data !== undefined
      ? state.customer.data.cart !== undefined
        ? state.customer.data.cart
          ? state.customer.data.cart.items
          : []
        : []
      : [],
  attributeOptions:
    state.queries !== undefined
      ? state.queries.data !== undefined
        ? typeof state.queries.data.attributes_metadata_information !==
          "undefined"
          ? state.queries.data.attributes_metadata_information.data
              .customAttributeMetadata.items[0]
          : []
        : []
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductReduxContainer);
