import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import ContentDataContainer from "./content-data-container";
import RepCardSkeleton from "./repCardSkeleton";

const REP_LOCATORS_WITH_FILTERS = gql`
  query getRepLocators($filters: RepLocatorFilterInput) {
    getRepLocators(filter: $filters) {
      company
      address
      country
      state
      fax
      firstname
      lastname
      phone
      email
      country
      state
      website
      city
      postcode
      country_code
      state_code
      image
    }
  }
`;

const ContentQueryContainer = (props) => {
  let variables = "";
  variables = {
    state: props.selectState.value,
    country: props.countrySelect.value,
  };
  const { data, loading } = useQuery(REP_LOCATORS_WITH_FILTERS, {
    variables: {
      filters: variables,
    },
    fetchPolicy: "cache-first",
  });

  if (loading)
    return (
      <>
        <p>
          <RepCardSkeleton />
        </p>
      </>
    );

  return <ContentDataContainer data={data} />;
};

export default ContentQueryContainer;
