import React from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBTabPane,
  MDBTabContent,
  MDBNavLink,
  MDBNavItem,
  MDBNav,
  MDBRow,
  MDBCol,
} from "mdbreact";

import "./modal.scss";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";

const Modal = ({
  toggleModal,
  modal,
  handleLoginSubmit,
  onEmailChangeHandler,
  onPasswordChangeHandler,
  email,
  password,
  loading,
  hasEmailError,
  hasPasswordError,
  errorEmailMessage,
  errorPasswordMessage,
  activeItem,
  toggleTabs,
}) => {
  return (
    <MDBContainer className="login-modal-popup-wrapper">
      <MDBModal isOpen={modal} toggle={toggleModal}>
        <MDBModalBody className="loginModal" style={{ position: "relative" }}>
          <MDBContainer
            style={{
              width: "100%",
              margin: "0px",
              padding: "0px",
            }}
          >
            <MDBNav className="nav-tabs mt-5" style={{ width: "100%" }}>
              <MDBNavItem className={activeItem === "1" ? `activeTab` : `tabs`}>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItem === "1"}
                  onClick={() => toggleTabs("1")}
                  role="tab"
                  className="tabsLabel"
                >
                  Login
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem className={activeItem === "2" ? `activeTab` : `tabs`}>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItem === "2"}
                  onClick={() => toggleTabs("2")}
                  role="tab"
                  className="tabsLabel"
                >
                  Register
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent activeItem={activeItem}>
              <MDBTabPane tabId="1" role="tabpanel">
                <MDBRow className="login-tab-wrapper">
                  <MDBCol md="6">
                    <LoginForm
                      handleLoginSubmit={handleLoginSubmit}
                      onEmailChangeHandler={onEmailChangeHandler}
                      onPasswordChangeHandler={onPasswordChangeHandler}
                      email={email}
                      password={password}
                      loading={loading}
                      hasEmailError={hasEmailError}
                      hasPasswordError={hasPasswordError}
                      errorEmailMessage={errorEmailMessage}
                      errorPasswordMessage={errorPasswordMessage}
                      toggleModal={toggleModal}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="login-tab-right-wrapper">
                    <MDBContainer>
                      <div className="mb-5">
                        <p className="mb-2">
                          Are you an existing{" "}
                          <strong style={{ fontWeight: "800" }}>
                            {" "}
                            sales representative{" "}
                          </strong>
                          with Prima Lighting and are accessing our new site for
                          the 1st time? Please reset your password.
                        </p>
                        <Link
                          onClick={() => toggleModal()}
                          to={"/forgot-password"}
                        >
                          Reset Password
                        </Link>
                      </div>
                      <p>
                        If you are new here and do not have an account, please
                        register on the link below
                      </p>
                      <Link to="#" onClick={() => toggleTabs("2")}>
                        Register
                      </Link>
                    </MDBContainer>
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="2" role="tabpanel">
                <RegisterForm toggleModal={toggleModal} />
              </MDBTabPane>
            </MDBTabContent>
          </MDBContainer>
          <Icon
            style={{
              position: "absolute",
              bottom: "3%",
              cursor: "pointer",
              left: "2%",
            }}
            icon="closeSearch"
            onClick={() => {
              toggleModal();
            }}
          />
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default Modal;
