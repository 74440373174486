import React from "react";
import StateContainer from "./state-container";

const FooterDataContainer = (props) => {

  const selectDefaultCategoryListChildren = (props.data &&
      props.data.category &&
      props.data.category.children[1]) || { children: [] };

  return <StateContainer data={selectDefaultCategoryListChildren} />;
};

export default FooterDataContainer;
