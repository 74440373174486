import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import DashboardNav from "../dashboardNav";
import "../style.scss";
import NewsletterSubscriptionContent from "./newsletterSubscriptionContent";

const NewsletterSubscription = (props) => {
  const [activeItem, setActiveItem] = useState(3);
  const onClickHandler = () => setActiveItem(3);

  return (
    <MDBContainer
      id={`account-dashboard-container`}
      fluid
      className="m-0 dashboard-container"
    >
      <MDBRow className="m-0 p-0 justify-content-between align-items-stretch">
        <MDBCol
          xl="3"
          lg="3"
          md="4"
          sm="12"
          xs="12"
          className="m-0 p-0 main-navbar-account"
        >
          <DashboardNav
            activeItem={activeItem}
            onClickHandler={onClickHandler}
          />
        </MDBCol>
        <MDBCol xl="9" lg="9" md="8" sm="12" xs="12" className="m-0 p-0">
          <NewsletterSubscriptionContent {...props} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default NewsletterSubscription;
