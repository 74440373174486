import Icon from "app/assets/icon/icon";
import Accordion from "app/layout/accordion";
import { MDBBox, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";

const FaqListing = ({ getFaq, selectedCategory }) => {
  let data = [];
  data = getFaq.filter(
    (x) => x.mainCategoryInfo.category_id === selectedCategory.category_id
  );
  let downloadText = "Please download PDF files for each step";
  return (
    <>
      <MDBContainer>
        <MDBBox tag="h1">{selectedCategory.category_title}</MDBBox>
        {data.map((item, index) => {
          return (
            <Accordion
              key={item + index + selectedCategory.category_id}
              title={item.title}
            >
              {item.items.map((answer, index) => {
                if (answer.pdf.length === 0) {
                  return (
                    <>
                      <ul>
                        <li className="answer-text">{answer.title}</li>
                      </ul>
                    </>
                  );
                } else {
                  return (
                    <MDBRow className="d-flex flex-column pdf-wrapper">
                      <MDBCol className="pl-5">
                        <MDBRow
                          className={`download-text mb-3 ${
                            index === 0 ? "d-flex" : "d-none"
                          }`}
                        >
                          <MDBCol className="text-left">{downloadText}</MDBCol>
                        </MDBRow>
                        <MDBRow>
                          {answer.pdf.map((pdf) => {
                            return (
                              <MDBCol size="12">
                                <MDBBox className="download-steps-links mt-4 d-">
                                  <a href={pdf.url} download target="__blank">
                                    <Icon
                                      icon={"pdfFaqIcon"}
                                      className="mr-3 "
                                    />
                                    {`Step ${index + 1}:  ${pdf.title}.pdf`}
                                  </a>
                                </MDBBox>
                              </MDBCol>
                            );
                          })}
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  );
                }
              })}
            </Accordion>
          );
        })}
      </MDBContainer>
    </>
  );
};

export default FaqListing;
