import React, { useState } from "react";
import { MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Content from "./content";

const FindARepComponentContainer = (props) => {
  let { countries, industries, states, countriesAndStates } = props;
  const [countrySelect, setCountrySelect] = useState("");
  const [selectState, setSelectState] = useState("");
  const [canSearch, setCanSearch] = useState(false);

  const setSelectStateFn = (evt) => {
    if (evt !== selectState) {
      setCanSearch(false);
    }

    setSelectState(evt);
  };

  const setSelectCountryFn = (evt) => {
    if (evt !== countrySelect) {
      setCanSearch(false);
    }

    setCountrySelect(evt);
  };

  return (
    <MDBCol className="find-a-rep-main-content">
      <MDBCol>
        <Toolbar
          industries={industries}
          countries={countries}
          states={states}
          countrySelect={countrySelect}
          setCountrySelect={setCountrySelect}
          selectState={selectState}
          setSelectState={setSelectState}
          setSelectStateFn={setSelectStateFn}
          setSelectCountryFn={setSelectCountryFn}
          setCanSearch={setCanSearch}
          countriesAndStates={countriesAndStates}
        />
      </MDBCol>

      <MDBCol className="find-a-rep-column pb-3 pt-4">
        <Content
          countrySelect={countrySelect}
          selectState={selectState}
          canSearch={canSearch}
        />
      </MDBCol>
    </MDBCol>
  );
};

export default FindARepComponentContainer;
