import React from "react";
import { useCallback, useState, createContext, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useQuery } from "react-apollo-hooks";
import { GET_ALL_VERENIA_IMPORT_DATA } from "./query";
const VereniaContext = createContext({
  data: [],
});

const VereniaProvider = (props) => {
  const [vereniaData, setVereniaData] = useState(false);
  const { data, loading, error } = useQuery(GET_ALL_VERENIA_IMPORT_DATA, {
    fetchPolicy: "cache-and-network",
    skip: vereniaData !== false,
  });

  const setVereniaDataFn = useCallback(setVereniaData, [setVereniaData]);
  useEffect(() => {
    if (vereniaData === false) {
      setVereniaDataFn((staleData) =>
        data?.getAllVereniaImportData &&
        !isEqual(staleData !== data?.getAllVereniaImportData)
          ? [...data?.getAllVereniaImportData]
          : false
      );
    }
  }, [data, vereniaData]);

  return (
    <VereniaContext.Provider value={{ data: vereniaData }}>
      {props.children}
    </VereniaContext.Provider>
  );
};

export { VereniaContext, VereniaProvider };
