import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBBox,
} from "mdbreact";
import React from "react";
import Icon from "app/assets/icon/icon";
import Button from "app/core/components/button/button";
import { useMediaQuery } from "react-responsive";

const ProjectModal = ({
  modalProject,
  setModalProject,
  removeModaProject,
  setRemoveModalProject,
  setProject,
  project,
  projectName,
  editWishlist,
  setRemoveModalTitle,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  return (
    <MDBContainer className={`project-modal`}>
      <MDBModal
        isOpen={modalProject}
        toggle={(e) => {
          setModalProject(!modalProject);
        }}
        className={"wishlist-modal modal-wrapper"}
        centered
      >
        <MDBModalHeader>
          <div>
            <p className="text-center">Edit Project</p>
            <MDBBox className="close-btn">
              <Icon
                icon="closeIcon"
                onClick={() => {
                  setModalProject(!modalProject);
                }}
              />
            </MDBBox>
          </div>
        </MDBModalHeader>
        {isMobile ? (
          <MDBModalBody className="d-flex w-100 flex-column">
            <MDBRow className="modal-title-wrapper align-items-center w-100">
              <MDBCol md="7">
                <MDBInput
                  type="text"
                  hint={"Enter project name"}
                  group
                  placeholder={"Enter project name"}
                  value={project}
                  label="PROJECT NAME"
                  onChange={(e) => {
                    if (e.target.value.length <= 24) {
                      setProject(e.target.value);
                    }
                  }}
                  onKeyPress={async (event) => {
                    if (event.key === "Enter") {
                      await editWishlist({
                        fromName: projectName,
                        toName: project,
                      });
                      try {
                        projectName = project;
                        setModalProject(!modalProject);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
            <div
              className="cursor-pointer w-100 d-flex align-items-center container justify-content-start"
              style={{ flexDirection: "row-reverse" }}
              onClick={() => {
                setModalProject(!modalProject);
                setRemoveModalTitle("Remove Project");
                setRemoveModalProject(!removeModaProject);
              }}
            >
              <MDBCol md="5">
                <Button
                  className="link w-100 border-bottom d-flex align-items-center justify-content-center mb-0"
                  onClick={async (e) => {
                    await editWishlist({
                      fromName: projectName,
                      toName: project,
                    });
                    try {
                      projectName = project;
                      setModalProject(!modalProject);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  id={"saveProject"}
                  children={"save"}
                />
              </MDBCol>
              <div className="d-flex flex-column align-items-center">
                <Icon
                  icon={"trashSmall"}
                  width={33}
                  height={45}
                  className={`mr-2 cursor`}
                />
                <p className="mt-0 delete-project">DELETE PROJECT</p>
              </div>
            </div>
          </MDBModalBody>
        ) : (
          <MDBModalBody className="d-flex w-100 flex-column">
            <MDBRow className="modal-title-wrapper align-items-center w-100">
              <MDBCol md="7">
                <MDBInput
                  type="text"
                  hint={"Enter project name"}
                  group
                  placeholder={"Enter project name"}
                  value={project}
                  label="PROJECT NAME"
                  onChange={(e) => {
                    if (e.target.value.length <= 24) {
                      setProject(e.target.value);
                    }
                  }}
                  onKeyPress={async (event) => {
                    if (event.key === "Enter") {
                      await editWishlist({
                        fromName: projectName,
                        toName: project,
                      });
                      try {
                        projectName = project;
                        setModalProject(!modalProject);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md="5">
                <Button
                  className="link w-100 border-bottom d-flex align-items-center justify-content-center mb-0"
                  onClick={async (e) => {
                    await editWishlist({
                      fromName: projectName,
                      toName: project,
                    });
                    try {
                      projectName = project;
                      setModalProject(!modalProject);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  id={"saveProject"}
                  children={"save"}
                />
              </MDBCol>
            </MDBRow>
            <div
              className="cursor-pointer w-100 d-flex align-items-center container justify-content-start"
              onClick={() => {
                setModalProject(!modalProject);
                setRemoveModalTitle("Remove Project");
                setRemoveModalProject(!removeModaProject);
              }}
            >
              <Icon
                icon={"trashSmall"}
                width={33}
                height={45}
                className={`mr-2 cursor`}
              />
              <p className="mt-0 delete-project">DELETE PROJECT</p>
            </div>
          </MDBModalBody>
        )}
      </MDBModal>
    </MDBContainer>
  );
};

export default ProjectModal;
