import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const RepCardSkeleton = (props) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {!isMobile ? (
        <MDBRow style={{ marginTop: "7rem" }}>
          <MDBCol md="4">
            <Skeleton width={`100%`} height={`19rem`} />
          </MDBCol>
          <MDBCol md="4">
            <Skeleton width={`100%`} height={`19rem`} />
          </MDBCol>
          <MDBCol md="4">
            <Skeleton width={`100%`} height={`19rem`} />
          </MDBCol>
        </MDBRow>
      ) : (
        <MDBContainer fluid className="p-0">
          <Skeleton width={`100%`} height={`10rem`} />
        </MDBContainer>
      )}
    </>
  );
};

export default RepCardSkeleton;
