import React, { useState, useRef } from "react";
import {
  MDBBox,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBModalFooter,
  MDBBtn,
  MDBCollapse,
  MDBInput,
} from "mdbreact";
import Button from "app/core/components/button/button";
import Select2 from "core/components/select/select";
import Icon from "app/assets/icon/icon";
import Input from "app/core/components/input/input";
import InputField from "core/components/inputField/inputField";
import { useReactToPrint } from "react-to-print";
import Pdf from "./Pdf";

const PdfModal = ({
  showPdfModal,
  togglePdfModal,
  selectedWishlist,
  pdfPricingMarkupOptions,
  attributeOptions,
}) => {
  const pdfComponent = useRef();
  const pdfQtyOfItemsPerPage = [
    {
      size: 1,
      selectedImage: <Icon id="oneSelected" icon="oneSelected" />,
      disabledImage: <Icon id="oneDisabled" icon="oneDisabled" />,
    },
    {
      size: 2,
      selectedImage: <Icon id="twoSelected" icon="twoSelected" />,
      disabledImage: <Icon id="twoDisabled" icon="twoDisabled" />,
    },
    {
      size: 4,
      selectedImage: <Icon id="fourSelected" icon="fourSelected" />,
      disabledImage: <Icon id="fourDisabled" icon="fourDisabled" />,
    },
    {
      size: 9,
      selectedImage: <Icon id="eightSelected" icon="eightSelected" />,
      disabledImage: <Icon id="eightDisabled" icon="eightDisabled" />,
    },
  ];
  const pdfPricingOptions = [
    {
      label: "Show Pricing",
      value: "show",
    },
    {
      label: "Hide Pricing",
      value: "hide",
    },
    {
      label: "Markup Pricing",
      value: "markup",
    },
  ];

  const [showPdfPersonal, setShowPdfPersonal] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [pdfMessage, setPdfMessage] = useState("");
  const [pdfFormat, setPdfFormat] = useState(pdfQtyOfItemsPerPage[0].size);
  const [pdfPricing, setPdfPricing] = useState(pdfPricingOptions[0].value);
  const [pdfPricingMarkup, setPdfPricingMarkup] = useState(
    pdfPricingMarkupOptions[0]
  );
  let pdfOptions = {
    showPdfPersonal,
    contactName,
    contactEmail,
    contactCompany,
    contactPhone,
    pdfMessage,
    pdfFormat,
    pdfPricing,
    pdfPricingMarkup,
  };

  const handlePrint = useReactToPrint({
    content: () => pdfComponent.current,
  });

  const generateDownloadLinkAndOpen = () => {
    handlePrint();
  };

  const togglePdfPersonal = () => {
    setShowPdfPersonal(!showPdfPersonal);
  };

  const myFavorites = selectedWishlist.find(
    (wishlist) => wishlist.name === "My Favorites"
  );

  return (
    <>
      <MDBContainer id={"download-pdf-modal"}>
        <MDBModal
          isOpen={showPdfModal}
          toggle={togglePdfModal}
          className={"modal-wrapper wishlist-modal wishlist-pdf-modal"}
          centered
        >
          <MDBModalHeader>
            <div>
              <p className="text-center">Download Project</p>
              <MDBBox className="close-btn">
                <Icon icon="closeIcon" onClick={togglePdfModal} />
              </MDBBox>
            </div>
          </MDBModalHeader>
          <MDBModalBody className="px-sm-5 pb-1">
            <MDBRow className="w-100 mt-5">
              <MDBCol>
                <div className="" data-toggle="buttons">
                  <MDBInput
                    name="options"
                    type="checkbox"
                    label="Add Contact Information"
                    labelClass="add-contact-info-label"
                    id="option1"
                    onClick={() => {
                      togglePdfPersonal();
                    }}
                  />
                </div>
                <MDBCollapse isOpen={showPdfPersonal} className="mt-3">
                  <MDBRow>
                    <MDBCol>
                      <InputField
                        className={"contact-name-input input my-1"}
                        placeholder={"Name"}
                        group
                        type="text"
                        value={contactName}
                        onChange={(e) => {
                          setContactName(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol>
                      <InputField
                        className={"contact-email-input input my-1"}
                        placeholder={"Email"}
                        group
                        type="text"
                        value={contactEmail}
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <InputField
                        className={"contact-company-input input my-1"}
                        placeholder={"Company name"}
                        group
                        type="text"
                        value={contactCompany}
                        onChange={(e) => {
                          setContactCompany(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol>
                      <InputField
                        className={"contact-phone-input input my-1"}
                        placeholder={"Phone"}
                        group
                        type="text"
                        value={contactPhone}
                        onChange={(e) => {
                          setContactPhone(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCollapse>
              </MDBCol>
            </MDBRow>
            <MDBRow className="sizes mb-4 mt-3">
              {pdfQtyOfItemsPerPage.map((amount, index) => {
                return (
                  <MDBCol
                    key={`pdf-qty-index-${index}`}
                    size="6"
                    lg="3"
                    md="3"
                    onClick={() => {
                      setPdfFormat(amount.size);
                    }}
                    className="cursor-pointer my-3"
                  >
                    {pdfFormat !== amount.size
                      ? amount.disabledImage
                      : amount.selectedImage}
                  </MDBCol>
                );
              })}
            </MDBRow>
            {/* <MDBRow className="pricing">
              <MDBCol>
                {pdfPricingOptions.map((option, index) => {
                  if (option.label !== "Markup Pricing") {
                    return (
                      <MDBInput
                        name="pricing"
                        type="checkbox"
                        label={option.label}
                        id={option.label}
                        value={option.value}
                        checked={pdfPricing === option.value ? 1 : 0}
                        onChange={(e) => {
                          console.log(option.value);
                          setPdfPricing(e.target.value);
                        }}
                      />
                    );
                  } else {
                    return (
                      <>
                        <div className="radio-btn markup">
                          <MDBInput
                            name="pricing"
                            type="checkbox"
                            label={option.label}
                            id={option.label}
                            value={option.value}
                            // checked={pdfPricing === option.value ? 1 : 0}
                            onChange={(e) => {
                              setPdfPricing(option.value);
                            }}
                          />
                          <div className="select-field-container ml-3 d-flex align-items-baseline">
                            <Select2
                              className={
                                "input pricing-markup-input my-0 mx-2 w-auto p-2 small"
                              }
                              size="sm"
                              name="pricing-markup"
                              //isOpen="true"
                              options={pdfPricingMarkupOptions}
                              value={pdfPricingMarkup}
                              wrapperClass={
                                "markup-select d-inline-block mt-n5"
                              }
                              placeholder={pdfPricingMarkup}
                              onChange={(e) => {
                                setPdfPricingMarkup(e);
                              }}
                            ></Select2>
                            <p className="radio-btn-text">times</p>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </MDBCol>
            </MDBRow> */}
            <MDBRow>
              <MDBCol>
                <MDBInput
                  placeholder="Enter Message"
                  hint="Enter Message"
                  className={"custom-input"}
                  containerClass="textarea-pdf"
                  label={"Message"}
                  group
                  type="textarea"
                  validate
                  error="wrong"
                  success="right"
                  labelClass="text-area-label modal-text-label"
                  value={pdfMessage}
                  onChange={(e) => {
                    setPdfMessage(e.target.value);
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter className="d-flex px-5 justify-content-between pt-5 pb-1">
            <MDBRow className={"w-100 ml-0  align-items-center"}>
              <MDBCol sm="6">
                <MDBBtn
                  className="w-100"
                  onClick={() => {
                    setTimeout(() => {
                      generateDownloadLinkAndOpen();
                    }, 500);
                  }}
                >
                  Generate PDF
                </MDBBtn>
              </MDBCol>
              <MDBCol sm="6" className="d-flex align-items-center">
                <Button
                  className={"cancel-btn w-100 "}
                  direction={"mr-3"}
                  leftIcon={true}
                  icon={""}
                  onClick={() => {
                    togglePdfModal();
                  }}
                  children={<MDBBox tag="span">Cancel</MDBBox>}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
        <MDBBox className="d-none">
          <Pdf
            ref={pdfComponent}
            documentTitle={myFavorites.name}
            products={myFavorites.items}
            attributeOptions={attributeOptions}
            {...pdfOptions}
          />
        </MDBBox>
      </MDBContainer>
    </>
  );
};

export default PdfModal;
