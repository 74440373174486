import { cacheActions } from "app/state/redux/data/cache";
import { useSelector, useDispatch } from "react-redux";

const useCache = () => {
  const cache = useSelector((state) => state.cache);
  const queries = useSelector((state) => state.queries);
  const dispatch = useDispatch();

  const saveNewEntry = (data, key) => {
    let keyData = getCacheEntry(key);
    if (!keyData) {
      dispatch(cacheActions.addDataToCache({ key: key, data: data }));
    }

    return true;
  };

  const getUrlResolverEntry = (key) => {
    return typeof queries.urlResolver !== "undefined" &&
      typeof queries.urlResolver[key] !== "undefined"
      ? queries.urlResolver[key]
      : undefined;
  };

  const getCacheEntry = (key) => {
    return typeof cache.data !== "undefined" &&
      typeof cache.data[key] !== "undefined"
      ? cache.data[key]
      : false;
  };

  const getQueriesDataEntry = (key) => {
    return typeof queries.data !== "undefined" &&
      typeof queries.data[key] !== "undefined"
      ? queries.data[key]
      : undefined;
  };

  const removeCacheEntry = (key) => {};

  return {
    saveNewEntry,
    removeCacheEntry,
    getCacheEntry,
    getQueriesDataEntry,
    getUrlResolverEntry,
  };
};

export default useCache;
