import React from "react";
import { MDBContainer } from "mdbreact";
import NewProductsSectionQueryContainer from "./new-product-section-query-container";

const NewProducts = () => {
  return (
    <MDBContainer className="new-products-section">
      <NewProductsSectionQueryContainer />
    </MDBContainer>
  );
};

export default NewProducts;
