import React from "react";
import { MDBCol, MDBRow, MDBBox, MDBTypography } from "mdbreact";
import {
  ContactLargeImage,
  ContactSmallImage,
  ContactAddress,
  ContactPhone,
  ContactEmail,
  ContactBusinessHours,
  ContactFacebook,
  ContactInstagram,
  ContactPinterest,
  ContactYoutube,
} from "./contact-query-container";
import "./style.scss";

const LeftSection = () => {
  return (
    <MDBRow>
      <MDBCol lg={"6"}>
        <MDBBox className="large-image-div" tag="div">
          <ContactLargeImage />
        </MDBBox>
      </MDBCol>
      <MDBCol lg={"6"}>
        <MDBBox tag="div" className="left-text">
          <MDBBox className="small-image-div m-0" tag="div">
            <ContactSmallImage />
          </MDBBox>
          <MDBTypography tag="p">
            <b>a.</b> <ContactAddress />
          </MDBTypography>
          <MDBTypography tag="p">
            <b>t.</b> <ContactPhone />
          </MDBTypography>
          <MDBTypography tag="p">
            <b>e.</b> <ContactEmail />
          </MDBTypography>
          <MDBTypography tag="p">
            <ContactBusinessHours />
          </MDBTypography>
          <MDBBox>
            <MDBBox className="contact-icon">
              <ContactFacebook />
            </MDBBox>
            <MDBBox className="contact-icon">
              <ContactInstagram />
            </MDBBox>
            <MDBBox className="contact-icon">
              <ContactPinterest />
            </MDBBox>
            <MDBBox className="contact-icon">
              <ContactYoutube />
            </MDBBox>
          </MDBBox>
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};

export default LeftSection;
