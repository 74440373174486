import React, { useState } from "react";
import Accordion from "./accordion";

const StateContainer = (props) => {
  let openAccordion = false;
  if (props.className === "open") {
    openAccordion = true;
  }
  const [isActivePanel, setActivePanel] = useState(openAccordion);

  const [toggleItem, ActiveClass] = [
    () => {
      setActivePanel(!isActivePanel);
    },
    (currentClasses) => {
      if (isActivePanel) {
        return currentClasses + " open";
      }
      return currentClasses;
    },
  ];

  const RenderProps = {
    isActivePanel,
    toggleItem,
    ActiveClass,
  };
  return <Accordion {...props} {...RenderProps} />;
};

export default StateContainer;
