import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBBox,
} from "mdbreact";
import React from "react";
import Icon from "app/assets/icon/icon";
import Button from "app/core/components/button/button";

const RemoveModal = ({
  removemodalProject,
  setRemoveModalProject,
  projectName,
  deleteWishlist,
  history,
  title,
  removeFromWishlist,
  projectById,
}) => {
  return (
    <MDBContainer className={`remove-project-modal`}>
      <MDBModal
        isOpen={removemodalProject}
        toggle={(e) => {
          setRemoveModalProject(!removemodalProject);
        }}
        className={"wishlist-modal modal-wrapper"}
        centered
      >
        <MDBModalHeader>
          <div>
            <p className="text-center">{title}</p>
            <MDBBox className="close-btn">
              <Icon
                icon="closeIcon"
                onClick={() => {
                  setRemoveModalProject(!removemodalProject);
                }}
              />
            </MDBBox>
          </div>
        </MDBModalHeader>
        <MDBModalBody className="d-flex w-100 flex-column">
          <MDBRow className="modal-title-wrapper align-items-center">
            <Icon
              icon="deleteProject"
              className="mb-4 mt-5"
              style={{ display: "block", margin: "auto" }}
            />
            <MDBCol size="12" className="mb-3 mt-5 text-center">
              <h3 className="mb-5">{`Are you sure you want to remove this product ?`}</h3>
            </MDBCol>
          </MDBRow>
          <div className="d-flex w-100">
            <Button
              className={"w-100 cancel"}
              onClick={(e) => {
                setRemoveModalProject(!removemodalProject);
              }}
            >
              CANCEL
            </Button>
            <Button
              className={"w-100 confirm"}
              onClick={async (e) => {
                if (title === "Remove All Items") {
                  let skus = [];
                  projectById.items.map((item) => {
                    if (Object.keys(item).length > 0) {
                      skus.push(item.product.sku);
                    }
                  });
                  let input = { skus, wishlist: projectName };
                  await removeFromWishlist(input);
                  try {
                    setRemoveModalProject(!removemodalProject);
                    history.push({ search: "" });
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  await deleteWishlist(projectName);
                  try {
                    setRemoveModalProject(!removemodalProject);
                    history.push({ search: "" });
                  } catch (error) {
                    console.log(error);
                  }
                }
              }}
            >
              CONFIRM
            </Button>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default RemoveModal;
