import React, { useEffect, useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import CatalogList from "./catalog-list/catalog-list";
import CatalogItem from "./catalog-item/catalog-item";
import Slider from "react-slick";
import { sliderSettings } from "./sliderSettings";

const CatalogPage = ({ data }) => {
  let catalogs = data?.getCatalog?.catalogs;
  const [change, setChange] = useState(0);
  // useEffect(() => {
  //   const compare = (id) => {
  //     return id?.sort((a, b) => a.catalogs_id - b.catalogs_id);
  //   };
  //   return compare(catalogs);
  // }, [data]);

  return (
    <>
      {/* <MDBBox className="catalogs-main-banner">
        <CmsBlock id={"catalog_page_banner"} className={"w-100"} />
      </MDBBox> */}
      <MDBContainer className={"catalog-container"}>
        <MDBRow>
          <MDBCol size="12" xl="9">
            {catalogs.map((item, index) => (
              <CatalogItem
                catalogs={catalogs}
                item={item}
                index={index}
                change={change}
                setChange={setChange}
              />
            ))}
          </MDBCol>
          <MDBCol size="12" xl="3" className="catalogs-slider">
            <MDBTypography tag="h1" className="text-lg-center">
              Browse Brochures
            </MDBTypography>
            <MDBBox className="catalog-list-wrapper">
              {catalogs.map((item, index) => (
                <CatalogList
                  catalogs={catalogs}
                  item={item}
                  index={index}
                  change={change}
                  setChange={setChange}
                />
              ))}
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default CatalogPage;
