import React, { useEffect, useState } from "react";
import Header from "./header";

const StateContainer = ({ data }) => {

  const [showStickyClass, setShowStickyClass] = useState("fixed-top");

  useEffect(() => {
    const handler = () => {
      let activeClass = "fixed-top sticky";
      if (window.scrollY === 0) {
        activeClass = "fixed-top";
      }
      setShowStickyClass(activeClass);
    };
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  const renderProps = { showStickyClass, data };
  return <Header {...renderProps} />;
};
export default StateContainer;
