import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const HeaderLogo = () => {
  return <CmsBlock id={"header_logo"} />;
};

// export const HeaderQuoteIcon = () => {
//   return <CmsBlock id={"header_quote_icon"} />;
// };

export const HeaderWishlistIcon = () => {
  return <CmsBlock id={"header_wishlist_icon"} />;
};
