import React, { useState } from "react";
import { MDBView, MDBBox } from "mdbreact";
import Image from "core/components/image";
import CardComp from "./hotspotCard";

const Hotspot = (props) => {
  let image = props.hotspotImage;
  const [hotspotBackground] = useState(image);
  const [isVisible, setVisible] = useState(false);
  const showProductInfo = (dotSku, isDotVisible) => {
    let dotParams = props.dots;
    dotParams.map((newDot, index) => {
      if (newDot.sku === dotSku) {
        if (isDotVisible === true) {
          newDot.isVisible = false;
          setVisible(!isVisible);
        } else {
          newDot.isVisible = true;
          setVisible(!isVisible);
        }
      } else {
        newDot.isVisible = false;
        setVisible(!isVisible);
      }
      return newDot;
    });
  };
  return (
    <MDBBox className="hotspotContentContainer">
      <MDBView>
        <Image
          source={hotspotBackground}
          className="img-slider"
          alt="Product"
        />
        {props.dots.map((dot, index) => {
          return (
            <CardComp
              key={`card-comp-` + index}
              dot={dot}
              dots={props.dots}
              isHome={props.isHome}
              showProductInfo={showProductInfo}
              toggleModal={props.toggleModal}
              hideModal={props.hideModal}
            />
          );
        })}
      </MDBView>
    </MDBBox>
  );
};

export default Hotspot;
