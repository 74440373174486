import gql from "graphql-tag";

/**
 *  uncomment necessary urlResolver based on your project requirements
 **/

export default (query, additionalMapping = []) => {
  // this is the app urlResolver
  if (query === "urlResolver") {
    return gql`
      query urlResolver($url: String!) {
        route(url: $url) {
          relative_url
          type
          ... on SimpleProduct {
            sku
            attribute_set_id
          }
          ... on GroupedProduct {
            sku
            attribute_set_id
          }
          ... on ConfigurableProduct {
            sku
            attribute_set_id
          }
        }
      }
    `;
  }

  // this is the core urlResolver
  // if (query === "urlResolver") {
  //   return loader("core/graphql/queries/urlResolver.graphql");
  // }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
