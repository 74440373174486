import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const FooterPrimaLogo = () => {
  return <CmsBlock id={"footer_prima_logo"} />;
};

export const FooterArkansasLogo = () => {
  return <CmsBlock id={"footer_arkansas_logo"} />;
};

export const FooterPrimaInfo = () => {
  return <CmsBlock id={"footer_prima_info"} />;
};

export const FooterArkansasInfo = () => {
  return <CmsBlock id={"footer_arkansas_info"} />;
};
