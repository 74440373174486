import React, { useReducer } from "react";
import Modal from "./modal";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { initialState, reducer } from "./store";
import {
  setEmail,
  setPassword,
  setEmailError,
  setPasswordError,
  setEmailErrorMsg,
  setPasswordErrorMsg,
  setLoading,
} from "./store/actions.jsx";

const ModalWrapper = ({ toggleModal, modal, activeItem, toggleTabs }) => {
  const { login } = useCustomer();
  const [store, dispatch] = useReducer(reducer, initialState);

  const onEmailChangeHandler = (e) => setEmail(dispatch, e.target.value);
  const onPasswordChangeHandler = (e) => setPassword(dispatch, e.target.value);

  const handleLoginSubmit = (evt) => {
    evt.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;

    if (!store.email) {
      setEmailError(dispatch, true);
      setEmailErrorMsg(dispatch, "This field is required");
      return;
    } else if (!emailRegex.test(store.email)) {
      setEmailError(dispatch, true);
      setEmailErrorMsg(dispatch, "Invalid email");
      return;
    } else {
      setEmailError(dispatch, "");
      setEmailErrorMsg(dispatch, "");
    }
    if (!store.password) {
      setPasswordError(dispatch, true);
      setPasswordErrorMsg(dispatch, "This field is required");
      return;
    } else {
      setLoading(dispatch, true);
      login(store.email, store.password).then((res) => {
        if (res) {
          setEmailErrorMsg(dispatch, "");
          setPasswordErrorMsg(dispatch, "");
          setEmail(dispatch, "");
          setPassword(dispatch, "");
          setPasswordError(dispatch, false);
          setEmailError(dispatch, false);
        } else {
        }
        setLoading(dispatch, false);
        if(window.innerWidth < 991) {
          document.getElementById("hamburger1").click();
        }
      });
    }
  };

  return (
    <Modal
      toggleModal={toggleModal}
      modal={modal}
      email={store.email}
      password={store.password}
      loading={store.loading}
      handleLoginSubmit={handleLoginSubmit}
      onEmailChangeHandler={onEmailChangeHandler}
      onPasswordChangeHandler={onPasswordChangeHandler}
      errorEmailMessage={store.emailErrorMsg}
      activeItem={activeItem}
      toggleTabs={toggleTabs}
      errorPostalCodeMessage={store.postalCodeErrorMsg}
    />
  );
};

export default ModalWrapper;
