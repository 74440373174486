import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBTypography,
  MDBInput,
  MDBInputGroup,
  MDBSelect,
  MDBSelectOption,
  MDBSelectInput,
  MDBSelectOptions,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBCollapse,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBBox,
  MDBCloseIcon,
  MDBModalFooter,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import ArrowRight from "app/assets/images/right-arrow.png";

const Typography = () => {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <h2>Buttons</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn size="lg">Very big button</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn>Regular button</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn size="sm">Small</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn className="btn-create-new">
            <MDBIcon icon="plus" />
            create new
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn outline size="lg">
            Outlined button
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn outline>Outlined button</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn outline size="sm">
            Outlined
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow className="blue-back">
        <MDBCol>
          <MDBBtn className="outlined-white" outline>
            Outlined white
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h2>Headings</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h1" variant="display-1">
            Very Big
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol style={{ backgroundColor: "gray" }}>
          <MDBTypography tag="h1" variant="h1">
            H1
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h2" variant="h2">
            H2
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h3" variant="h3">
            H3
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h4" variant="h4">
            H4
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h5" variant="h5">
            H5
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h6" variant="h6">
            H6
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <label htmlFor="#">Labels</label>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInput placeholder="Input place Holder" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="p">P1 regular text</MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="p" className="paragraph-small">
            P2 small text
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <label htmlFor="#" className="some-label">
            Label 1
          </label>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <label htmlFor="#" className="price-label">
            price Label
          </label>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInput label="Input Label" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <a href="https://mdbootstrap.com/docs/react/navigation/link/">
            links
          </a>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <a
            href="https://mdbootstrap.com/docs/react/navigation/link/"
            className="small"
          >
            small links
          </a>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <span className="edit">edit</span>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography colorText="red">Error text</MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInput label="radio btn label" type="radio" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="p" className="very-small">
            very small text
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h2>Input fields</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBSelect label="small field" className="select-small">
            <MDBSelectInput />
            <MDBSelectOptions>
              <MDBSelectOption checked value="1">
                <img src="https://via.placeholder.com/37" alt="" />
                Selection
              </MDBSelectOption>
              <MDBSelectOption value="2">
                <img src="https://via.placeholder.com/37" alt="" />
                item 1
              </MDBSelectOption>
              <MDBSelectOption value="3">
                <img src="https://via.placeholder.com/37" alt="" />
                item 2
              </MDBSelectOption>
            </MDBSelectOptions>
          </MDBSelect>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBSelect label="label name">
            <MDBSelectInput />
            <MDBSelectOptions>
              <MDBSelectOption checked value="1">
                Selection
              </MDBSelectOption>
              <MDBSelectOption value="2">item 1</MDBSelectOption>
              <MDBSelectOption value="3">item 2</MDBSelectOption>
            </MDBSelectOptions>
          </MDBSelect>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInputGroup
            material
            hint="Enter Name"
            label="input field"
            labelClassName="input-label"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInputGroup
            material
            hint="Active"
            label="active field"
            labelClassName="input-label"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="error-col">
          <MDBInputGroup
            material
            hint="Error"
            label="error field"
            labelClassName="input-label error-label"
            className="error-input"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInput
            type="textarea"
            label="text field"
            rows="7"
            placeholder="Enter your comments"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBDropdown>
            <MDBDropdownToggle caret>Dropdown</MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem>item </MDBDropdownItem>
              <MDBDropdownItem>item 2</MDBDropdownItem>
              <MDBDropdownItem>item 3</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBPagination>
            <MDBPageItem>
              <MDBSelect label="Pagination:">
                <MDBSelectOptions>
                  <MDBSelectOption checked value="1">
                    1
                  </MDBSelectOption>
                  <MDBSelectOption value="2">2</MDBSelectOption>
                  <MDBSelectOption value="3">3</MDBSelectOption>
                </MDBSelectOptions>
              </MDBSelect>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav>1</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav>2</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav>3</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav>4</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav>5</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem>
              <MDBPageNav aria-label="Previous">
                <img src={ArrowRight} alt="" />
              </MDBPageNav>
            </MDBPageItem>
          </MDBPagination>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBInput label="checkmark" type="checkbox" id="checkbox1" />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h2>Lists</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBListGroup className="vertical-list">
            <li>item 1</li>
            <li>item 2</li>
            <li>item 3</li>
            <li>item 4</li>
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBListGroup className="horizontal-list">
            <MDBListGroupItem href="#">item</MDBListGroupItem>
            <MDBListGroupItem href="#" active>
              item
            </MDBListGroupItem>
            <MDBListGroupItem href="#">item</MDBListGroupItem>
            <MDBListGroupItem href="#">item</MDBListGroupItem>
            <MDBListGroupItem href="#">item</MDBListGroupItem>
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h2>Acordion</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBContainer className="md-accordion mt-5">
            <MDBCard className="mt-3">
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                Collapsible Group Item #1
                <MDBIcon icon={"minus"} />
              </MDBCollapseHeader>
              <MDBCollapse id="collapse1" isOpen={true}>
                <MDBCardBody>
                  Pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven&apos;t heard of them accusamus labore
                  sustainable VHS.
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>

            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                Collapsible Group Item #2
                <MDBIcon icon={"plus"} />
              </MDBCollapseHeader>
              <MDBCollapse id="collapse2" isOpen={false}>
                <MDBCardBody>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven&apos;t heard of them accusamus labore
                  sustainable VHS.
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>

            <MDBCard>
              <MDBCollapseHeader tagClassName="d-flex justify-content-between">
                Collapsible Group Item #3
                <MDBIcon icon={"plus"} />
              </MDBCollapseHeader>
              <MDBCollapse id="collapse3" isOpen={false}>
                <MDBCardBody>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven&apos;t heard of them accusamus labore
                  sustainable VHS.
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBNav className="flex-column">
            <MDBNavItem>
              <MDBNavLink to="#">Item</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="#">Item</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="#">Item</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="#">Item</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink active to="#">
                Active Item
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <h2>Product cards</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCard className="text-center product-card">
            <MDBCardImage
              className="img-fluid"
              src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
              waves
            />
            <MDBCardBody>
              <MDBCardTitle>Matrix</MDBCardTitle>
              <MDBCardText>SKU 12467</MDBCardText>
            </MDBCardBody>
            <i class="far fa-heart fa-2x"></i>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCard className="text-center product-card">
            <MDBCardImage
              className="img-fluid"
              src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
              waves
            />
            <MDBCardBody>
              <MDBCardTitle>Matrix</MDBCardTitle>
              <MDBCardText>SKU 12467</MDBCardText>
              <MDBCardText>$263</MDBCardText>
            </MDBCardBody>
            <i class="far fa-heart fa-2x"></i>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCard className="product-card small">
            <MDBRow>
              <MDBCol>
                <MDBCardImage
                  className="img-fluid"
                  src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
                  waves
                />
              </MDBCol>
              <MDBCol>
                <MDBCardBody>
                  <MDBCardTitle>Matrix</MDBCardTitle>
                  <MDBCardText>SKU 12467</MDBCardText>
                  <MDBCardText>$263</MDBCardText>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
            <i class="far fa-heart fa-2x"></i>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBModal isOpen={false} fullHeight position="left">
            <MDBModalHeader>
              Plp Selection Popup
              <MDBCloseIcon />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBBox className="active">
                <MDBIcon icon="check" />
                <img src="https://via.placeholder.com/75" alt="" />
                <MDBTypography tag="p">
                  (10) P-Track Adapter For 120V Fixture
                </MDBTypography>
              </MDBBox>
              <MDBBox>
                <MDBIcon icon="check" />
                <img src="https://via.placeholder.com/75" alt="" />
                <MDBTypography tag="p">
                  (25) Halo Track Retrofit Adapter for 120V (Standard Single
                  Circuit)
                </MDBTypography>
              </MDBBox>
              <MDBBox>
                <MDBIcon icon="check" className="fa-2x" />
                <img src="https://via.placeholder.com/75" alt="" />
                <MDBTypography tag="p">
                  (26) Juno Track Retrofit Adapter Fpr 120V (Standard Single
                  Circuit)
                </MDBTypography>
              </MDBBox>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBModal isOpen={false} centered>
            <MDBModalHeader>
              Popup
              <MDBCloseIcon />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBIcon icon="exclamation-circle" />
              <MDBTypography tag="p">
                (26) Juno Track Retrofit Adapter Fpr 120V (Standard Single
                Circuit)
              </MDBTypography>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn size="sm">cancel</MDBBtn>
              <MDBBtn outline size="sm">
                confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
export default Typography;
