import React from "react";
import HeaderDataContainer from "./data-container";
import categories from "generation/categories.json";

const HeaderQueryContainer = (props) => {
  return (
    <HeaderDataContainer
      toggleSearch={props.toggleSearch}
      data={{ categoryList: [categories.default] }}
    />
  );
};

export default HeaderQueryContainer;
