import { storage } from "config/themeConfiguration";

export const loadState = (key = storage.local_storage_key) => {
  let thisKey = key;

  try {
    const serializedState = localStorage.getItem(thisKey);

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, key = storage.local_storage_key) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};
