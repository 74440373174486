import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ArrowRightPdp from "app/assets/images/icon-right-pdp.png";

const QuestionBox = ({
  q,
  prevSelected,
  selectedOptionsMap,
  setQuestionCode,
  handleOptionClick,
  isMobile,
  isDisabled = true,
  index,
}) => {
  const isEmpty = !selectedOptionsMap[q.code];
  const isNext = (!isDisabled && isEmpty) || false;
  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  return (
    <div
      className={`questions-box ${isNext ? "featured" : ""}`.trim()}
      onClick={() => {
        if (prevSelected) { //  if previous does not have defined item
            // console.log("do you see me", q.code); // when option selected, and option before is also selected
          setQuestionCode(q.code);
          handleOptionClick(q.parsedAnswers, q.name, q.code);
        } else {
            // console.log("box it is");
          setTooltipVisible(true);
          setTimeout(() => {
            setTooltipVisible(false);
          }, 2000);
        }
      }}
    >
      <MDBRow
        className="no-gutters input-options-pdp"
        style={{ position: "relative" }}
      >
        {tooltipVisible && (
          <div className="question-box-tooltip-wrapper">
            <p>Please select previous option.</p>
          </div>
        )}
        <MDBCol
          style={
            prevSelected
              ? {}
              : {
                  opacity: "0.3",
                }
          }
          md={isNext ? "11" : "6"}
          className={`option-name ${
            selectedOptionsMap[q.code]?.iconUrl ? "two-rows" : "one-row"
          }`}
        >
          {isNext && <span>{index}. Select </span>}
          <span>{q.name}</span>
        </MDBCol>
        <MDBCol
          md={isNext ? "1" : "6"}
          className={`input-options-wrapper ${
            selectedOptionsMap[q.code]?.iconUrl ? "two-rows" : "one-row"
          }`}
          style={
            prevSelected
              ? {}
              : {
                  opacity: "0.1",
                }
          }
        >
          {selectedOptionsMap[q.code] &&
          selectedOptionsMap[q.code].iconUrl !== "" &&
          prevSelected ? (
            <>
              <img
                alt="selected-option"
                src={
                  selectedOptionsMap[q.code] &&
                  selectedOptionsMap[q.code].iconUrl
                }
              />
              {!isMobile ? (
                <p>
                  {` ${selectedOptionsMap[q.code]?.name.substring(0, 20)}...`}
                </p>
              ) : selectedOptionsMap[q.code]?.name.length > 40 ? (
                <p>
                  {`${selectedOptionsMap[q.code]?.name.substring(0, 40)}...`}
                </p>
              ) : (
                <p>{selectedOptionsMap[q.code]?.name}</p>
              )}
            </>
          ) : (
            <img src={ArrowRightPdp} className="arrow-right" alt="" />
          )}
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default QuestionBox;
