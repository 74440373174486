export const stepCats = {
  "P-Track (120V)": {
    "Choose Fixtures": "Interchangeable Pendants,Interchangeable Track Heads",
    "Choose Lamps & Accessories": "LED Lamps,Optical Accessories",
  },

  "Monorail (12V / 24V)": {
    "Choose Fixtures":
      "Interchangeable Pendants,Interchangeable Track Heads,Display / Picture Lights",
    // "Choose Remote Transformers, LED Drivers, or Surface Mount Transformer":
    //   "Magnetic Transformer,Electronic Transformer,12V Constant Voltage Driver,24V Constant Voltage Driver,Surface Mount Canopy w/ Transformer",
    "Choose Lamps & Accessories":
      "LED Lamps, Optical Accessories, Surge Protector",
  },

  "FIT I (12V / 24V)": {
    "Choose Fixtures": "Interchangeable Pendants, Interchangeable Track Heads",
    // "Choose Remote Transformers, LED Drivers, or Surface Mount Transformer":
    //   "Magnetic Transformer, Electronic Transformer, 12V Constant Voltage Driver, 24V Constant Voltage Driver, Surface Mount Canopy w/ Transformer",
    "Choose Lamps & Accessories":
      "LED Lamps, Optical Accessories, Surge Protector",
  },

  "FIT II (12V / 24V)": {
    "Choose Fixtures": "Interchangeable Pendants, Interchangeable Track Heads",
    // "Choose Remote Transformers, LED Drivers, or Surface Mount Transformer":
    //   "Magnetic Transformer, Electronic Transformer, 12V Constant Voltage Driver, 24V Constant Voltage Driver, Surface Mount Canopy w/ Transformer",
    "Choose Lamps & Accessories":
      "LED Lamps, Optical Accessories, Surge Protector",
  },

  "Aluminum MiniRail (12V / 24V)": {
    // "Choose Remote Transformers or LED Drivers":
    //   "Magnetic Transformer, Electronic Transformer, 12V Constant Voltage Driver",
    "Choose Lamps & Accessories":
      "LED Lamps, Optical Accessories, Surge Protector",
  },

  "Cable Systems (12V / 24V)": {
    "Choose Fixtures": "Interchangeable Pendants, Interchangeable Track Heads",
    "Choose Lamps & Accessories":
      "LED Lamps, Optical Accessories, Surge Protector",
  },
};
