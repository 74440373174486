export const SET_EMAIL = "SET_EMAIL";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_NAME = "SET_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_COMPANY = "SET_COMPANY";
export const SET_PHONE = "SET_PHONE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_CITY = "SET_CITY";
export const SET_STATE = "SET_STATE";
export const SET_POSTAL_CODE = "SET_POSTAL_CODE";

export const SET_LOADING = "SET_LOADING";
export const SET_EMAIL_ERROR = "SET_EMAIL_ERROR";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";
export const SET_NAME_ERROR = "SET_NAME_ERROR";
export const SET_LAST_NAME_ERROR = "SET_LAST_NAME_ERROR";
export const SET_COMPANY_ERROR = "SET_COMPANY_ERROR";
export const SET_PHONE_ERROR = "SET_PHONE_ERROR";
export const SET_ADDRESS_ERROR = "SET_ADDRESS_ERROR";
export const SET_COUNTRY_ERROR = "SET_COUNTRY_ERROR";
export const SET_CITY_ERROR = "SET_CITY_ERROR";
export const SET_STATE_ERROR = "SET_STATE_ERROR";
export const SET_POSTAL_CODE_ERROR = "SET_POSTAL_CODE_ERROR";

export const SET_EMAIL_ERROR_MSG = "SET_EMAIL_ERROR_MSG";
export const SET_PASSWORD_ERROR_MSG = "SET_PASSWORD_ERROR_MSG";
export const SET_NAME_ERROR_MSG = "SET_NAME_ERROR_MSG";
export const SET_LAST_NAME_ERROR_MSG = "SET_LAST_NAME_ERROR_MSG";
export const SET_COMPANY_ERROR_MSG = "SET_COMPANY_ERROR_MSG";
export const SET_PHONE_ERROR_MSG = "SET_PHONE_ERROR_MSG";
export const SET_ADDRESS_ERROR_MSG = "SET_ADDRESS_ERROR_MSG";
export const SET_COUNTRY_ERROR_MSG = "SET_COUNTRY_ERROR_MSG";
export const SET_CITY_ERROR_MSG = "SET_CITY_ERROR_MSG";
export const SET_STATE_ERROR_MSG = "SET_STATE_ERROR_MSG";
export const SET_POSTAL_CODE_ERROR_MSG = "SET_POSTAL_CODE_ERROR_MSG";

export const setEmail = (dispatch, payload) =>
  dispatch({ type: SET_EMAIL, payload });

export const setPassword = (dispatch, payload) =>
  dispatch({ type: SET_PASSWORD, payload });

export const setName = (dispatch, payload) =>
  dispatch({ type: SET_NAME, payload });

export const setLastName = (dispatch, payload) =>
  dispatch({ type: SET_LAST_NAME, payload });

export const setCompany = (dispatch, payload) =>
  dispatch({ type: SET_COMPANY, payload });

export const setPhone = (dispatch, payload) =>
  dispatch({ type: SET_PHONE, payload });

export const setAddress = (dispatch, payload) =>
  dispatch({ type: SET_ADDRESS, payload });

export const setCountry = (dispatch, payload) =>
  dispatch({ type: SET_COUNTRY, payload });

export const setCity = (dispatch, payload) =>
  dispatch({ type: SET_CITY, payload });

export const setState = (dispatch, payload) =>
  dispatch({ type: SET_STATE, payload });

export const setPostalCode = (dispatch, payload) =>
  dispatch({ type: SET_POSTAL_CODE, payload });

export const setEmailError = (dispatch, payload) =>
  dispatch({ type: SET_EMAIL_ERROR, payload });

export const setPasswordError = (dispatch, payload) =>
  dispatch({ type: SET_PASSWORD_ERROR, payload });

export const setNameError = (dispatch, payload) =>
  dispatch({ type: SET_NAME_ERROR, payload });

export const setLastNameError = (dispatch, payload) =>
  dispatch({ type: SET_LAST_NAME_ERROR, payload });

export const setCompanyError = (dispatch, payload) =>
  dispatch({ type: SET_COMPANY_ERROR, payload });

export const setPhoneError = (dispatch, payload) =>
  dispatch({ type: SET_PHONE_ERROR, payload });

export const setAddressError = (dispatch, payload) =>
  dispatch({ type: SET_ADDRESS_ERROR, payload });

export const setCountryError = (dispatch, payload) =>
  dispatch({ type: SET_COUNTRY_ERROR, payload });

export const setCityError = (dispatch, payload) =>
  dispatch({ type: SET_CITY_ERROR, payload });

export const setStateError = (dispatch, payload) =>
  dispatch({ type: SET_STATE_ERROR, payload });

export const setPostalCodeError = (dispatch, payload) =>
  dispatch({ type: SET_POSTAL_CODE_ERROR, payload });

export const setEmailErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_EMAIL_ERROR_MSG, payload });

export const setPasswordErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_PASSWORD_ERROR_MSG, payload });

export const setNameErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_NAME_ERROR_MSG, payload });

export const setLastNameErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_LAST_NAME_ERROR_MSG, payload });

export const setCompanyErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_COMPANY_ERROR_MSG, payload });

export const setPhoneErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_PHONE_ERROR_MSG, payload });

export const setAddressErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_ADDRESS_ERROR_MSG, payload });

export const setCountryErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_COUNTRY_ERROR_MSG, payload });

export const setCityErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_CITY_ERROR_MSG, payload });

export const setStateErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_STATE_ERROR_MSG, payload });

export const setPostalCodeErrorMsg = (dispatch, payload) =>
  dispatch({ type: SET_POSTAL_CODE_ERROR_MSG, payload });

export const setLoading = (dispatch, payload) => {
  dispatch({ type: SET_LOADING, payload });
};
