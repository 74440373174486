import React from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBBox,
  MDBCloseIcon,
  MDBModalBody,
  MDBTypography,
  MDBIcon,
} from "mdbreact";
import ThemeConfiguration from "config/themeConfiguration";
import { isEqual } from "lodash";

export const returnUrl = (sku, category, ext = "") => {
  try {
    return (
      ThemeConfiguration.magento_url +
      ThemeConfiguration.media_url +
      ThemeConfiguration.product_options_url +
      `/${category}/${sku + ext}.jpg`
    );
  } catch (err) {
    console.error("err ", err);
    return "https://via.placeholder.com/75";
  }
};

const OptionsModal = ({
  modal,
  toggleModal,
  modalTitle,
  options,
  handleSelectOption,
  questionCode,
  setVar,
  category,
  selectedOptionsMap,
}) => {
  return (
    <MDBModal
      isOpen={modal && options.length}
      fullHeight
      position="left"
      toggle={toggleModal}
      className="modal-pdp-page"
    >
      <MDBModalHeader>
        {modalTitle}
        <MDBCloseIcon onClick={toggleModal} />
      </MDBModalHeader>
      <MDBModalBody>
        {options.map((option) => (
          <MDBBox
            className="options-box"
            onClick={() => {
              let currentSelectedOption = {
                ...selectedOptionsMap[questionCode],
              };
              if (currentSelectedOption) {
                delete currentSelectedOption.iconUrl;
              }
              if (!isEqual(currentSelectedOption, option)) {
                setVar(questionCode, option.sku);
                handleSelectOption(option, questionCode);
              } else {
                toggleModal();
              }
            }}
          >
            <MDBIcon icon="check" />
            <img
              width="60"
              height="60"
              src={returnUrl(option.sku, category)}
              alt=""
            />
            <MDBTypography tag="p">{option.name}</MDBTypography>
          </MDBBox>
        ))}
      </MDBModalBody>
    </MDBModal>
  );
};

export default OptionsModal;
