const chunkArray = (myArray, chunk_size) => {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

const getPricing = (options) => {
  if (!options) {
    return [];
  }
  let pricingArray = options.find((option) => {
    if (!option?.prices) {
      return false;
    }
    return true;
  });
  return pricingArray?.prices || [];
};
const getComment = (comments, productSku) => {
  if (!comments) {
    return [];
  }
  let comment = comments.filter((item) => item.product_sku === productSku);
  return comment[0]?.comment;
};

export { chunkArray, getPricing, getComment };
