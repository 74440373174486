import React, { useState } from "react";
import {
  MDBBox,
  MDBTypography,
  MDBCardTitle,
  MDBCardBody,
  MDBCard,
} from "mdbreact";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import QuoteRequestItem from "./item";
import { Link } from "react-router-dom";
import Button from "app/core/components/button/button";

const QuoteRequestMini = (props) => {
  const { getCart } = useCart();
  const cart = getCart();
  const [visible, setIsVisible] = useState(true);

  let numberOfItems = props.items.reduce((acc, curr) => acc + curr.quantity, 0);

  return (
    visible && (
      <MDBBox className="quote-container">
        <MDBCard className="quote-box">
          {cart?.items.length > 0 ? (
            <>
             <MDBBox className="romb"></MDBBox>
              <MDBCardTitle className="quote-title d-flex align-items-center justify-content-between px-5 py-4">
                <MDBTypography tag="h6" variant="h6">
                  Quote Request
                </MDBTypography>
                <MDBTypography tag="p">
                  {numberOfItems} {numberOfItems > 1 ? "items" : "item"}
                </MDBTypography>

              </MDBCardTitle>
              <MDBCardBody className="quote-body px-5 pt-4 d-flex flex-column">
                {cart.items.map((item) => {
                  return (
                    <QuoteRequestItem
                      key={`quote-request-item-${item.id}`}
                      item={item}
                      removeItem={props.removeItem}
                      hideMiniCart={props.hideMiniCart}
                      updateCart={props.updateCart}
                      lock={props.lock}
                    />
                  );
                })}
              </MDBCardBody>
              <a
                style={{
                  marginBottom: "3rem",
                  marginTop: "2rem",
                }}
                href={"/quote-request"}
                className={"d-flex justify-content-center pl-5 pr-5"}
              >
                <Button
                  onClick={() => setIsVisible(false)}
                  className="mini-quote-go-to-quote-request-btn w-100"
                >
                  Go to quote request
                </Button>
              </a>
            </>
          ) : (
              <>
                  <MDBBox className="romb"></MDBBox>
                  <MDBCardTitle className="quote-title d-flex align-items-center justify-content-center px-5 py-4">
                      <MDBTypography tag="p" variant="p">
                          There are no items in Quote Request
                      </MDBTypography>

                  </MDBCardTitle>
              </>

          )}
        </MDBCard>
      </MDBBox>
    )
  );
};

export default QuoteRequestMini;
