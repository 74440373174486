// Action types
const GET_CACHE_VERSION = "jola/cache/GET_CACHE_VERSION";
const SET_CACHE_VERSION = "jola/cache/SET_CACHE_VERSION";
const SET_FLAG = "jola/cache/SET_FLAG";
const ADD_DATA_TO_CACHE = "jola/cache/ADD_DATA_TO_CACHE";
const CLEAR_DATA_FROM_CACHE = "jola/cache/CLEAR_DATA_FROM_CACHE";

export default {
  GET_CACHE_VERSION,
  SET_CACHE_VERSION,
  SET_FLAG,
  ADD_DATA_TO_CACHE,
  CLEAR_DATA_FROM_CACHE,
};
