import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBTypography, MDBCol } from "mdbreact";
import GalleryItem from "./galleryItem";
import SeoHelmet from "app/layout/seoHelmet";
import { useQuery } from "react-apollo-hooks";
import Skeleton from "react-loading-skeleton";
import { GET_ALL_GALLERY_DATA } from "./utils";

const PortfolioGallery = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  let skeleton = [];

  for (let i = 0; i < 10; i++) {
    skeleton.push(
      <MDBCol
        key={`gallery-skeleton-${i}`}
        xs="12"
        sm="12"
        md="12"
        lg="6"
        style={{ height: "550px" }}
        className="single-image mb-5"
      >
        <Skeleton width={`100%`} height={`100%`} />
      </MDBCol>
    );
  }

  const { data, loading } = useQuery(GET_ALL_GALLERY_DATA, {
    variables: "portfolio_gallery",
  });

  const imgData = data && data.getAllGalleryData;

  if (loading) {
    return <MDBRow className="row-container">{skeleton}</MDBRow>;
  }

  return (
    <MDBContainer className="portfolio-container">
      <SeoHelmet title="Portfolio" url={"/portfolio"} />
      <MDBRow>
        <MDBCol size="12">
          <MDBTypography tag="h3" className="gallery-title">
            Portfolio
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow className="row-container">
        {imgData.map((item, index) => (
          <MDBCol
            lg="6"
            size="12"
            key={`gallery-item-${index}`}
            className="single-image"
          >
            <GalleryItem
              image={item.image}
              name={item.name}
              toggleModal={toggleModal}
              hotspotData={item.hotspot}
              index={index}
              images={imgData}
            />
            <MDBTypography className="image-name">{item.name}</MDBTypography>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
};

export default PortfolioGallery;
