import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";

export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("addProduct"),
      variables: {
        items: action.items,
        cart_id,
      },
    });
    if (data.addSimpleProductsToCart !== null) {
      store.dispatch(
        customerActions.setCartInformation(data.addSimpleProductsToCart.cart)
      );
      store.dispatch(
        messagesActions.addMessage(
          "Item successfully added to quote!",
          "success"
        )
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
      store.dispatch(customerActions.addProductsToCart(action.items));
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
