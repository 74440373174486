import React, {useEffect, useState} from "react";
import {MDBBox, MDBCol, MDBRow, MDBTypography} from "mdbreact";
import VideoTutorial from "./videoTutorial/videoTutorial-query-container";
import {useQuery} from "@apollo/react-hooks";
import {FETCH_PRODUCT} from "../../catalog/product/queries";

const OpitonsArkansas = ({
catalogNoMap,
setActiveCatalogNo,
activeCatalogNo,
activeCatalogNoPossition,
setActiveCatalogNoPossition
}) => {
    const [hasModel, setHasModel] = useState(false);
    const [isImgError, setIsImgError] = useState(false);



    return(
        <div>

            {!isImgError && (

                <MDBBox style={{marginTop:"0",marginBottom: "1rem",paddingTop:"0"}} className="catalog-items d-flex">

                    { Array.isArray(catalogNoMap) ? catalogNoMap?.map((item, index)=>{

                        return (<>
                            <MDBBox
                                key={index}
                                className={`mr-2`}
                                style={{"height" : "57px", "width" : "57px"}}
                                onClick={() => {
                                    setActiveCatalogNoPossition(index);
                                    //     handleCatalogNoSelectOption(item, "CATA");
                                        setActiveCatalogNo(item?.product?.sku);

                                }}
                            >
                                <img
                                    style={{ "width" : "100%"}}
                                    src={item?.product?.image?.url}
                                    // onError={onError}
                                    alt=""
                                    className={
                                        activeCatalogNo === item?.product?.sku
                                            ? `catalog-item-active cursor-pointer`
                                            : `catalog-item cursor-pointer`
                                    }
                                />


                            </MDBBox>

                        </>)
                    }) : (
                        <>
                        <MDBBox

                    className={`mr-2`}
                    style={{"height" : "70px", "width" : "70px"}}
                    onClick={() => {
                        setActiveCatalogNoPossition(0);
                        //     handleCatalogNoSelectOption(item, "CATA");
                        setActiveCatalogNo(catalogNoMap?.sku);
                        //     setActiveCatalogNo(index);
                    }}
                >
                    <img
                        style={{ "width" : "100%"}}
                        src={catalogNoMap?.image?.url}
                        // onError={onError}
                        alt=""
                        className={
                            activeCatalogNo
                                ? `catalog-item-active cursor-pointer`
                                : `catalog-item cursor-pointer`
                        }
                    />


                </MDBBox>

                </>
                        )}
                </MDBBox>
            ) || <></>}
        </div>
    );
}

export default OpitonsArkansas;