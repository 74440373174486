import React from "react";
import Prima from "app/assets/images/prima.png";
import { MDBContainer } from "mdbreact";

const PrimaSpinner = () => {
  return (
    <MDBContainer className="prima-spinner-wrapper">
      <img src={Prima} />
    </MDBContainer>
  );
};

export default PrimaSpinner;
