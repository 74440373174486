import React, { useEffect } from "react";
import { MDBRow } from "mdbreact";
// import Info from "./info";
// import YouMayAlsoLike from "./youMayAlsoLike";
import SeoHelmet from "app/layout/seoHelmet";
import { JSONLD, Generic } from "react-structured-data";
import themeConfiguration from "config/themeConfiguration";
import ProductDetailsPage from "app/pages/product-details-page";

import { useSelector } from "react-redux";
import ProductDetailsPageArkansas from "../../product-details-page/product-details-page-arkansas";
import LightedMirrors from "../../product-details-page/lighted-mirrors/LightedMirrors";

const Product = (props) => {
  const customerGroupId = useSelector(
    (state) => state.customer.data.customer_group
  );

  let { data, player, setPlayer } = props;

  const { prima_spec_sheet, item_solid_model, install_instruction } =
    data || {};

  return (
    <>
      <JSONLD>
        <Generic
          type="product"
          jsonldype={"Product"}
          schema={{
            name: data.name,
            sku: data.sku,
            image: typeof data.image !== "undefined" ? data.image.url : "",
            url: themeConfiguration.app_url + data.url_key,
          }}
          json
        ></Generic>
      </JSONLD>
      <SeoHelmet
        url={props.variables.url}
        title={
          typeof data.meta_title !== "undefined" && data.meta_title !== null
            ? data.meta_title
            : data.name
        }
        description={
          typeof data.meta_description !== "undefined" &&
          data.meta_description !== null
            ? data.meta_description
            : data.description
        }
        image={
          typeof data.image !== "undefined" &&
          typeof data.image.url !== "undefined" &&
          data.image.url !== null
            ? data.image.url
            : ""
        }
      />
      {/*<ProductDetailsPageArkansas />*/}
      <div className="product-page-container mb-5">
        <MDBRow className="product-page-main-row no-gutters">
          {/* In Real Life */}
          {/* <InRealLife {...props} /> */}
          {/* WE ARE ASKING GOR CHILDREN FOR NOW
          ATTRIBUTE SET ID WOULD BE HELPFULL TOO*/}
          {props.data?.attribute_set_id !== 9 ? (
            <ProductDetailsPage
              {...props.data}
              skuExceptions={props.skuExceptions}
              player={player}
              setPlayer={setPlayer}
              customerGroupId={customerGroupId}
            />
          ) : window.location.href.includes(
              "/lighting-fixtures/lighted-mirrors/"
            ) ||
            props.data.categories.find(
              (item) => item.url_key == "lighted-mirrors"
            ) ? (
            <LightedMirrors {...props.data} customerGroupId={customerGroupId} />
          ) : (
            <ProductDetailsPageArkansas
              {...props.data}
              customerGroupId={customerGroupId}
            />
          )}
          {/*  <ProductDetailsPage*/}
          {/*      {...props.data}*/}
          {/*      skuExceptions={props.skuExceptions}*/}
          {/*      player={player}*/}
          {/*      setPlayer={setPlayer}*/}
          {/*      customerGroupId={customerGroupId}*/}
          {/*  />*/}

          {/*You May Also Like*/}
          {/*{typeof data.sku !== "undefined" && <YouMayAlsoLike {...props} />}*/}
        </MDBRow>
      </div>
    </>
  );
};

export default Product;
