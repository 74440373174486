import React, { useState } from "react";
import {
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from "mdbreact";

import { unescape } from "lodash";

const ProductDescriptionMobile = ({
  description,
  custom_item_prima_web_drawing,
}) => {
  const [activeItem, setActiveItem] = useState({ activeItem: "1" });

  const toggle = (id) =>
    setActiveItem((prevState) => ({
      activeItem: prevState.activeItem !== id ? id : "",
    }));

  return (
    <div className="md-accordion mt-5">
      <MDBCard className="mt-3" style={{ backgroundColor: "transparent" }}>
        <MDBCollapseHeader
          tagClassName="d-flex justify-content-between"
          onClick={() => toggle("1")}
        >
          Description
          <MDBIcon icon={activeItem.activeItem === "1" ? "minus" : "plus"} />
        </MDBCollapseHeader>
        <MDBCollapse id="1" isOpen={activeItem.activeItem}>
          <MDBCardBody>
            <div
              style={{ fontFamily: "Montserrat", fontSize: "1.8rem" }}
              dangerouslySetInnerHTML={{
                __html: unescape(description.html.replace("?", "")),
              }}
            />
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>

      <MDBCard>
        <MDBCollapseHeader
          tagClassName="d-flex justify-content-between"
          onClick={() => toggle("2")}
        >
          Size and Dimensions
          <MDBIcon icon={activeItem.activeItem === "2" ? "minus" : "plus"} />
        </MDBCollapseHeader>
        {custom_item_prima_web_drawing !== undefined && (
          <MDBCollapse id="2" isOpen={activeItem.activeItem}>
            <MDBCardBody>
              <div>
                <img
                  style={{ height: "30rem" }}
                  src={custom_item_prima_web_drawing}
                  alt="custom_item_prima_web_drawing"
                />
              </div>
            </MDBCardBody>
          </MDBCollapse>
        )}
      </MDBCard>
    </div>
  );
};

export default ProductDescriptionMobile;
