export const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  vertical: true,
  autoplay: false,
  slidesToShow: 3,
  variableWidth: false,
  draggable: false,
  slidesToScroll: 3,
  speed: 1250,
  ease: "linear",
  responsive: [
    {
      breakpoint: 576,
      settings: {
        vertical: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        vertical: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        vertical: false,
      },
    },
  ],
};
