import React, { useEffect, useState, useCallback } from "react";
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBBox,
} from "mdbreact";
import { wishlistTabs } from "./data";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { Redirect, useHistory } from "react-router-dom";
import SingleProduct from "app/pages/catalog/category/listing/singleProduct";
import SortBy from "app/layout/SortBy";
import WishlistProjects from "./wishlistProjects/wishlistProjects";
import Icon from "app/assets/icon/icon";
import Button from "app/core/components/button/button";
import RemoveModal from "./removeProjectModal/removeModal";
import ProjectModal from "./projectModal/projectModal";
import { useMediaQuery } from "react-responsive";
import PdfModal from "./pdfModal/pdfModal";

const WishlistContent = ({}) => {
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
    checkEmail,
  ] = useWishlist();
  const history = useHistory();
  const pathName = history.location.pathname;
  const isTablet = useMediaQuery({ maxWidth: 1440 });
  // const isMobile = useMediaQuery({ maxWidth: 991.98 });

  const pageSortOptions = [
    {
      label: "Date Added",
      value: { custitemsort_date: "DESC" },
    },
    {
      label: "Alphabetical",
      value: { name: "name" },
    },
    {
      label: "SKU",
      value: { name: "sku" },
    },
  ];

  const [sortSelected, setSortSelected] = useState({
    ProjectModal,
    custitemsort_date: "DESC",
  });

  const [myFavorites, setMyFavorites] = useState([]);
  const [project, setProject] = useState("");
  const [modalProject, setModalProject] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [removemodalProject, setRemoveModalProject] = useState(false);
  const [removeModalTitle, setRemoveModalTitle] = useState("");
  const {
    data: { wishlistItems },
  } = wishlist;

  const togglePdfModal = () => {
    setShowPdfModal(!showPdfModal);
  };

  let myWishlistsArr = [...wishlistItems];

  const projects = myWishlistsArr.filter(
    (wishlist) => wishlist.name !== "My Favorites"
  );

  const pdfPricingMarkupOptions = [];
  let i = 1;
  while (i < 5) {
    i += 0.5;
    pdfPricingMarkupOptions.push({
      value: i,
      label: i.toString(),
    });
  }

  const getProjectById = useCallback(() => {
    const project = projects.find(
      (project) => project.id === history.location.search.split("projectId=")[1]
    );
    if (project?.items) {
      if (sortSelected?.name === "ASC") {
        project.items = project.items.sort((a, b) => {
          if (Object.keys(a).length > 0)
            return a.added_at.localeCompare(b.added_at);
        });
      } else if (sortSelected?.name === "name") {
        project.items = project.items.sort((a, b) => {
          if (Object.keys(a).length > 0)
            return a.product.name
              .toLowerCase()
              .localeCompare(b.product.name.toLowerCase());
        });
      } else {
        project.items = project.items.sort((a, b) => {
          if (Object.keys(a).length > 0)
            return a.product.sku.localeCompare(b.product.sku);
        });
      }
    }
    return project;
  }, [history.location.search, sortSelected]);

  const getProjectItemsLength = useCallback(() => {
    let count = 0;
    const project = projects.find(
      (project) => project.id === history.location.search.split("projectId=")[1]
    );
    if (project) {
      project.items.map((item) => {
        if (Object.keys(item).length > 0) {
          count += 1;
        }
      });
    }
    return count;
  }, [history.location.search]);

  useEffect(() => {
    setProject(getProjectById()?.name);
  }, [history.location.search]);

  useEffect(() => {
    setMyFavorites(
      myWishlistsArr.find((wishlist) => wishlist.name === "My Favorites")
    );
  }, [myWishlistsArr]);

  useEffect(() => {
    if (myFavorites?.items) {
      switch (sortSelected.name) {
        case "name":
          return setMyFavorites(
            myFavorites.items?.sort((a, b) =>
              a.product.name
                .toLowerCase()
                .localeCompare(b.product.name.toLowerCase())
            )
          );
        case "sku":
          return setMyFavorites(
            myFavorites.items.sort((a, b) =>
              a.product.sku.localeCompare(b.product.sku)
            )
          );
        default:
          setMyFavorites(
            myFavorites.items.sort((a, b) =>
              a.added_at.localeCompare(b.added_at)
            )
          );
          return;
      }
    }
  }, [sortSelected]);

  useEffect(() => {
    if (pathName === "/customer/wishlist") {
      document.querySelectorAll(".wishlist-tab")[0].click();
    }
  }, []);

  if (myWishlistsArr.length === 0) return <Redirect to={"/"} />;

  const tabId = wishlistTabs.find((tab) => tab.url === pathName)?.id;
  return (
    <div className="wishlist-container">
      <MDBContainer id={`favorites-tabs`} className="d-flex">
        <MDBRow className={`justify-content-start`}>
          {wishlistTabs.map((tab) => {
            return (
              <MDBCol
                xl="1"
                lg="2"
                sm="3"
                size="4"
                key={`wishlist-tab-id-${tab.id}`}
                className={`wishlist-tab mr-5 pl-3 pt-4 pb-3 d-flex align-items-center border-bottom-5 text-center ${
                  pathName === tab.url ? "active" : "inactive"
                }`}
                onClick={(e) => {
                  history.push({
                    pathname: `${tab.url}`,
                  });
                }}
              >
                {/* {tab.id === 1
                            ? tab.name + ` (${myWishlistsArr.length - 1})`
                            : tab.id === 0
                            ? tab.name + ` (${myWishlistsArr[0].items.length})`
                            : tab.name} */}
                {tab.id === 1 ? tab.name : tab.id === 0 ? tab.name : tab.name}
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
      <MDBContainer id={`middle`}>
        <MDBRow>
          {tabId === 0 && (
            <h5>
              Your Favorites <span>{myFavorites?.items_count} items</span>
            </h5>
          )}
          {tabId === 1 ? (
            history.location.search === "" ? (
              <h5>
                Projects <span>{projects.length} items</span>
              </h5>
            ) : (
              <div className="d-flex align-items-center">
                <Icon
                  icon={`backArrow`}
                  width="15px"
                  height="15px"
                  className="mr-3 cursor-pointer"
                  onClick={() => history.push({ search: "" })}
                />
                <h5>
                  {getProjectById()?.name !== project &&
                  project &&
                  !modalProject
                    ? project
                    : getProjectById()?.name}
                  <div className="d-flex align-items-start">
                    <span>{getProjectItemsLength()} items</span>
                    <Icon
                      icon={`editSmall`}
                      width="16px"
                      height="16px"
                      className="ml-4 cursor-pointer"
                      onClick={() => {
                        setModalProject(!modalProject);
                      }}
                    />
                  </div>
                </h5>
              </div>
            )
          ) : null}
          {(tabId === 0 || (tabId === 1 && history.location.search !== "")) && (
            <SortBy
              pageSortOptions={pageSortOptions}
              sortSelected={sortSelected}
              setSortSelected={setSortSelected}
            />
          )}
        </MDBRow>
      </MDBContainer>
      {tabId === 0 && (
        <MDBContainer id={`listing`}>
          {!myFavorites ||
            (myFavorites?.items?.length === 0 && (
              <p>Your wishlist is empty.</p>
            ))}
          <MDBRow className={`products-list ${isTablet ? "tab_mobile" : ""}`}>
            {pathName === "/customer/wishlist/" &&
              myFavorites?.items?.map(({ product }) => (
                <SingleProduct
                  image={product?.small_image || product?.image}
                  name={product?.name}
                  sku={product?.sku}
                  url={product?.url_key}
                  product={product}
                  // isWishlist={true}
                />
              ))}
          </MDBRow>
        </MDBContainer>
      )}
      {pathName === "/customer/wishlist/projects" && (
        <WishlistProjects projects={projects} />
      )}
      {modalProject && (
        <ProjectModal
          setModalProject={setModalProject}
          modalProject={modalProject}
          removemodalProject={removemodalProject}
          setRemoveModalProject={setRemoveModalProject}
          projectName={getProjectById()?.name}
          editWishlist={editWishlist}
          setProject={setProject}
          project={project}
          setRemoveModalTitle={setRemoveModalTitle}
        />
      )}
      {removemodalProject && (
        <RemoveModal
          removemodalProject={removemodalProject}
          setRemoveModalProject={setRemoveModalProject}
          projectName={getProjectById()?.name}
          deleteWishlist={deleteWishlist}
          history={history}
          project={project}
          setProject={setProject}
          title={removeModalTitle}
          removeFromWishlist={removeFromWishlist}
          projectById={getProjectById()}
        />
      )}
      {(pathName !== "/customer/wishlist/projects" ||
        history.location.search !== "") && (
        <MDBContainer
          id={`bottom`}
          className="d-flex mt-4"
          style={{
            borderTop:
              getProjectById()?.items.length === 0
                ? "none"
                : "2px solid #e1e1e1",
          }}
        >
          <MDBRow
            className={`justify-content-between d-flex justify-content-between w-100`}
          >
            {getProjectById()?.items?.length > 0 && (
              <div
                className="d-flex cursor-pointer"
                onClick={() => {
                  setRemoveModalTitle("Remove All Items");
                  setRemoveModalProject(!removemodalProject);
                }}
              >
                <Icon
                  icon={"trashSmall"}
                  width={25}
                  height={25}
                  className={`mr-2 cursor`}
                />
                <p>REMOVE ALL</p>
              </div>
            )}
            {getProjectById()?.items?.length &&
              pathName === "/customer/wishlist/projects" && (
                <p>Your project list is empty.</p>
              )}
            {tabId === 0 && (
              <div
                className="d-flex cursor-pointer"
                onClick={() => togglePdfModal()}
              >
                <Icon
                  icon={"downloadPdf"}
                  width={25}
                  height={25}
                  className={`mr-2 cursor`}
                />
                <p>DOWNLOAD PDF</p>
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      )}
      {showPdfModal && (
        <PdfModal
          selectedWishlist={myWishlistsArr}
          showPdfModal={showPdfModal}
          togglePdfModal={togglePdfModal}
          pdfPricingMarkupOptions={pdfPricingMarkupOptions}
        />
      )}
    </div>
  );
};

export default WishlistContent;
