import React, { useMemo } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import queryLoader from "app/graphql/queryLoader";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import useLocation from "react-router-dom";
const query = queryLoader("klevuSearchProductUrl");

const ProductItemGridView = (props) => {
  let { item } = props;

  const wishlistGrid = props && props.wishlistGrid ? props.wishlistGrid : null;

  const { data, error } = useQuery(query, {
    fetchPolicy: "cache-first",
    variables: { product_id: item?.id },
    skip: !item,
  });

  if (wishlistGrid) {
    item = item.product;
  }

  const finalUrl = useMemo(() => {
    if (data?.klevuSearchProductUrl?.product_url) {
      let url = data?.klevuSearchProductUrl?.product_url.split("/");
      return `/${url[url.length - 1]}`;
    }
    return "/";
  }, [data]);

  return (
    <MDBContainer className="product-item-container mt-5">
      <Link
        onClick={(e) => {
          props.setDisplaySearch(false);
          window.location.replace(finalUrl);
        }}
      >
        <MDBRow className="product-item-image-row">
          <MDBCol lg="6" sm="6" xs="6" className="product-item-image-column">
            {item.custitem_closeout && item.custitem_closeout === "Yes" && (
              <div
                className={"closeout-item"}
                style={{
                  fontSize: "0.7em",
                  lineHeight: "28px",
                  top: "0",
                  left: "0",
                }}
              >
                Limited Inventory
              </div>
            )}

            {item.ark_closeout && item.ark_closeout === "1" && (
              <div
                className={"closeout-item"}
                style={{
                  fontSize: "0.7em",
                  lineHeight: "28px",
                  top: "0",
                  left: "0",
                }}
              >
                Clearance
              </div>
            )}
            {item.basics_flag && (
              <div
                className={"closeout-item"}
                style={{
                  fontSize: "0.7em",
                  lineHeight: "28px",
                  top: item.ark_closeout ? "5rem" : 0,
                  left: "0",
                }}
              >
                Basics
              </div>
            )}

            <img
              className={item.imageUrl.includes("placeholder") && "placeholder"}
              src={
                item.imageUrl.includes("placeholder")
                  ? "/public/placeholder.png"
                  : item?.imageUrl.replace("needtochange/", "")
              }
              alt="product-alt"
            />
          </MDBCol>
          <MDBCol
            lg="6"
            sm="6"
            xs="6"
            className="d-flex flex-column align-items-center justify-content-center product-item-info"
          >
            <p className="my-1 p">{item.name}</p>
            <p className="my-1 p">{"SKU" + " " + item.sku}</p>
          </MDBCol>
        </MDBRow>
      </Link>
    </MDBContainer>
  );
};
export default ProductItemGridView;
