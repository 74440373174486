import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import RelatedProducts from "./related-products";

const PRODUCTS_QUERY = gql`
  query getCategoryProducts(
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
    $categoryId: String
  ) {
    products(
      pageSize: $pageSize
      sort: $sort
      currentPage: $currentPage
      filter: { category_id: { eq: $categoryId } }
    ) {
      total_count
      items {
        name
        sku
        url_key
        small_image {
          url
          label
        }
      }
    }
  }
`;
const RelatedProductsQueryContainer = ({ currentCategory }) => {
  let subcategoryIndex = currentCategory.length - 1;
  let categoryId = currentCategory[subcategoryIndex].id.toString();

  let { data, loading, error } = useQuery(PRODUCTS_QUERY, {
    variables: { categoryId },
  });

  if (error) {
    console.warn(error);
  }
  return <RelatedProducts data={data} />;
};

export default RelatedProductsQueryContainer;
