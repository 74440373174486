import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "core/graphql/queryLoader";
import { isEmpty } from "core/helpers/functions";
import { apolloClientMutation } from "app/graphql/apolloClient";
import Hotspot from "./hotspot";

const query = queryLoader("products");

const HotspotQueryLoader = (props) => {
  let dots = [];
  let skuArray = [];
  let contentArray = [];
  contentArray = props.contentArray;
  if (contentArray !== null && contentArray !== undefined) {
    contentArray.map((dot) => {
      if (dot.product === null) return;
      if (dot.product.sku !== "") skuArray.push(dot.product.sku);
      dots.push({
        top: dot.top,
        left: dot.left,
        display_type: dot.display_type,
        sku: dot.product.sku,
        addInfo: dot.addInfo,
        isVisible: false,
      });
    });
  }
  const { data, loading, error } = useQuery(query, {
    variables: { filters: { sku: { in: skuArray } } },
    client: apolloClientMutation,
  });

  if (error) return <></>;
  if (!isEmpty(skuArray)) {
    if (data !== undefined) {
      data.products.items.map((info) => {
        let found = null;
        dots.find((value, index) => {
          if (value.sku === info.sku) {
            found = index;
          }
        });
        if (found !== null) {
          dots[found].image = info.image.url;
          dots[found].name = info.name;
          dots[found].content = info.name;
          dots[found].price = info.price_range.minimum_price.final_price.value;
          dots[found].priceCurrency =
            info.price_range.minimum_price.final_price.currency;
          dots[found].url = info.url_key;
          dots[found].colors = info.upsell_products.length;
          found += 1;
          return info;
        }
      });
    }
  }
  return (
    <>
      <Hotspot dots={dots} {...props} />
    </>
  );
};

export default HotspotQueryLoader;
