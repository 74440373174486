import React, {useState}  from "react";
import { MDBCol, MDBRow, MDBBox } from "mdbreact";
import {
  FooterPrimaInfo,
  FooterArkansasInfo,
} from "./footer-left-section-query-container";
import {
  FooterPrimaLogo,
  FooterArkansasLogo,
} from "./footer-left-section-query-container";
const Left = () => {
  const [leftFooterArkansasLogoStatus, setFooterArkansasLogoStatus] = useState();
  const [leftFooterArkansasInfoStatus, setFooterArkansasInfoStatus] = useState();
 if (FooterArkansasLogo){
   //console.log("footer akransas logo")
    }
  return (
    <MDBRow className="left-part">
      <MDBCol className="d-flex footer-column">
        <MDBBox tag="a" className="logo prima-logo">
          <FooterPrimaLogo />
        </MDBBox>
        <MDBBox className="prima-info">
          <FooterPrimaInfo />
        </MDBBox>
      </MDBCol>
      <MDBCol md="6" className="d-flex footer-column">
        {/*<MDBBox tag="a" className="logo arkansas-logo">*/}
        {/*  <FooterArkansasLogo setFooterArkansasLogoStatus={setFooterArkansasLogoStatus}/>*/}
        {/*</MDBBox>*/}
        <MDBBox className="arkansas-info">
          <FooterArkansasInfo setFooterArkansasInfoStatus={setFooterArkansasInfoStatus}/>
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};

export default Left;
