import React from "react";
import { MDBContainer, MDBBox, MDBTypography, MDBRow, MDBCol } from "mdbreact";
import {
  UpcomingImage,
  UpcomingDateTitle,
  UpcomingContent,
  UpcomingMainTitle,
} from "./upcoming-section-query-container";
const UpcomingSection = (props) => {
  return (
    <MDBContainer className="upcoming-main-container">
      <MDBBox className="section-title d-flex align-items-center ">
        <hr />
        <MDBTypography tag="h5" variant="h5">
          <UpcomingMainTitle />
        </MDBTypography>
        <hr />
      </MDBBox>
      <MDBRow>
        <MDBCol>
          <MDBBox className="upcoming-container">
            <MDBBox className="position-relative">
              <UpcomingImage />
              <UpcomingDateTitle />
            </MDBBox>
            <MDBBox className="upcoming-text">
              <UpcomingContent />
            </MDBBox>
          </MDBBox>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default UpcomingSection;
