import React from "react";
import { useQuery } from "react-apollo-hooks";

import gql from "graphql-tag";
import QuoteRequestThanks from "./quote-request-thank-you";

export const ids = [
  "quote_thanks_title",
  "quote_thanks_text",
  "quote_thanks_continue_button",
  "quote_thanks_home_button",
  "quote_thanks_image",
];

const QUOTE_REQUEST_THANKS = gql`
  query cmsBlock($ids: [String!]!) {
    cmsBlocks(identifiers: $ids) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const QuoteRequestThanksContainer = () => {
  const { data, loading } = useQuery(QUOTE_REQUEST_THANKS, {
    variables: { ids },
  });
  if (loading) {
    return null;
  }
  return <QuoteRequestThanks data={data} contentMap={ids} />;
};
