import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBInputGroup,
  MDBBtn,
  MDBLink,
  MDBTypography,
} from "mdbreact";
import SessionMessages from "core/components/sessionMessages";

const LoginForm = ({
  handleLoginSubmit,
  onEmailChangeHandler,
  onPasswordChangeHandler,
  email,
  password,
  loading,
  hasEmailError,
  hasPasswordError,
  errorEmailMessage,
  errorPasswordMessage,
  toggleModal,
}) => {
  return (
    <form style={{ marginTop: "40px" }} onSubmit={handleLoginSubmit} id="PL-login-form">
      <MDBRow className={`flex-column m-0`}>
        <MDBCol>
          <MDBInputGroup
            disabled={loading}
            value={email}
            onChange={onEmailChangeHandler}
            style={{ margin: "auto" }}
            required
            material
            hint="Enter Email *"
            label="Email"
            labelClassName={
              hasEmailError
                ? `input-label input-login-label error-label`
                : `input-label input-login-label`
            }
            className={hasEmailError && `error-input`}
            group
            type="email"
          />
          {hasEmailError && (
            <MDBTypography colorText="red" style={{ marginLeft: "40px" }}>
              {errorEmailMessage}
            </MDBTypography>
          )}
        </MDBCol>
        <MDBCol style={{ marginTop: "20px" }}>
          <MDBInputGroup
            disabled={loading}
            value={password}
            onChange={onPasswordChangeHandler}
            style={{ margin: "auto" }}
            required
            material
            hint="Enter Password *"
            label="Password"
            labelClassName={
              hasPasswordError
                ? `input-label input-login-label error-label`
                : `input-label input-login-label`
            }
            className={hasPasswordError && `error-input`}
            group
            type="password"
          />
          {hasPasswordError && (
            <MDBTypography colorText="red" style={{ marginLeft: "40px" }}>
              {errorPasswordMessage}
            </MDBTypography>
          )}
        </MDBCol>
      </MDBRow>

      <MDBCol>
        <MDBTypography
          colorText={`red`}
          style={{ marginLeft: "40px", marginTop: "40px" }}
        >
          <SessionMessages target="login" />
        </MDBTypography>
      </MDBCol>
      <MDBCol style={{ marginTop: "40px" }}>
        <MDBBtn size="lg" type="submit" disabled={loading}>
          Login
        </MDBBtn>
      </MDBCol>
      <MDBCol>
        <MDBLink
          className="text-muted text-capitalize p-0 forgot-password-link"
          to="/forgot-password"
          onClick={() => {
            toggleModal();
          }}
        >
          Forgot Password?
        </MDBLink>
      </MDBCol>
    </form>
  );
};

export default LoginForm;
