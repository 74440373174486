import React from "react";
import { connect } from "react-redux";
import SearchResultsQueryContainer from "./searchResults-query-container";
import { searchActions } from "core/state/redux/data/search";

const SearchResultsReduxContainer = (props) => {
  let {
    search,
    closeSearch,
    mobileonclick,
    changeSearch,
    setDisplaySearch,
    token,
    tmpSearch,
  } = props;
  if (typeof tmpSearch === "undefined" || tmpSearch.length < 1) {
    return <></>;
  }

  return (
    <SearchResultsQueryContainer
      token={token}
      search={search}
      closeSearch={closeSearch}
      mobileonclick={mobileonclick}
      changeSearch={changeSearch}
      setDisplaySearch={setDisplaySearch}
      tmpSearch={tmpSearch}
    />
  );
};
const mapStateToProps = (state) => ({
  search: state.search.search,
  allCategories: state.queries.data,
  allCategoriesTmp: state.queries.temp,
  token:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.token !== "undefined"
      ? state.customer.data.token
      : false,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeSearch: () => {
      dispatch(searchActions.toggleSearch(false));
    },
    changeSearch: (searchTerm) => {
      dispatch(searchActions.changeSearch(searchTerm));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsReduxContainer);
