import CmsBlock from 'app/layout/cmsBlock';
import React from 'react';
import classes from './lightedMirrorsPage.module.css'

export const LightedMirrorsPage = () => {
    return (
        <div className={classes.root}>
            <CmsBlock id="lighted-mirrors" />
        </div>
    )
}