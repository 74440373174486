import React from "react";
import {
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBCol,
  MDBTypography,
  MDBBox,
} from "mdbreact";
import ArrowRight from "app/assets/images/right-arrow.png";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Icon from "app/assets/icon/icon";

const Pagination = ({
  totalPages,
  pageSizeOptions,
  showPagesize = true,
  totalNumOfItems,
  isBottom,
}) => {
  const history = useHistory();
  let search = queryString.parse(history.location.search);
  let currentPage = search?.page ? parseInt(search.page) : 1;
  let currentPageSize = search?.pageSize ? parseInt(search.pageSize) : 36;
  let nextPage = currentPage < totalPages ? currentPage + 1 : false;
  let prevPage = currentPage > 1 ? currentPage - 1 : false;
  const [showDropDown, setShowDropDown] = React.useState(false);
  return (
    <MDBPagination className="pagination-container">
      {showPagesize && (
        <MDBCol className="page-number-container">
          <MDBBox
            className={`d-flex justify-content-between show-more show-more-bottom-${isBottom} ${
              (totalNumOfItems <= 36 && "disabled") || ""
            }`}
          >
            <MDBTypography tag="p">Show:</MDBTypography>
            <MDBTypography className="ml-2" tag="p">
              {currentPageSize}
              <Icon className="ml-1" icon={"arrowDown"} />
            </MDBTypography>
          </MDBBox>
          <ul
            onMouseLeave={() => {
              setShowDropDown(false);
            }}
            onClick={(e) => {
              if (totalNumOfItems <= 36 && totalPages === 1) {
                e.preventDefault();
              } else {
                setShowDropDown(!showDropDown);
              }
            }}
          >
            {pageSizeOptions.map((page, index) => {
              return (
                <>
                  {showDropDown ? (
                    <li
                      className={`${
                        page.value === currentPageSize ? "active" : ""
                      }`}
                      onClick={() => {
                        if (
                          (search.pageSize === undefined &&
                            page.value !== pageSizeOptions[1].value) ||
                          search.pageSize !== undefined
                        ) {
                          search = {
                            ...search,
                            pageSize: page.value,
                            page: currentPage,
                          };
                          history.push({
                            pathname: history.location.pathname,
                            search: queryString.stringify(search),
                          });
                        }
                      }}
                      key={index}
                    >
                      {page.label}
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </ul>
        </MDBCol>
      )}
      <MDBCol
        className={`d-flex justify-content-end pagination-inner pagination-bottom-${isBottom}`}
      >
        {prevPage && (
          <>
            <MDBPageItem
              onClick={() => {
                search = { ...search, page: prevPage };
                history.push({
                  pathname: history.location.pathname,
                  search: queryString.stringify(search),
                });
              }}
            >
              <MDBPageNav aria-label="Previous">
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src={ArrowRight}
                  alt=""
                />
              </MDBPageNav>
            </MDBPageItem>
            <MDBPageItem
              onClick={() => {
                search = { ...search, page: prevPage };
                history.push({
                  pathname: history.location.pathname,
                  search: queryString.stringify(search),
                });
              }}
            >
              <MDBPageNav>{prevPage}</MDBPageNav>
            </MDBPageItem>
          </>
        )}

        <MDBPageItem>
          {nextPage && <MDBPageNav>{currentPage}</MDBPageNav>}
        </MDBPageItem>
        {nextPage && (
          <>
            <MDBPageItem
              onClick={() => {
                search = { ...search, page: nextPage };
                history.push({
                  pathname: history.location.pathname,
                  search: queryString.stringify(search),
                });
              }}
            >
              <MDBPageNav>{nextPage}</MDBPageNav>
            </MDBPageItem>
            <MDBPageItem
              onClick={() => {
                search = { ...search, page: nextPage };
                history.push({
                  pathname: history.location.pathname,
                  search: queryString.stringify(search),
                });
              }}
            >
              <MDBPageNav aria-label="Previous">
                <img src={ArrowRight} alt="" />
              </MDBPageNav>
            </MDBPageItem>
          </>
        )}
      </MDBCol>
    </MDBPagination>
  );
};

export default Pagination;
