import React from "react";

import {
  MDBBox,
  MDBTypography,
  MDBCard,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBCardBody,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import { Link } from "react-router-dom";

const MobileMenuTop = ({
  menuItems,
  activeCollapse,
  setActiveCollapse,
  toggleMobileMenu,
  closeBurger,
}) => {
  return (
    <MDBBox className="md-accordion mobile-menu-accordion m-0 p-0">
      {typeof menuItems !== "undefined" &&
        menuItems.map((item, index) => {
          let subitemsArray = item?.children;
          subitemsArray.sort((a, b) => a.position - b.position);

          const isOpen = activeCollapse === index;
          return (
            <MDBCard key={index}>
              <MDBCollapseHeader
                style={{ borderBottom: "solid 10px red !important" }}
                onClick={() => {
                  item.children.length !== 0 && setActiveCollapse(index);
                  if (isOpen) {
                    setActiveCollapse(false);
                  }
                }}
                tagClassName="d-flex justify-content-between mobile-menu-header"
              >
                {item.children.length === 0 ? (
                  <>
                    <Link
                      to={`/${item.url_key}`}
                      onClick={closeBurger}
                      className={`d-flex align-items-center justify-content-between w-100`}
                    >
                      {item.name}
                      <MDBIcon icon={isOpen ? "angle-down" : "angle-right"} />
                    </Link>
                  </>
                ) : (
                  <>
                    {item.name}
                    <MDBIcon icon={isOpen ? "angle-down" : "angle-right"} />
                  </>
                )}
              </MDBCollapseHeader>
              <MDBCollapse isOpen={isOpen}>
                {item.id !== 3 && (
                  <MDBCardBody>
                    {item.children.map((submenuItem) => (
                      <MDBTypography className="mobile-menu-body">
                        <Link
                          onClick={closeBurger}
                          to={
                            submenuItem.id === 64 ||
                            submenuItem.id === 65 ||
                            submenuItem.id === 122
                              ? `/${submenuItem.url_key}`
                              : `/${submenuItem.url_path}`
                          }
                        >
                          {submenuItem.name}
                        </Link>
                      </MDBTypography>
                    ))}
                  </MDBCardBody>
                )}
                {item.id === 3 && (
                  <MDBCardBody>
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontWeight: 900,
                        fontSize: "12px",
                        color: "#49494f",
                        marginLeft: "10px",
                      }}
                    >
                      Pendants
                    </p>
                    {item.children.map((submenuItem) => (
                      <>
                        {(submenuItem.name === "Interchangeable Pendants" ||
                          submenuItem.name === "RLM Pendants" ||
                          submenuItem.name === "Imported Pendant Lighting" ||
                          submenuItem.name === "Made in USA Pendants") && (
                          <MDBTypography className="mobile-menu-body">
                            <Link
                              onClick={closeBurger}
                              to={
                                item.id === 64 ||
                                item.id === 65 ||
                                item.id === 122
                                  ? `/${submenuItem.url_key}`
                                  : `/${submenuItem.url_path}`
                              }
                            >
                              {submenuItem.name}
                            </Link>
                          </MDBTypography>
                        )}
                      </>
                    ))}
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontWeight: 900,
                        fontSize: "12px",
                        color: "#49494f",
                        marginLeft: "10px",
                      }}
                    >
                      Wall Mounted
                    </p>
                    {item.children.map((submenuItem) => (
                      <>
                        {(submenuItem.name === "ADA Wall Sconce" ||
                          submenuItem.name === "Non ADA Wall Lights" ||
                          submenuItem.name === "Bathroom Vanities") && (
                          <MDBTypography className="mobile-menu-body">
                            <Link
                              onClick={() => {
                                // toggleMobileMenu();
                                closeBurger();
                              }}
                              to={
                                submenuItem.id === 64 ||
                                submenuItem.id === 65 ||
                                submenuItem.id === 122
                                  ? `/${submenuItem.url_key}`
                                  : `/${submenuItem.url_path}`
                              }
                            >
                              {submenuItem.name}
                            </Link>
                          </MDBTypography>
                        )}
                      </>
                    ))}
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontWeight: 900,
                        fontSize: "12px",
                        color: "#49494f",
                        marginLeft: "10px",
                      }}
                    >
                      Ceiling Mounted
                    </p>
                    {item.children.map((submenuItem) => (
                      <>
                        {(submenuItem.name === 'Less Than 12" Diameter' ||
                          submenuItem.name === 'Between 12"-18" Diameter' ||
                          submenuItem.name === 'Greater Than 18" Diameter') && (
                          <MDBTypography className="mobile-menu-body">
                            <Link
                              onClick={() => {
                                // toggleMobileMenu();
                                closeBurger();
                              }}
                              to={
                                submenuItem.id === 64 ||
                                submenuItem.id === 65 ||
                                submenuItem.id === 122
                                  ? `/${submenuItem.url_key}`
                                  : `/${submenuItem.url_path}`
                              }
                            >
                              {submenuItem.name}
                            </Link>
                          </MDBTypography>
                        )}
                      </>
                    ))}
                    {item.children.map((submenuItem) => (
                      <>
                        {(submenuItem.name === "Recessed Lights" ||
                          submenuItem.name === "Lighted Mirrors" ||
                          submenuItem.name === "Linear LED Fixtures" ||
                          submenuItem.name === "Interchangeable Track Heads" ||
                          submenuItem.name === "Display / Picture Lights") && (
                          <MDBTypography className="mobile-menu-body">
                            <Link
                              style={{
                                fontWeight: 900,
                                color: "#49494f",
                                fontSize: "13px",
                                marginLeft: "10px",
                              }}
                              onClick={closeBurger}
                              to={
                                submenuItem.id === 64 ||
                                submenuItem.id === 65 ||
                                submenuItem.id === 122
                                  ? `/${submenuItem.url_key}`
                                  : `/${submenuItem.url_path}`
                              }
                            >
                              {submenuItem.name}
                            </Link>
                          </MDBTypography>
                        )}
                      </>
                    ))}
                  </MDBCardBody>
                )}
              </MDBCollapse>
            </MDBCard>
          );
        })}
    </MDBBox>
  );
};

export default MobileMenuTop;
