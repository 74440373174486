import { apolloClientMutation } from "app/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "app/graphql/queryLoader";
// import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";

const GUEST_CART = queryLoader("cartData");
const CUSTOMER_CART = queryLoader("customerCart");
const MERGE_CARTS = queryLoader("mergeCarts");
export default async (store, action) => {
  let cart_id = undefined;
  if (typeof store.getState().customer !== "undefined") {
    if (typeof store.getState().customer.data !== "undefined") {
      cart_id = store.getState().customer.data.cartToken;
    }
  }

  let customerState = store.getState().customer;
  let finalQuery = null;
  let mergedCarts = null;
  if (customerState.data !== undefined) cart_id = customerState.data.cartToken;

  if (
    typeof customerState.data !== "undefined" &&
    typeof customerState.data.token !== "undefined" &&
    customerState.data.token !== null &&
    customerState.data.token !== false
  ) {
    finalQuery = CUSTOMER_CART;
  } else {
    finalQuery = GUEST_CART;
  }
  if (cart_id === undefined) {
    store.dispatch(customerActions.createEmptyCart(action.oldCartToken));
    return;
  }
  try {
    const { data } = await apolloClientMutation.query({
      query: finalQuery,
      variables: { id: cart_id },
      fetchPolicy: "no-cache",
    });
    if (Object.keys(data).includes("cart")) {
      if (action.oldCartToken !== undefined) {
        const { data } = await apolloClientMutation.query({
          query: MERGE_CARTS,
          variables: {
            source_cart_id: action.oldCartToken,
            destination_cart_id: store.getState().customer.data.cartToken,
          },
          fetchPolicy: "no-cache",
        });

        mergedCarts = data;
      }

      if (mergedCarts !== null) {
        store.dispatch(
          customerActions.setCartInformation(mergedCarts.mergeCarts)
        );
      } else {
        store.dispatch(customerActions.setCartInformation(data.cart));
      }
    } else {
      if (action.oldCartToken !== undefined) {
        const { data } = await apolloClientMutation.query({
          query: MERGE_CARTS,
          variables: {
            source_cart_id: action.oldCartToken,
            destination_cart_id: store.getState().customer.data.cartToken,
          },
          fetchPolicy: "no-cache",
        });
        mergedCarts = data;
      }
      if (mergedCarts !== null) {
        store.dispatch(
          customerActions.setCartInformation(mergedCarts.mergeCarts)
        );
      } else {
        store.dispatch(customerActions.setCartInformation(data.customerCart));
      }
    }
  } catch (error) {
    console.log(error);
    if (
      error.message.includes("The request is allowed for logged in customer")
    ) {
      store.dispatch(commonActions.unlock());
      return;
    } else {
      if (error.message.includes("Could not find a cart with ID")) {
        store.dispatch(customerActions.createEmptyCart(action.oldCartToken));
        //store.dispatch(customerActions.getCartInformation());
      } else {
        if (
          !error.message.includes(
            'Variable "$id" of required type "String!" was not provided'
          )
        ) {
          // store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
        }
      }
      store.dispatch(commonActions.unlock());
      store.dispatch(queryActions.removeQuery());
      return;
    }
  }
};
