import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
} from "mdbreact";
import config from "config/themeConfiguration";
import "./style.scss";

const PdpCarousel = ({ images, activeImageId }) => {
  const visibleImages = images.filter((image) => image.file);

  return (
    <MDBContainer className="pdp-carousel-container">
      <MDBCarousel
        activeItem={activeImageId + 1}
        length={visibleImages.length}
        showControls={true}
        thumbnails={true}
        className="z-depth-1"
        slide={true}
        interval={1000000}
      >
        <MDBCarouselInner>
          {visibleImages?.map((image, id) => (
            <MDBCarouselItem itemId={id + 1}>
              <img
                className="d-block pdp-carousel-image"
                src={
                  config.magento_url +
                  config.media_url +
                  config.catalog_url +
                  image?.file
                }
                alt={image?.label || ""}
              />
            </MDBCarouselItem>
          ))}
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  );
};

export default PdpCarousel;
