import React from "react";
import ContentQueryContainer from "./content-query-container";
import Content from "./content";

const ContentPreQueryContainer = (props) => {
  let { countrySelect, selectState, canSearch } = props;
  if (selectState === "" || countrySelect === "" || canSearch === false) {
    return <Content repLocators={undefined} canSearch={canSearch} />;
  }
  return <ContentQueryContainer {...props} canSearch={canSearch} />;
};

export default ContentPreQueryContainer;
