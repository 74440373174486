import React from "react";
import CatalogsPage from "./catalogs-page";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";
import gql from "graphql-tag";
import SeoHelmet from "app/layout/seoHelmet";

const GET_CATALOGS = gql`
  query GetCatalogs {
    getCatalog {
      catalogs {
        catalogs_id
        fliphtml5
        image
        pdf
        title
        url
      }
    }
  }
`;

const CatalogQueryContainer = (props) => {
  let { data, error, loading } = useQuery(GET_CATALOGS);
  if (error) {
    return console.error("ERROR");
  }
  if (loading) {
    return <ReactLoader />;
  }
  return (
    <>
      <SeoHelmet title="Brochures" url={`/brochures`} />
      <CatalogsPage data={data} />
    </>
  );
};

export default CatalogQueryContainer;
