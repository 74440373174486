/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import axios from "axios";
import themeConfiguration from "config/themeConfiguration";
import { getPaymentMethods } from "utils/getPaymentMethods";
import {
  SET_PAYMENT_METHOD,
  PLACE_ORDER,
  SET_SHIPPING_METHODS,
  SET_ADDRESSES,
} from "utils/mutations";
import { MDBCol } from "mdbreact";
import QuoteForm from "./quoteForm";
import useGAEventsTracker from "app/state/hooks/reactGaHook/reactGaHook";
import { useDispatch } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import Countries from "config/data/countries";

const ContactInformation = ({
  cartId,
  message,
  setMessage,
  sku,
  currentSkuAndComments,
}) => {
  // TODO: refactor, split to more components
  const dispatch = useDispatch();
  const createEmptyCart = () => dispatch(customerActions.createEmptyCart());
  const GAEventsTracker = useGAEventsTracker("Submit Quote");
  const [activeCollapse, setActiveCollapse] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [fileForUpload, setFileForUpload] = useState();
  const { customer } = useCustomer();
  const [fileForGql, setFileForGql] = useState("");
  const [nonUsState, setNonUsState] = useState("");
  const { register, handleSubmit, errors, setValue, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { getCountryById, getTelephoneMaskByCountry } = useCustomer();
  const { countries } = Countries;

  let preloadedData = customer?.addresses[0]?.region;
  const selectedCountry = getCountryById(customer?.addresses[0]?.country_code);
  let defaultRegions;
  if (
    customer?.addresses[0]?.country_code === "US" ||
    customer?.addresses[0]?.country_code === "CA"
  ) {
    defaultRegions = selectedCountry.available_regions.map((region) => ({
      value: region.id,
      label: region.name,
      code: region.code,
      id: region.id,
      name: region.name,
    }));
  } else {
    defaultRegions = [];
  }

  const onStateChangeHandler = (value) => setState(value);
  const onCountryChangeHandler = (value) => {
    if (value.value === "CA") {
      setState({ value: 0, label: "Select Province" });
    } else {
      setState({ value: 0, label: "Select State" });
    }
    let _availableStates = value.available_regions
      ? value.available_regions.map((region) => {
          return {
            value: region.id,
            label: region.name,
            code: region.code,
            id: region.id,
            name: region.name,
          };
        })
      : [];
    setCountry(value);
    setAvailableStates(_availableStates);
  };

  const [availableStates, setAvailableStates] = useState([...defaultRegions]);
  const [country, setCountry] = useState({
    label:
      selectedCountry === null
        ? "Select Country *"
        : selectedCountry?.full_name_english,
    available_regions: [...defaultRegions],
    value: selectedCountry?.id,
  });
  const states = country && country.available_regions;
  let telephoneMask = getTelephoneMaskByCountry(country.value);
  const [state, setState] = useState({
    label: preloadedData?.region,
    value: preloadedData?.region_id,
    code: preloadedData?.region_code,
  });

  const triggerUploadFile = () => {
    document.getElementById("file-upload-id").click();
  };
  const url =
    themeConfiguration.magento_url + "rest/V1/jola-customerorders/uploadImage";
  const handleFileUpload = async () => {
    let file = fileForUpload;

    if (typeof file !== "undefined" && file.type !== undefined) {
      const formData = new FormData();
      formData.append("file", file);

      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            console.log("File uploaded");
            setFileForGql(data.data);
          }
        })
        .catch((err) => {
          console.log("Failed to upload file ", err);
        });
    }
  };

  useEffect(() => {
    handleFileUpload();
  }, [fileForUpload]);

  const [
    setAddresses,
    { loading: loadingAddresses, error: errorAddresses },
  ] = useMutation(SET_ADDRESSES);

  const [
    setShippingMethod,
    { loading: shippingLoading, error: shippingError },
  ] = useMutation(SET_SHIPPING_METHODS, {
    context: {
      headers: {
        authorization: customer.token,
      },
    },
  });

  const paymentMethods = getPaymentMethods(customer.token, cartId);
  const paymentCode =
    (paymentMethods &&
      paymentMethods.cart &&
      paymentMethods.cart.available_payment_methods &&
      paymentMethods.cart.available_payment_methods[0].code) ||
    "checkmo";

  const [
    setPaymentMethod,
    { loading: paymentLoading, error: paymentError },
  ] = useMutation(SET_PAYMENT_METHOD);

  const [
    placeOrder,
    { loading: orderLoading, error: orderError },
  ] = useMutation(PLACE_ORDER, {
    context: { headers: { authorization: customer.token } },
  });

  const loading =
    loadingAddresses || shippingLoading || paymentLoading || orderLoading;

  const error = errorAddresses || shippingError || paymentError || orderError;
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  let hasStateOrProvince = availableStates?.length > 0;

  useEffect(() => {
    if (state.value === 0 && hasStateOrProvince) {
      setStateError(true);
    }
  }, [state]);

  if (error) return null;
  if (shouldRedirect) return <Redirect to="quote-request-thank-you" />;
  return (
    <MDBCol className="md-accordion mt-2 p-0">
      <form
        id="PL-quote-request-form"
        className="w-100"
        onSubmit={handleSubmit(async (formData) => {
          if (selectedCountry === null) {
            setCountryError(true);
          }
          let inputUsAndCa = {
            cart_id: cartId,
            firstname: formData.firstName,
            lastname: formData.lastName,
            company: formData.company,
            street: [formData.address],
            city: formData.city,
            country: country?.value,
            region: state?.code,
            region_id: state?.id,
            postalcode: formData.postalCode,
            telephone: formData.phone,
            comment: message,
            image: fileForGql,
          };
          let inputNonUsAndCa = {
            cart_id: cartId,
            firstname: formData.firstName,
            lastname: formData.lastName,
            company: formData.company,
            street: [formData.address],
            city: formData.city,
            country: country?.value,
            region: nonUsState,
            postalcode: formData.postalCode,
            telephone: formData.phone,
            comment: message,
            image: fileForGql,
          };
          if (
            typeof country?.value !== "undefined" &&
            !stateError &&
            hasStateOrProvince
          ) {
            await setAddresses({
              variables: inputUsAndCa,
            });
            await setShippingMethod({
              variables: {
                cart_id: cartId,
                carrier_code: "freeshipping",
                method_code: "freeshipping",
                quote_item_comments: currentSkuAndComments,
              },
            });
            await setPaymentMethod({
              variables: {
                cart_id: cartId,
                code: paymentCode,
              },
            });
            GAEventsTracker("Submit Quote");
            const response = await placeOrder({
              variables: {
                cart_id: cartId,
              },
            });

            setShouldRedirect(true);
            createEmptyCart();
          } else if (
            !stateError &&
            !hasStateOrProvince &&
            typeof country?.value !== "undefined"
          ) {
            await setAddresses({
              variables: inputNonUsAndCa,
            });
            await setShippingMethod({
              variables: {
                cart_id: cartId,
                carrier_code: "freeshipping",
                method_code: "freeshipping",
                quote_item_comments: currentSkuAndComments,
              },
            });
            await setPaymentMethod({
              variables: {
                cart_id: cartId,
                code: paymentCode,
              },
            });
            GAEventsTracker("Submit Quote");
            const response = await placeOrder({
              variables: {
                cart_id: cartId,
              },
            });

            setShouldRedirect(true);
            createEmptyCart();
          }
        })}
      >
        <QuoteForm
          country={country}
          nonUsState={nonUsState}
          setNonUsState={setNonUsState}
          fileForUpload={fileForUpload}
          setFileForUpload={setFileForUpload}
          setActiveCollapse={setActiveCollapse}
          activeCollapse={activeCollapse}
          errors={errors}
          register={register}
          countries={countries}
          onCountryChangeHandler={onCountryChangeHandler}
          states={states}
          onStateChangeHandler={onStateChangeHandler}
          loading={loading}
          setValue={setValue}
          control={control}
          customer={customer}
          message={message}
          setMessage={setMessage}
          handleFileUpload={handleFileUpload}
          triggerUploadFile={triggerUploadFile}
          availableStates={availableStates}
          state={state}
          stateError={stateError}
          setStateError={setStateError}
          setCountryError={setCountryError}
          countryError={countryError}
          telephoneMask={telephoneMask}
        />
      </form>
    </MDBCol>
  );
};

export default ContactInformation;
