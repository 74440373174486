import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import { Controller } from "react-hook-form";
import "./style.scss";
import {
  FirstNameErrorMessage,
  LastNameErrorMessage,
  AddressErrorMessage,
  PhoneErrorMessage,
  PostcodeErrorMessage,
  EmailErrorMessage,
  AddressEmailErrorMessage,
} from "../accountInformation/errorMessages";
import { CityErrorMessage } from "../accountInformation/errorMessages";
import Select2 from "core/components/select/select";
import MaskedInput from "react-text-mask";

const EditAddressBookForm = ({
  register,
  errors,
  countries,
  onCountryChangeHandler,
  onStateChangeHandler,
  handleCheckedShipping,
  handleCheckedBilling,
  checkedBilling,
  checkedShipping,
  loading,
  control,
  country,
  availableStates,
  state,
  zipMask,
  telephoneMask,
  nonUsState,
  setNonUsState,
  stateError,
  setStateError,
}) => {
  let modifiedStates = [
    {
      label: `${country.value === "US" ? "Select State *" : "Select Province"}`,
      value: "",
    },
    ...availableStates,
  ];

  return (
    <MDBRow className="m-0 d-flex align-items-stretch justify-content-center customer-information-main-row address-book-form">
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <div>
          <h6 className="pt-4 pb-4 main-title-account h6">
            Contact Information
          </h6>
          <Controller
            as={MDBInput}
            name="firstname"
            control={control}
            className={`mb-5 account-input ${
              errors.firstname && `account-input-error`
            }`}
            placeholder="Enter Name *"
            label="First Name"
            labelClass={`account-input-label ${
              errors.firstname && `account-error-label`
            }`}
            rules={{ required: true }}
          />
          <FirstNameErrorMessage errors={errors} />
          <Controller
            as={MDBInput}
            name="lastname"
            control={control}
            className={`mb-5 account-input ${
              errors.lastname && `account-input-error`
            }`}
            placeholder="Enter Last Name *"
            label="Last Name"
            labelClass={`account-input-label ${
              errors.lastname && `account-error-label`
            }`}
            rules={{ required: true }}
          />
          <LastNameErrorMessage errors={errors} />

          <AddressEmailErrorMessage errors={errors} />
          <div className={`md-form`}>
            <Controller
              as={
                <MaskedInput
                  className={`form-control account-input mb-5 ${
                    errors.phone && `account-input-error`
                  }`}
                />
              }
              control={control}
              name={`phone`}
              type="text"
              hint="Phone*"
              error="wrong"
              success="right"
              mask={telephoneMask}
              guide={false}
              placeholder="Enter Phone Number *"
              label="Phone"
              rules={{ required: true }}
            />
            <label
              for={`phone`}
              className={`account-input-label active ${
                errors.phone && `account-error-label`
              } `}
            >
              Phone
            </label>
          </div>
          <PhoneErrorMessage errors={errors} />
          <Controller
            as={MDBInput}
            name="address_email"
            control={control}
            type="email"
            inputRef={register({ required: true })}
            className={`mb-5 account-input ${
              errors.address_email && `account-input-error`
            }`}
            placeholder="Enter Email *"
            label="Email"
            labelClass={`account-input-label ${
              errors.address_email && `account-error-label`
            }`}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
          />
          <EmailErrorMessage errors={errors} />
        </div>
      </MDBCol>
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <div className="pb-5">
          <h6 className="pt-4 pb-4 main-title-account h6">Address</h6>
          <Controller
            as={MDBInput}
            name="address1"
            control={control}
            type="text"
            className={`mb-5 account-input ${
              errors.address1 && `account-input-error`
            }`}
            placeholder="Enter Address 1 *"
            label="Address 1"
            labelClass={`account-input-label ${
              errors.address1 && `account-error-label`
            }`}
            rules={{ required: true }}
          />
          <AddressErrorMessage errors={errors} />
          <Controller
            as={MDBInput}
            name="address2"
            control={control}
            type="text"
            className={`mb-5 account-input ${
              errors.address2 && `account-input-error`
            }`}
            placeholder="Enter Address 2"
            label="Address 2"
            labelClass={`account-input-label ${
              errors.address2 && `account-error-label`
            }`}
          />

          <div className={`select2-container mb-5`}>
            <span className="select2-label">Country</span>
            <Select2
              icon="address"
              required={true}
              value={country}
              onChange={(event) => {
                onCountryChangeHandler(event);
              }}
              wrapperClass="small w-100"
              placeholder={"Country *"}
              options={countries.map((country) => ({
                label: country.full_name_english,
                value: country.id,
                available_regions: country.available_regions,
              }))}
              isSearchable={true}
              rules={{ required: true }}
            />
          </div>

          <Controller
            as={MDBInput}
            name="city"
            control={control}
            type="text"
            className={`mb-5 mt-5 account-input ${
              errors.city && `account-input-error`
            }`}
            placeholder="Enter City *"
            label="City"
            labelClass={`account-input-label ${
              errors.city && `account-error-label`
            }`}
            rules={{ required: true }}
          />
          <CityErrorMessage errors={errors} />
          {availableStates && availableStates.length > 0 ? (
            <MDBCol
              className={`select2-container`}
              style={{
                padding: 0,
                display: "block",
              }}
            >
              <span className="select2-label">
                {country.value === "US"
                  ? "State"
                  : country.value === "CA"
                  ? "Province"
                  : "State/Region"}
              </span>
              <Select2
                // disabled={loader}
                name={"country"}
                type={"text"}
                icon="address"
                required={true}
                value={state}
                onChange={(event) => {
                  onStateChangeHandler(event);
                  setStateError(false);
                }}
                wrapperClass="small w-100"
                placeholder={
                  country.value === "US"
                    ? "State *"
                    : country.value === "CA"
                    ? "Province *"
                    : "State/Region"
                }
                options={modifiedStates}
                isSearchable={true}
                className={`w-100 ${stateError && `error-quote`}`}
              />
            </MDBCol>
          ) : (
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              <MDBInput
                className="account-input"
                value={nonUsState}
                onChange={(e) => {
                  setNonUsState(e.target.value);
                }}
                name="NonUsState"
                type="text"
                placeholder="State / Region "
                label="State / Region"
                labelClass={`account-input-label`}
              />
            </MDBCol>
          )}

          <div className={`md-form`}>
            <Controller
              as={
                zipMask ? (
                  <MaskedInput
                    name="postcode"
                    className={`form-control account-input ${
                      errors.postcode && `account-input-error`
                    }`}
                  />
                ) : (
                  <MDBInput
                    type="text"
                    name="postcode"
                    placeholder="Enter Zip / Postal Code *"
                    label="Zip / Postal Code"
                    labelClass={`account-input-label ${
                      errors.postcode && `account-error-label`
                    } `}
                    className={`account-input ${
                      errors.postcode && `account-input-error`
                    }`}
                  />
                )
              }
              name={`postcode`}
              control={control}
              type="text"
              hint={`${country.value === "US" ? "Zip Code" : "Postal Code"}`}
              error="wrong"
              success="right"
              mask={zipMask}
              guide={false}
              placeholder={`${
                country.value === "US" ? "Zip Code" : "Postal Code"
              }`}
              rules={{ required: true }}
            />
            {zipMask ? (
              <label
                for={`postcode`}
                className={`account-input-label active ${
                  errors.postcode && `account-input-error-label`
                } `}
              >
                {country.value === "US" ? "Zip Code" : "Postal Code"}
              </label>
            ) : (
              <></>
            )}
            <PostcodeErrorMessage errors={errors} />
          </div>

          <MDBCol className="ml-0 pl-0 mt-5 mb-4">
            <Controller
              as={MDBInput}
              name="default_billing"
              control={control}
              label="use as my default billing address"
              type="checkbox"
              id="default_billing"
              onClick={handleCheckedBilling}
              checked={checkedBilling}
            />
          </MDBCol>
          <MDBCol className="ml-0 pl-0 mb-3">
            <Controller
              as={MDBInput}
              name="default_shipping"
              control={control}
              label="Use as my default shipping address"
              type="checkbox"
              id="default_shipping"
              onClick={handleCheckedShipping}
              checked={checkedShipping}
              value={checkedShipping}
            />
          </MDBCol>
          <MDBBtn
            disabled={loading}
            className="mt-5 new-address-button"
            style={{ float: "right" }}
            type="submit"
          >
            Submit
          </MDBBtn>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default EditAddressBookForm;
