import React from "react";
import { useQuery } from "react-apollo-hooks";
import CategorySection from "./category-section";
import { GET_CATEGORY_SECTION } from "./query";

const CategorySectionQueryContainer = (props) => {
  const { data, loading, error } = useQuery(GET_CATEGORY_SECTION);

  let categorySectionData = data?.getCategoryExplore;
  return <CategorySection categorySectionData={categorySectionData} />;
};

export default CategorySectionQueryContainer;
