import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import Data from "./catalog-data";
import { CmsDataFragment } from "utils/cmsDataFragment";

const Catalog = ({ catalogImage, catalogText, catalogButton }) => {
  return (
    <MDBBox className="catalog-section">
      <MDBContainer className="d-flex justify-content-end">
        <MDBBox
          className="catalog-container"
          style={{
            background: "url(" + Data.background + ") center center no-repeat",
          }}
        >
          <MDBRow>
            <MDBCol md="6">
              <CmsDataFragment content={catalogImage} />
            </MDBCol>
            <MDBCol
              md="6"
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <MDBTypography tag="h5" variant="h5">
                <CmsDataFragment content={catalogText} />
              </MDBTypography>
              <CmsDataFragment content={catalogButton} />
            </MDBCol>
          </MDBRow>
        </MDBBox>
      </MDBContainer>
    </MDBBox>
  );
};

export default Catalog;
