import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  MDBCol,
  MDBTypography,
  MDBContainer,
  ToastContainer,
  toast,
} from "mdbreact";
import ChangePasswordForm from "./form";
import { UPDATE_CUSTOMER, CHANGE_PASSWORD } from "utils/mutations";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const AccountInformationContent = () => {
  const { customer, setCustomerInformation } = useCustomer();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [changePasswordCheckbox, setChangePasswordCheckbox] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);

  const { register, handleSubmit, errors, getValues, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      firstname: customer?.firstname,
      lastname: customer?.lastname,
      email: customer?.email,
      currentPassword: "",
      newPassword: "",
      password: "",
      confirm_password: "",
      changePasswordCheckbox: false,
      changeEmail: false,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER);
  const [changePassword, { loading: changePasswordLoading }] = useMutation(
    CHANGE_PASSWORD
  );

  if (shouldRedirect) return <Redirect to="/customer" />;

  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol size={12} className="dashboard-title d-flex align-items-center">
        <MDBTypography
          tag="h3"
          variant="h3"
          className="pl-4 main-title-account"
        >
          Account information
        </MDBTypography>
      </MDBCol>
      <form
        onSubmit={handleSubmit(async (formData) => {
          try {
            await updateCustomer({
              variables: {
                firstname: formData.firstName,
                lastname: formData.lastName,
                email: changeEmail ? formData.email : customer?.email,
                password: formData.currentPassword,
                is_subscribed: customer?.is_subscribed,
              },
            }).then(({ data }) => {
              setCustomerInformation({
                firstname: data.updateCustomer.customer.firstname,
                email: data.updateCustomer.customer.email,
                lastname: data.updateCustomer.customer.lastname,
                is_subscribed: customer?.is_subscribed,
              });
            });
            if (changePasswordCheckbox) {
              await changePassword({
                variables: {
                  currentPassword: formData.currentPassword,
                  newPassword: formData.newPassword,
                },
              });
            }
            toast(
              <MDBTypography style={{ margin: "auto" }}>
                You successfully updated Account Information
              </MDBTypography>
            );
            setTimeout(() => setShouldRedirect(true), 3000);
          } catch (error) {
            console.log("!error ", error);
            setShouldRedirect(false);
          }
        })}
      >
        <ToastContainer autoClose={3000} hideProgressBar />
        <ChangePasswordForm
          changeEmail={changeEmail}
          setChangeEmail={setChangeEmail}
          setChangePasswordCheckbox={setChangePasswordCheckbox}
          control={control}
          customer={customer}
          register={register}
          getValues={getValues}
          errors={errors}
          loading={loading || changePasswordLoading}
          changePasswordCheckbox={changePasswordCheckbox}
        />
      </form>
    </MDBContainer>
  );
};

export default AccountInformationContent;
