import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const CustomizationSectionTitle = () => {
  return <CmsBlock id={"product_customization_title"} />;
};

export const CustomizationSectionHeading = () => {
  return <CmsBlock id={"product_customization_heading"} />;
};

export const CustomizationSectionText = () => {
  return <CmsBlock id={"product_customization_text"} />;
};

export const CustomizationSectionImage = () => {
  return <CmsBlock id={"product_customization_image"} />;
};

export const CustomizationSectionButton = () => {
  return <CmsBlock id={"product_customization_button"} />;
};
