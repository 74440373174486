import React, { useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { useForm } from "react-hook-form";
import RegisterFormContent from "./registerFormContent";
import { REGISTER } from "utils/mutations";
import { Redirect } from "react-router-dom";
import Countries from "config/data/countries";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { default as themeConfiguration } from "config/themeConfiguration";

const RegisterForm = ({ toggleModal }) => {
  const {
    getCountryById,
    getTelephoneMaskByCountry,
    getZipMaskByCountry,
  } = useCustomer();
  const { countries } = Countries;
  const selectedCountry = getCountryById("US");

  const defaultRegions = selectedCountry.available_regions.map((region) => ({
    value: region.id,
    label: region.name,
    code: region.code,
    id: region.id,
    name: region.name,
  }));

  const onStateChangeHandler = (value) => setState(value);
  const onCountryChangeHandler = (value) => {
    if (value.value === "CA") {
      setState({ value: 0, label: "Select Province" });
    } else {
      setState({ value: 0, label: "Select State" });
    }
    let _availableStates = value.available_regions
      ? value.available_regions.map((region) => {
          return {
            value: region.id,
            label: region.name,
            code: region.code,
            id: region.id,
            name: region.name,
          };
        })
      : [];
    setCountry(value);
    setAvailableStates(_availableStates);
  };
  const [availableStates, setAvailableStates] = useState([...defaultRegions]);

  const [country, setCountry] = useState({
    label: selectedCountry.full_name_english,
    value: selectedCountry.id,
    available_regions: [...defaultRegions],
  });

  const [businessType, setBusinessType] = useState();
  const businessTypes = themeConfiguration?.businessTypes;

  let telephoneMask = getTelephoneMaskByCountry(country.value);
  let zipMask = getZipMaskByCountry(country.value);
  const states = country && country.available_regions;
  const [state, setState] = useState({ label: "Select State *", value: 0 });
  const [subscribed, setSubscribed] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [registerCustomer, { loading, error, hasError }] = useMutation(
    REGISTER
  );
  const msgRegex = /GraphQL error: (.*)/gim;
  const errorMessage = msgRegex.exec(error?.message);

  const { register, handleSubmit, errors, getValues, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      confirm_password: "",
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
      address: "",
      country: "",
      city: "",
      state: "",
      postalCode: "",
      isSubscribed: false,
      termsConditions: false,
      businessType: "",
      nonUsState: "",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [nonUsState, setNonUsState] = useState("");
  if (shouldRedirect) return <Redirect to="/registration" />;
  if(!businessType && getValues().termsConditions) {
    errors["businessType"] = { type: 'required', message: '' };
  } else {
    delete errors["businessType"];
  }

  if((country.value === "US" || country.value === "CA") && state.value === 0 && getValues().termsConditions) {
    errors["state"] = { type: 'required', message: '' };
  } else {
    delete errors["state"];
  }
  if(errorMessage && errorMessage[1].includes("region_id")) {
    errorMessage[1] = "A state is required for the specified country code"
  }

  return (
    <form
      id="PL-register-form"
      style={{ marginTop: "40px" }}
      onSubmit={
        handleSubmit(async (formData) => {
        let regionInput;
        if(Object.keys(errors).length > 0) return;
        if (country.value === "US" || country.value === "CA") {
          regionInput = {
            region: state.name ? state.name : formData.state,
            region_code: state.code ? state.code : "",
            region_id: state.id ? state.id : 0,
          };
        } else {
          regionInput = { region: nonUsState };
        }
        try {
          await registerCustomer({
            variables: {
              firstname: formData.firstName,
              lastname: formData.lastName,
              email: formData.email,
              password: formData.password,
              city: formData.city,
              company: formData.company,
              postcode: formData.postalCode,
              telephone: formData.phone,
              country_code: country?.value,
              business_type: businessType,
              is_subscribed: subscribed,
              street: [formData.address],
              region: regionInput,
              default_shipping: true,
              default_billing: true,
            },
          });
          toggleModal();
          setShouldRedirect(true);
          if(window.innerWidth < 991) {
            document.getElementById("hamburger1").click();
          }
        } catch (error) {
          setShouldRedirect(false);
          console.error(error.message);
        }
      })}
    >
      <RegisterFormContent
        loading={loading}
        hasError={hasError}
        errorMessage={errorMessage && errorMessage[1]}
        errors={errors}
        register={register}
        countries={countries}
        onCountryChangeHandler={onCountryChangeHandler}
        states={states}
        onStateChangeHandler={onStateChangeHandler}
        setSubscribed={setSubscribed}
        getValues={getValues}
        country={country}
        state={state}
        availableStates={availableStates}
        telephoneMask={telephoneMask}
        zipMask={zipMask}
        control={control}
        businessTypes={businessTypes}
        setBusinessType={setBusinessType}
        businessType={businessType}
        nonUsState={nonUsState}
        setNonUsState={setNonUsState}
      />
    </form>
  );
};

export default RegisterForm;
