import Homepage from "app/pages/home";
import Dashboard from "app/layout/dashboard/accountDashboard";
import Information from "app/layout/dashboard/accountInformation";
import Addressbook from "app/layout/dashboard/addressBook";
import Edit from "app/layout/dashboard/editAddressBook";
import Add from "app/layout/dashboard/addNewAddress";
import Newsletter from "app/layout/dashboard/newsletterSubscription";
import logout from "app/pages/customer/logout/logout";
import wishlistPage from "app/layout/wishlist/wishlistPage";

export default [
  {
    url: "customer",
    component: Dashboard,
    exact: true,
    inSidebar: true,
    dropdownText: "my account",
    menuText: "Account Dashboard",
    inDropdown: true,
  },
  {
    url: "customer/information",
    component: Information,
    exact: true,
    inSidebar: true,
    dropdownText: "information",
    menuText: "Account Information",
    inDropdown: false,
  },
  {
    url: "customer/address-book",
    component: Addressbook,
    exact: true,
    inSidebar: true,
    menuText: "Address Book",
    inDropdown: false,
  },
  {
    url: "customer/address-book/edit",
    component: Edit,
    exact: false,
    inSidebar: false,
    menuText: "address book",
    inDropdown: false,
  },
  {
    url: "customer/address-book/add",
    component: Add,
    exact: true,
    inSidebar: false,
    menuText: "address book",
    inDropdown: false,
  },
  {
    url: "customer/newsletter",
    component: Newsletter,
    exact: true,
    inSidebar: true,
    menuText: "Newsletter Subscription",
    inDropdown: false,
  },
  {
    url: "customer/wishlist/",
    component: wishlistPage,
    exact: true,
    inSidebar: true,
    // dropdownText: "information",
    menuText: "Favorites",
    inDropdown: false,
  },
  {
    url: "customer/wishlist/projects",
    component: wishlistPage,
    exact: false,
    inSidebar: false,
    // dropdownText: "information",
    menuText: "Favorites",
    inDropdown: false,
  },
  {
    url: "customer/wishlist/downloads",
    component: wishlistPage,
    exact: true,
    inSidebar: false,
    // dropdownText: "information",
    menuText: "Favorites",
    inDropdown: false,
  },
  {
    url: "logout",
    component: logout,
    separated: true,
    exact: true,
    inSidebar: false,
    dropdownText: "sign out",
    menuText: "sign out",
    inDropdown: true,
    function: "logoutCustomer",
  },
];

export const pagesConfig = {
  sidebarTitle: "my account",
  mainTitle: "dashboard",
};

export { default as ErrorPage } from "app/pages/error";

export const DefaultPage = Homepage;
