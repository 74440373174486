export const updateCartItemFn = (
  item,
  value,
  updateCart,
  lock,
  setQuantity,
  setLoading
) => {
  if (value < 1 || value === "") {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(1);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: 1,
      },
    ]);
  } else {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(value);
      },
      fail: () => {
        setLoading(false);
      },
    });

    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: value === 1 ? 1 : value,
      },
    ]);
  }
};
