import React from "react";
import StateContainer from "./state-container";

const HeaderDataContainer = (props) => {
  const selectDefaultCategoryListChildren = (props.data &&
    props.data.categoryList &&
    props.data.categoryList[0]) || { children: [] };

  return <StateContainer data={selectDefaultCategoryListChildren} />;
};

export default HeaderDataContainer;
