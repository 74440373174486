import React, { useState } from "react";
import Login from "./Login";

const LoginStateContainer = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const state = { username, setUsername, password, setPassword };

  return <Login {...props} state={state} />;
};

export default LoginStateContainer;
