import React, {useEffect, useState} from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBFooter,
  MDBTypography,
  MDBBox,
} from "mdbreact";
import apolloClient from "core/graphql/apolloClient";
import Left from "app/layout/footer/left";
import Right from "app/layout/footer/right";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";
import GraphQlWrapper from "core/components/graphQlWrapper";
import gql from "graphql-tag";
import queryLoader from "../../graphql/queryLoader";
import { useQuery } from "react-apollo";
import {useSelector} from "react-redux";
import {SalesRepNewsModal} from "../login/salesRepNewsModal";



const STORE_CONFIG = gql`
  query storeConfig {
    storeConfig {
      copyright
    }
}
`;


const Footer = (props) => {

  const { data } = props;

  const {data:dataquery, loading: loadingquery, error: errorquery}  = useQuery(STORE_CONFIG);

  const customerObject = useSelector((state) => state.customer);
  const [sRLI, setSRLI] = useState(false);
  const [sRLIShow, setSRLIShow] = useState(false);

  useEffect(() => {
    if( customerObject?.data?.customer_group && customerObject?.data?.customer_group === 4 &&  customerObject?.data?.token !== localStorage.getItem("SRLI")) {
      localStorage.setItem('SRLI', customerObject?.data?.token)
      setSRLI(customerObject?.data?.token)
      setSRLIShow(true)
      localStorage.setItem('SRLIshow', true)
    }


    // if(sRLI === customerObject?.data?.token && customerObject?.data?.customer_group && sRLIShow) {
    //     localStorage.setItem('SRLIshow', false)
    // }

  }, [customerObject?.data?.customer_group]);
  // console.log("localStorage.getItem('SRLIshow')",localStorage.getItem('SRLIshow'))
  // console.log("customerObject?.data?.token",customerObject?.data?.token)
  return (
    <MDBFooter>
      <MDBContainer>
        { localStorage.getItem('SRLIshow') && localStorage.getItem('SRLIshow') === "true" &&  (
            <SalesRepNewsModal  sRLIShow={sRLIShow}  />
        )}
        <MDBRow>
          <MDBCol xl="5">
            <Left />
          </MDBCol>
          <MDBCol xl="7">
            <Right menuItems={data} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="py-3 px-5 border-top">
        <MDBContainer>
          <MDBRow className="py-3">
            <MDBCol
              size="12"
              md="6"
              className="d-flex align-items-center py-3 py-md-0 justify-content-center justify-content-md-start"
            >

              {
                loadingquery ? (
                     <MDBTypography tag="p" className="">Loading...</MDBTypography>
                ) : (
                    <MDBTypography tag="p" className="">{dataquery?.storeConfig?.copyright}</MDBTypography>
                )
              }

            </MDBCol>
            <MDBCol
              size="12"
              md="6"
              className="d-flex align-items-center py-3 py-md-0 justify-content-center justify-content-md-end"
            >
              <Link
                to={{
                  pathname: "https://jolausa.com",
                }}
                target="_blank"
              >
                <MDBBox style={{ width: "8rem" }}>
                  <Icon icon="logoDark" />
                </MDBBox>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </MDBFooter>
  );
};

export default Footer;
