import { gql } from "graphql.macro";
export const GET_ALL_VERENIA_IMPORT_DATA = gql`
  query {
    getAllVereniaImportData {
      name: question_name
      rule: question_hide_rule
      code: question_code
      seq
      answers {
        name
        rule: hide_rule
        sku
        seq
        price: std_price
        product_price: product_info {
          name
          price
        }
      }
    }
  }
`;
