/**
 * Used to parse the Q/A hide_rules coming from Verenia.
 * Use it to determine which questions/answers to show
 *
 * @param {string}input
 * @param {{[key:string]: string}}vars
 * @returns {boolean} true/false
 **/
export const parse = (input, vars, print = false) => {
  if (input === null) {
    return false;
  }

  if (input === "*/*" || !input) {
    return true;
  }

  const expression = new RegExp(/(\w+)\/([\w+*%,]+)/, "g");
  const notExists = new RegExp(/(\w+)\/([%]+)/, "g");

  let replaces = {
    X: "!(",
    "#": "||",
    "&": "&&",
  };

  Object.keys(replaces).forEach((sym) => {
    let re = new RegExp(sym, "g");
    if (sym === "X") {
      re = /X\(/g;
    }
    try {
      // if (input) {
      input = input.replace(re, replaces[sym]);
      // }
    } catch (e) {
      console.log("error found")
      console.error(e, input);
    }
  });

  function createNotExists(match, variable, exists) {
    return `!vars.${variable}`;
  }

  function createExpression(match, variable, values) {
    let valArr = values.split(","); //.join('","');
    let returnData = [];
    let includeValues = [];
    let addExits = false;
    if (variable === "*") {
      return "true";
    }

    valArr.forEach((val) => {
      if (val.endsWith("*")) {
        returnData.push(`vars.${variable}.startsWith('${val.slice(0, -1)}')`);
      } else if (val === "%%") {
        addExits = true;
      } else {
        includeValues.push(val);
      }
    });

    let returnStr = `["${includeValues.join(
      '","'
    )}"].includes(vars.${variable})`;
    if (returnData.length) {
      returnStr = returnStr + "||" + returnData.join("||");
    }
    if (addExits) {
      returnStr = returnStr + `|| !vars.${variable}`;
    }

    return returnStr;
  }

  try {
    input = input.replace(notExists, createNotExists);
    input = input.replace(expression, createExpression);

    if (print) {
      console.error(input, eval(input));
    }
    return eval(input);
  } catch (e) {
    console.log(e, input);
    return true;
  }
};
