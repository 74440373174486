import messagesActions from "core/state/redux/data/messages/actions";
import commonActions from "../../common/actions";

export default (store, action) => {
  try {
    store.dispatch(commonActions.unlock());
    if (!action.isLoggedIn) {
      store.dispatch(commonActions.closeModal("loginModal"));
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
