import gql from "graphql-tag";

export const ADD_PRODUCT_TO_QUOTE = gql`
  mutation AddProductToCart($cart_id: String!, $sku: String!) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cart_id
        cart_items: [{ data: { sku: $sku, quantity: 1 } }]
      }
    ) {
      cart {
        items {
          id
          product {
            prices
            sku
            name
            image {
              url
            }
            stock_status
          }
          quantity
          ... on SimpleCartItem {
            customizable_options {
              label
              values {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ELEMENT = gql`
  mutation deleteElement($cart_id: String!, $cart_item_id: Int!) {
    removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_id: $cart_item_id }
    ) {
      cart {
        id
      }
    }
  }
`;

export const SET_ADDRESSES = gql`
  mutation setAddresses(
    $cart_id: String!
    $firstname: String!
    $lastname: String!
    $company: String
    $street: [String]!
    $city: String!
    $region: String
    $region_id: Int
    $postalcode: String
    $country: String!
    $telephone: String!
    $comment: String!
    $image: String!
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cart_id
        billing_address: {
          address: {
            firstname: $firstname
            lastname: $lastname
            company: $company
            street: $street
            city: $city
            region: $region
            region_id: $region_id
            postcode: $postalcode
            country_code: $country
            telephone: $telephone
            comment: $comment
            save_in_address_book: false
            image: $image
          }
          same_as_shipping: false
        }
      }
    ) {
      cart {
        billing_address {
          firstname
          lastname
          company
          street
          city
          image
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
        shipping_addresses {
          comment
          firstname
          lastname
          company
          street
          city
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_COMMENTS = gql`
  mutation addComment($order_id: Int, $product_sku: String, $comment: String) {
    addCustomerOrderItemAttributes(
      input: {
        order_id: $order_id
        order_item: [{ product_sku: $product_sku, comment: $comment }]
      }
    )
  }
`;

export const SET_SHIPPING_METHODS = gql`
  mutation setShippingMethod(
    $cart_id: String!
    $carrier_code: String!
    $method_code: String!
    $quote_item_comments: [QuoteItemCommentInput]
  ) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cart_id
        shipping_methods: [
          {
            carrier_code: $carrier_code
            method_code: $method_code
            quote_item_comments: $quote_item_comments
          }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            method_code
            carrier_title
            method_title
          }
        }
      }
    }
  }
`;

export const REGISTER = gql`
  mutation CreateCustomer(
    $firstname: String
    $lastname: String
    $email: String
    $password: String
    $city: String
    $company: String
    $postcode: String
    $telephone: String
    $business_type: Int
    $country_code: CountryCodeEnum
    $street: [String]
    $region: CustomerAddressRegionInput
    $is_subscribed: Boolean
  ) {
    createCustomer(
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
        business_type: $business_type
        is_subscribed: $is_subscribed
        address_input: {
          city: $city
          company: $company
          postcode: $postcode
          telephone: $telephone
          country_code: $country_code
          default_billing: true
          default_shipping: true
          firstname: $firstname
          lastname: $lastname
          street: $street
          region: $region
        }
      }
    ) {
      customer {
        firstname
        lastname
        email
        business_type
        addresses {
          city
          company
          country_code
          default_billing
          default_shipping
          postcode
          street
          telephone
          region {
            region
            region_code
          }
        }
        created_at
        date_of_birth
        default_billing
        default_shipping
        gender
        is_subscribed
      }
    }
  }
`;

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethod($cart_id: String!, $code: String!) {
    setPaymentMethodOnCart(
      input: { cart_id: $cart_id, payment_method: { code: $code } }
    ) {
      cart {
        selected_payment_method {
          code
        }
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder($cart_id: String!) {
    placeOrder(input: { cart_id: $cart_id }) {
      order {
        order_number
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $firstname: String
    $lastname: String
    $email: String
    $password: String
    $is_subscribed: Boolean
  ) {
    updateCustomer(
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
        is_subscribed: $is_subscribed
      }
    ) {
      customer {
        email
        lastname
        firstname
        is_subscribed
        addresses {
          company
          telephone
          street
          postcode
          city
          country_code
          id
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation updateCustomerAddress(
    $id: Int!
    $city: String
    $company: String
    $country_code: CountryCodeEnum
    $default_billing: Boolean
    $default_shipping: Boolean
    $firstname: String
    $lastname: String
    $postcode: String
    $region: CustomerAddressRegionInput
    $street: [String]
    $telephone: String
    $address_email: String
  ) {
    updateCustomerAddress(
      id: $id
      input: {
        city: $city
        company: $company
        country_code: $country_code
        default_billing: $default_billing
        default_shipping: $default_shipping
        firstname: $firstname
        lastname: $lastname
        postcode: $postcode
        region: $region
        street: $street
        telephone: $telephone
        address_email: $address_email
      }
    ) {
      city
      company
      country_code
      default_billing
      default_shipping
      firstname
      id
      lastname
      postcode
      region {
        region
        region_code
      }
      street
      telephone
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changeCustomerPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      firstname
      lastname
    }
  }
`;

export const ADD_NEW_ADDRESS = gql`
  mutation addNewAddress(
    $city: String
    $company: String
    $country_code: CountryCodeEnum
    $default_billing: Boolean
    $default_shipping: Boolean
    $firstname: String
    $lastname: String
    $postcode: String
    $region: CustomerAddressRegionInput
    $street: [String]
    $telephone: String
    $address_email: String
  ) {
    createCustomerAddress(
      input: {
        city: $city
        company: $company
        country_code: $country_code
        default_billing: $default_billing
        default_shipping: $default_shipping
        firstname: $firstname
        lastname: $lastname
        postcode: $postcode
        region: $region
        street: $street
        telephone: $telephone
        address_email: $address_email
      }
    ) {
      city
      company
      country_code
      default_billing
      default_shipping
      extension_attributes {
        attribute_code
        value
      }
      fax
      firstname
      id
      lastname
      middlename
      postcode
      prefix
      region {
        region
        region_code
      }

      street
      suffix
      telephone
      vat_id
    }
  }
`;

export const UPDATE_CUSTOMER_SUBSCRIPTION = gql`
  mutation updateCustomer($is_subscribed: Boolean) {
    updateCustomer(input: { is_subscribed: $is_subscribed }) {
      customer {
        firstname
        is_subscribed
      }
    }
  }
`;
