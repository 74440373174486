import React, { memo, useEffect, useCallback, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Search from "./search";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { klevuFilters } from "app/layout/search/klevu/klevuFilters";
import queryLoader from "app/graphql/queryLoader";

const configQuery = queryLoader("klevuConfig");

const SEARCH_QUERY = gql`
  query Products(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      aggregations {
        attribute_code
        label
        options {
          label
          value
        }
      }
      items {
        name
        sku
        url_key
        categories {
          name
          url_key
        }
        price_range {
          maximum_price {
            regular_price {
              value
              currency
            }
          }
        }
        small_image {
          url
          label
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

const SearchQueryContainer = memo((props) => {
  const { data: klevuConfigData, error: klevuConfigError } = useQuery(
      configQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  const [klevuData, setKlevuData] = useState({
    items: [],
    meta: {},
  });
  const [error, setError] = useState(null);
  // console.log(props.sortSelected);
  const location = useLocation();
  const { sortSelected } = props;
  let search = queryString.parse(location.search);
  let currentPage = search?.page ? parseInt(search.page) : 1;
  let pageSize = search?.pageSize ? parseInt(search.pageSize) : 12;
  // let currentSortBy = search?.sortBy
  //   ? queryString.parse(search.sortBy)
  //   : { position: "ASC" };

  let currentSortBy =
    Object.keys(sortSelected).length > 1
      ? { position: "DESC" }
      : { position: "ASC" };
  let searchArg = search?.search;
  let { queryProps } = props;
  let updatedProps = { ...props };

  let getSortAttribute = useCallback(
    (sortSelected) => {
      if (
        "custitemsort_date" in sortSelected &&
        sortSelected["custitemsort_date"]
      ) {
        if (sortSelected["custitemsort_date"] === "ASC")
          return "NEW_ARRIVAL_ASC";
        return "NEW_ARRIVAL_DESC";
      } else if ("name" in sortSelected) {
        if (sortSelected["name"] === "ASC") return "NAME_ASC";
        return "NAME_DESC";
      } else return "NEW_ARRIVAL_DESC";

    },
    [sortSelected]
  );

  const fetchKlevuData = useCallback(
    async (value, klevuConfig, pageSize, currentPage, sortSelected) => {
      if (!klevuConfig) return;

      let data = await klevuFilters(
        value,
        klevuConfig,
        pageSize,
        currentPage,
        getSortAttribute(sortSelected)
      );
      try {
        const { queryResults } = data;

        setKlevuData({
          items: queryResults?.[0]?.records,
          meta: queryResults?.[0]?.meta,
        });
      } catch (error) {
        setError(error);
      }
    },
    []
  );

  useEffect(() => {
    fetchKlevuData(
      search?.search,
      klevuConfigData?.klevuConfig,
      pageSize,
      currentPage,
      sortSelected
    );
  }, [
    search?.search,
    pageSize,
    currentPage,
    klevuConfigData?.klevuConfig,
    sortSelected,
  ]);

  // const { data, error } = useQuery(SEARCH_QUERY, {
  //   variables: {
  //     ...queryProps,
  //     search: searchArg,
  //     currentPage: currentPage,
  //     pageSize: pageSize,
  //     sort: currentSortBy,
  //   },
  // });

  updatedProps.toolbarProps.totalCount =
    klevuData?.meta?.totalResultsFound || 0;
  updatedProps.toolbarProps.totalPages = Math.ceil(
    (klevuData?.meta?.totalResultsFound || 1) /
      (props?.queryProps?.pageSize || 1)
  );
  updatedProps.listingProps = klevuData?.items || [];
  let totalNumOfItems = klevuData?.meta?.totalResultsFound;
  if (error) {
    console.warn(error);
    return <Redirect to="/" />;
  }


  return <Search {...updatedProps} searchResultString={searchArg} totalNumOfItems={totalNumOfItems} />;
});

export default SearchQueryContainer;
