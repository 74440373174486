import queryLoader from "app/graphql/queryLoader";
import { transform } from "core/components/cmsBlock/Parser";
import GraphQlWrapper from "core/components/graphQlWrapper";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "react-loading-skeleton";
//import cmsBlocks from "generation/cmsBlocks.json";
import {useQuery} from "react-apollo-hooks";
import gql from "graphql-tag";



const CMS_BLOCK = gql`
query cmsBlock($id:String!) {
  cmsBlocks(identifiers:[$id]) {
    items {
      content
      identifier
      title
    }
  }
}
`;
const CmsDataSection = ({ data, skeleton = null, skeletonProps = null }) => {
  //console.log("cmsData", data);
  if (data?.cmsBlocks?.items?.length > 0) {
    return data.cmsBlocks.items.map((item) => (
      <React.Fragment key={`${item.identifier}`}>
        {ReactHtmlParser(
            item.content.replaceAll("&lt;", "<").replaceAll("&gt;", ">"), {
          transform: (node, index) => transform(node, index, true),
        }
        )
        }
      </React.Fragment>
    ));
  }
  if (skeleton) {
    let SkeletonComp = skeleton;
    return <SkeletonComp {...skeletonProps} />;
  }
  return <Skeleton width={"100%"} height={"100%"} {...skeletonProps} />;
};

const QueryContainer = ({ id, skeleton = null, skeletonProps = null }) => {
//console.log(JSON.stringify(id));
  const { loading, error, data } = useQuery(CMS_BLOCK, {
    variables: { id: id},
    fetchPolicy: "no-cache"
  });

  if (loading) return (
      <p>Loading ...</p>
  )
  if(error) {
    console.log("Info: Block with id:  " + id + " is disabled")
    return false;
   // return false;
  }


 // console.log("loading", loading)
 // console.log("error", error)

  //console.log(data);
  if (!id) {
    return (
      <Skeleton duration={3000} width={`100%`} className={`${id} skeleton`} />
    );
  }

  // if (error) {
  //   console.log(error);
  //   return (
  //       <Skeleton duration={3000} width={`100%`} className={`${id} skeleton`} />
  //   );
  // }

  if (!loading && data) {

    return (
            <CmsDataSection
              skeleton={skeleton}
              skeletonProps={skeletonProps}
              data={data}
            />
    )
  }
  //ljubica - consult Milic
  // if (cmsBlocks.default?.[id]) {
  //
  //
  //   return (
  //     <CmsDataSection
  //       skeleton={skeleton}
  //       skeletonProps={skeletonProps}
  //       data={{ cmsBlocks: { items: [cmsBlocks.default?.[id]] } }}
  //     />
  //   );
  // }
  // let query = queryLoader("cmsBlock");
  // return (
  //   <GraphQlWrapper query={query} saveQuery={true} variables={{ id: id }}>
  //     <CmsDataSection skeleton={skeleton} skeletonProps={skeletonProps} />
  //   </GraphQlWrapper>
  // );
};

export default QueryContainer;
