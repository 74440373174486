import React, { useState } from "react";
import { MDBBox, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import ProductItem from "./productItem";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";

const ProductWidget = (props) => {
  let { category } = props;
  const isTablet = useMediaQuery({ query: "(max-width: 992px)" });
  const betweenMobileAndTablet = useMediaQuery({ query: "(max-width: 600px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 485px)" });
  let view = typeof props.view !== "undefined" ? props.view : "grid";
  const [sliderIndex, setSliderIndex] = useState(0);
  let sliderSize = isMobile ? 1 : betweenMobileAndTablet ? 2 : isTablet ? 3 : 4;

  return (
    <MDBContainer
      key={props.keys}
      className={`product-widget-container parentSize-${props.parentSize} view-${view}`}
    >
      {view === "grid" ? (
        <MDBBox className={`product-widget-grid`}>
          {props.items?.map((item, index) => {
            return (
              <ProductItem
                key={`product-widget-item-${item.id}-grid-view`}
                {...props}
                view={view}
                index={index}
                item={item}
                category={category}
              />
            );
          })}
        </MDBBox>
      ) : view === "list" ? (
        <MDBBox className={`d-flex flex-column flex-wrap`}>
          {props.items?.map((item, index) => {
            return (
              <ProductItem
                key={`product-widget-item-${item.id}-list-view-${props.keys}`}
                {...props}
                view={view}
                index={index}
                item={item}
                category={category}
              />
            );
          })}
        </MDBBox>
      ) : view === "slider" ? (
        props.items &&
        props.items?.length > 0 && (
          <MDBContainer className={props.items?.length > 0 && "has-content"}>
            <MDBRow className="slider-title">
              <MDBCol lg="6">
                {props.subtitle && (
                  <p className="wow slideInDown">
                    <label className="style-element"></label>
                    {props.subtitle}
                  </p>
                )}
                {props.title && (
                  <h1 className="wow slideInUp">{props.title}</h1>
                )}
              </MDBCol>
              {props.items?.length > sliderSize && !isTablet && (
                <MDBCol lg="6" className="slider-arrows-container">
                  <Icon
                    className={sliderIndex === 0 && "disabled"}
                    icon="left_arrow_black"
                    onClick={() => {
                      sliderIndex > 0 &&
                        setSliderIndex(sliderIndex - sliderSize);
                    }}
                  />

                  <Icon
                    className={
                      sliderIndex + sliderSize >= props.items?.length &&
                      "disabled"
                    }
                    icon="right_arrow_black"
                    onClick={() => {
                      sliderIndex < props.items?.length - 1 &&
                        setSliderIndex(sliderIndex + sliderSize);
                    }}
                  />
                </MDBCol>
              )}
            </MDBRow>

            <MDBBox className={`product-widget-slider`}>
              {isMobile ? (
                <ProductItem
                  key={`product-widget-item-${props.items[sliderIndex].id}-slider-view`}
                  {...props}
                  view={view}
                  index={sliderIndex}
                  item={props.items[sliderIndex]}
                  category={category}
                ></ProductItem>
              ) : (
                props.items
                  .slice(sliderIndex, sliderIndex + sliderSize)
                  .map((item, index) => {
                    return (
                      <ProductItem
                        key={`product-widget-item-${item.id}-slider-view`}
                        {...props}
                        view={view}
                        index={index}
                        item={item}
                        category={category}
                      />
                    );
                  })
              )}
            </MDBBox>
            {props.items?.length > sliderSize && (
              <MDBRow className="product-widget-slider-dots">
                <MDBCol>
                  <ul className="list-custom-dots">
                    {props.items
                      .slice(
                        0,
                        props.items?.length % sliderSize === 0
                          ? props.items?.length / sliderSize
                          : props.items?.length / sliderSize + 1
                      )
                      .map((item, index) => {
                        return (
                          <li
                            className={
                              sliderIndex === index * sliderSize
                                ? "slider-custom-dot active"
                                : "slider-custom-dot"
                            }
                            onClick={() => setSliderIndex(index * sliderSize)}
                          ></li>
                        );
                      })}
                  </ul>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        )
      ) : (
        <MDBBox className={`d-flex flex-direction-row flex-wrap`}>
          {props.items?.map((item, index) => {
            return (
              <ProductItem
                key={`product-widget-item-${item.id}-default-view`}
                {...props}
                view={view}
                index={index}
                item={item}
                category={category}
              />
            );
          })}
        </MDBBox>
      )}
    </MDBContainer>
  );
};

export default ProductWidget;
