import TabsLeft from 'app/assets/images/tabs-left.jpg';
import TabsRight from 'app/assets/images/tabs-right.jpg';
import TabsProduct from 'app/assets/images/tabs-product.png';

export default {
  imageLeft: TabsLeft,
  imageRight: TabsRight,
  productImage: TabsProduct,
  title: 'Industries',
  tabs: [
    {
      title: 'Restaurants',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
    {
      title: 'Public Spaces',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
    {
      title: 'RETAIL',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
    {
      title: 'Residential and multi family',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
    {
      title: 'Government',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
    {
      title: 'Active  Adult',
      text: 'Whether the goal is a contemporary downtown space destined to become the next Insta-worthy sensation, a casual bistro that welcomes neighborhood regulars, or a hospital cafeteria that feeds the soul as well as the stomach, our endless options in customizable lighting can help create your ideal environment. Our experts will work with you to maximize the customer experience while minimizing utility usage.'
    },
  ]
}