import React from "react";

const Component = (props) => {
  return (
    <>
      {props.children.map((item) => {
        return item;
      })}
    </>
  );
};

export default Component;
