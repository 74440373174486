import React, { memo, useEffect, useState } from "react";
import JolaViewer from "@jola_interactive/jola_viewer";
import { Box3, Vector3, Color, Group, Euler } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Loader from "app/layout/loader";
import Icon from "app/assets/icon/icon";
import { MDBRow, MDBTypography } from "mdbreact";
import { faSuperscript } from "@fortawesome/free-solid-svg-icons";

const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class PrimaPlayer extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);

    this.scene.background = new Color(0xfafafa);
    this.gltfLoader = new GLTFLoader();

    this.materials = new Set();

    this.bases = new Group();
    this.adapters = new Group();
    this.cables = new Group();
    this.models = new Group();

    this.path = "/models/";

    this.isTrack = false;
    this.isTrackHead = false;
    this.needsAdapter = false;
    this.sysCode = null;

    this.baseInput = null;
    this.adapterInput = null;
    this.cableInput = this.path + "cable/cable.glb";
    this.modelInput = null;

    this.hardwareColor = null;
    this.cableColor = null;

    this.baseHeight = 0;
    this.adapterHeight = 0;
    this.cableHeight = 0;
    this.modelHeight = 0;

    this.colorCodes = {
      BK: 0x040404,
      BZ: 0x080401,
      CP: 0x966618,
      PC: 0xcccccc,
      SV: 0x727272,
      WH: 0xe2e2e2,
    };

    this.cableColors = {
      BC: "black",
      WC: "white",
      SC: "silver",
    };
  }

  async Reset() {
    this.bases.children = [];
    this.adapters.children = [];
    this.cables.children = [];
    this.models.children = [];

    this.model.add(this.bases);
    this.model.add(this.adapters);
    this.model.add(this.cables);
    this.model.add(this.models);
  }

  FileExist = async (urlToFile) => {
    let xhr = new XMLHttpRequest();
    xhr.open("HEAD", urlToFile, true);
    xhr.send();

    if (xhr.status === 404) {
      return false;
    } else {
      return true;
    }
  };

  LoadObject(url) {
    return new Promise((resolve) => {
      this.loader.load(url, (result) => {
        resolve(result);
      });
    });
  }

  async Load() {
    await this.Reset();

    if (this.isTrackHead) {
      if (this.baseInput) {
        await this.LoadBase(this.baseInput);
        if (this.isTrack) {
          let specificCodes = ["10", "25", "26", "27", "28", "29", "40"];
          if (specificCodes.includes(this.sysCode)) {
            await this.LoadAdapter(
              this.path + "/base/" + this.sysCode + "_adapter.glb"
            );
          } else {
            if (this.needsAdapter) {
              await this.LoadAdapter(
                this.path + "/base/" + this.sysCode + "_adapter.glb"
              );
            }
          }
        } else {
          let specificCodes = ["P1", "P2", "P3"];
          if (specificCodes.includes(this.sysCode)) {
            if (this.needsAdapter) {
              await this.LoadAdapter(this.path + "/base/20_adapter.glb");
            }
          }
        }
      }
    } else if (this.isCeilingSconce) {
      if (this.baseInput) {
        await this.LoadBase(this.baseInput);
      }
    } else {
      if (this.baseInput) {
        await this.LoadBase(this.baseInput);
        if (this.isTrack) {
          await this.LoadAdapter(
            this.path + "/base/" + this.sysCode + "_adapter.glb"
          );
          await this.LoadCable(this.cableInput);
        } else {
          let specificCodes = ["P1", "P2", "P3"];
          if (specificCodes.includes(this.sysCode)) {
            await this.LoadAdapter(this.path + "/base/20_adapter.glb");
          }
          await this.LoadCable(this.cableInput);
        }
      } else {
        await this.LoadCable(this.cableInput);
      }
    }

    await this.LoadModel(this.modelInput);

    this.hardwareColor = 0x727272; //Default color - Silver
    this.changeHardwareColor();
    this.changeCableColor();
    this.changeCustomColor();

    this.updateCameraPosition();

    await this.setMaxDistance();

    this.loadHDR("/hdr/hdr.hdr", 0.5);
    this.resize();
  }

  async LoadBase(inputBase) {
    this.baseHeight = 0;
    this.baseInput = null;

    if (inputBase) {
      this.baseInput = inputBase;
      let baseObject = await this.LoadObject(inputBase);
      let base = baseObject.scene;
      base.name = "base";
      this.bases.add(base);
    }
  }

  async LoadAdapter(inputAdapter) {
    this.adapterHeight = 0;
    if (inputAdapter) {
      let adapterObject = await this.LoadObject(inputAdapter);
      let adapter = adapterObject.scene;
      adapter.name = "adapter";

      let base = this.bases.children[0];
      base.traverse((o) => {
        if (o.name.startsWith("s_track")) {
          let newAdapter = adapter.clone();
          newAdapter.position.copy(o.position);

          let adapterSocketPosition =
            newAdapter.getObjectByName("s_track").position;

          newAdapter.position.add(adapterSocketPosition.negate());
          this.adapters.add(newAdapter);
        }
      });
    }
  }

  async LoadCable(inputCable) {
    this.cableHeight = 0;
    if (inputCable) {
      if (await this.FileExist(inputCable)) {
        let cableObject = await this.LoadObject(inputCable);
        let cable = cableObject.scene;
        cable.name = "cable";

        if (this.adapters.children.length > 0) {
          let adapter = this.adapters.children[0];
          adapter.traverse((o) => {
            if (o.name.startsWith("s_cable")) {
              let newCable = cable.clone();
              newCable.name = "cable";
              newCable.position.copy(o.position);

              let box = new Box3().setFromObject(newCable);
              let size = box.max.y - box.min.y;

              newCable.position.add(new Vector3(0, -size, 0));
              this.cables.add(newCable);
            }
          });
        } else if (this.bases.children.length > 0) {
          let base = this.bases.children[0];
          let index = 0;
          base.traverse((o) => {
            if (o.name.startsWith("s_point")) {
              index++;
              let newCable = cable.clone();
              newCable.scale.set(1, index, 1);
              newCable.name = "cable";
              newCable.position.copy(o.position);

              let box = new Box3().setFromObject(newCable);
              let size = box.max.y - box.min.y;

              newCable.position.add(new Vector3(0, -size, 0));
              this.cables.add(newCable);
            }
          });
        } else {
          this.cables.add(cable);
        }
      }
    }
  }

  async LoadModel(inputModel) {
    this.modelHeight = 0;
    if (inputModel) {
      if (await this.FileExist(inputModel)) {
        let bodyObject = await this.LoadObject(inputModel);
        let body = bodyObject.scene;
        body.name = "body";

        if (this.cables.children.length > 0) {
          for (const cable of this.cables.children) {
            if (cable.getObjectByName("s_model")) {
              let newModel = body.clone();
              let box = new Box3().setFromObject(newModel);

              if (newModel.getObjectByName("s_model")) {
                var socket = newModel
                  .getObjectByName("s_model")
                  .getWorldPosition();
                newModel.position.copy(
                  cable.getObjectByName("s_model").getWorldPosition()
                );
                newModel.position.add(socket.negate());
              } else {
                let size = box.max.y - box.min.y;
                newModel.position.copy(cable.getWorldPosition());
                newModel.position.add(new Vector3(0, -size, 0));
              }

              this.models.add(newModel);
            }
          }
        } else if (this.adapters.children.length > 0) {
          for (const adapter of this.adapters.children) {
            if (adapter.getObjectByName("s_cable")) {
              let newModel = body.clone();
              let box = new Box3().setFromObject(newModel);

              if (newModel.getObjectByName("s_model")) {
                var socket = newModel
                  .getObjectByName("s_model")
                  .getWorldPosition();
                newModel.position.copy(
                  adapter.getObjectByName("s_cable").getWorldPosition()
                );
                newModel.position.add(socket.negate());
              } else {
                let size = box.max.y - box.min.y;
                newModel.position.copy(adapter.getWorldPosition());
                newModel.position.add(new Vector3(0, -size, 0));
              }

              this.models.add(newModel);
            }
          }
        } else if (this.bases.children.length > 0) {
          for (const base of this.bases.children) {
            if (base.getObjectByName("s_track")) {
              let newModel = body.clone();
              let box = new Box3().setFromObject(newModel);

              if (newModel.getObjectByName("s_model")) {
                var socket = newModel
                  .getObjectByName("s_model")
                  .getWorldPosition();
                newModel.position.copy(
                  base.getObjectByName("s_track").getWorldPosition()
                );
                newModel.position.add(socket.negate());
              } else {
                let size = box.max.y - box.min.y;
                newModel.position.copy(base.getWorldPosition());
                newModel.position.add(new Vector3(0, -size, 0));
              }
              this.models.add(newModel);
            } else {
              base.traverse((o) => {
                if (o.name.startsWith("s_point")) {
                  let newModel = body.clone();
                  let box = new Box3().setFromObject(newModel);

                  if (newModel.getObjectByName("s_model")) {
                    var socket = newModel
                      .getObjectByName("s_model")
                      .getWorldPosition();
                    newModel.position.copy(o.getWorldPosition());
                    newModel.position.add(socket.negate());
                  } else {
                    let size = box.max.y - box.min.y;
                    newModel.position.copy(o.getWorldPosition());
                    newModel.position.add(new Vector3(0, -size, 0));
                  }

                  this.models.add(newModel);
                }
              });
            }
          }
        } else {
          this.models.add(body);
        }
      }
    }
  }

  async changeMaterial(part, material) {
    this.model.traverse((child) => {
      if (child.name === part) {
        child.traverse((o) => {
          if (o.material) {
            if (material.color) {
              o.material.color = material.color;
            }

            if (material.roughness) {
              o.material.roughness = material.roughness;
            }

            if (material.metalness) {
              o.material.metalness = material.metalness;
            }

            o.material.needsUpdate = true;
          }
        });
      }
    });
  }

  changeBody = (bodyName) => {
    let bodyPath = this.path + bodyName + ".glb";
    if (this.modelInput !== bodyPath) {
      this.modelInput = bodyPath;
      this.Load();
    }
  };

  changeBase(baseName) {
    let path = "/models/base/" + baseName + ".glb";
    if (this.baseInput !== path) {
      this.baseInput = "/models/base/" + baseName + ".glb";
      this.Load();
    }
  }

  changeHardwareColor() {
    if (this.hardwareColor != null) {
      let material = {
        color: new Color(this.hardwareColor),
      };
      switch (this.hardwareColor) {
        case 0x040404: //BLACK
          material.roughness = 0.4;
          material.metalness = 0.4;
          break;
        case 0xcccccc: //POLISHED CHROME
          material.roughness = 0.2;
          material.metalness = 0.8;
          break;
        case 0x727272: //SILVER
          material.roughness = 0.5;
          material.metalness = 0.1;
          break;
        case 0xe2e2e2: //WHITE
          material.roughness = 0.5;
          material.metalness = 0.1;
          break;
        case 0x080401: //BRONZE
          material.roughness = 0.4;
          material.metalness = 0.2;
          break;
        case 0x966618: //CHAMPAGNE MATTED GOLD
          material.roughness = 0.5;
          material.metalness = 0.1;
          break;
        default:
          break;
      }
      this.changeMaterial("hardware", material);
    }
  }

  changeCableColor = () => {
    if (this.cableColor != null) {
      let material = {
        color: new Color(this.cableColor),
      };
      switch (this.cableColor) {
        default:
          this.changeMaterial("cable", material);
          break;
      }
    }
  };

  getObjectSize = (target) => {
    let box = new Box3().setFromObject(target);
    let size = {
      depth: -1 * box.min.z + box.max.z,
      height: -1 * box.min.y + box.max.y,
      width: -1 * box.min.x + box.max.x,
    };
    return size;
  };

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  async setMaxDistance() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.controls.object.updateProjectionMatrix();
  }

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  update = () => {
    super.update();
    this.cameraAngle = this.getCameraAngle();
  };

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }

  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }

  setCustomColor(name, input) {
    this.customColor = {
      name: name,
      input: input,
    };
    this.changeCustomColor();
  }

  changeCustomColor() {
    if (!this.customColor) return;

    this.model.traverse((o) => {
      if (o.material?.name === this.customColor.name) {
        o.material.color = new Color(this.customColor.input);
      }
    });
  }
}

export const Player = memo(
  ({
    vars = {},
    width,
    height,
    fullscreen,
    player = null,
    setPlayer = () => {},
  }) => {
    let options = {
      lights: [
        {
          name: "main_light",
          intensity: 0.7,
          type: "DirectionalLight",
          position: new Vector3(0.5, 0, 0.866),
          parent: "camera",
        },
      ],
      controls: {
        enableZoom: false,
        enablePan: true,
      },
    };

    useEffect(() => {
      const createPlayer = () => {
        let newPlayer = new PrimaPlayer("player", options);
        setPlayer(newPlayer);
        window.player = newPlayer;
      };
      createPlayer();
    }, []);

    if (player) {
      player.isTrackHead = vars.isTrackHead;
      player.needsAdapter = vars.needsAdapter;
      player.isWallSconce = vars.isWallSconce;
      player.isCeilingSconce = vars.isCeilingSconce;
    }

    useEffect(() => {
      if (player) {
        let keys = Object.keys(vars);

        if (keys.includes("CATA")) {
          let catNo = vars.CATA;
          if (player.isWallSconce) {
            catNo = catNo + "W";
          }
          player.changeBody(catNo);
        }

        player.isTrack = false;

        if (keys.includes("SYS")) {
          if (vars.SYS === "MULTI_CS" && keys.includes("MULTI_CS")) {
            player.sysCode = vars.MULTI_CS;
            player.changeBase(vars.MULTI_CS);
          }
          if (vars.SYS === "MONO_CS" && keys.includes("MONO_CS")) {
            player.sysCode = vars.MONO_CS;
            player.changeBase(vars.MONO_CS);
          }
          if (vars.SYS === "TCS" && keys.includes("TCSC")) {
            player.isTrack = true;
            player.sysCode = vars.TCSC;
            player.changeBase(vars.TCSC);
          }
        }

        if (keys.includes("H_FIN")) {
          if (player.colorCodes[vars.H_FIN]) {
            player.hardwareColor = player.colorCodes[vars.H_FIN];
            player.changeHardwareColor();
          }
        }

        if (keys.includes("SUSP")) {
          if (player.cableColors[vars.SUSP]) {
            player.cableColor = player.cableColors[vars.SUSP];
            player.changeCableColor();
          }
        }
      }
    }, [vars]);
    React.useEffect(() => {
      if (player) {
        player.resize();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullscreen]);

    return (
      <>
        <RotationBar />
        <div
          id="player"
          style={{
            width: width,
            height: height,
            display: "block !important",
            // border: "1px solid rgb(230,230,230)",
          }}
        />
        <ZoomControls player={player} />
        <LoadingScreen />
      </>
    );
  }
);

const ZoomControls = memo(
  ({ player }) => {
    let [zoomState, setZoomState] = React.useState({ ...zoomOptions });
    let zoomLevels = [];
    for (let i = zoomState.min; i <= zoomState.max; i++) {
      if (zoomLevels) {
        zoomLevels.push(i);
      } else {
        zoomLevels = [i];
      }
    }

    return (
      <div
        id="playerControls"
        className="d-flex align-items-center justify-content-between"
        style={{
          position: "absolute",
          right: "5%",
          bottom: "5%",
          height: "3rem",
          width: "12rem",
        }}
      >
        <Icon
          onClick={() => {
            setZoomState(player.updateZoom(zoomState.current, -1));
          }}
          icon="zoomMinus"
          style={{
            height: "2rem",
            width: "2rem",
            cursor: "pointer",
            userSelect: "none",
          }}
        />
        {zoomLevels.map((i) => {
          return (
            <div
              onClick={() => {
                setZoomState(player.updateZoom(i));
              }}
              style={{
                background:
                  i <= zoomState.current
                    ? "rgba(21, 21, 33,1)"
                    : "rgba(21, 21, 33,0.3)",
                height: "1.5rem",
                width: "0.3rem",
                cursor: "pointer",
              }}
            ></div>
          );
        })}
        <Icon
          onClick={() => {
            setZoomState(player.updateZoom(zoomState.current, 1));
          }}
          icon="zoomPlus"
          style={{
            height: "2rem",
            width: "2rem",
            cursor: "pointer",
            userSelect: "none",
          }}
        />
      </div>
    );
  },
  (prevPlayer, nextPlayer) => {
    return (
      typeof prevPlayer?.player !== "undefined" &&
      typeof prevPlayer?.player?.controls !== "undefined" &&
      typeof prevPlayer?.player?.controls.minDistance !== "undefined" &&
      prevPlayer?.player?.controls?.minDistance !== 0 &&
      typeof nextPlayer?.player !== "undefined" &&
      typeof nextPlayer?.player?.controls !== "undefined" &&
      typeof nextPlayer?.player?.controls.minDistance !== "undefined" &&
      nextPlayer?.player?.controls?.minDistance !== 0
    ); //=== nextPlayer.controls.minDistance;
  }
);

const LoadingScreen = () => {
  return (
    <MDBRow
      id="loading-screen"
      className="flex-center flex-column"
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        fontSize: "2rem",
        zIndex: 9,
      }}
    >
      <Loader />
      <MDBTypography tag="h3" className="mt-3">
        Loading...
      </MDBTypography>
      <Icon
        icon="jola"
        style={{
          position: "absolute",
          bottom: "10%",
          right: "10%",
          opacity: "0.8",
        }}
      />
    </MDBRow>
  );
};

const RotationBar = () => {
  const [currentRotationPercent, setCRP] = useState(
    (window?.player?.cameraAngle / 360) * 100 || 50
  );
  React.useEffect(() => {
    setInterval(() => {
      setCRP((window?.player?.cameraAngle / 360) * 100 || 50);
    }, 1000 / 30);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "0.2rem",
        background: `linear-gradient(90deg, rgba(242, 152, 348,1) 0%, rgba(242, 152, 34,1) ${currentRotationPercent}%, rgba(174,174,174,0.5) ${
          currentRotationPercent + 1
        }%, rgba(174,174,174,0.500437675070028) 100%)`,
      }}
    ></div>
  );
};
