import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const HeaderSmallLogo = () => {
  return <CmsBlock id={"header_small_logo"} />;
};

export const HeaderUserIcon = () => {
  return <CmsBlock id={"header_user_icon"} />;
};

export const HeaderAccountDashboard = () => {
  return <CmsBlock id={"header_account_dashboard"} />;
};

export const HeaderMyFavorites = () => {
  return <CmsBlock id={"header_favorites"} />;
};

export const HeaderSignOut = () => {
  return <CmsBlock id={"header_sign_out"} />;
};

export const HeaderRegister = () => {
  return <CmsBlock id={"header_register"} />;
};

export const HeaderLogin = () => {
  return <CmsBlock id={"header_login"} />;
};
