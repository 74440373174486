import React from "react";
import {
  MDBCard,
  MDBCollapseHeader,
  Typography,
  MDBIcon,
  MDBCollapse,
  MDBCardBody,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBTypography,
  MDBBtn,
  MDBBox
} from "mdbreact";
import { useWatch } from "react-hook-form";
import Select2 from "core/components/select/select";
import PrimaSpinner from "app/layout/primaSpinner";
import MaskedInput from "react-text-mask";
import { Controller } from "react-hook-form";

const QuoteForm = ({
  message,
  setMessage,
  setActiveCollapse,
  activeCollapse,
  errors,
  register,
  loading,
  control,
  customer,
  country,
  countries,
  availableStates,
  onStateChangeHandler,
  onCountryChangeHandler,
  state,
  setFileForUpload,
  fileForUpload,
  triggerUploadFile,
  nonUsState,
  setNonUsState,
  stateError,
  setStateError,
  countryError,
  setCountryError,
  telephoneMask
}) => {
  const firstName = useWatch({
    control,
    name: "firstName",
    defaultValue: customer.firstname,
  });
  const lastName = useWatch({
    control,
    name: "lastName",
    defaultValue: customer.lastname,
  });
  const company = useWatch({
    control,
    name: "company",
    defaultValue: customer?.addresses[0]?.company,
  });
  const phone = useWatch({
    control,
    name: "phone",
    defaultValue: customer?.addresses[0]?.telephone,
  });
  const email = useWatch({
    control,
    name: "email",
    defaultValue: customer?.email,
  });
  const address = useWatch({
    control,
    name: "address",
    defaultValue: customer?.addresses[0]?.street[0],
  });

  const city = useWatch({
    control,
    name: "city",
    defaultValue: customer?.addresses[0]?.city,
  });
  const postalCode = useWatch({
    control,
    name: "postalCode",
    defaultValue: customer?.addresses[0]?.postcode,
  });

  let modifiedStates = [
    {
      label: `${country.value === "US" ? "Select State *" : "Select Province"}`,
      value: 0,
    },
    ...availableStates,
  ];

  return (
    <>
      <MDBCard className="mt-1 collapse-right">
        <MDBCollapseHeader
          tagClassName="d-flex justify-content-between"
          onClick={() => {
            setActiveCollapse(!activeCollapse);
          }}
        >
          <Typography tag="h3" className="contact-information-right">
            Contact Information
          </Typography>
          <MDBIcon icon={activeCollapse ? "minus" : "plus"} />
        </MDBCollapseHeader>
        <MDBCollapse id="collapse1" isOpen={activeCollapse}>
          <MDBCardBody>
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              <MDBInput
                value={firstName}
                type="text"
                name="firstName"
                placeholder="Enter Name"
                label="FIRST NAME"
                labelClass={`quote-label ${
                  errors.firstName && `error-quote-label`
                }`}
                className={`quote-input ${errors.firstName && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              <MDBInput
                value={lastName}
                type="text"
                name="lastName"
                placeholder="Enter Last Name"
                label="LAST NAME"
                labelClass={`quote-label ${
                  errors.lastName && `error-quote-label`
                }`}
                className={`quote-input ${errors.lastName && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              <MDBInput
                control={control}
                value={company}
                type="text"
                name="company"
                placeholder="Enter Company"
                label="company"
                labelClass={`quote-label ${
                  errors.company && `error-quote-label`
                }`}
                className={`quote-input ${errors.company && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              
              <MDBBox className={`md-form phone`}>
                  <Controller
                    as={
                      <MaskedInput
                        className={`form-control`}
                      />
                    }
                    control={control}
                    name={`phone`}
                    value={phone}
                    type="text"
                    hint="Enter Telephone*"
                    error="wrong"
                    material
                    success="right"
                    mask={telephoneMask}
                    guide={false}
                    placeholder="Enter Phone *"
                    label="phone"
                    className={`quote-input ${errors.phone && `error-quote`}`}
                    rules={{ required: true }}
                  />
                  <label
                          for={`phone`}
                          className={`quote-label ${
                            errors.phone && `error-quote-label`
                          }`}
                        >
                          Phone
                  </label>
                </MDBBox>
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4 mb-5">
              <MDBInput
                value={email}
                type="email"
                name="email"
                placeholder="Enter Email"
                label="email"
                labelClass={`quote-label ${
                  errors.email && `error-quote-label`
                }`}
                className={`quote-input ${errors.email && `error-quote`}`}
                inputRef={register({
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4">
              <MDBInput
                value={address}
                type="text"
                name="address"
                placeholder="Enter Address"
                label="Address"
                labelClass={`quote-label ${
                  errors.address && `error-quote-label`
                }`}
                className={`quote-input ${errors.address && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>
            <MDBCol className={`quote-form-select-2`}>
              <span className="select2-label">Country</span>
              <Select2
                value={country}
                onChange={(event) => {
                  onCountryChangeHandler(event);
                  setCountryError(false);
                }}
                wrapperClass="small w-100"
                placeholder={"Country *"}
                options={countries.map((country) => ({
                  label: country.full_name_english,
                  value: country.id,
                  available_regions: country.available_regions,
                }))}
                isSearchable={true}
                className={`${countryError && `error-quote`}`}
              />
            </MDBCol>
            <MDBCol className="pl-0 pr-0 mt-4">
              <MDBInput
                value={city}
                type="text"
                name="city"
                placeholder="Enter City"
                label="City"
                labelClass={`quote-label ${errors.city && `error-quote-label`}`}
                className={`quote-input ${errors.city && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>

            {availableStates && availableStates.length > 0 ? (
              <MDBCol className={`quote-form-select-2`}>
                <span className="select2-label">
                  {country.value === "CA" ? "Province" : "State"}
                </span>
                <Select2
                  name={"state"}
                  type={"text"}
                  icon="address"
                  required={true}
                  value={state}
                  onChange={(event) => {
                    onStateChangeHandler(event);
                    setStateError(false);
                  }}
                  wrapperClass="small w-100"
                  placeholder={
                    country.value === "CA" ? "Select Province" : "Select State"
                  }
                  options={modifiedStates}
                  isSearchable={true}
                  className={`w-100 ${stateError && `error-quote`}`}
                />
              </MDBCol>
            ) : (
              <MDBCol className="pl-0 pr-0 mt-4">
                <MDBInput
                  className="quote-input"
                  value={nonUsState}
                  onChange={(e) => {
                    setNonUsState(e.target.value);
                  }}
                  name="NonUsState"
                  type="text"
                  placeholder="State / Region"
                  label="State / Region"
                  labelClass={`quote-label`}
                />
              </MDBCol>
            )}
            <MDBCol className="pl-0 pr-0 mt-4">
              <MDBInput
                value={postalCode}
                type="text"
                name="postalCode"
                placeholder="Enter Zip / Postal Code"
                label="Zip / postal code"
                labelClass={`quote-label ${
                  errors.postalCode && `error-quote-label`
                }`}
                className={`quote-input ${errors.postalCode && `error-quote`}`}
                inputRef={register({ required: true })}
              />
            </MDBCol>
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
      <MDBCol className="pl-0 pr-0 mt-5">
        <MDBInput
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          type="textarea"
          label="MESSAGE"
          name="message"
          rows="7"
          placeholder="Enter your message"
          labelClass={`quote-label ${errors.message && `error-quote-label`}`}
          className={`quote-input ${errors.message && `error-quote`}`}
          inputRef={register({ required: false })}
        />
      </MDBCol>
      <MDBRow className="pl-0 pr-0 mt-4 mb-5">
        <MDBCol xl="12" lg="12" md="12" sm="12" className="quote pl-2">
          <MDBTypography
            tag="span"
            className="m-0 pl-2 pr-2 pt-0 pb-0 upload-label"
          >
            ADDITIONAL IMAGES AND FILES
          </MDBTypography>

          <div class="file-field">
            <div class="btn btn-primary btn-sm float-right">
              <span
                onClick={(e) => {
                  triggerUploadFile(e);
                }}
              >
                Upload
              </span>
              <input
                id={"file-upload-id"}
                type={`file`}
                accept=".pdf,.jpg,.tiff"
                onChange={(e) => {
                  setFileForUpload(e.target.files[0]);
                }}
              />
            </div>
            <div class="file-path-wrapper">
              <input
                class="file-path validate"
                type="text"
                placeholder={`${
                  typeof fileForUpload === "undefined"
                    ? "Upload file"
                    : fileForUpload.name
                }`}
                accept=".jpg, .pdf, .tff"
              />
            </div>
          </div>
          {errors.files && (
            <p className="error-message pl-3">Maximum upload size is 2MB</p>
          )}
        </MDBCol>
      </MDBRow>
      <MDBCol className="pl-0 pr-0 mt-3">
        <Typography tag="h6" className="save-quote mb-5 mt-">
          (jpg,pdf, tff) max 2MB
        </Typography>
        {loading ? (
          <PrimaSpinner />
        ) : (
          <MDBBtn size="lg" type="submit">
            Submit Quote
          </MDBBtn>
        )}
      </MDBCol>
    </>
  );
};

export default QuoteForm;
