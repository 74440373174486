import React, { useState } from "react";
import MiniQuoteRequestItem from "./MiniQuoteRequestItem";

const MiniQuoteRequestItemStateContainer = (props) => {
  let { item } = props;
  const [quantity, setQuantity] = useState(item.quantity);
  const [loading, setLoading] = useState(false);
  return (
    <MiniQuoteRequestItem
      {...props}
      quantity={quantity}
      setQuantity={setQuantity}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default MiniQuoteRequestItemStateContainer;
