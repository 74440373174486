// move useCart from here to separate place
import { useCart } from "app/state/hooks/cacheHooks/useCart";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import React, { useEffect } from "react";

const GetCart = (props) => {
  const { getCartFromMagento } = useCart();
  const { isLoggedIn } = useCustomer();

  useEffect(() => {
    getCartFromMagento();
  }, [isLoggedIn]);

  return <></>;
};

export default GetCart;
