import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../redux/data/common";
import { customerActions } from "../../redux/data/customer";
import wishlistActions from "core/state/redux/data/wishlist/actions";
import { messagesActions } from "core/state/redux/data/messages";
import Countries from "config/data/countries";
import ZipMaskJSON from "config/data/zipMask";
import TelephoneMaskJson from "config/data/telephoneMask";

const useCustomer = () => {
  const customer = useSelector((state) => state.customer.data);
  let dispatch = useDispatch();
  const customerObject = useSelector((state) => state.customer);
  const isLoggedIn = Boolean(customer.token);

  const login = async (email, password, cartToken) => {
    try {
      if (!email || !password) {
        throw new Error("Invalid email or password");
      }
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("userLogin"),
        variables: { email: email, password: password },
        fetchPolicy: "no-cache",
      });
      if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
        console.log("GraphQL response", data);
        throw new Error("No customer token received");
      }
      dispatch(
        customerActions._setCustomerToken(
          data.generateCustomerToken.token,
          cartToken
        )
      );
      const wishlist = await apolloClient.query({
        query: queryLoader("getWishlists"),
        fetchPolicy: "no-cache",
      });
      if (wishlist.data.getWishlists !== null) {
        dispatch(wishlistActions.setWishlist(wishlist.data.getWishlists));
      }
      return data.generateCustomerToken;
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      dispatch(messagesActions.addMessage(error.toString(), "danger", "login"));
      // dispatch(customerActions._reduceCustomer());
      return false;
    }
  };

  const setCustomerInformation = (customerData) => {
    const newCustomerData = customerData;

    dispatch(customerActions.setCustomerInformation(newCustomerData));
  };

  const getCountryById = (countryCode) => {
    if (!countryCode) return null;

    let foundCountry = Countries.countries.find(
      (country) => country.id === countryCode
    );
    return foundCountry;
  };

  const getCustomerInformation = () => {
    dispatch(customerActions.getCustomerInformation(customer.token));
  };

  const getCustomerAddressesById = (id) => {
    if (!id) return false;

    let address =
      customer.addresses && customer.addresses.length > 0
        ? customer.addresses.find((x) => x.id === parseInt(id))
        : false;

    return address;
  };

  const getTelephoneMaskByCountry = (countryValue) => {
    let value = countryValue;
    let mask = [];
    mask = findMaskByCountry(value, "telephone");
    let maskSanitized = mask.mask.split("");
    return generateSanitizedMaskArray(maskSanitized);
  };

  const getZipMaskByCountry = (countryValue) => {
    let value = countryValue;
    let mask = [];
    mask = findMaskByCountry(value, "zip");
    if (mask.length === 0) return false;
    let maskSanitized = mask.Format.split("");
    return generateSanitizedMaskArray(maskSanitized);
  };
  const getCookies = () => {
    return customerObject.cookies;
  };

  const setCookies = (payload) => {
    return customerActions.setCookies(payload);
  };

  return {
    customer,
    isLoggedIn,
    login,
    setCustomerInformation,
    getCountryById,
    getCustomerInformation,
    getCustomerAddressesById,
    getTelephoneMaskByCountry,
    getZipMaskByCountry,
    getCookies,
    setCookies,
  };
};

const generateSanitizedMaskArray = (object) => {
  return Object.keys(object).map((key) => {
    let regexChar = object[key];
    if (object[key] === "+") regexChar = "+";
    if (object[key] === "A") regexChar = /[A-Z]/i;
    if (object[key] === "#" || object[key] === "N") regexChar = /\d/;
    return regexChar;
  });
};
const findMaskByCountry = (country, type = "telephone") => {
  let maskJson = type === "telephone" ? TelephoneMaskJson : ZipMaskJSON;
  let filtered = maskJson.filter((mask) => {
    return type === "telephone"
      ? mask.cc === country || mask.cc.includes(country)
      : mask.ISO === country;
  });
  return filtered.length > 0 ? filtered[0] : "";
};

export default useCustomer;
