import React, { useState } from "react";
import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBCard,
  MDBCollapseHeader,
  Typography,
  MDBIcon,
  MDBCollapse,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBox,
} from "mdbreact";
import CustomerPages from "app/config/routing/pages/customerPages";

const DashboardNav = ({ activeItem, onClickHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MDBBox id={`account-dashboard-navigation`}>
      <MDBNav
        className="flex-column flex-column-navigation-mobile"
        id="navigation-no-mobile"
      >
        {CustomerPages.map((cp, index) => {
          return cp.inSidebar ? (
            <MDBNavItem key={`cp-${cp.url}-${index}`}>
              <MDBNavLink
                to={`/${cp.url}`}
                className={
                  activeItem === index
                    ? "link active-link account-font-style"
                    : "link account-font-style"
                }
                onClick={onClickHandler}
              >
                {cp.menuText}
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <React.Fragment key={`cp-${cp.url}-${index}`} />
          );
        })}
      </MDBNav>

      <MDBCol className="md-accordion mt-2 p-0 collapse-menu-account mb-md-5">
        <MDBCard className="mt-1">
          <MDBCollapseHeader
            tagClassName="d-flex justify-content-between"
            id="collapse-menu"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Typography tag="h2">Account Menu</Typography>
            <MDBIcon icon={`${isOpen ? "minus" : "plus"}`} className="" />
          </MDBCollapseHeader>
          <MDBCollapse id="collapse1" isOpen={isOpen}>
            <MDBCardBody>
              <MDBRow className="card-body-row">
                <MDBCol className="mt-5 mb-5 card-body-column">
                  <MDBNav className="flex-column">
                    {CustomerPages.map((cp, index) => {
                      return cp.inSidebar ? (
                        <MDBNavItem key={`cp-${cp.url}-${index}-mobile`}>
                          <MDBNavLink
                            to={`/${cp.url}`}
                            className={
                              activeItem === index
                                ? "link active-link account-font-style"
                                : "link account-font-style"
                            }
                            onClick={onClickHandler}
                          >
                            {cp.menuText}
                          </MDBNavLink>
                        </MDBNavItem>
                      ) : (
                        <React.Fragment key={`cp-${cp.url}-${index}-mobile`} />
                      );
                    })}
                  </MDBNav>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCollapse>
        </MDBCard>
      </MDBCol>
    </MDBBox>
  );
};

export default DashboardNav;
