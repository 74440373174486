import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Listing from "./listing";

const Search = ({ toolbarProps, listingProps, totalNumOfItems,searchResultString }) => {

  return (
    <MDBContainer className="search-results-page-wrapper" fluid>
      <MDBRow className="d-flex">
        <MDBCol className="products-header">
          <Toolbar {...toolbarProps} totalNumOfItems={totalNumOfItems} searchResultString={searchResultString}/>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBContainer>
            <Listing items={listingProps} />
            <Toolbar
              showSorting={false}
              showPageSize={true}
              showName={false}
              {...toolbarProps}
              totalNumOfItems={totalNumOfItems}
            />
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Search;
