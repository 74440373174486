import React from "react";

export const EmailErrorMessage = ({ errors }) => {
  if (errors.email && errors.email.type === "required")
    return <p className="account-error-message">This field is required</p>;
  if (errors.email && errors.email.type === "pattern")
    return <p className="account-error-message">Invalid email</p>;
  if (errors.email && errors.email.type === "validate")
    return <p className="account-error-message">Wrong email</p>;
  return null;
};
export const AddressEmailErrorMessage = ({ errors }) => {
  if (errors.address_email && errors.address_email.type === "required")
    return <p className="account-error-message">This field is required</p>;
  if (errors.address_email && errors.address_email.type === "pattern")
    return <p className="account-error-message">Invalid email</p>;
  if (errors.address_email && errors.address_email.type === "validate")
    return <p className="account-error-message">Wrong email</p>;
  return null;
};

export const FirstNameErrorMessage = ({ errors }) => {
  if (errors.firstname && errors.firstname.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};

export const LastNameErrorMessage = ({ errors }) => {
  if (errors.lastname && errors.lastname.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};

export const CurrentPasswordErrorMessage = ({ errors }) => {
  if (errors.currentPassword && errors.currentPassword.type === "required")
    return <p className="account-error-message">This field is required</p>;
  if (errors.currentPassword && errors.currentPassword.type === "pattern")
    return (
      <p className="account-error-message">
        Password must con at least 8 characters, including upper/lowercase,
        digits and/or special characters
      </p>
    );
  return null;
};

export const NewPasswordErrorMessage = ({ errors }) => {
  if (errors.newPassword && errors.newPassword.type === "required")
    return <p className="account-error-message">This field is required</p>;
  if (errors.newPassword && errors.newPassword.type === "pattern")
    return (
      <p className="account-error-message">
        Password must con at least 8 characters, including upper/lowercase,
        digits and/or special characters
      </p>
    );

  if (errors.newPassword && errors.newPassword.type === "validate")
    return (
      <p className="account-error-message">
        New password can't be same as old password.
      </p>
    );
  return null;
};

export const PasswordErrorMessage = ({ errors }) => {
  if (errors.password && errors.password.type === "required")
    return <p className="account-error-message">This field is required</p>;
  if (errors.password && errors.password.type === "pattern")
    return (
      <p className="account-error-message">
        Password must con at least 8 characters, including upper/lowercase,
        digits and/or special character
      </p>
    );
  if (errors.password && errors.password.type === "validate")
    return (
      <p className="account-error-message">Please confirm new password.</p>
    );
  return null;
};

export const PhoneErrorMessage = ({ errors }) => {
  if (errors.phone && errors.phone.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};

export const AddressErrorMessage = ({ errors }) => {
  if (errors.address1 && errors.address1.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};

export const PostcodeErrorMessage = ({ errors }) => {
  if (errors.postcode && errors.postcode.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};

export const CityErrorMessage = ({ errors }) => {
  if (errors.city && errors.city.type === "required")
    return <p className="account-error-message">This field is required</p>;
  return null;
};
