import React from "react";
import {
  MDBBox,
  MDBContainer,
  MDBRow,
  MDBCol,
  Typography,
  MDBBtn,
  MDBLink,
} from "mdbreact";
import Bckgrnd from "./bck.png";
import "./style.scss";

const EmptyQuoteRequest = () => {
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol className="main-column">
          <img src={Bckgrnd} alt="background" />
          <Typography tag="h1" className="pb-5">
            There are no items in Quote Request
          </Typography>
          <MDBRow center>
            <MDBBox md="3" sm="12" className="ml-4 mr-4 mt-5 home-btn">
              <MDBBtn>
                <MDBLink to="/">Home</MDBLink>
              </MDBBtn>
            </MDBBox>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default EmptyQuoteRequest;
