import gql from "graphql-tag";

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $resetPasswordToken: String!
    $newPassword: String!
    $input: ResetPasswordRequestInput!
  ) {
    resetPassword(
      email: $email
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
      input: $input
    ) {
      message
      code
    }
  }
`;
