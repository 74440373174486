import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from "mdbreact";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import {value} from "lodash/seq";

const custitemSortOptionDesc = {
    custitemsort_date: "DESC",
    name: "ASC"
};
const custitemSortOptionAsc = {
    custitemsort_date: "ASC",
    name: "ASC"
};


const SortBy = ({ pageSortOptions, sortSelected, setSortSelected }) => {
  
    const history = useHistory();
    let search = queryString.parse(history.location.search);

  return (
    <MDBSelect
        key={sortSelected}
       placeholder={"hello"}
      style={{ zIndex: "99" }}
      label="Sort By:"
      className="select-wob d-flex flex-row-reverse"

      getValue={(v) => {

          if(search.custitemsort_date === undefined && search.name === undefined) {
              setSortSelected({custitemsort_date: "DESC", name: "ASC" })
          }

          let sortValue = v[0]
          setSortSelected( sortValue )
          search = {
              ...search,
              ...sortValue,

          };
          history.replace({
              pathname: history.location.pathname,
              search: queryString.stringify(search),
          });
      }}
    >
      <MDBSelectInput  />
      <MDBSelectOptions  >
          <MDBSelectOption key={pageSortOptions[0].label} value={pageSortOptions[0].value} selected={_.isEqual(pageSortOptions[0].value, sortSelected)  ?? "selected"} > {pageSortOptions[0].label} </MDBSelectOption>
          <MDBSelectOption key={pageSortOptions[0].label} value={pageSortOptions[1].value} selected={_.isEqual(pageSortOptions[1].value, sortSelected) ?? "selected"} > {pageSortOptions[1].label} </MDBSelectOption>
          <MDBSelectOption key={pageSortOptions[0].label} value={pageSortOptions[2].value} selected={_.isEqual(pageSortOptions[2].value, sortSelected) ?? "selected"} > {pageSortOptions[2].label} </MDBSelectOption>
          {/* <MDBSelectOption key={pageSortOptions[0].label} value={pageSortOptions[3].value} selected={_.isEqual(pageSortOptions[3].value, sortSelected) ?? "selected"}> {pageSortOptions[3].label} </MDBSelectOption> */}
        {/*{pageSortOptions.map((item, index) => {*/}
        {/*    console.log("sortSelected sortSelected sortSelected", sortSelected)*/}
        {/*    console.log("sortSelected sortSelected sortSelected value", item.value)*/}
        {/*    if(_.isEqual(item.value, sortSelected)) {*/}
        {/*        console.log("sortSelected sortSelected sortSelected label", item.label)*/}
        {/*    }*/}
        {/*  return (*/}
        {/*    <MDBSelectOption key={index} value={item.value}*/}
        {/*        id={"selected"+ index }*/}

        {/*   //   checked={ _.isEqual(item.value, sortSelected) ? true : false  }*/}

        {/*    >  { item.label  } </MDBSelectOption>*/}
        {/*  );*/}
        {/*})}*/}
      </MDBSelectOptions>
    </MDBSelect>
  );
};
export default SortBy;
