import React, { useState, useEffect } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBox,
  MDBLink,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdbreact";
import Image from "core/components/image";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import Icon from "app/assets/icon/icon";
import WishlistPlpItem from "./wishlistPlpItem";
import { validate, shouldMarkError } from "./validation";

const Wishlist = (props) => {
  const [modalWishlist, setModalWishlist] = useState(false);
  const [modalWishlistSecond, setModalWishlistSecond] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createLink, setCreateLink] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(false);
  const [error, setError] = useState(false);
  const [fetchArrSorted, setFetchArrSorted] = useState(false);
  let errors = validate(email, password);
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    ,
    ,
    ,
    ,
    ,
    createWishlist,
    ,
    checkEmail,
  ] = useWishlist();
  let { fetchArr } = props;

  useEffect(() => {
    fetchWishlistData();
  });

  useEffect(() => {
    let newFetchArr = [...fetchArr];
    const myFavoritesItem = newFetchArr.find(
      (items) => items.name === "My Favorites"
    );
    newFetchArr = newFetchArr.filter((item) => item.name !== "My Favorites");
    newFetchArr.unshift(myFavoritesItem);
    setFetchArrSorted(newFetchArr);
  }, [fetchArr]);

  useEffect(() => {
    const products = document.querySelectorAll(".single-product");
    if (modalWishlistSecond || modalWishlist) {
      products.forEach((product) => (product.style.zIndex = "auto"));
    } else {
      products.forEach((product) => (product.style.zIndex = 5));
    }
  }, [modalWishlistSecond, modalWishlist]);

  const validateInput = (e) => {
    e.preventDefault();
    let isTrue = true;
    setTouched({
      email: true,
      password: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
  };

  const modalToggle = () => {
    setUsername(false);
    setEmail("");
    setModalWishlist(!modalWishlist);
  };
  const modalToggleSecond = async () => {
    if (fetchArr) {
      let arr = [];
      const myFavorites = fetchArr.find((fav) => fav.name === "My Favorites");
      const ifInMyFavorites = myFavorites.items.find(
        (item) => item.product.sku === props.product?.sku
      );

      fetchArr.map((wishlist) => {
        return wishlist.items?.filter((wishistitem) => {
          if (wishistitem?.product?.sku === props.product?.sku) {
            arr.push(wishistitem);
            return wishistitem;
          }
        });
      });
      if (!props.isFavorite && !ifInMyFavorites) {
        let input = {
          products: [{ sku: props.product.sku }],
          wishlists: [{ name: "My Favorites" }],
        };
        await addToWishlist(input);
        try {
          props.setIsFavorite(true);
          setModalWishlistSecond(!modalWishlistSecond);
        } catch (error) {
          console.log(error);
        }
      } else if (props.isFavorite && arr.length === 1 && ifInMyFavorites) {
        let removeWishlistPlp = {
          wishlist: "My Favorites",
          skus: [props.product.sku],
        };
        await removeFromWishlist(removeWishlistPlp);
        try {
          props.setIsFavorite(false);
        } catch (error) {
          console.log(error);
        }
      } else {
        setModalWishlistSecond(!modalWishlistSecond);
      }
    }
  };
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  const [project, setProject] = useState("");

  // determine if product is added to any wishlist
  let isInWishlist = false;

  fetchArr.map((list) => {
    return list?.items.map((item) => {
      if (item.product?.sku === props.product?.sku) {
        isInWishlist = true;
      }
      return "";
    });
  });

  const handleLoginCheck = async () => {
    setLoading(true);
    if (username === false) {
      checkEmail(email)
        .then((response) => {
          setLoading(false);
          setUsername(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // login logic
      props.lock({
        always: () => {},
        success: () => {
          setLoading(false);
          setModalWishlist(false);
          setModalWishlistSecond(true);
          setError(false);
        },
        fail: () => {
          setLoading(false);
          setError(true);
        },
      });
      const loginData = await props.login(
        email,
        password,
        props.customer.cartToken
      );
      try {
        console.log(loginData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {React.cloneElement(props.children, {
        onClick: async (e) => {
          if (!loggedIn) {
            modalToggle();
          } else {
            e.target.classList.add("loading");
            const favorites = fetchArr.find(
              (item) => item.name === "My Favorites"
            );
            if (!favorites) {
              await createWishlist("My Favorites");
              try {
                modalToggleSecond();
              } catch (error) {
                console.log(error);
              }
            } else {
              modalToggleSecond();
            }
          }
        },
        icon: isInWishlist === true ? "heartFull" : "heartPlp",
      })}

      {modalWishlist && (
        <MDBContainer id={`plp-wishlist-modal`} className={`first-modal`}>
          <MDBModal
            isOpen={modalWishlist}
            toggle={(e) => {
              modalToggle();
            }}
            className={"wishlist-modal modal-wrapper"}
            centered
          >
            <MDBModalHeader>
              <MDBBox>
                <div>
                  <p className="text-center">Add this item to your Favorites</p>
                  <MDBBox className="close-btn">
                    <Icon
                      icon="closeIcon"
                      onClick={() => {
                        modalToggle();
                      }}
                    />
                  </MDBBox>
                </div>
              </MDBBox>
            </MDBModalHeader>
            <MDBModalBody className="align-items-center">
              <MDBContainer fluid>
                <MDBRow className={`border-bottom mb-5 pb-3 pt-3`}>
                  <MDBCol>
                    {username === false ? (
                      <MDBRow className={`text-center`}>
                        <MDBCol size="12" className="mb-3">
                          <Image source={props.product.image.url} />
                        </MDBCol>
                        <MDBCol
                          size="12"
                          className={`d-flex align-items-center justify-content-center`}
                        >
                          <MDBRow>
                            <MDBCol size={"12"}>
                              <MDBBox tag="h5">{props.product.name}</MDBBox>
                            </MDBCol>
                            {(loggedIn && (
                              <MDBCol size={"12"}>
                                <MDBBox className={"product-price"} tag="p">
                                  $67.00
                                </MDBBox>
                              </MDBCol>
                            )) || <></>}
                          </MDBRow>
                        </MDBCol>
                        <MDBCol size="12">
                          <MDBBox tag="h5">SKU {props.product.sku}</MDBBox>
                        </MDBCol>
                      </MDBRow>
                    ) : (
                      <MDBRow>
                        <MDBCol size="12" className="mb-3 text-center">
                          <Image source={props.product.image.url} />
                        </MDBCol>
                        <MDBCol size={"12"} className="text-center">
                          <MDBBox tag="h5">{props.product.name}</MDBBox>
                        </MDBCol>
                        <MDBCol size="12" className="text-center">
                          <MDBBox tag="h5">SKU {props.product.sku}</MDBBox>
                        </MDBCol>
                        <MDBCol size={`12`} className="text-center wrong-email">
                          <MDBBox
                            tag="p"
                            className={"link-elaine-favorites"}
                            onClick={(e) => {
                              setEmail("");
                              setUsername(false);
                            }}
                          >
                            <MDBBox tag="span">Not your email? Go back</MDBBox>
                          </MDBBox>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow className={`action-content`}>
                  <MDBCol size="12">
                    <MDBRow>
                      <MDBCol className="text-center mx-auto">
                        <MDBBox tag="h3" className="text-center mb-0">
                          {username === false
                            ? "Before you do that..."
                            : `Welcome back, ${username}`}
                        </MDBBox>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="12">
                        {username === false ? (
                          <MDBBox
                            tag="p"
                            className="text-center mb-5 register-block"
                          >
                            <MDBBox
                              tag="span"
                              className={"link-elaine-favorites sign-in"}
                            >
                              <MDBBox tag="span" className={`text-uppercase`}>
                                Sign in
                              </MDBBox>
                            </MDBBox>
                            or
                            <MDBLink
                              className={"link-elaine"}
                              to={`/register/trade`}
                              onClick={(e) => {
                                modalToggle();
                              }}
                            >
                              <MDBBox tag="span" className={`text-uppercase`}>
                                Register
                              </MDBBox>
                            </MDBLink>
                            with your email address
                          </MDBBox>
                        ) : (
                          <MDBBox
                            tag="p"
                            className="text-center mb-5 enter-password"
                          >
                            Great to see you again. Enter your account password
                            to continue
                          </MDBBox>
                        )}

                        {username === false ? (
                          <InputField
                            className={`custom-input ${
                              shouldMarkError("email", errors, touched)
                                ? "validation-error"
                                : ""
                            }`}
                            label={`${
                              shouldMarkError("email", errors, touched)
                                ? email.length === 0
                                  ? "Email is required"
                                  : "Email is invalid"
                                : "Email"
                            }`}
                            labelClass={`enter-email`}
                            onBlur={(e) => {
                              setTouched({ ...touched, email: true });
                            }}
                            hint="Enter Email"
                            placeholder="Enter Email"
                            group
                            type="email"
                            validate
                            error="wrong"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleLoginCheck();
                              }
                            }}
                          />
                        ) : (
                          <InputField
                            className={`custom-input ${
                              shouldMarkError("password", errors, touched)
                                ? "validation-error"
                                : ""
                            }`}
                            label={`${
                              shouldMarkError("password", errors, touched)
                                ? "Password is required"
                                : "Password"
                            }`}
                            labelClass="password-label"
                            onBlur={(e) => {
                              setTouched({ ...touched, password: true });
                            }}
                            hint="Password"
                            group
                            validate
                            error="wrong"
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleLoginCheck();
                              }
                            }}
                          />
                        )}
                      </MDBCol>
                      <MDBCol size="12">
                        <Button
                          className={"btn-with-icon w-100"}
                          onClick={(e) => {
                            validateInput(e);
                            handleLoginCheck();
                          }}
                        >
                          {loading ? "processing..." : "continue"}
                        </Button>
                        {error}
                      </MDBCol>
                    </MDBRow>
                    {username === false ? (
                      <></>
                    ) : (
                      <MDBRow className={`text-center`}>
                        <MDBCol size="12">
                          <MDBLink
                            className={"link-elaine"}
                            to={`/forgot-password`}
                            onClick={(e) => {
                              modalToggle();
                            }}
                          >
                            <MDBBox tag="span">forgot your password?</MDBBox>
                          </MDBLink>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      )}
      {modalWishlistSecond && loggedIn && (
        <MDBContainer id={`plp-wishlist-modal`} className={`second-modal`}>
          <MDBModal
            isOpen={modalWishlistSecond}
            toggle={(e) => {
              setModalWishlistSecond(!modalWishlistSecond);
            }}
            className={"wishlist-modal modal-wrapper"}
            centered
          >
            <MDBModalHeader>
              <div>
                <p className="text-center">Added to Your Favorites</p>
                <MDBBox className="close-btn">
                  <Icon
                    icon="closeIcon"
                    onClick={() => {
                      setModalWishlistSecond(!modalWishlistSecond);
                    }}
                  />
                </MDBBox>
              </div>
            </MDBModalHeader>
            <MDBModalBody className="d-flex w-100 flex-column">
              <MDBRow className="modal-title-wrapper align-items-center">
                <MDBCol size="12" className="mb-3 mt-5 text-center">
                  <h3 className="mb-3">{props.product?.name}</h3>
                </MDBCol>
                <MDBCol size="12" className="mb-3 text-center">
                  <img
                    style={{ maxWidth: "100%", height: "150px" }}
                    src={props.product?.small_image?.url}
                    alt={props.product?.small_image?.label}
                  />
                </MDBCol>
                <MDBCol md="7">
                  <MDBInput
                    type="text"
                    hint={
                      project.length === 0
                        ? "Enter project name"
                        : "Project name"
                    }
                    group
                    placeholder={
                      project.length === 0
                        ? "Enter project name"
                        : "Project name"
                    }
                    value={project}
                    label="PROJECT NAME"
                    labelClass={`project-name`}
                    onChange={(e) => {
                      if (e.target.value.length <= 24) {
                        setProject(e.target.value);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        setLoading(true);
                        props.lock({
                          always: () => {},
                          success: () => {
                            setLoading(false);
                          },
                          fail: () => {
                            setLoading(false);
                          },
                        });
                        createWishlist(project);
                        setProject("");
                      }
                    }}
                  />
                </MDBCol>
                <MDBCol md="5">
                  <Button
                    className="link w-100 border-bottom d-flex align-items-center justify-content-center mb-0"
                    onClick={(e) => {
                      setLoading(true);
                      props.lock({
                        always: () => {},
                        success: () => {
                          setLoading(false);
                        },
                        fail: () => {
                          setLoading(false);
                        },
                      });
                      setProject("");
                      createWishlist(project);
                    }}
                    direction={"ml-3"}
                    // leftIcon={true}
                    // id={"addFavorite"}
                    children={"create"}
                  />
                </MDBCol>
              </MDBRow>
              {fetchArr.map((list) => {
                let checked = false;
                let foundItem = list?.items?.filter(
                  (fItem) => fItem.product?.sku === props.product?.sku
                );
                if (foundItem.length !== 0) {
                  checked = true;
                }
                return (
                  <WishlistPlpItem
                    key={`wishlist-list-id-${list.id}`}
                    loading={loading}
                    setLoading={setLoading}
                    list={list}
                    checked={checked}
                    modalToggleSecond={modalToggleSecond}
                    sku={props.product.sku}
                    setIsFavorite={props.setIsFavorite}
                  />
                );
              })}
              <Button
                className={"btn-with-icon w-100 mt-5"}
                onClick={(e) => {
                  setModalWishlistSecond(!modalWishlistSecond);
                }}
              >
                Done
              </Button>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      )}
    </>
  );
};

export default Wishlist;
