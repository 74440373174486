import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import GraphQlQueryWrapper from "./dynamicGraphQlWrapper-query-container";
import { pendingTask, begin } from "react-redux-spinner";
import { default as queryActionTypes } from "core/state/redux/data/queries/types";
import { default as queriesActions } from "core/state/redux/data/queries/actions";
import store from "core/state/redux/store";
import { default as graphQlConfig } from "config/graphQlConfig";
import { customerIsLoggedIn } from "core/helpers/customer";
import { isQueryCached } from "core/helpers/queries";
import { findCategoryByAttribute } from "core/helpers/category";
import Component from "./dynamicGraphQlWrapper";
import useCache from "app/state/hooks/cacheHooks/useCache";
import categories from "generation/categories.json";
import { isUndefined } from "core/helpers/functions";

// import DynamicGraphQlWrapperCachedQueryContainer from "./cached-query-container";

const ADD_QUERY = queryActionTypes.ADD_QUERY;

const DynamicGraphQlWrapperReduxContainer = (props) => {
  //console.log("DynamicGraphQlWrapperReduxContainer++++++++++++++ReduxContainer", props)
  const { getCacheEntry, getUrlResolverEntry } = useCache();
  let flag = props.flag;
  let token = false;
  let isCached = false;

  token = customerIsLoggedIn(props.customer);
  isCached = isQueryCached(props);
  if (isCached === false) {
    if (props.queryIdentifier !== "urlResolver") {
      isCached = getCacheEntry(props.variables.url);
    } else {
      isCached = getUrlResolverEntry(props.variables.url);
    }
  }

  if (categories) {
    let allCategories = categories.default.children;

    let category = findCategoryByAttributeHere(
      props.variables.url,
      allCategories,
      "url_path"
    );
   // console.log("DynamicGraphQlWrapperReduxContainer++++++++++++++ReduxContainer category", category);
    if (category) {
      const children = React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          data: {
            ...props.data,
            urlResolver: { id: category.id, type: "CATEGORY" },
          },
          category: category,
        });
      });
      return <Component {...props}>{children}</Component>;
    }
  }

  if (isCached) {
    const children = React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        data: {
          ...isCached,
        },
      });
    });
    return <Component {...props}>{children}</Component>;
  }
  return (
    <GraphQlQueryWrapper
      {...props}
      token={token}
      store={store}
      isCached={isCached}
      flag={flag}
    />
  );

  // TODO: see if this part needs to be refactored?
  // return <DynamicGraphQlWrapperCachedQueryContainer {...props} isCached={isCached}/>
};

const mapStateToProps = (state) => ({
  customer: state.customer.data,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  data: state.queries.data,
  flag: state.cache.flag,
  queries: state.queries.urlResolver,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    },
    addQuery: (
      id,
      url,
      query,
      variables = [],
      type = graphQlConfig.types["query"]
    ) => {
      dispatch({
        type: ADD_QUERY,
        [pendingTask]: begin, // Make sure you embrace `pendingTask` in brackets [] to evaluate it,
        // Any additional key/values may be included here
        id: id,
        query: query,
        variables: variables,
        queryType: type,
      });
    },
    closeAllModals: () => {
      dispatch(commonActions.closeAllModals());
    },
  };
};


export function findCategoryByAttributeHere(
    attribute_value,
    categoryTree,
    attribute
) {
  if (typeof categoryTree === "undefined") return [];
  if (categoryTree[attribute] === null ) {
    categoryTree = categoryTree.children;
  }

  function iter(a) {

    if (
        !isUndefined(attribute) &&
        !isUndefined(attribute_value) &&
        !isUndefined(a[attribute]) &&
        a !== attribute_value
    ) {
      if (
          (typeof a[attribute] === "number" &&
              a[attribute] === attribute_value) ||
          (typeof a[attribute] === "string" &&
              a[attribute].toLowerCase().includes(attribute_value.toLowerCase()) && a.url_path === attribute_value)
      ) {
        result = a;
        //console.log("=================> ",result);
        return true;
      }

      return Array.isArray(a.children) && a.children.some(iter);
    }
  }

  let result;
  categoryTree.some(iter);
  return result;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicGraphQlWrapperReduxContainer);
