import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "react-apollo-hooks";
import { transform } from "core/components/cmsBlock/Parser";

import gql from "graphql-tag";
import PrivacyPolicy from "./privacy-policy";

export const ids = [
  "privacy_title",
  "privacy_first_heading",
  "privacy_first_text",
  "privacy_second_heading",
  "privacy_second_text",
  "privacy_third_heading",
  "privacy_third_text",
  "privacy_fourth_heading",
  "privacy_fourth_text",
  "privacy_fifth_heading",
  "privacy_fifth_text",
  "privacy_sixth_heading",
  "privacy_sixth_text",
];

const PRIVACY_POLICY = gql`
  query cmsBlock($ids: [String!]!) {
    cmsBlocks(identifiers: $ids) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const CmsDataFragment = ({ content }) =>
  ReactHtmlParser(content, { transform: transform });
const PrivacyPolicyContainer = () => {
  const { data, loading } = useQuery(PRIVACY_POLICY, { variables: { ids } });
  if (loading) {
    return null;
  }
  return <PrivacyPolicy data={data} contentMap={ids} />;
};
export default PrivacyPolicyContainer;
