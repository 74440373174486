import React from "react";
import { MDBLink } from "mdbreact";
import WishlistIcon from "app/assets/images/wishlist.svg";

const WishlistFront = () => {
  return (
    <>
      <MDBLink to="/customer/wishlist" link>
      <img className="wishlist-header-icon" src={WishlistIcon} alt=""/>
      </MDBLink>
    </>
  );
};

export default WishlistFront;
