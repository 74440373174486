import React from "react";
import { MDBBox } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import expand from "app/assets/images/expand.png";
import Hotspot from "app/layout/hotspot";
import PortfolioModal from "./portfolioModal";
import { useMediaQuery } from "react-responsive";

const GalleryItem = ({ image, hotspotData, images, index }) => {
  const shouldHideEnlargeIcon = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <>
      <Hotspot
        contentArray={hotspotData[0].content}
        isHome={true}
        hotspotImage={image}
      />
      {!shouldHideEnlargeIcon && (
        <ModalConsumer value={{ useWrapper: true }}>
          {({ showModal, hideModal }) => (
            <MDBBox
              className="modal-button cursor-pointer"
              style={{ background: `url(${expand})` }}
              onClick={(e) => {
                showModal(() => {
                  return (
                    <PortfolioModal
                      hideModal={hideModal}
                      images={images}
                      index={index}
                    />
                  );
                });
              }}
            />
          )}
        </ModalConsumer>
      )}

      {/* <a href={image} download target="_blank" rel="noopener noreferrer">
        <MDBBox
          style={{ background: `url(${download})` }}
          className="download"
        />
      </a> */}
    </>
  );
};

export default GalleryItem;
