import apolloClient from "app/graphql/apolloClient";
import commonActions from "../actions";
import queryActions from "app/state/redux/data/queries/actions";
import queryLoader from "app/graphql/queryLoader";
import { cacheActions } from "..";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("generateCache"),
      variables: { input: "fetch" },
    });
    if (!data.generateCache) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }

    if (action.version !== data.generateCache && action.flag !== "wait") {
      store.dispatch(commonActions.setFlag("wait")).then(() => {
        store
          .dispatch(commonActions.setCacheVersion(data.generateCache))
          .then(() => {
            store.dispatch(queryActions.removeAllQueries()).then(() => {
              store.dispatch(cacheActions.clearDataFromCache()).then(() => {
                store.dispatch(commonActions.setFlag("ready")).then(() => {});
              });
            });
          });
      });
    } else if (
      action.version !== data.generateCache &&
      action.flag === "wait"
    ) {
      store.dispatch(commonActions.setFlag("wait")).then(() => {
        store
          .dispatch(commonActions.setCacheVersion(data.generateCache))
          .then(() => {
            store.dispatch(queryActions.removeAllQueries()).then(() => {
              store.dispatch(cacheActions.clearDataFromCache()).then(() => {
                store.dispatch(commonActions.setFlag("ready")).then(() => {});
              });
            });
          });
      });
    }
  } catch (error) {
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
