
import React, {useEffect, useState} from "react";
import {HeaderSalesRepsNews} from "../header/top/header-top-query-container";
import gql from "graphql-tag";
import {useQuery} from "react-apollo-hooks";
import {
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBNav,
    MDBNavItem,
    MDBNavLink, MDBRow,
    MDBTabContent,
    MDBTabPane
} from "mdbreact";
import LoginForm from "./loginForm";
import {Link} from "react-router-dom";
import RegisterForm from "./registerForm";
import Icon from "../../assets/icon/icon";
import ReactHtmlParser from "react-html-parser";

export const SalesRepNewsModal = (
    sRLIShow,
    setSRLIShow
) => {
    const [modal, setModal] = useState(true);


    useEffect(() => {

    }, []);
    const toggleModal = () => {
        setModal(!modal)
        localStorage.setItem('SRLIshow', false)

    }

    const CMS_BLOCK_SALES_REP_NEWS = gql`
        query cmsBlock($id:String!) {
          cmsBlocks(identifiers:[$id]) {
            items {
              content
              identifier
              title
            }
          }
        }
        `;

    const { loading, error, data } = useQuery(CMS_BLOCK_SALES_REP_NEWS, {
        variables: { id: "header_sales_rep_news"},
        fetchPolicy: "no-cache"
    });



    return (
        <MDBContainer className="login-modal-popup-wrapper">
            <MDBModal isOpen={modal} toggle={toggleModal}>
                <MDBModalBody className="loginModal" style={{ position: "relative" }}>
                    <Icon
                        style={{
                            position: "absolute",
                            top: "3%",
                            cursor: "pointer",
                            right: "3%",
                            color: "#fff"
                        }}
                        icon="closeSearch"
                        onClick={() => {
                            toggleModal();
                            // localStorage.setItem('SRLIshow', false)

                        }}
                    />
                    <MDBContainer
                        style={{
                            width: "100%",
                            margin: "0px",
                            padding: "0px",
                        }}
                    >
                        <div className="popupModalInerWrap">
                            {ReactHtmlParser(data?.cmsBlocks?.items[0]?.content)}
                        </div>

                    </MDBContainer>

                </MDBModalBody>
            </MDBModal>
        </MDBContainer>

    )

}
