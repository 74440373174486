import React from "react";
import { ReactComponent as AddQty } from "./addQty.svg";
import { ReactComponent as AddFavorite } from "./addFavorite.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as BackArrow } from "./backArrow.svg";
import { ReactComponent as Cart } from "./cart.svg";
import { ReactComponent as CloseSearch } from "./closeSearch.svg";
import { ReactComponent as ClosePromo } from "./closePromo.svg";
import { ReactComponent as CloseBig } from "./closeBig.svg";
import { ReactComponent as DownloadBlack } from "./downloadBlack.svg";
import { ReactComponent as DownloadPlp } from "./downloadPlp.svg";
import { ReactComponent as DownloadImage } from "./downloadImage.svg";
import { ReactComponent as DownloadPdf } from "./downloadPdf.svg";
import { ReactComponent as DeleteProject } from "./deleteProject.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EditSmall } from "./editSmall.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as EmptyProject } from "./emptyProject.svg";
import { ReactComponent as HeartEmptyBlack } from "./heartEmptyBlack.svg";
import { ReactComponent as HeartFull } from "./heartFull.svg";
import { ReactComponent as HeartPlp } from "./heartPlp.svg";
import { ReactComponent as HeartPdp } from "./heartPdp.svg";
import { ReactComponent as HeartBig } from "./heartBig.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as LeftArrow } from "./leftArrow.svg";
import { ReactComponent as LogoMobile } from "./logoMobile.svg";
import { ReactComponent as LogoMobileSmall } from "./logoMobileSmall.svg";
import { ReactComponent as LabelNew } from "./labelNew.svg";
import { ReactComponent as MinusQty } from "./minusQty.svg";
import { ReactComponent as MobileLogo } from "./mobileLogo.svg";
import { ReactComponent as PinIcon } from "./pinIcon.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Pause } from "./pause.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as TrashBig } from "./trashBig.svg";
import { ReactComponent as TrashSmall } from "./trashSmall.svg";
import { ReactComponent as TrashWhite } from "./trashWhite.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as UserArrow } from "./userArrow.svg";
import { ReactComponent as Underline } from "./underline.svg";
import { ReactComponent as CloseIcon } from "./close-icon.svg";
import { ReactComponent as DrawingShape } from "./drawingShape.svg";
import { ReactComponent as MenuCircle } from "./menuCircle.svg";
import { ReactComponent as StoreLocator } from "./storeLocatorWhite.svg";
import { ReactComponent as Zoom } from "./zoom.svg";
import { ReactComponent as Quote } from "./quote.svg";
import { ReactComponent as GmapsMarker } from "./gmaps-marker.svg";
import { ReactComponent as PdfDownload } from "./pdf-download.svg";
import { ReactComponent as RotateIcon } from "./360icon.svg";
import { ReactComponent as SearchBlack } from "./searchBlack.svg";
import { ReactComponent as PlayIcon } from "./play-small.svg";
import { ReactComponent as FindARepIcon } from "./findARepIcon.svg";
import { ReactComponent as RepLocatorUser } from "./repLocatorUser.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as PdfFaqIcon } from "./pdf_faq_icon.svg";
import { ReactComponent as ExploreLine } from "./exploreLine.svg";
import { ReactComponent as Jola } from "./jola.svg";
import { ReactComponent as ThreeDModelIcon } from "./3Dmodel.svg";
import { ReactComponent as Enlarge } from "./enlarge.svg";
import { ReactComponent as ZoomPlus } from "./zoomPlus.svg";
import { ReactComponent as ZoomMinus } from "./zoomMinus.svg";
import { ReactComponent as NewThreeD } from "./new_3D_icon.svg";
import { ReactComponent as Camera360 } from "./Camera360.svg";
import { ReactComponent as MinimizeIcon } from "./minimize.svg";
import { ReactComponent as Printpdf } from "./printpdf.svg";
import { ReactComponent as OneSelected } from "./1-selected.svg";
import { ReactComponent as OneDisabled } from "./1-disabled.svg";
import { ReactComponent as TwoSelected } from "./2-selected.svg";
import { ReactComponent as TwoDisabled } from "./2-disabled.svg";
import { ReactComponent as FourSelected } from "./4-selected.svg";
import { ReactComponent as FourDisabled } from "./4-disabled.svg";
import { ReactComponent as EightSelected } from "./8-selected.svg";
import { ReactComponent as EightDisabled } from "./8-disabled.svg";
import { ReactComponent as LogoWhite } from "./logo_white.svg";
import { ReactComponent as LogoDark } from "./logo_dark.svg";

const icons = {
  oneSelected: OneSelected,
  oneDisabled: OneDisabled,
  twoSelected: TwoSelected,
  twoDisabled: TwoDisabled,
  fourSelected: FourSelected,
  fourDisabled: FourDisabled,
  eightSelected: EightSelected,
  eightDisabled: EightDisabled,
  addQty: AddQty,
  addFavorite: AddFavorite,
  arrowDown: ArrowDown,
  backArrow: BackArrow,
  cart: Cart,
  closeSearch: CloseSearch,
  closePromo: ClosePromo,
  closeBig: CloseBig,
  quote: Quote,
  downloadBlack: DownloadBlack,
  downloadPlp: DownloadPlp,
  downloadImage: DownloadImage,
  downloadPdf: DownloadPdf,
  deleteProject: DeleteProject,
  email: Email,
  edit: Edit,
  editSmall: EditSmall,
  emptyProject: EmptyProject,
  heartEmptyBlack: HeartEmptyBlack,
  heartFull: HeartFull,
  heartPlp: HeartPlp,
  heartPdp: HeartPdp,
  heartBig: HeartBig,
  info: Info,
  leftArrow: LeftArrow,
  logoMobile: LogoMobile,
  logoMobileSmall: LogoMobileSmall,
  labelNew: LabelNew,
  minusQty: MinusQty,
  mobileLogo: MobileLogo,
  pinIcon: PinIcon,
  phone: Phone,
  pause: Pause,
  play: Play,
  rightArrow: RightArrow,
  search: Search,
  share: Share,
  trashBig: TrashBig,
  trashSmall: TrashSmall,
  trashWhite: TrashWhite,
  user: User,
  userArrow: UserArrow,
  underline: Underline,
  closeIcon: CloseIcon,
  drawingShape: DrawingShape,
  menuCircle: MenuCircle,
  storeLocator: StoreLocator,
  zoom: Zoom,
  gmapsMarker: GmapsMarker,
  pdfDownload: PdfDownload,
  rotateIcon: RotateIcon,
  searchBlack: SearchBlack,
  playIcon: PlayIcon,
  findARepIcon: FindARepIcon,
  repLocatorUser: RepLocatorUser,
  plus: Plus,
  minus: Minus,
  pdfFaqIcon: PdfFaqIcon,
  exploreLine: ExploreLine,
  jola: Jola,
  threeDModel: ThreeDModelIcon,
  enlarge: Enlarge,
  zoomPlus: ZoomPlus,
  zoomMinus: ZoomMinus,
  newThreeD: NewThreeD,
  camera360: Camera360,
  minimize: MinimizeIcon,
  printpdf: Printpdf,
  logoWhite: LogoWhite,
  logoDark: LogoDark,
};
const Icon = (props) => {
  const ReturnIcon = icons[props.icon];

  return icons.hasOwnProperty(props.icon) ? <ReturnIcon {...props} /> : "";
};

export default Icon;
