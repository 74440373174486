import React, { useState } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { isEmpty } from "core/helpers/functions";
import CheckButton from "core/components/checkButton/checkButton";

const WishlistPlpItem = (props) => {
  const [addToWishlist, removeFromWishlist, , , , , , , , ,] = useWishlist();

  let { list, checked } = props;
  const [checkedItem, setCheckedItem] = useState(checked);

  return (
    <MDBContainer className="choose-project-wrapper">
      <MDBRow className="border-bottom choose-project">
        <MDBCol
          size="2"
          className="d-flex align-items-center justify-content-center"
        >
          {(!isEmpty(list.items) && (
            <img
              src={list.items[0].product.image.url}
              alt="Product"
              className="img-fluid"
            />
          )) || <Icon icon="heartBig" />}
        </MDBCol>
        <MDBCol size="8" className="my-4">
          <MDBRow>
            <MDBCol size="12" className={`project-name`}>
              {(list.name === "Wish List" && "Your Favorites") || list.name}
            </MDBCol>
            <MDBCol size="12" className={`number-of-items`}>
              {list.items_count} Items
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          size="2"
          className="my-4 d-flex align-items-center justify-content-end check-wishlist-wrapper"
        >
          <CheckButton
            containerClass={
              "checkbox-container wishlist-plp check-button multiselect-how-did-u"
            }
            checked={checkedItem}
            wrapperClass={"checkmark"}
            onChange={() => {
              if (checkedItem) {
                let removeWishlistPlp = {
                  wishlist: list.name,
                  skus: [props.sku],
                };
                removeFromWishlist(removeWishlistPlp).then(() => {
                  setCheckedItem(!checked);
                  props.setIsFavorite(false);
                });
                // modalToggleSecond();
              } else {
                let addWishlistPlp = {
                  products: [{ sku: props.sku }],
                  wishlists: [{ name: list.name }],
                };
                addToWishlist(addWishlistPlp).then(
                  setCheckedItem(!checkedItem)
                );
                // modalToggleSecond();
              }
            }}
            type={"checkbox"}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default WishlistPlpItem;
