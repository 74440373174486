import React, { useEffect } from "react";
import Wishlist from "./wishlist";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";

const WishlistDataContainer = (props) => {
  const [, , fetchWishlistData, getWishlistItems] = useWishlist();
  let fetchArr = [...getWishlistItems()];
  fetchArr = fetchArr.sort((a, b) => {
    if (
      a.name.toLowerCase() === "wish list" ||
      b.name.toLowerCase() === "wish list"
    ) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    } else {
      return 1;
    }
  });
  useEffect(() => {
    fetchWishlistData();
  }, [props.customer, fetchWishlistData]);

  return <Wishlist {...props} fetchArr={fetchArr} />;
};

export default WishlistDataContainer;
