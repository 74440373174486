import React, { useState } from "react";
import { MDBContainer } from "mdbreact";
import BackGround from "./background.png";
import CmsBlock from "app/layout/cmsBlock";
import Button from "app/core/components/button/button";
import { Link } from "react-router-dom";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";
import ModalWrapper from "app/layout/login/modalWrapper";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const RegisterThankYou = () => {
  const { isLoggedIn } = useCustomer();
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeItem, setActiveItem] = useState("1");
  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };
  return (
    <MDBContainer className="register-thank-you-page-wrapper">
      <SeoHelmet title={"Register Thank You"} url={"/registration"} />
      <MDBContainer className="img-and-text-wrapper">
        <img src={BackGround} alt="" />
        <MDBContainer className="text-wrapper d-flex text-center justify-content-center">
          <CmsBlock dangHtml={true} id="registration_thank_you" />
          <MDBContainer className="d-flex justify-content-center buttons-wrapper mt-5">
            <Link to="/" className="portfolio-link">
              <Button>HOME PAGE</Button>
            </Link>
            <Link to="#">
              <Button
                onClick={() => {
                  toggleModal();
                }}
              >
                LOGIN
              </Button>
            </Link>
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
      <ModalWrapper
        toggleModal={toggleModal}
        modal={!isLoggedIn && modal}
        activeItem={activeItem}
        toggleTabs={toggleTabs}
      />
    </MDBContainer>
  );
};

export default RegisterThankYou;
