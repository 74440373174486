import React from "react";
import DynamicGraphQlWrapperQueryContainer from "app/overrides/dynamicGraphQlWrapper";
import queryLoader from "core/graphql/queryLoader";
import CmsPage from "./cms";

const query = queryLoader("cmsPage");

const CmsPageQueryContainer = (props) => {
  return (
    <>
      {props.isCached && (
        <CmsPage
          {...props}
          dangHtml={props.dangHtml}
          title={props.title}
          cssClass={props.scssClass}
          id={props.id}
          data={props.isCached}
          isCached={props.isCached}
        />
      )}
      {props.data && !props.isCached && (
        <CmsPage
          {...props}
          dangHtml={props.data.dangHtml}
          title={props.data.title}
          cssClass={props.data.scssClass}
          id={props.data.id}
          data={props.data}
          isCached={props.isCached}
        />
      )}
      {!props.data && !props.isCached && (
        <DynamicGraphQlWrapperQueryContainer
          query={query}
          variables={{ ...props.variables, id: props.id }}
          url={props.url}
          loader={true}
        >
          <CmsPage
            {...props}
            dangHtml={props.dangHtml}
            title={props.title}
            cssClass={props.scssClass}
            id={props.id}
            isCached={props.isCached}
          />
        </DynamicGraphQlWrapperQueryContainer>
      )}
    </>
  );
};

export default CmsPageQueryContainer;
