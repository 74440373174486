import React, { memo, useEffect } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import InputField from "core/components/inputField";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

const SearchInput = memo(
  ({ search, changeSearch, tmpSearch, setTmpSearch, setOpen }) => {
    useEffect(() => {
      document.getElementById("search-input").focus();
    }, []);
    let isMobile = useMediaQuery({ maxWidth: 650 });
    return (
      <MDBContainer id="search-input-wrapper">
        <MDBRow className="input-filed-container">
          <MDBCol className="input-filed-column">
            <MDBBox className={`d-flex align-items-center pb-3`}>
              {!isMobile ? (
                <Icon
                  icon={"searchBlack"}
                  className={`search-icon-input`}
                  onClick={(e) => {
                    if (search.length > 0) {
                      changeSearch("");
                      setTmpSearch("");
                    } else {
                      changeSearch(tmpSearch);
                    }
                  }}
                />
              ) : (
                <Icon
                  icon={"closeSearch"}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                  }}
                />
              )}

              <InputField
                id={`search-input`}
                wrapperClass={`w-100`}
                className={`p-0 m-0`}
                value={tmpSearch}
                placeholder={(!isMobile && "Enter keyword or SKU") || "Search"}
                onChange={(e) => {
                  setTmpSearch(e.target.value);
                }}
              />
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchInput;
