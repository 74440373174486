import { MDBBox, MDBTypography } from "mdbreact";
import React from "react";

const CatalogList = ({ catalogs, item, index, change, setChange }) => {
  return (
    <MDBBox className="catalog-list">
      <MDBBox
        className={`catalog-list-inner text-left  ${
          change === index ? "active" : ""
        } `}
        key={index}
        onClick={(e) => {
          setChange(index);
        }}
      >
        <>
          <img
            className="catalog-list-img"
            src={item.image}
            alt={`img-${item.title}`}
          />
          <MDBTypography tag="p">{item?.title}</MDBTypography>
        </>
      </MDBBox>
    </MDBBox>
  );
};

export default CatalogList;
