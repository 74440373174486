import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "react-apollo-hooks";
import { transform } from "core/components/cmsBlock/Parser";

import gql from "graphql-tag";
import ForgotPassword from "./forgotPassword";

export const ids = [
  "forgot_pass_title",
  "forgot_pass_text",
  "forgot_pass_button_text",
  "forgot_pass_image",
];

const FORGOT_PASSWORD = gql`
  query cmsBlock($ids: [String!]!) {
    cmsBlocks(identifiers: $ids) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

export const CmsDataFragment = ({ content }) =>
  ReactHtmlParser(content, { transform: transform });

const ForgotPasswordQueryContainer = (props) => {
  const { data, loading } = useQuery(FORGOT_PASSWORD, { variables: { ids } });
  if (loading) {
    return null;
  }
  return (
    <ForgotPassword data={data} contentMap={ids} {...props} loading={loading} />
  );
};

export default ForgotPasswordQueryContainer;
