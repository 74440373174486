import React from "react";
import { MDBTypography } from "mdbreact";

export const Msg = () => (
  <MDBTypography style={{ margin: "auto" }}>
    Item added to wishlist
  </MDBTypography>
);

export const Msg2 = () => (
  <MDBTypography style={{ margin: "auto" }}>
    Item added to quote request
  </MDBTypography>
);
