import React, { useState } from "react";
import {
  MDBMask,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBox,
} from "mdbreact";
import { Link } from "react-router-dom";

const CardComp = (props) => {
  const [isHome] = useState(props.isHome); // Passing page that called hotspot (PDP or HomePage)
  let containerClassName = "cardContainer";

  if (props.dot.top > 45 && props.dot.left > 55) {
    containerClassName = "cardContainerBottomRight";
  }
  if (props.dot.top < 45 && props.dot.left > 55) {
    containerClassName = "cardContainerRight";
  }
  if (props.dot.top > 45 && props.dot.left < 55) {
    containerClassName = "cardContainerBottom";
  }
  return (
    <>
      {isHome ? (
        <>
          <MDBMask
            style={{
              top: props.dot.top + "%",
              left: props.dot.left + "%",
            }}
            onClick={() => {
              props.showProductInfo(props.dot.sku, props.dot.isVisible);
            }}
            className={`hotSpot ${
              (props.dot.isVisible && "hotspot-active") || "hotspot-inactive"
            }`}
          >
            {props.dot.url ? (
              <Link
                to={"/" + props.dot.url}
                onClick={(e) => {
                  if (props.hideModal) {
                    props.hideModal();
                  }
                  if (typeof props.toggleModal !== "undefined") {
                    props.toggleModal();
                  }
                }}
              >
                {props.dot.isVisible && (
                  <>
                    <MDBCard
                      className={`${containerClassName} hotspot-card card-with-price d-flex flex-row align-items-center`}
                    >
                      {props.dot.display_type === "product" && (
                        <MDBCardImage
                          className="img-hotspotdetail card-img"
                          src={props.dot.image}
                          alt="hot spot picture"
                        />
                      )}
                      <MDBCardBody>
                        {props.dot.display_type === "product" && (
                          <>
                            <MDBCardTitle className="product-name">
                              {props.dot.name}
                            </MDBCardTitle>
                          </>
                        )}
                        {props.dot.display_type === "text" && (
                          <>{props.dot.addInfo}</>
                        )}
                      </MDBCardBody>
                      <MDBBox className="hotspot-card-triangle" />
                    </MDBCard>
                  </>
                )}
              </Link>
            ) : (
              <>
                {props.dot.isVisible && (
                  <>
                    <MDBCard
                      className={`${containerClassName} hotspot-card card-with-price d-flex flex-row align-items-center`}
                    >
                      {props.dot.display_type === "product" && (
                        <MDBCardImage
                          className="img-hotspotdetail"
                          src={props.dot.image}
                          alt="hot spot picture"
                        />
                      )}
                      <MDBCardBody>
                        {props.dot.display_type === "product" && (
                          <>
                            <MDBCardTitle className="product-name">
                              {props.dot.name}
                            </MDBCardTitle>
                          </>
                        )}
                        {props.dot.display_type === "text" && (
                          <>{props.dot.addInfo}</>
                        )}
                      </MDBCardBody>
                      <MDBBox className="hotspot-card-triangle" />
                    </MDBCard>
                  </>
                )}
              </>
            )}

            <MDBBox className={`hotspot-circle`}>
              {(props.dot.isVisible && "-") || "+"}
            </MDBBox>
          </MDBMask>
        </>
      ) : (
        <MDBMask className="detailContainer">
          <MDBCardText>{props.dot.content}</MDBCardText>
        </MDBMask>
      )}
    </>
  );
};
export default CardComp;
