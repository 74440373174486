import React, { useState } from "react";
import Slider from "react-slick";
import { MDBModal } from "mdbreact";
import PdpCarousel from "../carousel";
import config from "config/themeConfiguration";
import "./style.scss";
import { sliderSettings } from "./sliderSettings";

const ProductsSlider = ({ images }) => {
  const [modal, setModal] = useState(false);
  const [activeImageId, setActiveImageId] = useState(0);

  const handleImageClicked = (url, id) => {
    setModal(!modal);
    setActiveImageId(id);
  };

  return (
    <div className="pdp-small-slider-container">
      <Slider {...sliderSettings} className="slider">
        {images?.map(
          (slide, id) =>
            slide.file !== null && (
              <img
                className="img-fluid slide-image cursor-pointer"
                src={
                  config.magento_url +
                  config.media_url +
                  config.catalog_url +
                  slide?.file
                }
                alt={slide?.id || ""}
                onClick={() => handleImageClicked(slide?.url, id)}
              />
            )
        )}
      </Slider>
      <MDBModal
        className="pdp-modal"
        modalClassName="pdp-modal"
        backdropClassName="pdp-modal-backdrop"
        centered
        size="lg"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <PdpCarousel images={images} activeImageId={activeImageId} />
      </MDBModal>
    </div>
  );
};

export default ProductsSlider;
