import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryLoader from "app/graphql/queryLoader";

export default async (store, action) => {
  let cart_id = store.getState().customer.data.cart.id;

  try {
    const { data } = await apolloClient.query({
      query: queryLoader("setPaymentMethod"),
      variables: {
        input: {
          cart_id,
          payment_method: action.payment_method,
        },
      },
      fetchPolicy: "no-cache",
    });
    store.dispatch(
      customerActions.setCartInformation(data.setPaymentMethodOnCart.cart)
    );
    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
