import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBTypography, MDBContainer } from "mdbreact";
import {
  ExploreSectionImage,
  ExploreSectionFindRepText,
  ExploreSectionFindRepLink,
  ExploreSectionFindRepImage,
  ExploreSectionText,
  ExploreSectionLink,
  ExploreSectionCatalogText,
  ExploreSectionCatalogLink,
  ExploreSectionCatalogImage,
} from "./explore-section-query-container";

const ExploreSection = () => {
  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol lg="6" className="big-part p-0">
          <ExploreSectionImage />
          <MDBBox className="d-flex align-items-center justify-content-center text-center">
            <MDBTypography tag="h5" variant="h5">
              <ExploreSectionText />
            </MDBTypography>
            <ExploreSectionLink />
          </MDBBox>
        </MDBCol>
        <MDBCol lg="6" className="d-flex smaller-parts">
          <MDBRow className="mobile-change-side">
            <MDBCol size="6" className="d-flex justify-content-center blue-box">
              <MDBTypography tag="h5" variant="h5">
                <ExploreSectionFindRepText />
              </MDBTypography>
              <ExploreSectionFindRepLink />
            </MDBCol>
            <MDBCol size="6" className="find-rep-image">
              <ExploreSectionFindRepImage />
            </MDBCol>
          </MDBRow>
          <MDBRow className="catalog-part">
            <MDBCol md="6">
              <ExploreSectionCatalogImage />
            </MDBCol>
            <MDBCol md="6" className="d-flex justify-content-center">
              <MDBTypography tag="h6" variant="h6">
                <ExploreSectionCatalogText />
              </MDBTypography>
              <MDBBox className={`catalog-link`}>
                <ExploreSectionCatalogLink />
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ExploreSection;
