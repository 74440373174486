import React, { useState } from "react";
import { MDBCol, MDBTypography, MDBBox } from "mdbreact";
import "./style.scss";
import Form from "./form";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { useForm } from "react-hook-form";

const ContactForm = ({
  handleSubmit,
  name,
  email,
  company,
  phone,
  message,
  onNameChangeHandler,
  onEmailChangeHandler,
  onCompanyChangeHandler,
  onPhoneChangeHandler,
  onMessageChangeHandler,
  hasNameError,
  hasEmailError,
  nameErrorMsg,
  emailErrorMsg,
  submit,
  loading,
  successMessage,
  cleanUpForm,
  formId
}) => {

  const {
    getCountryById,
    getTelephoneMaskByCountry
  } = useCustomer();

  const selectedCountry = getCountryById("US");

  const defaultRegions = selectedCountry.available_regions.map((region) => ({
    value: region.id,
    label: region.name,
    code: region.code,
    id: region.id,
    name: region.name,
  }));

  const [country, setCountry] = useState({
    label: selectedCountry.full_name_english,
    value: selectedCountry.id,
    available_regions: [...defaultRegions],
  });

  let telephoneMask = getTelephoneMaskByCountry(country.value);

  const { control } = useForm({
    mode: "onSubmit",
    // reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      confirm_password: "",
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
      address: "",
      country: "",
      city: "",
      state: "",
      postalCode: "",
      isSubscribed: false,
      termsConditions: false,
      businessType: "",
      nonUsState: "",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  return (
    <MDBBox tag="div" className={`rep-contact-form`}>
      <MDBCol>
        <MDBTypography tag="h5" variant="h5">
          Contact Us
        </MDBTypography>
      </MDBCol>
      <Form
        name={name}
        email={email}
        company={company}
        phone={phone}
        message={message}
        onNameChangeHandler={onNameChangeHandler}
        onEmailChangeHandler={onEmailChangeHandler}
        onCompanyChangeHandler={onCompanyChangeHandler}
        onPhoneChangeHandler={onPhoneChangeHandler}
        onMessageChangeHandler={onMessageChangeHandler}
        hasEmailError={hasEmailError}
        hasNameError={hasNameError}
        emailErrorMsg={emailErrorMsg}
        nameErrorMsg={nameErrorMsg}
        submit={submit}
        loading={loading}
        successMessage={successMessage}
        cleanUpForm={cleanUpForm}
        telephoneMask={telephoneMask}
        control={control}
        formId={formId}
      />
    </MDBBox>
  );
};

export default ContactForm;
