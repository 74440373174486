import React, { useState } from "react";
// import ReactHtmlParser from "react-html-parser";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBox,
  MDBTypography,
} from "mdbreact";
import Icon from "app/assets/icon/icon";
import ReactHtmlParser from "react-html-parser";

const VideoTutorial = (props) => {
  let videoUrl = props?.data?.cmsBlocks?.items[0]?.content;
  let finaVideolUrl = ReactHtmlParser(videoUrl)[0]?.props?.children;
  const [toggleModal, setToggleModal] = useState(false);
  if (typeof props.data === "undefined") return <></>;

  return (
    <MDBContainer className="video-tutorial-wrapper">
      <MDBBox
        className="play-tutorial"
        onClick={() => setToggleModal(!toggleModal)}
      >
        <Icon icon={"playIcon"} className="play-icon" />
        <span>PLAY VIDEO DEMO</span>
      </MDBBox>
      <MDBModal
        isOpen={toggleModal}
        toggle={() => setToggleModal(!toggleModal)}
        centered
      >
        <MDBModalHeader>
          <MDBTypography tag="p" variant="p">
            Learn how it works
          </MDBTypography>
          <MDBTypography tag="h4" variant="h4" className="h4">
            Create your custom product
          </MDBTypography>
        </MDBModalHeader>

        <MDBModalBody>
          <div className="container">
            <div className="video">
              <video
                loop
                controls={true}
                autoPlay={true}
                type="video/mp4"
                playsInline
                muted
                src={finaVideolUrl}
              ></video>
            </div>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default VideoTutorial;
