import React, { useState } from "react";
import { MDBContainer, ToastContainer, toast } from "mdbreact";
import { useMutation } from "react-apollo-hooks";
import { RESET_PASSWORD } from "./resetPasswordMutation";
import ResetPasswordImage from "app/assets/images/reset-password.png";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Form from "./form";
import { useForm } from "react-hook-form";

const ResetPasswordPage = () => {
  const { register, handleSubmit, errors, getValues, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      newPassword: "",
      password: "",
      confirm_password: "",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  let currentUrl = window.location.href;
  const getToken = (str) => {
    return str.split("=")[1];
  };
  let token = getToken(currentUrl);

  const [resetPassword, { data, error, loading }] = useMutation(RESET_PASSWORD);

  if (shouldRedirect)
    return <Redirect to={{ pathname: "/", state: { from: location } }} />;

  return (
    <>
      <MDBContainer
        className="reset-password-page"
        style={{ marginTop: "20rem" }}
      >
        <img
          src={ResetPasswordImage}
          alt=""
          style={{ maxWidth: "100%", height: "60rem" }}
        />

        <MDBContainer className="reset-password-page-form-wrapper">
          <form
          id="PL-forgot-password-form"
            onSubmit={handleSubmit(async (formData) => {
              try {
                await resetPassword({
                  variables: {
                    email: formData.email,
                    resetPasswordToken: token,
                    newPassword: formData.newPassword,
                    input: {
                      password: formData.newPassword,
                      password_confirm: formData.newPassword,
                      token: token,
                    },
                  },
                });

                toast(
                  <h4 style={{ margin: "auto" }}>
                    You successfully updated Account Information
                  </h4>
                );
                setTimeout(() => setShouldRedirect(true), 3000);
              } catch (error) {
                console.log("!error ", error);
                setShouldRedirect(false);
              }
            })}
          >
            <Form
              register={register}
              errors={errors}
              getValues={getValues}
              loading={loading}
            />
          </form>
        </MDBContainer>
        <ToastContainer autoClose={5000} hideProgressBar />
      </MDBContainer>
    </>
  );
};

export default ResetPasswordPage;
