import { MDBBtn, MDBInput } from "mdbreact";
import React from "react";

const Form = ({ errors, register, getValues, loading }) => {
  return (
    <>
      <h1>Reset your Password</h1>
      <p>Please enter your new password </p>
      <MDBInput
        name="email"
        type="email"
        inputRef={register({
          required: true,
          pattern: /^\S+@\S+$/i,
        })}
        className={`mb-5 account-input ${
          errors.email && `account-input-error`
        }`}
        placeholder="Enter Email *"
      />
      {errors.email && errors.email.type === "required" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
            marginTop: "-0.2rem",
          }}
        >
          This field is required
        </p>
      )}
      <MDBInput
        name="newPassword"
        type="password"
        inputRef={register({
          required: true,
          min: 8,
          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
        })}
        className={`mb-5 account-input ${
          errors.newPassword && `account-input-error`
        }`}
        placeholder="Enter New Password *"
      />
      <p style={{fontSize: '1.2rem'}}>Password must contain at least 8 characters, including
      upper/lowercase, digits and/or special characters</p>
      {errors.newPassword && errors.newPassword.type === "required" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
            marginTop: "-0.2rem",
          }}
        >
          This field is required
        </p>
      )}
      {errors.newPassword && errors.newPassword.type === "pattern" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
            marginTop: "-0.2rem",
          }}
        >
          Password must contain at least 8 characters, including
          upper/lowercase, digits and/or special characters
        </p>
      )}
      <MDBInput
        type="password"
        name="confirm_password"
        inputRef={register({
          validate: (value) =>
            value === getValues("newPassword") || "The passwords do not match",
          required: true,
          min: 8,
          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
        })}
        className={`mb-5 account-input ${
          errors.confirm_password && `account-input-error`
        }`}
        placeholder="Confirm New Password *"
      />
      {errors.confirm_password && errors.confirm_password.type === "required" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
          }}
        >
          This field is required
        </p>
      )}
      {errors.confirm_password && errors.confirm_password.type === "validate" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
          }}
        >
          {errors.confirm_password && errors.confirm_password.message}
        </p>
      )}
      {errors.confirm_password && errors.confirm_password.type === "pattern" && (
        <p
          style={{
            color: "red",
            fontSize: "1.2rem",
            fontWeight: "300",
            marginTop: "-0.2rem",
          }}
        >
          Password must contain at least 8 characters, including
          upper/lowercase, digits and/or special characters
        </p>
      )}
      <MDBBtn type="submit" className="mt-5 save-button" disabled={loading}>
        {loading ? "Saving.." : "Save "}
      </MDBBtn>
    </>
  );
};

export default Form;
