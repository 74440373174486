import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Alert = (props) => {
  return (
    <div className="session-messages-inner">
      <div className={"session " + props.variant}>
        {props.variant === "success"
          ? props.successIcon || <FontAwesomeIcon icon="check-circle" />
          : props.errorIcon || <FontAwesomeIcon icon="times-circle" />}
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default Alert;
