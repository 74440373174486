import filtersMiddleware from "./data/filters/middleware";
import customerMiddleware from "./data/customer/middleware";
import cacheMiddleware from "./data/cache/middleware";
import queriesMiddleware from "./data/queries/middleware";
// additional middleware that will work on top of existing ones are defined here
let projectMiddleware = [];

// overwriting middleware, object index is mandatory, since filtering will not work if the middleware are not the same
export const overwritteMapper = {
  0: customerMiddleware,
  2: queriesMiddleware,
  3: filtersMiddleware,
  4: cacheMiddleware,
};

export default projectMiddleware;
