import React from "react";
import {MDBCol, MDBRow} from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";

export const NoResult = ({handleClearFilter}) => {
    return (
        <MDBCol style={{width:"100%"}} className="mt5 pt-5 no-results-page" size="12">
            <MDBRow>
                <h2 className="">No Results</h2>
            </MDBRow>
            <MDBRow className="pt-3 pb-5">
                <MDBCol className={"col-md-6 ml-0 pl-0"}>

                        <CmsBlock id={"filtering_no_results"} />

                </MDBCol>

            </MDBRow>
            <MDBRow>
                <p onClick={() => {handleClearFilter()}}
                    style={{fontFamily:"Montserrat-Regular, Montserrat", fontSize:"16px", lineHeight:"19.5px", textDecoration:"underline", cursor:"pointer"}}>Reset Filter</p>
            </MDBRow>
        </MDBCol>

    )
}