import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Price from "core/components/price/price";
import FooterPdf from "./footer";
import Header from "./header";
import { chunkArray, getPricing, getComment } from "./functions";
import { useSelector } from "react-redux";
import {hidden} from "colorette";
import {fixed} from "lodash/fp/_falseOptions";
import Logo from "../../logo";
import PrimaLogoMobile from "../../../assets/icon/primaLogoPdf.svg";
import PrimaLogo from "../../../assets/images/logo-black.png";

const QuotePdfDisplay = ({ items, currentSkuAndComments }) => {
  const customerGroupId = useSelector(
    (state) => state.customer.data.customer_group
  );
  let canSeePricing;
  if (customerGroupId === 4) {
    canSeePricing = true;
  } else {
    canSeePricing = false;
  }
  let pages = chunkArray(items, 2);

  let hidePrice = false;

  return (


    <div>

      {pages.map((page, key) => {
        return (



           <div  key={key}  style={{ height: "100%",  width: "88%", fontSize: "25em", top:"0",  position: "relative", pageBreakAfter: "always"}} >
               <MDBRow className="m-0 p-0" style={{position: "absolute", top: "1cm", left: "1cm", height: "3cm"}}>
                 <MDBCol>
                   <img src={PrimaLogo} alt=""/>
                 </MDBCol>
               </MDBRow>

              <MDBRow className="" style={{  width: "100%", height: "293.4mm", display:"inline-block", margin:"1cm"}} >


                {page.map((quoteItem, i) => {

                  let product = quoteItem?.product?.sku;
                  const prices = getPricing(quoteItem?.parsedOptions);
                  const comment = getComment(currentSkuAndComments, product);
                  const colorPicker = quoteItem?.parsedOptions;
                  const isPairNext =
                    items[i + 1]?.id === quoteItem?.pairId || false;

                  return (
                    <div className="ml-0 mr-0">
                      <MDBRow
                        className="pb-3 pl-0 pr-0 ml-0 mr-0"
                        style={{
                          marginTop:"5rem",
                          maxWidth: "100%",

                        }}
                      >
                        <MDBCol  className="pl-0 pr-0 mt-5" style={{position:"relative", overflow:"hidden",  width: "9cm", height: "9cm"}}>
                          <img
                            src={quoteItem?.product?.image?.url}
                            alt={quoteItem?.product?.name + ""}
                            style={{
                              marginTop:"2.3rem",
                             width: "100%",
                              height:"100%",
                              objectFit:"contain"

                            }}
                          />
                        </MDBCol>
                        <MDBCol   className="pl-0 pr-0 ml-0 mr-0">

                          <MDBRow >
                            <MDBCol>
                              <p
                                style={{
                                  fontSize: "2.5rem",
                                  fontWeight: "500",
                                  fontFamily: "Montserrat",
                                  color: "rgb(73,73,39)",
                                  lineHeight:"4.2rem"
                                }}
                              >
                                SKU {quoteItem?.product?.sku}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow
                            className="options d-flex  pr-0"
                            style={{
                              width: "100%",
                              paddingTop: "2rem",
                              paddingBottom: "2rem",
                              borderBottom: `${
                                comment.length === 0 ? "1px solid #979797" : ""
                              }`,
                            }}
                          >

                            <MDBCol >
                              {quoteItem?.parsedOptions?.map((parsedOption) => {
                                if (
                                  !parsedOption?.question ||
                                  !parsedOption?.name
                                ) {
                                  return null;
                                }
                                return (
                                  <MDBRow >
                                    <MDBCol
                                      className="flex-shrink-1 flex-grow-0"
                                      style={{
                                        whiteSpace: "nowrap",
                                        fontWeight: "600",
                                        fontSize: "2rem",
                                        fontFamily: "Montserrat",
                                        lineHeight:"4.2rem",
                                        color:"#1F1E3F"
                                      }}
                                    >
                                      {parsedOption.question}
                                    </MDBCol>
                                    <MDBCol
                                      style={{
                                        whiteSpace: "nowrap",
                                        fontWeight: "500",
                                        fontSize: "2rem",
                                        fontFamily: "Montserrat",
                                        color:'#1F1E3F'
                                      }}
                                    >
                                      {parsedOption?.name?.length > 16
                                        ? parsedOption?.name?.substring(0, 20) +
                                          "..."
                                        : parsedOption?.name}
                                    </MDBCol>
                                  </MDBRow>
                                );
                              })}
                              {colorPicker.map((findColorpicker, i) => {
                                if (
                                  findColorpicker?.colorPicker &&
                                  findColorpicker?.colorPicker !== undefined
                                ) {
                                  return (
                                    <MDBRow>
                                      <MDBCol
                                        style={{
                                          whiteSpace: "nowrap",
                                          fontWeight: "600",
                                          fontSize: "2rem",
                                          fontFamily: "Montserrat",
                                          color:"#1F1E3F"
                                        }}
                                      >
                                        Selected Color
                                      </MDBCol>
                                      <MDBCol
                                        style={{
                                          whiteSpace: "nowrap",
                                          fontWeight: "500",
                                          fontSize: "2rem",
                                          fontFamily: "Montserrat",
                                        }}
                                      >
                                        <div style={{
                                          width:"15px",
                                          display: "inline-block",
                                          height:"15px",
                                          borderRadius:"50%",
                                          background: findColorpicker?.colorPicker instanceof Array ? findColorpicker?.colorPicker["1"] : findColorpicker?.colorPicker
                                        }}> </div>
                                        {findColorpicker?.colorPicker instanceof Array ? findColorpicker?.colorPicker["0"] : findColorpicker?.colorPicker}
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                }
                              })}
                              {quoteItem?.product?.ark_custitem_prima_sales_desc && (
                                  <MDBRow style={{
                                    marginLeft:"0px",
                                    paddingLeft: "0px",
                                    whiteSpace: "nowrap",
                                    fontWeight: "400",
                                    fontSize: "2rem",
                                    fontFamily: "Montserrat",
                                    lineHeight:"4.2rem",
                                    paddingTop:"2rem",
                                    paddingBottom:"9rem",
                                    color: "#1F1E3F",

                                  }} >
                                  <MDBCol className="ml-0 pl-0">
                                    <p style={{width:"100%", fontWeight:600,fontSize: "2rem", color:"#1F1E3F" }}>DESCRIPTION</p>
                                    <span style={{whiteSpace:"initial"}}>{quoteItem?.product?.ark_custitem_prima_sales_desc}</span>
                                  </MDBCol>


                                  </MDBRow>
                              )}
                            </MDBCol>
                          </MDBRow>

                          {comment.length !== 0 && (
                            <MDBRow className="py-3" style={{paddingTop:"2rem"}}>
                              <MDBCol className="ml-4" >
                                {/*<MDBRow>*/}
                                  <MDBRow
                                   // size="2"
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontWeight: "600",
                                      fontSize: "2rem",
                                      fontFamily: "Montserrat",
                                      color: "#1F1E3F"
                                    }}
                                  >
                                    <div   style={{display:"inline-block", marginLeft:"0"}}>
                                      COMMENT
                                    </div>
                                    <div
                                        style={{
                                          width:"69%",
                                          maxWidth:"80%",
                                          marginTop: "1.3rem",
                                          marginLeft:"3rem",
                                          borderTop: "1px solid #979797",
                                          display: "inline-block"
                                        }}
                                    ></div>
                                  </MDBRow>



                                  <MDBRow
                                      size="12"
                                      style={{
                                        paddingTop:"1em",
                                        paddingBottom:"1em",
                                        fontWeight: "400",
                                        fontSize: "1.8rem",
                                        color: "#1F1E3F",
                                        fontFamily: "Montserrat",
                                      }}
                                  >
                                    {comment}
                                  </MDBRow>
                                  <MDBRow style={{width:"100%"}}>
                                    <div
                                        style={{
                                          width:"100%",
                                          marginTop: "1.3rem",
                                          border: "1px solid #979797",
                                        }}
                                    ></div>
                                  </MDBRow>
                                {/*</MDBRow>*/}
                              </MDBCol>

                            </MDBRow>
                          )}
                          {canSeePricing && (

                              <div className="py-0 px-0">
                                {quoteItem?.product?.ark_custitem_prima_sales_desc && quoteItem?.product?.ark_custitem_prima_sales_desc !== null && (
                                    <MDBRow  className=" ml-0 pl-0">
                                      <MDBCol className="ml-0 pl-0 pt-5" style={{
                                        whiteSpace: "nowrap",
                                        fontWeight: "500",
                                        fontSize: "2rem",
                                        fontFamily: "Montserrat",
                                      }} >
                                            <span style={{
                                              whiteSpace: "nowrap",
                                              fontWeight: "600",
                                              fontSize: "2rem",
                                              fontFamily: "Montserrat",
                                              //marginLeft: "2rem",
                                              color:"#49494F"
                                            }}
                                            >SKU {quoteItem?.product?.sku} </span>

                                      </MDBCol>
                                      <MDBCol className={ "d-flex align-items-center justify-content-end pt-5" }
                                              style={{
                                                whiteSpace: "nowrap",
                                                fontWeight: "600",
                                                fontSize: "2rem",
                                                fontFamily: "Montserrat",
                                                marginRight: "1rem",
                                                color:"#49494F"
                                              }}
                                      >
                                        {/*{quoteItem?.product?.ark_closeout_price !== null && quoteItem?.product?.ark_closeout && quoteItem?.product?.ark_closeout === 1 ?*/}
                                        {/*    (*/}

                                        {/*        <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">*/}
                                        {/*          <span style={{textAlign:"right", fontSize:"1.6rem", textDecorationLine: "line-through", padding: "0 10px"}}>{quoteItem?.product?.price_range?.minimum_price?.regular_price?.value}$</span>*/}
                                        {/*          <Price price={"$"+parseFloat(quoteItem?.product?.ark_closeout_price).toFixed(2) } type="fixed" />*/}
                                        {/*        </MDBCol>*/}


                                        {/*    ):*/}
                                        {/*    (*/}
                                        {/*        <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">*/}
                                        {/*          <Price price={"$"+quoteItem?.product?.price_range?.minimum_price?.regular_price?.value} type="fixed" />*/}
                                        {/*        </MDBCol>*/}
                                        {/*    )}*/}
                                        { quoteItem?.product?.ark_closeout_price !== null && quoteItem?.product?.ark_closeout && quoteItem?.product?.ark_closeout === 1 &&
                                            (

                                                <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                                  <p style={{textAlign:"right", fontSize:"1.7rem", fontWeight:600, color:"#212529"}}><span style={{textAlign:"right", fontSize:"1.4rem", textDecorationLine: "line-through"}}>${quoteItem?.product?.price_range?.minimum_price?.regular_price?.value}</span> ${ parseFloat(quoteItem?.product?.ark_closeout_price).toFixed(2) }</p>
                                                </MDBCol>
                                            )}
                                        {quoteItem?.product?.price_tiers && Object.keys(quoteItem?.product?.price_tiers).length === 0 &&   (
                                            <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                              <Price price={quoteItem?.product?.price_range?.minimum_price?.regular_price?.value} type="fixed" />
                                            </MDBCol>
                                        )}
                                        {quoteItem?.product?.price_tiers && Object.keys(quoteItem?.product?.price_tiers).length !== 0 && quoteItem?.product?.ark_closeout_price === null && !quoteItem?.product?.ark_closeout && quoteItem?.product?.ark_closeout !== 1 &&  (
                                            <MDBCol className="d-flex align-items-center justify-content-end px-1 mx-2 price-quote-request">
                                              {quoteItem?.product?.price_tiers && quoteItem?.product?.price_tiers?.map((tiers, index) => {

                                                let mqty = quoteItem?.product?.price_tiers[index-1]?.quantity ===  undefined ? 1 : quoteItem?.product?.price_tiers[index-1]?.quantity
                                                let mAXqty = quoteItem?.product?.price_tiers[index+1]?.quantity ===  undefined ? quoteItem?.product?.price_tiers[index].quantity : quoteItem?.product?.price_tiers[index+1]?.quantity
                                                let current =  quoteItem?.product?.price_tiers[index]?.quantity;

                                                return (
                                                    <>{((mqty <= quoteItem.quantity && quoteItem.quantity < quoteItem?.product?.price_tiers[index].quantity)  )&& (

                                                        <Price price={quoteItem?.product?.price_tiers[index-1].final_price?.value} type="fixed" />
                                                    )}
                                                      { quoteItem.quantity >= mAXqty && quoteItem?.product?.price_tiers[index+1]?.quantity ===  undefined && (

                                                          <Price price={quoteItem?.product?.price_tiers[index].final_price?.value} type="fixed" />
                                                      )}
                                                    </>
                                                )


                                              })}
                                            </MDBCol>
                                        )}

                                      </MDBCol>
                                    </MDBRow>
                                )}
                                {prices.map((pricingItem) => {
                                  if (!pricingItem?.price) {
                                    return null;
                                  }

                                  let titles = Object.keys(pricingItem);

                                  if (quoteItem.product.sku) {
                                    if (
                                      quoteItem.product.sku.includes("M3") ||
                                      quoteItem.product.sku.includes("M4") ||
                                      quoteItem.product.sku.includes("M5")
                                    ) {
                                      hidePrice = true;
                                    }
                                  }

                                  return (
                                    <MDBRow className="pricing pt-5" >
                                      <MDBCol>
                                        <MDBRow className="py-1">
                                            <MDBCol  style={{
                                                whiteSpace: "nowrap",
                                                fontWeight: "500",
                                                fontSize: "2rem",
                                                fontFamily: "Montserrat",
                                                color:"#49494F"
                                              }} >
                                              <span style={{
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "600",
                                                  fontSize: "2rem",
                                                  fontFamily: "Montserrat",
                                                  marginRight: "1rem",
                                                  color:"#49494F"
                                                }}
                                              >SKU </span>

                                              {pricingItem[titles[0]]}
                                            </MDBCol>
                                            <MDBCol className={ "d-flex align-items-center justify-content-end" }
                                                    style={{whiteSpace: "nowrap", fontWeight: "600", fontSize: "2rem", fontFamily: "Montserrat", marginRight: "1rem", color:"#49494F"}} >
                                                {!hidePrice && (
                                                  <Price
                                                    price={
                                                      titles[0].toLowerCase() === "sku"
                                                        ? pricingItem.price / 2
                                                        : pricingItem.price
                                                    }
                                                    type="fixed"
                                                  />
                                                )}
                                            </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                })}

                          </div>

                          )}
                        </MDBCol>
                      </MDBRow>
                      {(i === 0) && (
                        <MDBRow
                          style={{
                            border: "1px solid  #979797",
                            marginTop:"3rem",
                            marginBottom:"3rem"
                          }}
                        ></MDBRow>
                      )}
                    </div>
                  );
                })}

              </MDBRow>
              {/*<FooterPdf  className="pb-0 mb-5" key={key} /> was here earlier*/}
             <MDBRow  className="footerForPdf" style={{ position: "absolute",
               bottom:"1cm",  left: "2cm", right: "0", width: "100%", borderTop:"1px solid"}} >
               <div style={{display: "inline-block", fontSize: "10pt", marginTop: "5px", width: "100%" }}>
                 <Logo  style={{ display: "inline-block"}} mobileLogo={true} />
                 <div style={{ display: "inline-block", verticalAlign:"top", marginTop:"10px",  justifyItems:"center"}}>
                   <a style={{fontSize: "10pt"}} rel="noopener noreferrer" target="_blank"
                      href="tel:479-474-0876"
                      className="text-decoration-none">
                     <span style={{fontSize: "10pt"}} className="font-weight-bold">t.</span> 479-474-0876
                   </a>
                   <a rel="noopener noreferrer" target="_blank" href="mailto:contact@primalighting.com"
                      style={{padding: "0 0", fontSize: "10pt", lineHeight: "16pt"}}
                      className="text-decoration-none">
                     <span className="font-weight-bold">e.</span>contact@primalighting.com
                   </a>
                   <a style={{fontSize: "10pt"}}>business hours 6am - 5pm CT Monday -
                     Thursday</a>
                 </div>
               </div>

             </MDBRow>

            </div>


        );
      })}

    </div>
  );
};

export default QuotePdfDisplay;
