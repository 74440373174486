import React, { useEffect } from "react";
import Layout from "app/layout";
import apolloClient from "app/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider as ReduxProvider } from "react-redux";
import {Route, Router, useLocation} from "react-router-dom";
import Cache from "core/components/cache";
import store from "core/state/redux/store";
import history from "core/state/redux/history";
import "app/main.scss";
import ScrollToTop from "react-router-scroll-top";

import { VereniaProvider } from "app/state/context/verenia";
import DynamicRouter from "./app/overrides/router/dynamic-content-router";
import {createBrowserHistory} from "history";



const App = () => {

  return (
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <VereniaProvider client={apolloClient}>
          <ReduxProvider store={store}>
            <Cache>
              <Router history={history}>
                <ScrollToTop>
                  <Layout />
                </ScrollToTop>
              </Router>
            </Cache>
          </ReduxProvider>
        </VereniaProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;
