import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import FindRepDataContainer from "./find-a-rep-data-container";

const REP_LOCATORS = gql`
  query getAllRepLocators {
    getAllRepLocators {
      company
      address
      fax
      firstname
      lastname
      email
      country
      state
      phone
      image
      website
      city
      postcode
    }
  }
`;

const FindRepQueryContainer = (props) => {
  const { data, loading, error } = useQuery(REP_LOCATORS, {
    variables: {},
    fetchPolicy: "cache-first",
  });
  if (error) return <h1>something is not working</h1>;
  if (loading) {
    return (
      <div className="data-loader-container loading">
        <p>Loading..</p>
      </div>
    );
  }

  return <FindRepDataContainer data={data} />;
};

export default FindRepQueryContainer;
