import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import DashboardNav from "../dashboardNav";
import "../style.scss";
import EditAddressBookContent from "./editAddressBookContent";

const EditAddressBook = ({ location }) => {
  const [activeItem, setActiveItem] = useState(2);
  const onClickHandler = () => setActiveItem(2);

  let address = undefined;

  //get address id from url so i can pass correct address to edit component
  let currentAddressId = window?.location?.pathname?.split("/")[4];
  if (location.state) {
    let currentAddress = location?.state?.address.find((x) => {
      return x.id === parseInt(currentAddressId);
    });

    address = currentAddress;
  }

  return (
    <MDBContainer
      id={`account-dashboard-container`}
      fluid
      className="m-0 dashboard-container"
    >
      <MDBRow className="m-0 p-0 justify-content-between align-items-stretch">
        <MDBCol
          xl="3"
          lg="3"
          md="4"
          sm="12"
          xs="12"
          className="m-0 p-0 main-navbar-account"
        >
          <DashboardNav
            activeItem={activeItem}
            onClickHandler={onClickHandler}
          />
        </MDBCol>
        {address && (
          <MDBCol xl="9" lg="9" md="8" sm="12" xs="12" className="m-0 p-0">
            <EditAddressBookContent address={address} />
          </MDBCol>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default EditAddressBook;
