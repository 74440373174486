import React from "react";
import { Link } from "react-router-dom";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const DefaultBilling = ({ customer }) => {
  const { getCountryById } = useCustomer();
  let address = customer?.addresses?.filter((item) => {
    return item.default_billing === true;
  })[0];
  let defaultBillingAddress = customer?.addresses?.filter((address) => {
    return address.default_billing === true;
  });
  let defaultBillingAddressId = defaultBillingAddress[0]?.id;
  let region = address?.region?.region === null ? "" : address?.region?.region;
  const country = getCountryById(address?.country_code);
  return (
    <>
      {typeof address === "undefined" ? (
        <p className="mb-5 mt-2">You have no default billing address.</p>
      ) : (
        <div>
          <p className="mb-1">{address?.firstname + " " + address?.lastname}</p>
          <p className="mb-1">{address?.address_email}</p>
          <p>{address?.company}</p>
          {address?.street?.map((s) => (
            <p>{s}</p>
          ))}
          <p className="mb-1">
            {address?.city +
              ", " +
              region +
              `${region.length !== 0 ? ", " : ""}` +
              address?.postcode}
          </p>
          <p className="mb-1">{country?.full_name_english}</p>
          <p className="mb-1">
            <b>t:</b> {address?.telephone}
          </p>
          <p className="mb-5 mt-5">
            <Link
              className="p-0 text-uppercase"
              to={{
                pathname: `/customer/address-book/edit/${defaultBillingAddressId}`,
                state: { address: defaultBillingAddress },
              }}
            >
              <b>
                <u>Change billing address</u>
              </b>
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default DefaultBilling;
