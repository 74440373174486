export default {
  countries: [
    {
      id: "US",
      available_regions: [
        {
          id: 1,
          name: "Alabama",
          code: "AL",
          __typename: "Region",
        },
        {
          id: 2,
          name: "Alaska",
          code: "AK",
          __typename: "Region",
        },
        {
          id: 3,
          name: "American Samoa",
          code: "AS",
          __typename: "Region",
        },
        {
          id: 4,
          name: "Arizona",
          code: "AZ",
          __typename: "Region",
        },
        {
          id: 5,
          name: "Arkansas",
          code: "AR",
          __typename: "Region",
        },
        {
          id: 6,
          name: "Armed Forces Africa",
          code: "AE",
          __typename: "Region",
        },
        {
          id: 7,
          name: "Armed Forces Americas",
          code: "AA",
          __typename: "Region",
        },
        {
          id: 8,
          name: "Armed Forces Canada",
          code: "AE",
          __typename: "Region",
        },
        {
          id: 9,
          name: "Armed Forces Europe",
          code: "AE",
          __typename: "Region",
        },
        {
          id: 10,
          name: "Armed Forces Middle East",
          code: "AE",
          __typename: "Region",
        },
        {
          id: 11,
          name: "Armed Forces Pacific",
          code: "AP",
          __typename: "Region",
        },
        {
          id: 12,
          name: "California",
          code: "CA",
          __typename: "Region",
        },
        {
          id: 13,
          name: "Colorado",
          code: "CO",
          __typename: "Region",
        },
        {
          id: 14,
          name: "Connecticut",
          code: "CT",
          __typename: "Region",
        },
        {
          id: 15,
          name: "Delaware",
          code: "DE",
          __typename: "Region",
        },
        {
          id: 16,
          name: "District of Columbia",
          code: "DC",
          __typename: "Region",
        },
        {
          id: 17,
          name: "Federated States Of Micronesia",
          code: "FM",
          __typename: "Region",
        },
        {
          id: 18,
          name: "Florida",
          code: "FL",
          __typename: "Region",
        },
        {
          id: 19,
          name: "Georgia",
          code: "GA",
          __typename: "Region",
        },
        {
          id: 20,
          name: "Guam",
          code: "GU",
          __typename: "Region",
        },
        {
          id: 21,
          name: "Hawaii",
          code: "HI",
          __typename: "Region",
        },
        {
          id: 22,
          name: "Idaho",
          code: "ID",
          __typename: "Region",
        },
        {
          id: 23,
          name: "Illinois",
          code: "IL",
          __typename: "Region",
        },
        {
          id: 24,
          name: "Indiana",
          code: "IN",
          __typename: "Region",
        },
        {
          id: 25,
          name: "Iowa",
          code: "IA",
          __typename: "Region",
        },
        {
          id: 26,
          name: "Kansas",
          code: "KS",
          __typename: "Region",
        },
        {
          id: 27,
          name: "Kentucky",
          code: "KY",
          __typename: "Region",
        },
        {
          id: 28,
          name: "Louisiana",
          code: "LA",
          __typename: "Region",
        },
        {
          id: 29,
          name: "Maine",
          code: "ME",
          __typename: "Region",
        },
        {
          id: 30,
          name: "Marshall Islands",
          code: "MH",
          __typename: "Region",
        },
        {
          id: 31,
          name: "Maryland",
          code: "MD",
          __typename: "Region",
        },
        {
          id: 32,
          name: "Massachusetts",
          code: "MA",
          __typename: "Region",
        },
        {
          id: 33,
          name: "Michigan",
          code: "MI",
          __typename: "Region",
        },
        {
          id: 34,
          name: "Minnesota",
          code: "MN",
          __typename: "Region",
        },
        {
          id: 35,
          name: "Mississippi",
          code: "MS",
          __typename: "Region",
        },
        {
          id: 36,
          name: "Missouri",
          code: "MO",
          __typename: "Region",
        },
        {
          id: 37,
          name: "Montana",
          code: "MT",
          __typename: "Region",
        },
        {
          id: 38,
          name: "Nebraska",
          code: "NE",
          __typename: "Region",
        },
        {
          id: 39,
          name: "Nevada",
          code: "NV",
          __typename: "Region",
        },
        {
          id: 40,
          name: "New Hampshire",
          code: "NH",
          __typename: "Region",
        },
        {
          id: 41,
          name: "New Jersey",
          code: "NJ",
          __typename: "Region",
        },
        {
          id: 42,
          name: "New Mexico",
          code: "NM",
          __typename: "Region",
        },
        {
          id: 43,
          name: "New York",
          code: "NY",
          __typename: "Region",
        },
        {
          id: 44,
          name: "North Carolina",
          code: "NC",
          __typename: "Region",
        },
        {
          id: 45,
          name: "North Dakota",
          code: "ND",
          __typename: "Region",
        },
        {
          id: 46,
          name: "Northern Mariana Islands",
          code: "MP",
          __typename: "Region",
        },
        {
          id: 47,
          name: "Ohio",
          code: "OH",
          __typename: "Region",
        },
        {
          id: 48,
          name: "Oklahoma",
          code: "OK",
          __typename: "Region",
        },
        {
          id: 49,
          name: "Oregon",
          code: "OR",
          __typename: "Region",
        },
        {
          id: 50,
          name: "Palau",
          code: "PW",
          __typename: "Region",
        },
        {
          id: 51,
          name: "Pennsylvania",
          code: "PA",
          __typename: "Region",
        },
        {
          id: 52,
          name: "Puerto Rico",
          code: "PR",
          __typename: "Region",
        },
        {
          id: 53,
          name: "Rhode Island",
          code: "RI",
          __typename: "Region",
        },
        {
          id: 54,
          name: "South Carolina",
          code: "SC",
          __typename: "Region",
        },
        {
          id: 55,
          name: "South Dakota",
          code: "SD",
          __typename: "Region",
        },
        {
          id: 56,
          name: "Tennessee",
          code: "TN",
          __typename: "Region",
        },
        {
          id: 57,
          name: "Texas",
          code: "TX",
          __typename: "Region",
        },
        {
          id: 58,
          name: "Utah",
          code: "UT",
          __typename: "Region",
        },
        {
          id: 59,
          name: "Vermont",
          code: "VT",
          __typename: "Region",
        },
        {
          id: 60,
          name: "Virgin Islands",
          code: "VI",
          __typename: "Region",
        },
        {
          id: 61,
          name: "Virginia",
          code: "VA",
          __typename: "Region",
        },
        {
          id: 62,
          name: "Washington",
          code: "WA",
          __typename: "Region",
        },
        {
          id: 63,
          name: "West Virginia",
          code: "WV",
          __typename: "Region",
        },
        {
          id: 64,
          name: "Wisconsin",
          code: "WI",
          __typename: "Region",
        },
        {
          id: 65,
          name: "Wyoming",
          code: "WY",
          __typename: "Region",
        },
      ],
      full_name_locale: "United States",
      full_name_english: "United States",
      __typename: "Country",
    },
    {
      id: "CA",
      available_regions: [
        {
          id: 66,
          name: "Alberta",
          code: "AB",
          __typename: "Region",
        },
        {
          id: 67,
          name: "British Columbia",
          code: "BC",
          __typename: "Region",
        },
        {
          id: 68,
          name: "Manitoba",
          code: "MB",
          __typename: "Region",
        },
        {
          id: 70,
          name: "New Brunswick",
          code: "NB",
          __typename: "Region",
        },
        {
          id: 69,
          name: "Newfoundland and Labrador",
          code: "NL",
          __typename: "Region",
        },
        {
          id: 72,
          name: "Northwest Territories",
          code: "NT",
          __typename: "Region",
        },
        {
          id: 71,
          name: "Nova Scotia",
          code: "NS",
          __typename: "Region",
        },
        {
          id: 73,
          name: "Nunavut",
          code: "NU",
          __typename: "Region",
        },
        {
          id: 74,
          name: "Ontario",
          code: "ON",
          __typename: "Region",
        },
        {
          id: 75,
          name: "Prince Edward Island",
          code: "PE",
          __typename: "Region",
        },
        {
          id: 76,
          name: "Quebec",
          code: "QC",
          __typename: "Region",
        },
        {
          id: 77,
          name: "Saskatchewan",
          code: "SK",
          __typename: "Region",
        },
        {
          id: 78,
          name: "Yukon Territory",
          code: "YT",
          __typename: "Region",
        },
      ],
      full_name_locale: "Canada",
      full_name_english: "Canada",
      __typename: "Country",
    },
    {
      id: "AD",
      available_regions: null,
      full_name_locale: "Andorra",
      full_name_english: "Andorra",
      __typename: "Country",
    },
    {
      id: "AE",
      available_regions: null,
      full_name_locale: "United Arab Emirates",
      full_name_english: "United Arab Emirates",
      __typename: "Country",
    },
    {
      id: "AF",
      available_regions: null,
      full_name_locale: "Afghanistan",
      full_name_english: "Afghanistan",
      __typename: "Country",
    },
    {
      id: "AG",
      available_regions: null,
      full_name_locale: "Antigua and Barbuda",
      full_name_english: "Antigua and Barbuda",
      __typename: "Country",
    },
    {
      id: "AI",
      available_regions: null,
      full_name_locale: "Anguilla",
      full_name_english: "Anguilla",
      __typename: "Country",
    },
    {
      id: "AL",
      available_regions: null,
      full_name_locale: "Albania",
      full_name_english: "Albania",
      __typename: "Country",
    },
    {
      id: "AM",
      available_regions: null,
      full_name_locale: "Armenia",
      full_name_english: "Armenia",
      __typename: "Country",
    },
    {
      id: "AN",
      available_regions: null,
      full_name_locale: "Netherlands Antilles",
      full_name_english: "Netherlands Antilles",
      __typename: "Country",
    },
    {
      id: "AO",
      available_regions: null,
      full_name_locale: "Angola",
      full_name_english: "Angola",
      __typename: "Country",
    },
    {
      id: "AQ",
      available_regions: null,
      full_name_locale: "Antarctica",
      full_name_english: "Antarctica",
      __typename: "Country",
    },
    {
      id: "AR",
      available_regions: null,
      full_name_locale: "Argentina",
      full_name_english: "Argentina",
      __typename: "Country",
    },
    {
      id: "AS",
      available_regions: null,
      full_name_locale: "American Samoa",
      full_name_english: "American Samoa",
      __typename: "Country",
    },
    {
      id: "AT",
      available_regions: [
        {
          id: 102,
          name: "Burgenland",
          code: "BL",
          __typename: "Region",
        },
        {
          id: 99,
          name: "Kärnten",
          code: "KN",
          __typename: "Region",
        },
        {
          id: 96,
          name: "Niederösterreich",
          code: "NO",
          __typename: "Region",
        },
        {
          id: 97,
          name: "Oberösterreich",
          code: "OO",
          __typename: "Region",
        },
        {
          id: 98,
          name: "Salzburg",
          code: "SB",
          __typename: "Region",
        },
        {
          id: 100,
          name: "Steiermark",
          code: "ST",
          __typename: "Region",
        },
        {
          id: 101,
          name: "Tirol",
          code: "TI",
          __typename: "Region",
        },
        {
          id: 103,
          name: "Vorarlberg",
          code: "VB",
          __typename: "Region",
        },
        {
          id: 95,
          name: "Wien",
          code: "WI",
          __typename: "Region",
        },
      ],
      full_name_locale: "Austria",
      full_name_english: "Austria",
      __typename: "Country",
    },
    {
      id: "AU",
      available_regions: [
        {
          id: 569,
          name: "Australian Capital Territory",
          code: "ACT",
          __typename: "Region",
        },
        {
          id: 570,
          name: "New South Wales",
          code: "NSW",
          __typename: "Region",
        },
        {
          id: 576,
          name: "Northern Territory",
          code: "NT",
          __typename: "Region",
        },
        {
          id: 572,
          name: "Queensland",
          code: "QLD",
          __typename: "Region",
        },
        {
          id: 573,
          name: "South Australia",
          code: "SA",
          __typename: "Region",
        },
        {
          id: 574,
          name: "Tasmania",
          code: "TAS",
          __typename: "Region",
        },
        {
          id: 571,
          name: "Victoria",
          code: "VIC",
          __typename: "Region",
        },
        {
          id: 575,
          name: "Western Australia",
          code: "WA",
          __typename: "Region",
        },
      ],
      full_name_locale: "Australia",
      full_name_english: "Australia",
      __typename: "Country",
    },
    {
      id: "AW",
      available_regions: null,
      full_name_locale: "Aruba",
      full_name_english: "Aruba",
      __typename: "Country",
    },
    {
      id: "AX",
      available_regions: null,
      full_name_locale: "Åland Islands",
      full_name_english: "Åland Islands",
      __typename: "Country",
    },
    {
      id: "AZ",
      available_regions: null,
      full_name_locale: "Azerbaijan",
      full_name_english: "Azerbaijan",
      __typename: "Country",
    },
    {
      id: "BA",
      available_regions: null,
      full_name_locale: "Bosnia and Herzegovina",
      full_name_english: "Bosnia and Herzegovina",
      __typename: "Country",
    },
    {
      id: "BB",
      available_regions: null,
      full_name_locale: "Barbados",
      full_name_english: "Barbados",
      __typename: "Country",
    },
    {
      id: "BD",
      available_regions: null,
      full_name_locale: "Bangladesh",
      full_name_english: "Bangladesh",
      __typename: "Country",
    },
    {
      id: "BE",
      available_regions: [
        {
          id: 577,
          code: "VAN",
          name: "Antwerpen",
        },
        {
          id: 578,
          code: "WBR",
          name: "Brabant wallon",
        },
        {
          id: 579,
          code: "BRU",
          name: "Brussels-Capital Region",
        },
        {
          id: 580,
          code: "WHT",
          name: "Hainaut",
        },
        {
          id: 582,
          code: "WLG",
          name: "Liège",
        },
        {
          id: 581,
          code: "VLI",
          name: "Limburg",
        },
        {
          id: 583,
          code: "WLX",
          name: "Luxembourg",
        },
        {
          id: 584,
          code: "WNA",
          name: "Namur",
        },
        {
          id: 585,
          code: "VOV",
          name: "Oost-Vlaanderen",
        },
        {
          id: 586,
          code: "VBR",
          name: "Vlaams-Brabant",
        },
        {
          id: 587,
          code: "VWV",
          name: "West-Vlaanderen",
        },
      ],
      full_name_locale: "Belgium",
      full_name_english: "Belgium",
      __typename: "Country",
    },
    {
      id: "BF",
      available_regions: null,
      full_name_locale: "Burkina Faso",
      full_name_english: "Burkina Faso",
      __typename: "Country",
    },
    {
      id: "BG",
      available_regions: [
        {
          id: 702,
          code: "BG-01",
          name: "Blagoevgrad",
        },
        {
          id: 703,
          code: "BG-02",
          name: "Burgas",
        },
        {
          id: 709,
          code: "BG-08",
          name: "Dobrich",
        },
        {
          id: 708,
          code: "BG-07",
          name: "Gabrovo",
        },
        {
          id: 727,
          code: "BG-26",
          name: "Haskovo",
        },
        {
          id: 710,
          code: "BG-09",
          name: "Kardzhali",
        },
        {
          id: 711,
          code: "BG-10",
          name: "Kyustendil",
        },
        {
          id: 712,
          code: "BG-11",
          name: "Lovech",
        },
        {
          id: 713,
          code: "BG-12",
          name: "Montana",
        },
        {
          id: 714,
          code: "BG-13",
          name: "Pazardzhik",
        },
        {
          id: 715,
          code: "BG-14",
          name: "Pernik",
        },
        {
          id: 716,
          code: "BG-15",
          name: "Pleven",
        },
        {
          id: 717,
          code: "BG-16",
          name: "Plovdiv",
        },
        {
          id: 718,
          code: "BG-17",
          name: "Razgrad",
        },
        {
          id: 719,
          code: "BG-18",
          name: "Ruse",
        },
        {
          id: 728,
          code: "BG-27",
          name: "Shumen",
        },
        {
          id: 720,
          code: "BG-19",
          name: "Silistra",
        },
        {
          id: 721,
          code: "BG-20",
          name: "Sliven",
        },
        {
          id: 722,
          code: "BG-21",
          name: "Smolyan",
        },
        {
          id: 723,
          code: "BG-22",
          name: "Sofia City",
        },
        {
          id: 724,
          code: "BG-23",
          name: "Sofia Province",
        },
        {
          id: 725,
          code: "BG-24",
          name: "Stara Zagora",
        },
        {
          id: 726,
          code: "BG-25",
          name: "Targovishte",
        },
        {
          id: 704,
          code: "BG-03",
          name: "Varna",
        },
        {
          id: 705,
          code: "BG-04",
          name: "Veliko Tarnovo",
        },
        {
          id: 706,
          code: "BG-05",
          name: "Vidin",
        },
        {
          id: 707,
          code: "BG-06",
          name: "Vratsa",
        },
        {
          id: 729,
          code: "BG-28",
          name: "Yambol",
        },
      ],
      full_name_locale: "Bulgaria",
      full_name_english: "Bulgaria",
      __typename: "Country",
    },
    {
      id: "BH",
      available_regions: null,
      full_name_locale: "Bahrain",
      full_name_english: "Bahrain",
      __typename: "Country",
    },
    {
      id: "BI",
      available_regions: null,
      full_name_locale: "Burundi",
      full_name_english: "Burundi",
      __typename: "Country",
    },
    {
      id: "BJ",
      available_regions: null,
      full_name_locale: "Benin",
      full_name_english: "Benin",
      __typename: "Country",
    },
    {
      id: "BL",
      available_regions: null,
      full_name_locale: "Saint Barthélemy",
      full_name_english: "Saint Barthélemy",
      __typename: "Country",
    },
    {
      id: "BM",
      available_regions: null,
      full_name_locale: "Bermuda",
      full_name_english: "Bermuda",
      __typename: "Country",
    },
    {
      id: "BN",
      available_regions: null,
      full_name_locale: "Brunei",
      full_name_english: "Brunei",
      __typename: "Country",
    },
    {
      id: "BO",
      available_regions: null,
      full_name_locale: "Bolivia",
      full_name_english: "Bolivia",
      __typename: "Country",
    },
    {
      id: "BR",
      available_regions: [
        {
          id: 485,
          name: "Acre",
          code: "AC",
          __typename: "Region",
        },
        {
          id: 486,
          name: "Alagoas",
          code: "AL",
          __typename: "Region",
        },
        {
          id: 487,
          name: "Amapá",
          code: "AP",
          __typename: "Region",
        },
        {
          id: 488,
          name: "Amazonas",
          code: "AM",
          __typename: "Region",
        },
        {
          id: 489,
          name: "Bahia",
          code: "BA",
          __typename: "Region",
        },
        {
          id: 490,
          name: "Ceará",
          code: "CE",
          __typename: "Region",
        },
        {
          id: 511,
          name: "Distrito Federal",
          code: "DF",
          __typename: "Region",
        },
        {
          id: 491,
          name: "Espírito Santo",
          code: "ES",
          __typename: "Region",
        },
        {
          id: 492,
          name: "Goiás",
          code: "GO",
          __typename: "Region",
        },
        {
          id: 493,
          name: "Maranhão",
          code: "MA",
          __typename: "Region",
        },
        {
          id: 494,
          name: "Mato Grosso",
          code: "MT",
          __typename: "Region",
        },
        {
          id: 495,
          name: "Mato Grosso do Sul",
          code: "MS",
          __typename: "Region",
        },
        {
          id: 496,
          name: "Minas Gerais",
          code: "MG",
          __typename: "Region",
        },
        {
          id: 497,
          name: "Pará",
          code: "PA",
          __typename: "Region",
        },
        {
          id: 498,
          name: "Paraíba",
          code: "PB",
          __typename: "Region",
        },
        {
          id: 499,
          name: "Paraná",
          code: "PR",
          __typename: "Region",
        },
        {
          id: 500,
          name: "Pernambuco",
          code: "PE",
          __typename: "Region",
        },
        {
          id: 501,
          name: "Piauí",
          code: "PI",
          __typename: "Region",
        },
        {
          id: 502,
          name: "Rio de Janeiro",
          code: "RJ",
          __typename: "Region",
        },
        {
          id: 503,
          name: "Rio Grande do Norte",
          code: "RN",
          __typename: "Region",
        },
        {
          id: 504,
          name: "Rio Grande do Sul",
          code: "RS",
          __typename: "Region",
        },
        {
          id: 505,
          name: "Rondônia",
          code: "RO",
          __typename: "Region",
        },
        {
          id: 506,
          name: "Roraima",
          code: "RR",
          __typename: "Region",
        },
        {
          id: 507,
          name: "Santa Catarina",
          code: "SC",
          __typename: "Region",
        },
        {
          id: 508,
          name: "São Paulo",
          code: "SP",
          __typename: "Region",
        },
        {
          id: 509,
          name: "Sergipe",
          code: "SE",
          __typename: "Region",
        },
        {
          id: 510,
          name: "Tocantins",
          code: "TO",
          __typename: "Region",
        },
      ],
      full_name_locale: "Brazil",
      full_name_english: "Brazil",
      __typename: "Country",
    },
    {
      id: "BS",
      available_regions: null,
      full_name_locale: "Bahamas",
      full_name_english: "Bahamas",
      __typename: "Country",
    },
    {
      id: "BT",
      available_regions: null,
      full_name_locale: "Bhutan",
      full_name_english: "Bhutan",
      __typename: "Country",
    },
    {
      id: "BV",
      available_regions: null,
      full_name_locale: "Bouvet Island",
      full_name_english: "Bouvet Island",
      __typename: "Country",
    },
    {
      id: "BW",
      available_regions: null,
      full_name_locale: "Botswana",
      full_name_english: "Botswana",
      __typename: "Country",
    },
    {
      id: "BY",
      available_regions: null,
      full_name_locale: "Belarus",
      full_name_english: "Belarus",
      __typename: "Country",
    },
    {
      id: "BZ",
      available_regions: null,
      full_name_locale: "Belize",
      full_name_english: "Belize",
      __typename: "Country",
    },

    {
      id: "CC",
      available_regions: null,
      full_name_locale: "Cocos [Keeling] Islands",
      full_name_english: "Cocos [Keeling] Islands",
      __typename: "Country",
    },
    {
      id: "CD",
      available_regions: null,
      full_name_locale: "Congo - Kinshasa",
      full_name_english: "Congo - Kinshasa",
      __typename: "Country",
    },
    {
      id: "CF",
      available_regions: null,
      full_name_locale: "Central African Republic",
      full_name_english: "Central African Republic",
      __typename: "Country",
    },
    {
      id: "CG",
      available_regions: null,
      full_name_locale: "Congo - Brazzaville",
      full_name_english: "Congo - Brazzaville",
      __typename: "Country",
    },
    {
      id: "CH",
      available_regions: [
        {
          id: 104,
          name: "Aargau",
          code: "AG",
          __typename: "Region",
        },
        {
          id: 106,
          name: "Appenzell Ausserrhoden",
          code: "AR",
          __typename: "Region",
        },
        {
          id: 105,
          name: "Appenzell Innerrhoden",
          code: "AI",
          __typename: "Region",
        },
        {
          id: 108,
          name: "Basel-Landschaft",
          code: "BL",
          __typename: "Region",
        },
        {
          id: 109,
          name: "Basel-Stadt",
          code: "BS",
          __typename: "Region",
        },
        {
          id: 107,
          name: "Bern",
          code: "BE",
          __typename: "Region",
        },
        {
          id: 110,
          name: "Freiburg",
          code: "FR",
          __typename: "Region",
        },
        {
          id: 111,
          name: "Genf",
          code: "GE",
          __typename: "Region",
        },
        {
          id: 112,
          name: "Glarus",
          code: "GL",
          __typename: "Region",
        },
        {
          id: 113,
          name: "Graubünden",
          code: "GR",
          __typename: "Region",
        },
        {
          id: 114,
          name: "Jura",
          code: "JU",
          __typename: "Region",
        },
        {
          id: 115,
          name: "Luzern",
          code: "LU",
          __typename: "Region",
        },
        {
          id: 116,
          name: "Neuenburg",
          code: "NE",
          __typename: "Region",
        },
        {
          id: 117,
          name: "Nidwalden",
          code: "NW",
          __typename: "Region",
        },
        {
          id: 118,
          name: "Obwalden",
          code: "OW",
          __typename: "Region",
        },
        {
          id: 120,
          name: "Schaffhausen",
          code: "SH",
          __typename: "Region",
        },
        {
          id: 122,
          name: "Schwyz",
          code: "SZ",
          __typename: "Region",
        },
        {
          id: 121,
          name: "Solothurn",
          code: "SO",
          __typename: "Region",
        },
        {
          id: 119,
          name: "St. Gallen",
          code: "SG",
          __typename: "Region",
        },
        {
          id: 124,
          name: "Tessin",
          code: "TI",
          __typename: "Region",
        },
        {
          id: 123,
          name: "Thurgau",
          code: "TG",
          __typename: "Region",
        },
        {
          id: 125,
          name: "Uri",
          code: "UR",
          __typename: "Region",
        },
        {
          id: 126,
          name: "Waadt",
          code: "VD",
          __typename: "Region",
        },
        {
          id: 127,
          name: "Wallis",
          code: "VS",
          __typename: "Region",
        },
        {
          id: 128,
          name: "Zug",
          code: "ZG",
          __typename: "Region",
        },
        {
          id: 129,
          name: "Zürich",
          code: "ZH",
          __typename: "Region",
        },
      ],
      full_name_locale: "Switzerland",
      full_name_english: "Switzerland",
      __typename: "Country",
    },
    {
      id: "CI",
      available_regions: null,
      full_name_locale: "Côte d’Ivoire",
      full_name_english: "Côte d’Ivoire",
      __typename: "Country",
    },
    {
      id: "CK",
      available_regions: null,
      full_name_locale: "Cook Islands",
      full_name_english: "Cook Islands",
      __typename: "Country",
    },
    {
      id: "CL",
      available_regions: null,
      full_name_locale: "Chile",
      full_name_english: "Chile",
      __typename: "Country",
    },
    {
      id: "CM",
      available_regions: null,
      full_name_locale: "Cameroon",
      full_name_english: "Cameroon",
      __typename: "Country",
    },
    {
      id: "CN",
      available_regions: [
        {
          id: 588,
          code: "CN-AH",
          name: "Anhui Sheng",
        },
        {
          id: 589,
          code: "CN-BJ",
          name: "Beijing Shi",
        },
        {
          id: 590,
          code: "CN-CQ",
          name: "Chongqing Shi",
        },
        {
          id: 591,
          code: "CN-FJ",
          name: "Fujian Sheng",
        },
        {
          id: 592,
          code: "CN-GS",
          name: "Gansu Sheng",
        },
        {
          id: 593,
          code: "CN-GD",
          name: "Guangdong Sheng",
        },
        {
          id: 594,
          code: "CN-GX",
          name: "Guangxi Zhuangzu Zizhiqu",
        },
        {
          id: 595,
          code: "CN-GZ",
          name: "Guizhou Sheng",
        },
        {
          id: 596,
          code: "CN-HI",
          name: "Hainan Sheng",
        },
        {
          id: 597,
          code: "CN-HE",
          name: "Hebei Sheng",
        },
        {
          id: 598,
          code: "CN-HL",
          name: "Heilongjiang Sheng",
        },
        {
          id: 599,
          code: "CN-HA",
          name: "Henan Sheng",
        },
        {
          id: 600,
          code: "CN-HK",
          name: "Hong Kong SAR",
        },
        {
          id: 601,
          code: "CN-HB",
          name: "Hubei Sheng",
        },
        {
          id: 602,
          code: "CN-HN",
          name: "Hunan Sheng",
        },
        {
          id: 603,
          code: "CN-JS",
          name: "Jiangsu Sheng",
        },
        {
          id: 604,
          code: "CN-JX",
          name: "Jiangxi Sheng",
        },
        {
          id: 605,
          code: "CN-JL",
          name: "Jilin Sheng",
        },
        {
          id: 606,
          code: "CN-LN",
          name: "Liaoning Sheng",
        },
        {
          id: 607,
          code: "CN-MO",
          name: "Macao SAR",
        },
        {
          id: 608,
          code: "CN-NM",
          name: "Nei Mongol Zizhiqu",
        },
        {
          id: 609,
          code: "CN-NX",
          name: "Ningxia Huizi Zizhiqu",
        },
        {
          id: 610,
          code: "CN-QH",
          name: "Qinghai Sheng",
        },
        {
          id: 611,
          code: "CN-SN",
          name: "Shaanxi Sheng",
        },
        {
          id: 612,
          code: "CN-SD",
          name: "Shandong Sheng",
        },
        {
          id: 613,
          code: "CN-SH",
          name: "Shanghai Shi",
        },
        {
          id: 614,
          code: "CN-SX",
          name: "Shanxi Sheng",
        },
        {
          id: 615,
          code: "CN-SC",
          name: "Sichuan Sheng",
        },
        {
          id: 616,
          code: "CN-TW",
          name: "Taiwan Sheng",
        },
        {
          id: 617,
          code: "CN-TJ",
          name: "Tianjin Shi",
        },
        {
          id: 618,
          code: "CN-XJ",
          name: "Xinjiang Uygur Zizhiqu",
        },
        {
          id: 619,
          code: "CN-XZ",
          name: "Xizang Zizhiqu",
        },
        {
          id: 620,
          code: "CN-YN",
          name: "Yunnan Sheng",
        },
        {
          id: 621,
          code: "CN-ZJ",
          name: "Zhejiang Sheng",
        },
      ],
      full_name_locale: "China",
      full_name_english: "China",
      __typename: "Country",
    },
    {
      id: "CO",
      available_regions: null,
      full_name_locale: "Colombia",
      full_name_english: "Colombia",
      __typename: "Country",
    },
    {
      id: "CR",
      available_regions: null,
      full_name_locale: "Costa Rica",
      full_name_english: "Costa Rica",
      __typename: "Country",
    },
    {
      id: "CU",
      available_regions: null,
      full_name_locale: "Cuba",
      full_name_english: "Cuba",
      __typename: "Country",
    },
    {
      id: "CV",
      available_regions: null,
      full_name_locale: "Cape Verde",
      full_name_english: "Cape Verde",
      __typename: "Country",
    },
    {
      id: "CX",
      available_regions: null,
      full_name_locale: "Christmas Island",
      full_name_english: "Christmas Island",
      __typename: "Country",
    },
    {
      id: "CY",
      available_regions: null,
      full_name_locale: "Cyprus",
      full_name_english: "Cyprus",
      __typename: "Country",
    },
    {
      id: "CZ",
      available_regions: null,
      full_name_locale: "Czech Republic",
      full_name_english: "Czech Republic",
      __typename: "Country",
    },
    {
      id: "DE",
      available_regions: [
        {
          id: 80,
          name: "Baden-Württemberg",
          code: "BAW",
          __typename: "Region",
        },
        {
          id: 81,
          name: "Bayern",
          code: "BAY",
          __typename: "Region",
        },
        {
          id: 82,
          name: "Berlin",
          code: "BER",
          __typename: "Region",
        },
        {
          id: 83,
          name: "Brandenburg",
          code: "BRG",
          __typename: "Region",
        },
        {
          id: 84,
          name: "Bremen",
          code: "BRE",
          __typename: "Region",
        },
        {
          id: 85,
          name: "Hamburg",
          code: "HAM",
          __typename: "Region",
        },
        {
          id: 86,
          name: "Hessen",
          code: "HES",
          __typename: "Region",
        },
        {
          id: 87,
          name: "Mecklenburg-Vorpommern",
          code: "MEC",
          __typename: "Region",
        },
        {
          id: 79,
          name: "Niedersachsen",
          code: "NDS",
          __typename: "Region",
        },
        {
          id: 88,
          name: "Nordrhein-Westfalen",
          code: "NRW",
          __typename: "Region",
        },
        {
          id: 89,
          name: "Rheinland-Pfalz",
          code: "RHE",
          __typename: "Region",
        },
        {
          id: 90,
          name: "Saarland",
          code: "SAR",
          __typename: "Region",
        },
        {
          id: 91,
          name: "Sachsen",
          code: "SAS",
          __typename: "Region",
        },
        {
          id: 92,
          name: "Sachsen-Anhalt",
          code: "SAC",
          __typename: "Region",
        },
        {
          id: 93,
          name: "Schleswig-Holstein",
          code: "SCN",
          __typename: "Region",
        },
        {
          id: 94,
          name: "Thüringen",
          code: "THE",
          __typename: "Region",
        },
      ],
      full_name_locale: "Germany",
      full_name_english: "Germany",
      __typename: "Country",
    },
    {
      id: "DJ",
      available_regions: null,
      full_name_locale: "Djibouti",
      full_name_english: "Djibouti",
      __typename: "Country",
    },
    {
      id: "DK",
      available_regions: null,
      full_name_locale: "Denmark",
      full_name_english: "Denmark",
      __typename: "Country",
    },
    {
      id: "DM",
      available_regions: null,
      full_name_locale: "Dominica",
      full_name_english: "Dominica",
      __typename: "Country",
    },
    {
      id: "DO",
      available_regions: null,
      full_name_locale: "Dominican Republic",
      full_name_english: "Dominican Republic",
      __typename: "Country",
    },
    {
      id: "DZ",
      available_regions: null,
      full_name_locale: "Algeria",
      full_name_english: "Algeria",
      __typename: "Country",
    },
    {
      id: "EC",
      available_regions: null,
      full_name_locale: "Ecuador",
      full_name_english: "Ecuador",
      __typename: "Country",
    },
    {
      id: "EE",
      available_regions: [
        {
          id: 340,
          name: "Harjumaa",
          code: "EE-37",
          __typename: "Region",
        },
        {
          id: 341,
          name: "Hiiumaa",
          code: "EE-39",
          __typename: "Region",
        },
        {
          id: 342,
          name: "Ida-Virumaa",
          code: "EE-44",
          __typename: "Region",
        },
        {
          id: 344,
          name: "Järvamaa",
          code: "EE-51",
          __typename: "Region",
        },
        {
          id: 343,
          name: "Jõgevamaa",
          code: "EE-49",
          __typename: "Region",
        },
        {
          id: 346,
          name: "Lääne-Virumaa",
          code: "EE-59",
          __typename: "Region",
        },
        {
          id: 345,
          name: "Läänemaa",
          code: "EE-57",
          __typename: "Region",
        },
        {
          id: 348,
          name: "Pärnumaa",
          code: "EE-67",
          __typename: "Region",
        },
        {
          id: 347,
          name: "Põlvamaa",
          code: "EE-65",
          __typename: "Region",
        },
        {
          id: 349,
          name: "Raplamaa",
          code: "EE-70",
          __typename: "Region",
        },
        {
          id: 350,
          name: "Saaremaa",
          code: "EE-74",
          __typename: "Region",
        },
        {
          id: 351,
          name: "Tartumaa",
          code: "EE-78",
          __typename: "Region",
        },
        {
          id: 352,
          name: "Valgamaa",
          code: "EE-82",
          __typename: "Region",
        },
        {
          id: 353,
          name: "Viljandimaa",
          code: "EE-84",
          __typename: "Region",
        },
        {
          id: 354,
          name: "Võrumaa",
          code: "EE-86",
          __typename: "Region",
        },
      ],
      full_name_locale: "Estonia",
      full_name_english: "Estonia",
      __typename: "Country",
    },
    {
      id: "EG",
      available_regions: null,
      full_name_locale: "Egypt",
      full_name_english: "Egypt",
      __typename: "Country",
    },
    {
      id: "EH",
      available_regions: null,
      full_name_locale: "Western Sahara",
      full_name_english: "Western Sahara",
      __typename: "Country",
    },
    {
      id: "ER",
      available_regions: null,
      full_name_locale: "Eritrea",
      full_name_english: "Eritrea",
      __typename: "Country",
    },
    {
      id: "ES",
      available_regions: [
        {
          id: 130,
          name: "A Coruña",
          code: "A Coruсa",
          __typename: "Region",
        },
        {
          id: 131,
          name: "Alava",
          code: "Alava",
          __typename: "Region",
        },
        {
          id: 132,
          name: "Albacete",
          code: "Albacete",
          __typename: "Region",
        },
        {
          id: 133,
          name: "Alicante",
          code: "Alicante",
          __typename: "Region",
        },
        {
          id: 134,
          name: "Almeria",
          code: "Almeria",
          __typename: "Region",
        },
        {
          id: 135,
          name: "Asturias",
          code: "Asturias",
          __typename: "Region",
        },
        {
          id: 136,
          name: "Avila",
          code: "Avila",
          __typename: "Region",
        },
        {
          id: 137,
          name: "Badajoz",
          code: "Badajoz",
          __typename: "Region",
        },
        {
          id: 138,
          name: "Baleares",
          code: "Baleares",
          __typename: "Region",
        },
        {
          id: 139,
          name: "Barcelona",
          code: "Barcelona",
          __typename: "Region",
        },
        {
          id: 140,
          name: "Burgos",
          code: "Burgos",
          __typename: "Region",
        },
        {
          id: 141,
          name: "Caceres",
          code: "Caceres",
          __typename: "Region",
        },
        {
          id: 142,
          name: "Cadiz",
          code: "Cadiz",
          __typename: "Region",
        },
        {
          id: 143,
          name: "Cantabria",
          code: "Cantabria",
          __typename: "Region",
        },
        {
          id: 144,
          name: "Castellon",
          code: "Castellon",
          __typename: "Region",
        },
        {
          id: 145,
          name: "Ceuta",
          code: "Ceuta",
          __typename: "Region",
        },
        {
          id: 146,
          name: "Ciudad Real",
          code: "Ciudad Real",
          __typename: "Region",
        },
        {
          id: 147,
          name: "Cordoba",
          code: "Cordoba",
          __typename: "Region",
        },
        {
          id: 148,
          name: "Cuenca",
          code: "Cuenca",
          __typename: "Region",
        },
        {
          id: 149,
          name: "Girona",
          code: "Girona",
          __typename: "Region",
        },
        {
          id: 150,
          name: "Granada",
          code: "Granada",
          __typename: "Region",
        },
        {
          id: 151,
          name: "Guadalajara",
          code: "Guadalajara",
          __typename: "Region",
        },
        {
          id: 152,
          name: "Guipuzcoa",
          code: "Guipuzcoa",
          __typename: "Region",
        },
        {
          id: 153,
          name: "Huelva",
          code: "Huelva",
          __typename: "Region",
        },
        {
          id: 154,
          name: "Huesca",
          code: "Huesca",
          __typename: "Region",
        },
        {
          id: 155,
          name: "Jaen",
          code: "Jaen",
          __typename: "Region",
        },
        {
          id: 156,
          name: "La Rioja",
          code: "La Rioja",
          __typename: "Region",
        },
        {
          id: 157,
          name: "Las Palmas",
          code: "Las Palmas",
          __typename: "Region",
        },
        {
          id: 158,
          name: "Leon",
          code: "Leon",
          __typename: "Region",
        },
        {
          id: 159,
          name: "Lleida",
          code: "Lleida",
          __typename: "Region",
        },
        {
          id: 160,
          name: "Lugo",
          code: "Lugo",
          __typename: "Region",
        },
        {
          id: 161,
          name: "Madrid",
          code: "Madrid",
          __typename: "Region",
        },
        {
          id: 162,
          name: "Malaga",
          code: "Malaga",
          __typename: "Region",
        },
        {
          id: 163,
          name: "Melilla",
          code: "Melilla",
          __typename: "Region",
        },
        {
          id: 164,
          name: "Murcia",
          code: "Murcia",
          __typename: "Region",
        },
        {
          id: 165,
          name: "Navarra",
          code: "Navarra",
          __typename: "Region",
        },
        {
          id: 166,
          name: "Ourense",
          code: "Ourense",
          __typename: "Region",
        },
        {
          id: 167,
          name: "Palencia",
          code: "Palencia",
          __typename: "Region",
        },
        {
          id: 168,
          name: "Pontevedra",
          code: "Pontevedra",
          __typename: "Region",
        },
        {
          id: 169,
          name: "Salamanca",
          code: "Salamanca",
          __typename: "Region",
        },
        {
          id: 170,
          name: "Santa Cruz de Tenerife",
          code: "Santa Cruz de Tenerife",
          __typename: "Region",
        },
        {
          id: 171,
          name: "Segovia",
          code: "Segovia",
          __typename: "Region",
        },
        {
          id: 172,
          name: "Sevilla",
          code: "Sevilla",
          __typename: "Region",
        },
        {
          id: 173,
          name: "Soria",
          code: "Soria",
          __typename: "Region",
        },
        {
          id: 174,
          name: "Tarragona",
          code: "Tarragona",
          __typename: "Region",
        },
        {
          id: 175,
          name: "Teruel",
          code: "Teruel",
          __typename: "Region",
        },
        {
          id: 176,
          name: "Toledo",
          code: "Toledo",
          __typename: "Region",
        },
        {
          id: 177,
          name: "Valencia",
          code: "Valencia",
          __typename: "Region",
        },
        {
          id: 178,
          name: "Valladolid",
          code: "Valladolid",
          __typename: "Region",
        },
        {
          id: 179,
          name: "Vizcaya",
          code: "Vizcaya",
          __typename: "Region",
        },
        {
          id: 180,
          name: "Zamora",
          code: "Zamora",
          __typename: "Region",
        },
        {
          id: 181,
          name: "Zaragoza",
          code: "Zaragoza",
          __typename: "Region",
        },
      ],
      full_name_locale: "Spain",
      full_name_english: "Spain",
      __typename: "Country",
    },
    {
      id: "ET",
      available_regions: null,
      full_name_locale: "Ethiopia",
      full_name_english: "Ethiopia",
      __typename: "Country",
    },
    {
      id: "FI",
      available_regions: [
        {
          id: 339,
          name: "Ahvenanmaa",
          code: "Ahvenanmaa",
          __typename: "Region",
        },
        {
          id: 333,
          name: "Etelä-Karjala",
          code: "Etelä-Karjala",
          __typename: "Region",
        },
        {
          id: 326,
          name: "Etelä-Pohjanmaa",
          code: "Etelä-Pohjanmaa",
          __typename: "Region",
        },
        {
          id: 325,
          name: "Etelä-Savo",
          code: "Etelä-Savo",
          __typename: "Region",
        },
        {
          id: 337,
          name: "Itä-Uusimaa",
          code: "Itä-Uusimaa",
          __typename: "Region",
        },
        {
          id: 322,
          name: "Kainuu",
          code: "Kainuu",
          __typename: "Region",
        },
        {
          id: 335,
          name: "Kanta-Häme",
          code: "Kanta-Häme",
          __typename: "Region",
        },
        {
          id: 330,
          name: "Keski-Pohjanmaa",
          code: "Keski-Pohjanmaa",
          __typename: "Region",
        },
        {
          id: 331,
          name: "Keski-Suomi",
          code: "Keski-Suomi",
          __typename: "Region",
        },
        {
          id: 338,
          name: "Kymenlaakso",
          code: "Kymenlaakso",
          __typename: "Region",
        },
        {
          id: 320,
          name: "Lappi",
          code: "Lappi",
          __typename: "Region",
        },
        {
          id: 334,
          name: "Päijät-Häme",
          code: "Päijät-Häme",
          __typename: "Region",
        },
        {
          id: 328,
          name: "Pirkanmaa",
          code: "Pirkanmaa",
          __typename: "Region",
        },
        {
          id: 327,
          name: "Pohjanmaa",
          code: "Pohjanmaa",
          __typename: "Region",
        },
        {
          id: 323,
          name: "Pohjois-Karjala",
          code: "Pohjois-Karjala",
          __typename: "Region",
        },
        {
          id: 321,
          name: "Pohjois-Pohjanmaa",
          code: "Pohjois-Pohjanmaa",
          __typename: "Region",
        },
        {
          id: 324,
          name: "Pohjois-Savo",
          code: "Pohjois-Savo",
          __typename: "Region",
        },
        {
          id: 329,
          name: "Satakunta",
          code: "Satakunta",
          __typename: "Region",
        },
        {
          id: 336,
          name: "Uusimaa",
          code: "Uusimaa",
          __typename: "Region",
        },
        {
          id: 332,
          name: "Varsinais-Suomi",
          code: "Varsinais-Suomi",
          __typename: "Region",
        },
      ],
      full_name_locale: "Finland",
      full_name_english: "Finland",
      __typename: "Country",
    },
    {
      id: "FJ",
      available_regions: null,
      full_name_locale: "Fiji",
      full_name_english: "Fiji",
      __typename: "Country",
    },
    {
      id: "FK",
      available_regions: null,
      full_name_locale: "Falkland Islands",
      full_name_english: "Falkland Islands",
      __typename: "Country",
    },
    {
      id: "FM",
      available_regions: null,
      full_name_locale: "Micronesia",
      full_name_english: "Micronesia",
      __typename: "Country",
    },
    {
      id: "FO",
      available_regions: null,
      full_name_locale: "Faroe Islands",
      full_name_english: "Faroe Islands",
      __typename: "Country",
    },
    {
      id: "FR",
      available_regions: [
        {
          id: 182,
          name: "Ain",
          code: "1",
          __typename: "Region",
        },
        {
          id: 183,
          name: "Aisne",
          code: "2",
          __typename: "Region",
        },
        {
          id: 184,
          name: "Allier",
          code: "3",
          __typename: "Region",
        },
        {
          id: 185,
          name: "Alpes-de-Haute-Provence",
          code: "4",
          __typename: "Region",
        },
        {
          id: 187,
          name: "Alpes-Maritimes",
          code: "6",
          __typename: "Region",
        },
        {
          id: 188,
          name: "Ardèche",
          code: "7",
          __typename: "Region",
        },
        {
          id: 189,
          name: "Ardennes",
          code: "8",
          __typename: "Region",
        },
        {
          id: 190,
          name: "Ariège",
          code: "9",
          __typename: "Region",
        },
        {
          id: 191,
          name: "Aube",
          code: "10",
          __typename: "Region",
        },
        {
          id: 192,
          name: "Aude",
          code: "11",
          __typename: "Region",
        },
        {
          id: 193,
          name: "Aveyron",
          code: "12",
          __typename: "Region",
        },
        {
          id: 249,
          name: "Bas-Rhin",
          code: "67",
          __typename: "Region",
        },
        {
          id: 194,
          name: "Bouches-du-Rhône",
          code: "13",
          __typename: "Region",
        },
        {
          id: 195,
          name: "Calvados",
          code: "14",
          __typename: "Region",
        },
        {
          id: 196,
          name: "Cantal",
          code: "15",
          __typename: "Region",
        },
        {
          id: 197,
          name: "Charente",
          code: "16",
          __typename: "Region",
        },
        {
          id: 198,
          name: "Charente-Maritime",
          code: "17",
          __typename: "Region",
        },
        {
          id: 199,
          name: "Cher",
          code: "18",
          __typename: "Region",
        },
        {
          id: 200,
          name: "Corrèze",
          code: "19",
          __typename: "Region",
        },
        {
          id: 201,
          name: "Corse-du-Sud",
          code: "2A",
          __typename: "Region",
        },
        {
          id: 203,
          name: "Côte-d'Or",
          code: "21",
          __typename: "Region",
        },
        {
          id: 204,
          name: "Côtes-d'Armor",
          code: "22",
          __typename: "Region",
        },
        {
          id: 205,
          name: "Creuse",
          code: "23",
          __typename: "Region",
        },
        {
          id: 261,
          name: "Deux-Sèvres",
          code: "79",
          __typename: "Region",
        },
        {
          id: 206,
          name: "Dordogne",
          code: "24",
          __typename: "Region",
        },
        {
          id: 207,
          name: "Doubs",
          code: "25",
          __typename: "Region",
        },
        {
          id: 208,
          name: "Drôme",
          code: "26",
          __typename: "Region",
        },
        {
          id: 273,
          name: "Essonne",
          code: "91",
          __typename: "Region",
        },
        {
          id: 209,
          name: "Eure",
          code: "27",
          __typename: "Region",
        },
        {
          id: 210,
          name: "Eure-et-Loir",
          code: "28",
          __typename: "Region",
        },
        {
          id: 211,
          name: "Finistère",
          code: "29",
          __typename: "Region",
        },
        {
          id: 212,
          name: "Gard",
          code: "30",
          __typename: "Region",
        },
        {
          id: 214,
          name: "Gers",
          code: "32",
          __typename: "Region",
        },
        {
          id: 215,
          name: "Gironde",
          code: "33",
          __typename: "Region",
        },
        {
          id: 250,
          name: "Haut-Rhin",
          code: "68",
          __typename: "Region",
        },
        {
          id: 202,
          name: "Haute-Corse",
          code: "2B",
          __typename: "Region",
        },
        {
          id: 213,
          name: "Haute-Garonne",
          code: "31",
          __typename: "Region",
        },
        {
          id: 225,
          name: "Haute-Loire",
          code: "43",
          __typename: "Region",
        },
        {
          id: 234,
          name: "Haute-Marne",
          code: "52",
          __typename: "Region",
        },
        {
          id: 252,
          name: "Haute-Saône",
          code: "70",
          __typename: "Region",
        },
        {
          id: 256,
          name: "Haute-Savoie",
          code: "74",
          __typename: "Region",
        },
        {
          id: 269,
          name: "Haute-Vienne",
          code: "87",
          __typename: "Region",
        },
        {
          id: 186,
          name: "Hautes-Alpes",
          code: "5",
          __typename: "Region",
        },
        {
          id: 247,
          name: "Hautes-Pyrénées",
          code: "65",
          __typename: "Region",
        },
        {
          id: 274,
          name: "Hauts-de-Seine",
          code: "92",
          __typename: "Region",
        },
        {
          id: 216,
          name: "Hérault",
          code: "34",
          __typename: "Region",
        },
        {
          id: 217,
          name: "Ille-et-Vilaine",
          code: "35",
          __typename: "Region",
        },
        {
          id: 218,
          name: "Indre",
          code: "36",
          __typename: "Region",
        },
        {
          id: 219,
          name: "Indre-et-Loire",
          code: "37",
          __typename: "Region",
        },
        {
          id: 220,
          name: "Isère",
          code: "38",
          __typename: "Region",
        },
        {
          id: 221,
          name: "Jura",
          code: "39",
          __typename: "Region",
        },
        {
          id: 222,
          name: "Landes",
          code: "40",
          __typename: "Region",
        },
        {
          id: 223,
          name: "Loir-et-Cher",
          code: "41",
          __typename: "Region",
        },
        {
          id: 224,
          name: "Loire",
          code: "42",
          __typename: "Region",
        },
        {
          id: 226,
          name: "Loire-Atlantique",
          code: "44",
          __typename: "Region",
        },
        {
          id: 227,
          name: "Loiret",
          code: "45",
          __typename: "Region",
        },
        {
          id: 228,
          name: "Lot",
          code: "46",
          __typename: "Region",
        },
        {
          id: 229,
          name: "Lot-et-Garonne",
          code: "47",
          __typename: "Region",
        },
        {
          id: 230,
          name: "Lozère",
          code: "48",
          __typename: "Region",
        },
        {
          id: 231,
          name: "Maine-et-Loire",
          code: "49",
          __typename: "Region",
        },
        {
          id: 232,
          name: "Manche",
          code: "50",
          __typename: "Region",
        },
        {
          id: 233,
          name: "Marne",
          code: "51",
          __typename: "Region",
        },
        {
          id: 235,
          name: "Mayenne",
          code: "53",
          __typename: "Region",
        },
        {
          id: 236,
          name: "Meurthe-et-Moselle",
          code: "54",
          __typename: "Region",
        },
        {
          id: 237,
          name: "Meuse",
          code: "55",
          __typename: "Region",
        },
        {
          id: 238,
          name: "Morbihan",
          code: "56",
          __typename: "Region",
        },
        {
          id: 239,
          name: "Moselle",
          code: "57",
          __typename: "Region",
        },
        {
          id: 240,
          name: "Nièvre",
          code: "58",
          __typename: "Region",
        },
        {
          id: 241,
          name: "Nord",
          code: "59",
          __typename: "Region",
        },
        {
          id: 242,
          name: "Oise",
          code: "60",
          __typename: "Region",
        },
        {
          id: 243,
          name: "Orne",
          code: "61",
          __typename: "Region",
        },
        {
          id: 257,
          name: "Paris",
          code: "75",
          __typename: "Region",
        },
        {
          id: 244,
          name: "Pas-de-Calais",
          code: "62",
          __typename: "Region",
        },
        {
          id: 245,
          name: "Puy-de-Dôme",
          code: "63",
          __typename: "Region",
        },
        {
          id: 246,
          name: "Pyrénées-Atlantiques",
          code: "64",
          __typename: "Region",
        },
        {
          id: 248,
          name: "Pyrénées-Orientales",
          code: "66",
          __typename: "Region",
        },
        {
          id: 251,
          name: "Rhône",
          code: "69",
          __typename: "Region",
        },
        {
          id: 253,
          name: "Saône-et-Loire",
          code: "71",
          __typename: "Region",
        },
        {
          id: 254,
          name: "Sarthe",
          code: "72",
          __typename: "Region",
        },
        {
          id: 255,
          name: "Savoie",
          code: "73",
          __typename: "Region",
        },
        {
          id: 259,
          name: "Seine-et-Marne",
          code: "77",
          __typename: "Region",
        },
        {
          id: 258,
          name: "Seine-Maritime",
          code: "76",
          __typename: "Region",
        },
        {
          id: 275,
          name: "Seine-Saint-Denis",
          code: "93",
          __typename: "Region",
        },
        {
          id: 262,
          name: "Somme",
          code: "80",
          __typename: "Region",
        },
        {
          id: 263,
          name: "Tarn",
          code: "81",
          __typename: "Region",
        },
        {
          id: 264,
          name: "Tarn-et-Garonne",
          code: "82",
          __typename: "Region",
        },
        {
          id: 272,
          name: "Territoire-de-Belfort",
          code: "90",
          __typename: "Region",
        },
        {
          id: 277,
          name: "Val-d'Oise",
          code: "95",
          __typename: "Region",
        },
        {
          id: 276,
          name: "Val-de-Marne",
          code: "94",
          __typename: "Region",
        },
        {
          id: 265,
          name: "Var",
          code: "83",
          __typename: "Region",
        },
        {
          id: 266,
          name: "Vaucluse",
          code: "84",
          __typename: "Region",
        },
        {
          id: 267,
          name: "Vendée",
          code: "85",
          __typename: "Region",
        },
        {
          id: 268,
          name: "Vienne",
          code: "86",
          __typename: "Region",
        },
        {
          id: 270,
          name: "Vosges",
          code: "88",
          __typename: "Region",
        },
        {
          id: 271,
          name: "Yonne",
          code: "89",
          __typename: "Region",
        },
        {
          id: 260,
          name: "Yvelines",
          code: "78",
          __typename: "Region",
        },
      ],
      full_name_locale: "France",
      full_name_english: "France",
      __typename: "Country",
    },
    {
      id: "GA",
      available_regions: null,
      full_name_locale: "Gabon",
      full_name_english: "Gabon",
      __typename: "Country",
    },
    {
      id: "GB",
      available_regions: null,
      full_name_locale: "United Kingdom",
      full_name_english: "United Kingdom",
      __typename: "Country",
    },
    {
      id: "GD",
      available_regions: null,
      full_name_locale: "Grenada",
      full_name_english: "Grenada",
      __typename: "Country",
    },
    {
      id: "GE",
      available_regions: null,
      full_name_locale: "Georgia",
      full_name_english: "Georgia",
      __typename: "Country",
    },
    {
      id: "GF",
      available_regions: null,
      full_name_locale: "French Guiana",
      full_name_english: "French Guiana",
      __typename: "Country",
    },
    {
      id: "GG",
      available_regions: null,
      full_name_locale: "Guernsey",
      full_name_english: "Guernsey",
      __typename: "Country",
    },
    {
      id: "GH",
      available_regions: null,
      full_name_locale: "Ghana",
      full_name_english: "Ghana",
      __typename: "Country",
    },
    {
      id: "GI",
      available_regions: null,
      full_name_locale: "Gibraltar",
      full_name_english: "Gibraltar",
      __typename: "Country",
    },
    {
      id: "GL",
      available_regions: null,
      full_name_locale: "Greenland",
      full_name_english: "Greenland",
      __typename: "Country",
    },
    {
      id: "GM",
      available_regions: null,
      full_name_locale: "Gambia",
      full_name_english: "Gambia",
      __typename: "Country",
    },
    {
      id: "GN",
      available_regions: null,
      full_name_locale: "Guinea",
      full_name_english: "Guinea",
      __typename: "Country",
    },
    {
      id: "GP",
      available_regions: null,
      full_name_locale: "Guadeloupe",
      full_name_english: "Guadeloupe",
      __typename: "Country",
    },
    {
      id: "GQ",
      available_regions: null,
      full_name_locale: "Equatorial Guinea",
      full_name_english: "Equatorial Guinea",
      __typename: "Country",
    },
    {
      id: "GR",
      available_regions: null,
      full_name_locale: "Greece",
      full_name_english: "Greece",
      __typename: "Country",
    },
    {
      id: "GS",
      available_regions: null,
      full_name_locale: "South Georgia and the South Sandwich Islands",
      full_name_english: "South Georgia and the South Sandwich Islands",
      __typename: "Country",
    },
    {
      id: "GT",
      available_regions: null,
      full_name_locale: "Guatemala",
      full_name_english: "Guatemala",
      __typename: "Country",
    },
    {
      id: "GU",
      available_regions: null,
      full_name_locale: "Guam",
      full_name_english: "Guam",
      __typename: "Country",
    },
    {
      id: "GW",
      available_regions: null,
      full_name_locale: "Guinea-Bissau",
      full_name_english: "Guinea-Bissau",
      __typename: "Country",
    },
    {
      id: "GY",
      available_regions: null,
      full_name_locale: "Guyana",
      full_name_english: "Guyana",
      __typename: "Country",
    },
    {
      id: "HK",
      available_regions: null,
      full_name_locale: "Hong Kong SAR China",
      full_name_english: "Hong Kong SAR China",
      __typename: "Country",
    },
    {
      id: "HM",
      available_regions: null,
      full_name_locale: "Heard Island and McDonald Islands",
      full_name_english: "Heard Island and McDonald Islands",
      __typename: "Country",
    },
    {
      id: "HN",
      available_regions: null,
      full_name_locale: "Honduras",
      full_name_english: "Honduras",
      __typename: "Country",
    },
    {
      id: "HR",
      available_regions: [
        {
          id: 518,
          name: "Bjelovarsko-bilogorska županija",
          code: "HR-07",
          __typename: "Region",
        },
        {
          id: 523,
          name: "Brodsko-posavska županija",
          code: "HR-12",
          __typename: "Region",
        },
        {
          id: 530,
          name: "Dubrovačko-neretvanska županija",
          code: "HR-19",
          __typename: "Region",
        },
        {
          id: 532,
          name: "Grad Zagreb",
          code: "HR-21",
          __typename: "Region",
        },
        {
          id: 529,
          name: "Istarska županija",
          code: "HR-18",
          __typename: "Region",
        },
        {
          id: 515,
          name: "Karlovačka županija",
          code: "HR-04",
          __typename: "Region",
        },
        {
          id: 517,
          name: "Koprivničko-križevačka županija",
          code: "HR-06",
          __typename: "Region",
        },
        {
          id: 513,
          name: "Krapinsko-zagorska županija",
          code: "HR-02",
          __typename: "Region",
        },
        {
          id: 520,
          name: "Ličko-senjska županija",
          code: "HR-09",
          __typename: "Region",
        },
        {
          id: 531,
          name: "Međimurska županija",
          code: "HR-20",
          __typename: "Region",
        },
        {
          id: 525,
          name: "Osječko-baranjska županija",
          code: "HR-14",
          __typename: "Region",
        },
        {
          id: 522,
          name: "Požeško-slavonska županija",
          code: "HR-11",
          __typename: "Region",
        },
        {
          id: 519,
          name: "Primorsko-goranska županija",
          code: "HR-08",
          __typename: "Region",
        },
        {
          id: 526,
          name: "Šibensko-kninska županija",
          code: "HR-15",
          __typename: "Region",
        },
        {
          id: 514,
          name: "Sisačko-moslavačka županija",
          code: "HR-03",
          __typename: "Region",
        },
        {
          id: 528,
          name: "Splitsko-dalmatinska županija",
          code: "HR-17",
          __typename: "Region",
        },
        {
          id: 516,
          name: "Varaždinska županija",
          code: "HR-05",
          __typename: "Region",
        },
        {
          id: 521,
          name: "Virovitičko-podravska županija",
          code: "HR-10",
          __typename: "Region",
        },
        {
          id: 527,
          name: "Vukovarsko-srijemska županija",
          code: "HR-16",
          __typename: "Region",
        },
        {
          id: 524,
          name: "Zadarska županija",
          code: "HR-13",
          __typename: "Region",
        },
        {
          id: 512,
          name: "Zagrebačka županija",
          code: "HR-01",
          __typename: "Region",
        },
      ],
      full_name_locale: "Croatia",
      full_name_english: "Croatia",
      __typename: "Country",
    },
    {
      id: "HT",
      available_regions: null,
      full_name_locale: "Haiti",
      full_name_english: "Haiti",
      __typename: "Country",
    },
    {
      id: "HU",
      available_regions: null,
      full_name_locale: "Hungary",
      full_name_english: "Hungary",
      __typename: "Country",
    },
    {
      id: "ID",
      available_regions: null,
      full_name_locale: "Indonesia",
      full_name_english: "Indonesia",
      __typename: "Country",
    },
    {
      id: "IE",
      available_regions: null,
      full_name_locale: "Ireland",
      full_name_english: "Ireland",
      __typename: "Country",
    },
    {
      id: "IL",
      available_regions: null,
      full_name_locale: "Israel",
      full_name_english: "Israel",
      __typename: "Country",
    },
    {
      id: "IM",
      available_regions: null,
      full_name_locale: "Isle of Man",
      full_name_english: "Isle of Man",
      __typename: "Country",
    },
    {
      id: "IN",
      available_regions: [
        {
          id: 533,
          name: "Andaman and Nicobar Islands",
          code: "AN",
          __typename: "Region",
        },
        {
          id: 534,
          name: "Andhra Pradesh",
          code: "AP",
          __typename: "Region",
        },
        {
          id: 535,
          name: "Arunachal Pradesh",
          code: "AR",
          __typename: "Region",
        },
        {
          id: 536,
          name: "Assam",
          code: "AS",
          __typename: "Region",
        },
        {
          id: 537,
          name: "Bihar",
          code: "BR",
          __typename: "Region",
        },
        {
          id: 538,
          name: "Chandigarh",
          code: "CH",
          __typename: "Region",
        },
        {
          id: 539,
          name: "Chhattisgarh",
          code: "CT",
          __typename: "Region",
        },
        {
          id: 540,
          name: "Dadra and Nagar Haveli",
          code: "DN",
          __typename: "Region",
        },
        {
          id: 541,
          name: "Daman and Diu",
          code: "DD",
          __typename: "Region",
        },
        {
          id: 542,
          name: "Delhi",
          code: "DL",
          __typename: "Region",
        },
        {
          id: 543,
          name: "Goa",
          code: "GA",
          __typename: "Region",
        },
        {
          id: 544,
          name: "Gujarat",
          code: "GJ",
          __typename: "Region",
        },
        {
          id: 545,
          name: "Haryana",
          code: "HR",
          __typename: "Region",
        },
        {
          id: 546,
          name: "Himachal Pradesh",
          code: "HP",
          __typename: "Region",
        },
        {
          id: 547,
          name: "Jammu and Kashmir",
          code: "JK",
          __typename: "Region",
        },
        {
          id: 548,
          name: "Jharkhand",
          code: "JH",
          __typename: "Region",
        },
        {
          id: 549,
          name: "Karnataka",
          code: "KA",
          __typename: "Region",
        },
        {
          id: 550,
          name: "Kerala",
          code: "KL",
          __typename: "Region",
        },
        {
          id: 551,
          name: "Lakshadweep",
          code: "LD",
          __typename: "Region",
        },
        {
          id: 552,
          name: "Madhya Pradesh",
          code: "MP",
          __typename: "Region",
        },
        {
          id: 553,
          name: "Maharashtra",
          code: "MH",
          __typename: "Region",
        },
        {
          id: 554,
          name: "Manipur",
          code: "MN",
          __typename: "Region",
        },
        {
          id: 555,
          name: "Meghalaya",
          code: "ML",
          __typename: "Region",
        },
        {
          id: 556,
          name: "Mizoram",
          code: "MZ",
          __typename: "Region",
        },
        {
          id: 557,
          name: "Nagaland",
          code: "NL",
          __typename: "Region",
        },
        {
          id: 558,
          name: "Odisha",
          code: "OR",
          __typename: "Region",
        },
        {
          id: 559,
          name: "Puducherry",
          code: "PY",
          __typename: "Region",
        },
        {
          id: 560,
          name: "Punjab",
          code: "PB",
          __typename: "Region",
        },
        {
          id: 561,
          name: "Rajasthan",
          code: "RJ",
          __typename: "Region",
        },
        {
          id: 562,
          name: "Sikkim",
          code: "SK",
          __typename: "Region",
        },
        {
          id: 563,
          name: "Tamil Nadu",
          code: "TN",
          __typename: "Region",
        },
        {
          id: 564,
          name: "Telangana",
          code: "TG",
          __typename: "Region",
        },
        {
          id: 565,
          name: "Tripura",
          code: "TR",
          __typename: "Region",
        },
        {
          id: 566,
          name: "Uttar Pradesh",
          code: "UP",
          __typename: "Region",
        },
        {
          id: 567,
          name: "Uttarakhand",
          code: "UT",
          __typename: "Region",
        },
        {
          id: 568,
          name: "West Bengal",
          code: "WB",
          __typename: "Region",
        },
      ],
      full_name_locale: "India",
      full_name_english: "India",
      __typename: "Country",
    },
    {
      id: "IO",
      available_regions: null,
      full_name_locale: "British Indian Ocean Territory",
      full_name_english: "British Indian Ocean Territory",
      __typename: "Country",
    },
    {
      id: "IQ",
      available_regions: null,
      full_name_locale: "Iraq",
      full_name_english: "Iraq",
      __typename: "Country",
    },
    {
      id: "IR",
      available_regions: null,
      full_name_locale: "Iran",
      full_name_english: "Iran",
      __typename: "Country",
    },
    {
      id: "IS",
      available_regions: null,
      full_name_locale: "Iceland",
      full_name_english: "Iceland",
      __typename: "Country",
    },
    {
      id: "IT",
      available_regions: null,
      full_name_locale: "Italy",
      full_name_english: "Italy",
      __typename: "Country",
    },
    {
      id: "JE",
      available_regions: null,
      full_name_locale: "Jersey",
      full_name_english: "Jersey",
      __typename: "Country",
    },
    {
      id: "JM",
      available_regions: null,
      full_name_locale: "Jamaica",
      full_name_english: "Jamaica",
      __typename: "Country",
    },
    {
      id: "JO",
      available_regions: null,
      full_name_locale: "Jordan",
      full_name_english: "Jordan",
      __typename: "Country",
    },
    {
      id: "JP",
      available_regions: null,
      full_name_locale: "Japan",
      full_name_english: "Japan",
      __typename: "Country",
    },
    {
      id: "KE",
      available_regions: null,
      full_name_locale: "Kenya",
      full_name_english: "Kenya",
      __typename: "Country",
    },
    {
      id: "KG",
      available_regions: null,
      full_name_locale: "Kyrgyzstan",
      full_name_english: "Kyrgyzstan",
      __typename: "Country",
    },
    {
      id: "KH",
      available_regions: null,
      full_name_locale: "Cambodia",
      full_name_english: "Cambodia",
      __typename: "Country",
    },
    {
      id: "KI",
      available_regions: null,
      full_name_locale: "Kiribati",
      full_name_english: "Kiribati",
      __typename: "Country",
    },
    {
      id: "KM",
      available_regions: null,
      full_name_locale: "Comoros",
      full_name_english: "Comoros",
      __typename: "Country",
    },
    {
      id: "KN",
      available_regions: null,
      full_name_locale: "Saint Kitts and Nevis",
      full_name_english: "Saint Kitts and Nevis",
      __typename: "Country",
    },
    {
      id: "KP",
      available_regions: null,
      full_name_locale: "North Korea",
      full_name_english: "North Korea",
      __typename: "Country",
    },
    {
      id: "KR",
      available_regions: null,
      full_name_locale: "South Korea",
      full_name_english: "South Korea",
      __typename: "Country",
    },
    {
      id: "KW",
      available_regions: null,
      full_name_locale: "Kuwait",
      full_name_english: "Kuwait",
      __typename: "Country",
    },
    {
      id: "KY",
      available_regions: null,
      full_name_locale: "Cayman Islands",
      full_name_english: "Cayman Islands",
      __typename: "Country",
    },
    {
      id: "KZ",
      available_regions: null,
      full_name_locale: "Kazakhstan",
      full_name_english: "Kazakhstan",
      __typename: "Country",
    },
    {
      id: "LA",
      available_regions: null,
      full_name_locale: "Laos",
      full_name_english: "Laos",
      __typename: "Country",
    },
    {
      id: "LB",
      available_regions: null,
      full_name_locale: "Lebanon",
      full_name_english: "Lebanon",
      __typename: "Country",
    },
    {
      id: "LC",
      available_regions: null,
      full_name_locale: "Saint Lucia",
      full_name_english: "Saint Lucia",
      __typename: "Country",
    },
    {
      id: "LI",
      available_regions: null,
      full_name_locale: "Liechtenstein",
      full_name_english: "Liechtenstein",
      __typename: "Country",
    },
    {
      id: "LK",
      available_regions: null,
      full_name_locale: "Sri Lanka",
      full_name_english: "Sri Lanka",
      __typename: "Country",
    },
    {
      id: "LR",
      available_regions: null,
      full_name_locale: "Liberia",
      full_name_english: "Liberia",
      __typename: "Country",
    },
    {
      id: "LS",
      available_regions: null,
      full_name_locale: "Lesotho",
      full_name_english: "Lesotho",
      __typename: "Country",
    },
    {
      id: "LT",
      available_regions: [
        {
          id: 475,
          name: "Alytaus Apskritis",
          code: "LT-AL",
          __typename: "Region",
        },
        {
          id: 476,
          name: "Kauno Apskritis",
          code: "LT-KU",
          __typename: "Region",
        },
        {
          id: 477,
          name: "Klaipėdos Apskritis",
          code: "LT-KL",
          __typename: "Region",
        },
        {
          id: 478,
          name: "Marijampolės Apskritis",
          code: "LT-MR",
          __typename: "Region",
        },
        {
          id: 479,
          name: "Panevėžio Apskritis",
          code: "LT-PN",
          __typename: "Region",
        },
        {
          id: 480,
          name: "Šiaulių Apskritis",
          code: "LT-SA",
          __typename: "Region",
        },
        {
          id: 481,
          name: "Tauragės Apskritis",
          code: "LT-TA",
          __typename: "Region",
        },
        {
          id: 482,
          name: "Telšių Apskritis",
          code: "LT-TE",
          __typename: "Region",
        },
        {
          id: 483,
          name: "Utenos Apskritis",
          code: "LT-UT",
          __typename: "Region",
        },
        {
          id: 484,
          name: "Vilniaus Apskritis",
          code: "LT-VL",
          __typename: "Region",
        },
      ],
      full_name_locale: "Lithuania",
      full_name_english: "Lithuania",
      __typename: "Country",
    },
    {
      id: "LU",
      available_regions: null,
      full_name_locale: "Luxembourg",
      full_name_english: "Luxembourg",
      __typename: "Country",
    },
    {
      id: "LV",
      available_regions: [
        {
          id: 471,
          name: "Ādažu novads",
          code: "Ādažu novads",
          __typename: "Region",
        },
        {
          id: 366,
          name: "Aglonas novads",
          code: "Aglonas novads",
          __typename: "Region",
        },
        {
          id: 367,
          name: "Aizkraukles novads",
          code: "LV-AI",
          __typename: "Region",
        },
        {
          id: 368,
          name: "Aizputes novads",
          code: "Aizputes novads",
          __typename: "Region",
        },
        {
          id: 369,
          name: "Aknīstes novads",
          code: "Aknīstes novads",
          __typename: "Region",
        },
        {
          id: 370,
          name: "Alojas novads",
          code: "Alojas novads",
          __typename: "Region",
        },
        {
          id: 371,
          name: "Alsungas novads",
          code: "Alsungas novads",
          __typename: "Region",
        },
        {
          id: 372,
          name: "Alūksnes novads",
          code: "LV-AL",
          __typename: "Region",
        },
        {
          id: 373,
          name: "Amatas novads",
          code: "Amatas novads",
          __typename: "Region",
        },
        {
          id: 374,
          name: "Apes novads",
          code: "Apes novads",
          __typename: "Region",
        },
        {
          id: 375,
          name: "Auces novads",
          code: "Auces novads",
          __typename: "Region",
        },
        {
          id: 376,
          name: "Babītes novads",
          code: "Babītes novads",
          __typename: "Region",
        },
        {
          id: 377,
          name: "Baldones novads",
          code: "Baldones novads",
          __typename: "Region",
        },
        {
          id: 378,
          name: "Baltinavas novads",
          code: "Baltinavas novads",
          __typename: "Region",
        },
        {
          id: 379,
          name: "Balvu novads",
          code: "LV-BL",
          __typename: "Region",
        },
        {
          id: 380,
          name: "Bauskas novads",
          code: "LV-BU",
          __typename: "Region",
        },
        {
          id: 381,
          name: "Beverīnas novads",
          code: "Beverīnas novads",
          __typename: "Region",
        },
        {
          id: 382,
          name: "Brocēnu novads",
          code: "Brocēnu novads",
          __typename: "Region",
        },
        {
          id: 383,
          name: "Burtnieku novads",
          code: "Burtnieku novads",
          __typename: "Region",
        },
        {
          id: 384,
          name: "Carnikavas novads",
          code: "Carnikavas novads",
          __typename: "Region",
        },
        {
          id: 387,
          name: "Cēsu novads",
          code: "LV-CE",
          __typename: "Region",
        },
        {
          id: 385,
          name: "Cesvaines novads",
          code: "Cesvaines novads",
          __typename: "Region",
        },
        {
          id: 386,
          name: "Ciblas novads",
          code: "Ciblas novads",
          __typename: "Region",
        },
        {
          id: 388,
          name: "Dagdas novads",
          code: "Dagdas novads",
          __typename: "Region",
        },
        {
          id: 355,
          name: "Daugavpils",
          code: "LV-DGV",
          __typename: "Region",
        },
        {
          id: 389,
          name: "Daugavpils novads",
          code: "LV-DA",
          __typename: "Region",
        },
        {
          id: 390,
          name: "Dobeles novads",
          code: "LV-DO",
          __typename: "Region",
        },
        {
          id: 391,
          name: "Dundagas novads",
          code: "Dundagas novads",
          __typename: "Region",
        },
        {
          id: 392,
          name: "Durbes novads",
          code: "Durbes novads",
          __typename: "Region",
        },
        {
          id: 393,
          name: "Engures novads",
          code: "Engures novads",
          __typename: "Region",
        },
        {
          id: 472,
          name: "Ērgļu novads",
          code: "Ērgļu novads",
          __typename: "Region",
        },
        {
          id: 394,
          name: "Garkalnes novads",
          code: "Garkalnes novads",
          __typename: "Region",
        },
        {
          id: 395,
          name: "Grobiņas novads",
          code: "Grobiņas novads",
          __typename: "Region",
        },
        {
          id: 396,
          name: "Gulbenes novads",
          code: "LV-GU",
          __typename: "Region",
        },
        {
          id: 397,
          name: "Iecavas novads",
          code: "Iecavas novads",
          __typename: "Region",
        },
        {
          id: 398,
          name: "Ikšķiles novads",
          code: "Ikšķiles novads",
          __typename: "Region",
        },
        {
          id: 399,
          name: "Ilūkstes novads",
          code: "Ilūkstes novads",
          __typename: "Region",
        },
        {
          id: 400,
          name: "Inčukalna novads",
          code: "Inčukalna novads",
          __typename: "Region",
        },
        {
          id: 401,
          name: "Jaunjelgavas novads",
          code: "Jaunjelgavas novads",
          __typename: "Region",
        },
        {
          id: 402,
          name: "Jaunpiebalgas novads",
          code: "Jaunpiebalgas novads",
          __typename: "Region",
        },
        {
          id: 403,
          name: "Jaunpils novads",
          code: "Jaunpils novads",
          __typename: "Region",
        },
        {
          id: 357,
          name: "Jēkabpils",
          code: "Jēkabpils",
          __typename: "Region",
        },
        {
          id: 405,
          name: "Jēkabpils novads",
          code: "LV-JK",
          __typename: "Region",
        },
        {
          id: 356,
          name: "Jelgava",
          code: "LV-JEL",
          __typename: "Region",
        },
        {
          id: 404,
          name: "Jelgavas novads",
          code: "LV-JL",
          __typename: "Region",
        },
        {
          id: 358,
          name: "Jūrmala",
          code: "LV-JUR",
          __typename: "Region",
        },
        {
          id: 406,
          name: "Kandavas novads",
          code: "Kandavas novads",
          __typename: "Region",
        },
        {
          id: 412,
          name: "Kārsavas novads",
          code: "Kārsavas novads",
          __typename: "Region",
        },
        {
          id: 473,
          name: "Ķeguma novads",
          code: "Ķeguma novads",
          __typename: "Region",
        },
        {
          id: 474,
          name: "Ķekavas novads",
          code: "Ķekavas novads",
          __typename: "Region",
        },
        {
          id: 407,
          name: "Kokneses novads",
          code: "Kokneses novads",
          __typename: "Region",
        },
        {
          id: 410,
          name: "Krāslavas novads",
          code: "LV-KR",
          __typename: "Region",
        },
        {
          id: 408,
          name: "Krimuldas novads",
          code: "Krimuldas novads",
          __typename: "Region",
        },
        {
          id: 409,
          name: "Krustpils novads",
          code: "Krustpils novads",
          __typename: "Region",
        },
        {
          id: 411,
          name: "Kuldīgas novads",
          code: "LV-KU",
          __typename: "Region",
        },
        {
          id: 413,
          name: "Lielvārdes novads",
          code: "Lielvārdes novads",
          __typename: "Region",
        },
        {
          id: 359,
          name: "Liepāja",
          code: "LV-LPX",
          __typename: "Region",
        },
        {
          id: 360,
          name: "Liepājas novads",
          code: "LV-LE",
          __typename: "Region",
        },
        {
          id: 417,
          name: "Līgatnes novads",
          code: "Līgatnes novads",
          __typename: "Region",
        },
        {
          id: 414,
          name: "Limbažu novads",
          code: "LV-LM",
          __typename: "Region",
        },
        {
          id: 418,
          name: "Līvānu novads",
          code: "Līvānu novads",
          __typename: "Region",
        },
        {
          id: 415,
          name: "Lubānas novads",
          code: "Lubānas novads",
          __typename: "Region",
        },
        {
          id: 416,
          name: "Ludzas novads",
          code: "LV-LU",
          __typename: "Region",
        },
        {
          id: 419,
          name: "Madonas novads",
          code: "LV-MA",
          __typename: "Region",
        },
        {
          id: 421,
          name: "Mālpils novads",
          code: "Mālpils novads",
          __typename: "Region",
        },
        {
          id: 422,
          name: "Mārupes novads",
          code: "Mārupes novads",
          __typename: "Region",
        },
        {
          id: 420,
          name: "Mazsalacas novads",
          code: "Mazsalacas novads",
          __typename: "Region",
        },
        {
          id: 423,
          name: "Naukšēnu novads",
          code: "Naukšēnu novads",
          __typename: "Region",
        },
        {
          id: 424,
          name: "Neretas novads",
          code: "Neretas novads",
          __typename: "Region",
        },
        {
          id: 425,
          name: "Nīcas novads",
          code: "Nīcas novads",
          __typename: "Region",
        },
        {
          id: 426,
          name: "Ogres novads",
          code: "LV-OG",
          __typename: "Region",
        },
        {
          id: 427,
          name: "Olaines novads",
          code: "Olaines novads",
          __typename: "Region",
        },
        {
          id: 428,
          name: "Ozolnieku novads",
          code: "Ozolnieku novads",
          __typename: "Region",
        },
        {
          id: 432,
          name: "Pārgaujas novads",
          code: "Pārgaujas novads",
          __typename: "Region",
        },
        {
          id: 433,
          name: "Pāvilostas novads",
          code: "Pāvilostas novads",
          __typename: "Region",
        },
        {
          id: 434,
          name: "Pļaviņu novads",
          code: "Pļaviņu novads",
          __typename: "Region",
        },
        {
          id: 429,
          name: "Preiļu novads",
          code: "LV-PR",
          __typename: "Region",
        },
        {
          id: 430,
          name: "Priekules novads",
          code: "Priekules novads",
          __typename: "Region",
        },
        {
          id: 431,
          name: "Priekuļu novads",
          code: "Priekuļu novads",
          __typename: "Region",
        },
        {
          id: 435,
          name: "Raunas novads",
          code: "Raunas novads",
          __typename: "Region",
        },
        {
          id: 361,
          name: "Rēzekne",
          code: "LV-REZ",
          __typename: "Region",
        },
        {
          id: 442,
          name: "Rēzeknes novads",
          code: "LV-RE",
          __typename: "Region",
        },
        {
          id: 436,
          name: "Riebiņu novads",
          code: "Riebiņu novads",
          __typename: "Region",
        },
        {
          id: 362,
          name: "Rīga",
          code: "LV-RIX",
          __typename: "Region",
        },
        {
          id: 363,
          name: "Rīgas novads",
          code: "LV-RI",
          __typename: "Region",
        },
        {
          id: 437,
          name: "Rojas novads",
          code: "Rojas novads",
          __typename: "Region",
        },
        {
          id: 438,
          name: "Ropažu novads",
          code: "Ropažu novads",
          __typename: "Region",
        },
        {
          id: 439,
          name: "Rucavas novads",
          code: "Rucavas novads",
          __typename: "Region",
        },
        {
          id: 440,
          name: "Rugāju novads",
          code: "Rugāju novads",
          __typename: "Region",
        },
        {
          id: 443,
          name: "Rūjienas novads",
          code: "Rūjienas novads",
          __typename: "Region",
        },
        {
          id: 441,
          name: "Rundāles novads",
          code: "Rundāles novads",
          __typename: "Region",
        },
        {
          id: 444,
          name: "Salacgrīvas novads",
          code: "Salacgrīvas novads",
          __typename: "Region",
        },
        {
          id: 445,
          name: "Salas novads",
          code: "Salas novads",
          __typename: "Region",
        },
        {
          id: 446,
          name: "Salaspils novads",
          code: "Salaspils novads",
          __typename: "Region",
        },
        {
          id: 447,
          name: "Saldus novads",
          code: "LV-SA",
          __typename: "Region",
        },
        {
          id: 448,
          name: "Saulkrastu novads",
          code: "Saulkrastu novads",
          __typename: "Region",
        },
        {
          id: 455,
          name: "Sējas novads",
          code: "Sējas novads",
          __typename: "Region",
        },
        {
          id: 449,
          name: "Siguldas novads",
          code: "Siguldas novads",
          __typename: "Region",
        },
        {
          id: 451,
          name: "Skrīveru novads",
          code: "Skrīveru novads",
          __typename: "Region",
        },
        {
          id: 450,
          name: "Skrundas novads",
          code: "Skrundas novads",
          __typename: "Region",
        },
        {
          id: 452,
          name: "Smiltenes novads",
          code: "Smiltenes novads",
          __typename: "Region",
        },
        {
          id: 453,
          name: "Stopiņu novads",
          code: "Stopiņu novads",
          __typename: "Region",
        },
        {
          id: 454,
          name: "Strenču novads",
          code: "Strenču novads",
          __typename: "Region",
        },
        {
          id: 456,
          name: "Talsu novads",
          code: "LV-TA",
          __typename: "Region",
        },
        {
          id: 458,
          name: "Tērvetes novads",
          code: "Tērvetes novads",
          __typename: "Region",
        },
        {
          id: 457,
          name: "Tukuma novads",
          code: "LV-TU",
          __typename: "Region",
        },
        {
          id: 459,
          name: "Vaiņodes novads",
          code: "Vaiņodes novads",
          __typename: "Region",
        },
        {
          id: 460,
          name: "Valkas novads",
          code: "LV-VK",
          __typename: "Region",
        },
        {
          id: 364,
          name: "Valmiera",
          code: "Valmiera",
          __typename: "Region",
        },
        {
          id: 461,
          name: "Valmieras novads",
          code: "LV-VM",
          __typename: "Region",
        },
        {
          id: 462,
          name: "Varakļānu novads",
          code: "Varakļānu novads",
          __typename: "Region",
        },
        {
          id: 469,
          name: "Vārkavas novads",
          code: "Vārkavas novads",
          __typename: "Region",
        },
        {
          id: 463,
          name: "Vecpiebalgas novads",
          code: "Vecpiebalgas novads",
          __typename: "Region",
        },
        {
          id: 464,
          name: "Vecumnieku novads",
          code: "Vecumnieku novads",
          __typename: "Region",
        },
        {
          id: 365,
          name: "Ventspils",
          code: "LV-VEN",
          __typename: "Region",
        },
        {
          id: 465,
          name: "Ventspils novads",
          code: "LV-VE",
          __typename: "Region",
        },
        {
          id: 466,
          name: "Viesītes novads",
          code: "Viesītes novads",
          __typename: "Region",
        },
        {
          id: 467,
          name: "Viļakas novads",
          code: "Viļakas novads",
          __typename: "Region",
        },
        {
          id: 468,
          name: "Viļānu novads",
          code: "Viļānu novads",
          __typename: "Region",
        },
        {
          id: 470,
          name: "Zilupes novads",
          code: "Zilupes novads",
          __typename: "Region",
        },
      ],
      full_name_locale: "Latvia",
      full_name_english: "Latvia",
      __typename: "Country",
    },
    {
      id: "LY",
      available_regions: null,
      full_name_locale: "Libya",
      full_name_english: "Libya",
      __typename: "Country",
    },
    {
      id: "MA",
      available_regions: null,
      full_name_locale: "Morocco",
      full_name_english: "Morocco",
      __typename: "Country",
    },
    {
      id: "MC",
      available_regions: null,
      full_name_locale: "Monaco",
      full_name_english: "Monaco",
      __typename: "Country",
    },
    {
      id: "MD",
      available_regions: null,
      full_name_locale: "Moldova",
      full_name_english: "Moldova",
      __typename: "Country",
    },
    {
      id: "ME",
      available_regions: null,
      full_name_locale: "Montenegro",
      full_name_english: "Montenegro",
      __typename: "Country",
    },
    {
      id: "MF",
      available_regions: null,
      full_name_locale: "Saint Martin",
      full_name_english: "Saint Martin",
      __typename: "Country",
    },
    {
      id: "MG",
      available_regions: null,
      full_name_locale: "Madagascar",
      full_name_english: "Madagascar",
      __typename: "Country",
    },
    {
      id: "MH",
      available_regions: null,
      full_name_locale: "Marshall Islands",
      full_name_english: "Marshall Islands",
      __typename: "Country",
    },
    {
      id: "MK",
      available_regions: null,
      full_name_locale: "Macedonia",
      full_name_english: "Macedonia",
      __typename: "Country",
    },
    {
      id: "ML",
      available_regions: null,
      full_name_locale: "Mali",
      full_name_english: "Mali",
      __typename: "Country",
    },
    {
      id: "MM",
      available_regions: null,
      full_name_locale: "Myanmar [Burma]",
      full_name_english: "Myanmar [Burma]",
      __typename: "Country",
    },
    {
      id: "MN",
      available_regions: null,
      full_name_locale: "Mongolia",
      full_name_english: "Mongolia",
      __typename: "Country",
    },
    {
      id: "MO",
      available_regions: null,
      full_name_locale: "Macau SAR China",
      full_name_english: "Macau SAR China",
      __typename: "Country",
    },
    {
      id: "MP",
      available_regions: null,
      full_name_locale: "Northern Mariana Islands",
      full_name_english: "Northern Mariana Islands",
      __typename: "Country",
    },
    {
      id: "MQ",
      available_regions: null,
      full_name_locale: "Martinique",
      full_name_english: "Martinique",
      __typename: "Country",
    },
    {
      id: "MR",
      available_regions: null,
      full_name_locale: "Mauritania",
      full_name_english: "Mauritania",
      __typename: "Country",
    },
    {
      id: "MS",
      available_regions: null,
      full_name_locale: "Montserrat",
      full_name_english: "Montserrat",
      __typename: "Country",
    },
    {
      id: "MT",
      available_regions: null,
      full_name_locale: "Malta",
      full_name_english: "Malta",
      __typename: "Country",
    },
    {
      id: "MU",
      available_regions: null,
      full_name_locale: "Mauritius",
      full_name_english: "Mauritius",
      __typename: "Country",
    },
    {
      id: "MV",
      available_regions: null,
      full_name_locale: "Maldives",
      full_name_english: "Maldives",
      __typename: "Country",
    },
    {
      id: "MW",
      available_regions: null,
      full_name_locale: "Malawi",
      full_name_english: "Malawi",
      __typename: "Country",
    },
    {
      id: "MX",
      available_regions: [
        {
          id: 577,
          name: "Aguascalientes",
          code: "AGU",
          __typename: "Region",
        },
        {
          id: 578,
          name: "Baja California",
          code: "BCN",
          __typename: "Region",
        },
        {
          id: 579,
          name: "Baja California Sur",
          code: "BCS",
          __typename: "Region",
        },
        {
          id: 580,
          name: "Campeche",
          code: "CAM",
          __typename: "Region",
        },
        {
          id: 581,
          name: "Chiapas",
          code: "CHP",
          __typename: "Region",
        },
        {
          id: 582,
          name: "Chihuahua",
          code: "CHH",
          __typename: "Region",
        },
        {
          id: 583,
          name: "Ciudad de México",
          code: "CMX",
          __typename: "Region",
        },
        {
          id: 584,
          name: "Coahuila",
          code: "COA",
          __typename: "Region",
        },
        {
          id: 585,
          name: "Colima",
          code: "COL",
          __typename: "Region",
        },
        {
          id: 586,
          name: "Durango",
          code: "DUR",
          __typename: "Region",
        },
        {
          id: 587,
          name: "Estado de México",
          code: "MEX",
          __typename: "Region",
        },
        {
          id: 588,
          name: "Guanajuato",
          code: "GUA",
          __typename: "Region",
        },
        {
          id: 589,
          name: "Guerrero",
          code: "GRO",
          __typename: "Region",
        },
        {
          id: 590,
          name: "Hidalgo",
          code: "HID",
          __typename: "Region",
        },
        {
          id: 591,
          name: "Jalisco",
          code: "JAL",
          __typename: "Region",
        },
        {
          id: 592,
          name: "Michoacán",
          code: "MIC",
          __typename: "Region",
        },
        {
          id: 593,
          name: "Morelos",
          code: "MOR",
          __typename: "Region",
        },
        {
          id: 594,
          name: "Nayarit",
          code: "NAY",
          __typename: "Region",
        },
        {
          id: 595,
          name: "Nuevo León",
          code: "NLE",
          __typename: "Region",
        },
        {
          id: 596,
          name: "Oaxaca",
          code: "OAX",
          __typename: "Region",
        },
        {
          id: 597,
          name: "Puebla",
          code: "PUE",
          __typename: "Region",
        },
        {
          id: 598,
          name: "Querétaro",
          code: "QUE",
          __typename: "Region",
        },
        {
          id: 599,
          name: "Quintana Roo",
          code: "ROO",
          __typename: "Region",
        },
        {
          id: 600,
          name: "San Luis Potosí",
          code: "SLP",
          __typename: "Region",
        },
        {
          id: 601,
          name: "Sinaloa",
          code: "SIN",
          __typename: "Region",
        },
        {
          id: 602,
          name: "Sonora",
          code: "SON",
          __typename: "Region",
        },
        {
          id: 603,
          name: "Tabasco",
          code: "TAB",
          __typename: "Region",
        },
        {
          id: 604,
          name: "Tamaulipas",
          code: "TAM",
          __typename: "Region",
        },
        {
          id: 605,
          name: "Tlaxcala",
          code: "TLA",
          __typename: "Region",
        },
        {
          id: 606,
          name: "Veracruz",
          code: "VER",
          __typename: "Region",
        },
        {
          id: 607,
          name: "Yucatán",
          code: "YUC",
          __typename: "Region",
        },
        {
          id: 608,
          name: "Zacatecas",
          code: "ZAC",
          __typename: "Region",
        },
      ],
      full_name_locale: "Mexico",
      full_name_english: "Mexico",
      __typename: "Country",
    },
    {
      id: "MY",
      available_regions: null,
      full_name_locale: "Malaysia",
      full_name_english: "Malaysia",
      __typename: "Country",
    },
    {
      id: "MZ",
      available_regions: null,
      full_name_locale: "Mozambique",
      full_name_english: "Mozambique",
      __typename: "Country",
    },
    {
      id: "NA",
      available_regions: null,
      full_name_locale: "Namibia",
      full_name_english: "Namibia",
      __typename: "Country",
    },
    {
      id: "NC",
      available_regions: null,
      full_name_locale: "New Caledonia",
      full_name_english: "New Caledonia",
      __typename: "Country",
    },
    {
      id: "NE",
      available_regions: null,
      full_name_locale: "Niger",
      full_name_english: "Niger",
      __typename: "Country",
    },
    {
      id: "NF",
      available_regions: null,
      full_name_locale: "Norfolk Island",
      full_name_english: "Norfolk Island",
      __typename: "Country",
    },
    {
      id: "NG",
      available_regions: null,
      full_name_locale: "Nigeria",
      full_name_english: "Nigeria",
      __typename: "Country",
    },
    {
      id: "NI",
      available_regions: null,
      full_name_locale: "Nicaragua",
      full_name_english: "Nicaragua",
      __typename: "Country",
    },
    {
      id: "NL",
      available_regions: null,
      full_name_locale: "Netherlands",
      full_name_english: "Netherlands",
      __typename: "Country",
    },
    {
      id: "NO",
      available_regions: null,
      full_name_locale: "Norway",
      full_name_english: "Norway",
      __typename: "Country",
    },
    {
      id: "NP",
      available_regions: null,
      full_name_locale: "Nepal",
      full_name_english: "Nepal",
      __typename: "Country",
    },
    {
      id: "NR",
      available_regions: null,
      full_name_locale: "Nauru",
      full_name_english: "Nauru",
      __typename: "Country",
    },
    {
      id: "NU",
      available_regions: null,
      full_name_locale: "Niue",
      full_name_english: "Niue",
      __typename: "Country",
    },
    {
      id: "NZ",
      available_regions: null,
      full_name_locale: "New Zealand",
      full_name_english: "New Zealand",
      __typename: "Country",
    },
    {
      id: "OM",
      available_regions: null,
      full_name_locale: "Oman",
      full_name_english: "Oman",
      __typename: "Country",
    },
    {
      id: "PA",
      available_regions: null,
      full_name_locale: "Panama",
      full_name_english: "Panama",
      __typename: "Country",
    },
    {
      id: "PE",
      available_regions: null,
      full_name_locale: "Peru",
      full_name_english: "Peru",
      __typename: "Country",
    },
    {
      id: "PF",
      available_regions: null,
      full_name_locale: "French Polynesia",
      full_name_english: "French Polynesia",
      __typename: "Country",
    },
    {
      id: "PG",
      available_regions: null,
      full_name_locale: "Papua New Guinea",
      full_name_english: "Papua New Guinea",
      __typename: "Country",
    },
    {
      id: "PH",
      available_regions: null,
      full_name_locale: "Philippines",
      full_name_english: "Philippines",
      __typename: "Country",
    },
    {
      id: "PK",
      available_regions: null,
      full_name_locale: "Pakistan",
      full_name_english: "Pakistan",
      __typename: "Country",
    },
    {
      id: "PL",
      available_regions: null,
      full_name_locale: "Poland",
      full_name_english: "Poland",
      __typename: "Country",
    },
    {
      id: "PM",
      available_regions: null,
      full_name_locale: "Saint Pierre and Miquelon",
      full_name_english: "Saint Pierre and Miquelon",
      __typename: "Country",
    },
    {
      id: "PN",
      available_regions: null,
      full_name_locale: "Pitcairn Islands",
      full_name_english: "Pitcairn Islands",
      __typename: "Country",
    },
    {
      id: "PS",
      available_regions: null,
      full_name_locale: "Palestinian Territories",
      full_name_english: "Palestinian Territories",
      __typename: "Country",
    },
    {
      id: "PT",
      available_regions: null,
      full_name_locale: "Portugal",
      full_name_english: "Portugal",
      __typename: "Country",
    },
    {
      id: "PW",
      available_regions: null,
      full_name_locale: "Palau",
      full_name_english: "Palau",
      __typename: "Country",
    },
    {
      id: "PY",
      available_regions: null,
      full_name_locale: "Paraguay",
      full_name_english: "Paraguay",
      __typename: "Country",
    },
    {
      id: "QA",
      available_regions: null,
      full_name_locale: "Qatar",
      full_name_english: "Qatar",
      __typename: "Country",
    },
    {
      id: "RE",
      available_regions: null,
      full_name_locale: "Réunion",
      full_name_english: "Réunion",
      __typename: "Country",
    },
    {
      id: "RO",
      available_regions: [
        {
          id: 278,
          name: "Alba",
          code: "AB",
          __typename: "Region",
        },
        {
          id: 279,
          name: "Arad",
          code: "AR",
          __typename: "Region",
        },
        {
          id: 280,
          name: "Argeş",
          code: "AG",
          __typename: "Region",
        },
        {
          id: 281,
          name: "Bacău",
          code: "BC",
          __typename: "Region",
        },
        {
          id: 282,
          name: "Bihor",
          code: "BH",
          __typename: "Region",
        },
        {
          id: 283,
          name: "Bistriţa-Năsăud",
          code: "BN",
          __typename: "Region",
        },
        {
          id: 284,
          name: "Botoşani",
          code: "BT",
          __typename: "Region",
        },
        {
          id: 286,
          name: "Brăila",
          code: "BR",
          __typename: "Region",
        },
        {
          id: 285,
          name: "Braşov",
          code: "BV",
          __typename: "Region",
        },
        {
          id: 287,
          name: "Bucureşti",
          code: "B",
          __typename: "Region",
        },
        {
          id: 288,
          name: "Buzău",
          code: "BZ",
          __typename: "Region",
        },
        {
          id: 290,
          name: "Călăraşi",
          code: "CL",
          __typename: "Region",
        },
        {
          id: 289,
          name: "Caraş-Severin",
          code: "CS",
          __typename: "Region",
        },
        {
          id: 291,
          name: "Cluj",
          code: "CJ",
          __typename: "Region",
        },
        {
          id: 292,
          name: "Constanţa",
          code: "CT",
          __typename: "Region",
        },
        {
          id: 293,
          name: "Covasna",
          code: "CV",
          __typename: "Region",
        },
        {
          id: 294,
          name: "Dâmboviţa",
          code: "DB",
          __typename: "Region",
        },
        {
          id: 295,
          name: "Dolj",
          code: "DJ",
          __typename: "Region",
        },
        {
          id: 296,
          name: "Galaţi",
          code: "GL",
          __typename: "Region",
        },
        {
          id: 297,
          name: "Giurgiu",
          code: "GR",
          __typename: "Region",
        },
        {
          id: 298,
          name: "Gorj",
          code: "GJ",
          __typename: "Region",
        },
        {
          id: 299,
          name: "Harghita",
          code: "HR",
          __typename: "Region",
        },
        {
          id: 300,
          name: "Hunedoara",
          code: "HD",
          __typename: "Region",
        },
        {
          id: 301,
          name: "Ialomiţa",
          code: "IL",
          __typename: "Region",
        },
        {
          id: 302,
          name: "Iaşi",
          code: "IS",
          __typename: "Region",
        },
        {
          id: 303,
          name: "Ilfov",
          code: "IF",
          __typename: "Region",
        },
        {
          id: 304,
          name: "Maramureş",
          code: "MM",
          __typename: "Region",
        },
        {
          id: 305,
          name: "Mehedinţi",
          code: "MH",
          __typename: "Region",
        },
        {
          id: 306,
          name: "Mureş",
          code: "MS",
          __typename: "Region",
        },
        {
          id: 307,
          name: "Neamţ",
          code: "NT",
          __typename: "Region",
        },
        {
          id: 308,
          name: "Olt",
          code: "OT",
          __typename: "Region",
        },
        {
          id: 309,
          name: "Prahova",
          code: "PH",
          __typename: "Region",
        },
        {
          id: 311,
          name: "Sălaj",
          code: "SJ",
          __typename: "Region",
        },
        {
          id: 310,
          name: "Satu-Mare",
          code: "SM",
          __typename: "Region",
        },
        {
          id: 312,
          name: "Sibiu",
          code: "SB",
          __typename: "Region",
        },
        {
          id: 313,
          name: "Suceava",
          code: "SV",
          __typename: "Region",
        },
        {
          id: 314,
          name: "Teleorman",
          code: "TR",
          __typename: "Region",
        },
        {
          id: 315,
          name: "Timiş",
          code: "TM",
          __typename: "Region",
        },
        {
          id: 316,
          name: "Tulcea",
          code: "TL",
          __typename: "Region",
        },
        {
          id: 318,
          name: "Vâlcea",
          code: "VL",
          __typename: "Region",
        },
        {
          id: 317,
          name: "Vaslui",
          code: "VS",
          __typename: "Region",
        },
        {
          id: 319,
          name: "Vrancea",
          code: "VN",
          __typename: "Region",
        },
      ],
      full_name_locale: "Romania",
      full_name_english: "Romania",
      __typename: "Country",
    },
    {
      id: "RS",
      available_regions: null,
      full_name_locale: "Serbia",
      full_name_english: "Serbia",
      __typename: "Country",
    },
    {
      id: "RU",
      available_regions: null,
      full_name_locale: "Russia",
      full_name_english: "Russia",
      __typename: "Country",
    },
    {
      id: "RW",
      available_regions: null,
      full_name_locale: "Rwanda",
      full_name_english: "Rwanda",
      __typename: "Country",
    },
    {
      id: "SA",
      available_regions: null,
      full_name_locale: "Saudi Arabia",
      full_name_english: "Saudi Arabia",
      __typename: "Country",
    },
    {
      id: "SB",
      available_regions: null,
      full_name_locale: "Solomon Islands",
      full_name_english: "Solomon Islands",
      __typename: "Country",
    },
    {
      id: "SC",
      available_regions: null,
      full_name_locale: "Seychelles",
      full_name_english: "Seychelles",
      __typename: "Country",
    },
    {
      id: "SD",
      available_regions: null,
      full_name_locale: "Sudan",
      full_name_english: "Sudan",
      __typename: "Country",
    },
    {
      id: "SE",
      available_regions: null,
      full_name_locale: "Sweden",
      full_name_english: "Sweden",
      __typename: "Country",
    },
    {
      id: "SG",
      available_regions: null,
      full_name_locale: "Singapore",
      full_name_english: "Singapore",
      __typename: "Country",
    },
    {
      id: "SH",
      available_regions: null,
      full_name_locale: "Saint Helena",
      full_name_english: "Saint Helena",
      __typename: "Country",
    },
    {
      id: "SI",
      available_regions: null,
      full_name_locale: "Slovenia",
      full_name_english: "Slovenia",
      __typename: "Country",
    },
    {
      id: "SJ",
      available_regions: null,
      full_name_locale: "Svalbard and Jan Mayen",
      full_name_english: "Svalbard and Jan Mayen",
      __typename: "Country",
    },
    {
      id: "SK",
      available_regions: null,
      full_name_locale: "Slovakia",
      full_name_english: "Slovakia",
      __typename: "Country",
    },
    {
      id: "SL",
      available_regions: null,
      full_name_locale: "Sierra Leone",
      full_name_english: "Sierra Leone",
      __typename: "Country",
    },
    {
      id: "SM",
      available_regions: null,
      full_name_locale: "San Marino",
      full_name_english: "San Marino",
      __typename: "Country",
    },
    {
      id: "SN",
      available_regions: null,
      full_name_locale: "Senegal",
      full_name_english: "Senegal",
      __typename: "Country",
    },
    {
      id: "SO",
      available_regions: null,
      full_name_locale: "Somalia",
      full_name_english: "Somalia",
      __typename: "Country",
    },
    {
      id: "SR",
      available_regions: null,
      full_name_locale: "Suriname",
      full_name_english: "Suriname",
      __typename: "Country",
    },
    {
      id: "ST",
      available_regions: null,
      full_name_locale: "São Tomé and Príncipe",
      full_name_english: "São Tomé and Príncipe",
      __typename: "Country",
    },
    {
      id: "SV",
      available_regions: null,
      full_name_locale: "El Salvador",
      full_name_english: "El Salvador",
      __typename: "Country",
    },
    {
      id: "SY",
      available_regions: null,
      full_name_locale: "Syria",
      full_name_english: "Syria",
      __typename: "Country",
    },
    {
      id: "SZ",
      available_regions: null,
      full_name_locale: "Swaziland",
      full_name_english: "Swaziland",
      __typename: "Country",
    },
    {
      id: "TC",
      available_regions: null,
      full_name_locale: "Turks and Caicos Islands",
      full_name_english: "Turks and Caicos Islands",
      __typename: "Country",
    },
    {
      id: "TD",
      available_regions: null,
      full_name_locale: "Chad",
      full_name_english: "Chad",
      __typename: "Country",
    },
    {
      id: "TF",
      available_regions: null,
      full_name_locale: "French Southern Territories",
      full_name_english: "French Southern Territories",
      __typename: "Country",
    },
    {
      id: "TG",
      available_regions: null,
      full_name_locale: "Togo",
      full_name_english: "Togo",
      __typename: "Country",
    },
    {
      id: "TH",
      available_regions: null,
      full_name_locale: "Thailand",
      full_name_english: "Thailand",
      __typename: "Country",
    },
    {
      id: "TJ",
      available_regions: null,
      full_name_locale: "Tajikistan",
      full_name_english: "Tajikistan",
      __typename: "Country",
    },
    {
      id: "TK",
      available_regions: null,
      full_name_locale: "Tokelau",
      full_name_english: "Tokelau",
      __typename: "Country",
    },
    {
      id: "TL",
      available_regions: null,
      full_name_locale: "Timor-Leste",
      full_name_english: "Timor-Leste",
      __typename: "Country",
    },
    {
      id: "TM",
      available_regions: null,
      full_name_locale: "Turkmenistan",
      full_name_english: "Turkmenistan",
      __typename: "Country",
    },
    {
      id: "TN",
      available_regions: null,
      full_name_locale: "Tunisia",
      full_name_english: "Tunisia",
      __typename: "Country",
    },
    {
      id: "TO",
      available_regions: null,
      full_name_locale: "Tonga",
      full_name_english: "Tonga",
      __typename: "Country",
    },
    {
      id: "TR",
      available_regions: null,
      full_name_locale: "Turkey",
      full_name_english: "Turkey",
      __typename: "Country",
    },
    {
      id: "TT",
      available_regions: null,
      full_name_locale: "Trinidad and Tobago",
      full_name_english: "Trinidad and Tobago",
      __typename: "Country",
    },
    {
      id: "TV",
      available_regions: null,
      full_name_locale: "Tuvalu",
      full_name_english: "Tuvalu",
      __typename: "Country",
    },
    {
      id: "TW",
      available_regions: null,
      full_name_locale: "Taiwan",
      full_name_english: "Taiwan",
      __typename: "Country",
    },
    {
      id: "TZ",
      available_regions: null,
      full_name_locale: "Tanzania",
      full_name_english: "Tanzania",
      __typename: "Country",
    },
    {
      id: "UA",
      available_regions: null,
      full_name_locale: "Ukraine",
      full_name_english: "Ukraine",
      __typename: "Country",
    },
    {
      id: "UG",
      available_regions: null,
      full_name_locale: "Uganda",
      full_name_english: "Uganda",
      __typename: "Country",
    },
    {
      id: "UM",
      available_regions: null,
      full_name_locale: "U.S. Outlying Islands",
      full_name_english: "U.S. Outlying Islands",
      __typename: "Country",
    },

    {
      id: "UY",
      available_regions: null,
      full_name_locale: "Uruguay",
      full_name_english: "Uruguay",
      __typename: "Country",
    },
    {
      id: "UZ",
      available_regions: null,
      full_name_locale: "Uzbekistan",
      full_name_english: "Uzbekistan",
      __typename: "Country",
    },
    {
      id: "VA",
      available_regions: null,
      full_name_locale: "Vatican City",
      full_name_english: "Vatican City",
      __typename: "Country",
    },
    {
      id: "VC",
      available_regions: null,
      full_name_locale: "Saint Vincent and the Grenadines",
      full_name_english: "Saint Vincent and the Grenadines",
      __typename: "Country",
    },
    {
      id: "VE",
      available_regions: null,
      full_name_locale: "Venezuela",
      full_name_english: "Venezuela",
      __typename: "Country",
    },
    {
      id: "VG",
      available_regions: null,
      full_name_locale: "British Virgin Islands",
      full_name_english: "British Virgin Islands",
      __typename: "Country",
    },
    {
      id: "VI",
      available_regions: null,
      full_name_locale: "U.S. Virgin Islands",
      full_name_english: "U.S. Virgin Islands",
      __typename: "Country",
    },
    {
      id: "VN",
      available_regions: null,
      full_name_locale: "Vietnam",
      full_name_english: "Vietnam",
      __typename: "Country",
    },
    {
      id: "VU",
      available_regions: null,
      full_name_locale: "Vanuatu",
      full_name_english: "Vanuatu",
      __typename: "Country",
    },
    {
      id: "WF",
      available_regions: null,
      full_name_locale: "Wallis and Futuna",
      full_name_english: "Wallis and Futuna",
      __typename: "Country",
    },
    {
      id: "WS",
      available_regions: null,
      full_name_locale: "Samoa",
      full_name_english: "Samoa",
      __typename: "Country",
    },
    {
      id: "YE",
      available_regions: null,
      full_name_locale: "Yemen",
      full_name_english: "Yemen",
      __typename: "Country",
    },
    {
      id: "YT",
      available_regions: null,
      full_name_locale: "Mayotte",
      full_name_english: "Mayotte",
      __typename: "Country",
    },
    {
      id: "ZA",
      available_regions: null,
      full_name_locale: "South Africa",
      full_name_english: "South Africa",
      __typename: "Country",
    },
    {
      id: "ZM",
      available_regions: null,
      full_name_locale: "Zambia",
      full_name_english: "Zambia",
      __typename: "Country",
    },
    {
      id: "ZW",
      available_regions: null,
      full_name_locale: "Zimbabwe",
      full_name_english: "Zimbabwe",
      __typename: "Country",
    },
  ],
};
