import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import { gql } from "graphql.macro";

const ADD_PO_NUMBER_TO_CART_MUTATION = gql`
  mutation AddOrderNoteToCart($input: addOrderNoteToCartInput) {
    addOrderNoteToCart(input: $input)
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let input = {
      cart_id: cart_id,
      order_comment: action.payload.order_comment,
    };

    const { data } = await apolloClient.mutate({
      mutation: ADD_PO_NUMBER_TO_CART_MUTATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data.addOrderNoteToCart) {
      let newCart = {
        ...store.getState().customer.data.cart,
        order_comment: data.addOrderNoteToCart,
      };
      store.dispatch(customerActions.setCartInformation(newCart));
      store.dispatch(
        messagesActions.addMessage("updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
