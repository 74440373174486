import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import { CmsDataFragment } from "utils/cmsDataFragment";

const TextPart = ({
  secondHeading,
  textLeftSecond,
  textRightSecond,
  highlightedTextSecond,
  largeImage,
  link,
}) => {
  return (
    <MDBBox className="text-part-with-image">
      <MDBTypography tag="h2" variant="h1">
        <CmsDataFragment content={secondHeading} />
      </MDBTypography>
      <MDBRow className="no-gutters justify-content-between text-column">
        <MDBCol md="5">
          <MDBTypography tag="p" variant="p">
            <CmsDataFragment content={textLeftSecond} />
          </MDBTypography>
        </MDBCol>
        <MDBCol md="6">
          <MDBTypography tag="p" variant="p">
            <CmsDataFragment content={textRightSecond} />
          </MDBTypography>
          <MDBTypography tag="h6" variant="h6">
            <CmsDataFragment content={highlightedTextSecond} />
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow center className="large-image no-gutters">
        <MDBCol>
          <CmsDataFragment content={largeImage} />
          <CmsDataFragment content={link} />
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default TextPart;
