import React from "react";
import { Link } from "react-router-dom";

const CustomerInformation = ({ customer }) => {
  return (
    <div className="mb-5">
      <h6 className="mt-5 mb-5 h6">Contact information</h6>
      <p>
        {customer?.firstname} {customer?.lastname}
      </p>
      <p>{customer?.email}</p>
      <div className="links-acc-dash mt-3">
        <Link className="mr-4" to={"/customer/information"}>
          <b>
            <u>Edit</u>
          </b>
        </Link>
        <Link to={"customer/information"}>
          <b>
            <u>Change Password</u>
          </b>
        </Link>
      </div>
    </div>
  );
};

export default CustomerInformation;
