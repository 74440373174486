export default (state, action) => {
  return Object.assign({}, state, {
    data: {
      ...state.data,
      cart: {
        ...state.data.cart,
        ...action.payload,
      },
    },
  });
};
