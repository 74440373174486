import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBox } from "mdbreact";
import Button from "core/components/button";
import Select2 from "core/components/select";
import { findMaskByCountry } from "./functions";
import { validate, shouldMarkError } from "./validation";

const Toolbar = (props) => {
  let {
    countrySelect,
    selectState,
    countries,
    states,
    setSelectStateFn,
    setSelectCountryFn,
    setCanSearch,
    countriesAndStates,
  } = props;
  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };
  const [maskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );

  let errors = validate(countrySelect, selectState, maskSanitizedArray);
  const [touched, setTouched] = useState({
    countrySelect: false,
    selectState: false,
  });
  const showResults = () => {
    setTouched({
      countryselect: true,
      selectstate: true,
    });
    setCanSearch(true);
  };

  //filtered states for selected country
  const [filteredStatesArray, setFilteredStatesArray] = useState(states);
  let filteredStates = [];
  useEffect(() => {
    countriesAndStates.map((country) => {
      if (country.value === countrySelect.value) {
        country.states.map((state) => {
          filteredStates.push(state);
        });
      }
    });
    setFilteredStatesArray(filteredStates);
    setSelectStateFn("");
  }, [countrySelect]);

  filteredStatesArray.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <MDBBox className="mb-3" tag="h1">
        Find Sales Rep
      </MDBBox>
      <MDBRow fluid className={`align-items-center find-rep-toolbar mt-5 `}>
        <MDBCol lg="3" className="select2-container">
          <span className="select2-label">Country</span>
          <Select2
            name={"country"}
            type={"text"}
            value={countrySelect}
            onChange={(event) => {
              setSelectCountryFn(event);
            }}
            required={true}
            placeholder={"Select Country"}
            options={countries}
            useCustom={true}
            isSearchable={true}
            onBlur={(e) => {
              setTouched({ ...touched, countryselect: true });
            }}
            className={
              shouldMarkError("countryselect", errors, touched)
                ? "validation-error"
                : ""
            }
            wrapperClass={"country-select"}
          />
        </MDBCol>

        <MDBCol lg="3" className="select2-container">
          <span className="select2-label">
            {countrySelect?.value?.toLowerCase() === "canada"
              ? "Province"
              : "State"}
          </span>
          <Select2
            wrapperClass={!countrySelect && "disabled"}
            name={"state"}
            type={"text"}
            required={true}
            value={selectState}
            onChange={(event) => setSelectStateFn(event)}
            placeholder={
              countrySelect?.value?.toLowerCase() === "canada"
                ? "Select Province"
                : "Select State"
            }
            options={countrySelect ? filteredStatesArray : states}
            isSearchable={true}
            onBlur={(e) => {
              setTouched({ ...touched, selectstate: true });
            }}
            className={
              shouldMarkError("selectstate", errors, touched)
                ? "validation-error"
                : ""
            }
          />
        </MDBCol>
        <MDBCol lg="6" className="button-container">
          <Button
            disabled={selectState === "" && true}
            className="search-button"
            onClick={() => showResults()}
          >
            Search
          </Button>
          <Button
            className="reset-btn"
            onClick={() => {
              setSelectCountryFn("");
              setSelectStateFn("");
            }}
          >
            Reset
          </Button>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Toolbar;
