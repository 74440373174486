import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";

export default async (store, action) => {
  let cart_id = store.getState().customer.data.cartToken;
  let items = store.getState().customer.data.cart.items.map((item) => ({
    cart_item_id: item.id,
    quantity: 0,
  }));

  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("updateCart"),
      variables: {
        cart_id,
        items,
      },
    });
    if (data.updateCartItems !== null) {
      store.dispatch(
        customerActions.setCartInformation(data.updateCartItems.cart)
      );
      store.dispatch(commonActions.unlock("success"));
      store.dispatch(
        messagesActions.addMessage("cart has been cleared", "sucess")
      );
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
