import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import queryActions from "../../queries/actions";
import queryLoader from "core/graphql/queryLoader";
import { messagesActions } from "../../messages";
import { commonActions } from "../../common";

export default async (store, action) => {
  console.log("this is for core middleware");
  try {
    if (!action.email || !action.password) {
      throw new Error("Invalid email or password");
    }

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("userLogin"),
      variables: { email: action.email, password: action.password },
      fetchPolicy: "no-cache",
    });

    if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
      console.log("GraphQL response", data);
      throw new Error("No customer token received");
    }

    store.dispatch(
      customerActions._setCustomerToken(
        data.generateCustomerToken.token,
        action.cartToken
      )
    );
  } catch (error) {
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "login")
    );
    store.dispatch(queryActions.removeQuery());
    // store.dispatch(customerActions._reduceCustomer());
    return;
  }
};
