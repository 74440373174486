// this is custom style for this section, not best practice,
//  designer should adopt bootstrap standards in order to avoid this kind of code

export const currentStyleFunction = (position) => {
  let currentPosition = "";

  if (position === "1") {
    currentPosition = "position-one";
  } else if (position === "2") {
    currentPosition = "position-two";
  } else if (position === "3") {
    currentPosition = "position-three";
  } else if (position === "4") {
    currentPosition = "position-four";
  } else if (position === "5") {
    currentPosition = "position-five";
  } else if (position === "6") {
    currentPosition = "position-six";
  } else {
    currentPosition = "other-position";
  }

  return {
    currentPosition,
  };
};
