import React, { useState, memo } from "react";
import { unescape, isEqual } from "lodash";
import { MDBRow, MDBCol } from "mdbreact";

const ParametersSection = memo(
  ({ data }) => {
    const customItemParameters = [
      data.custom_item_option_1,
      data.custom_item_option_2,
      data.custom_item_option_3,
      data.custom_item_option_4,
      data.custom_item_option_5,
      data.custom_item_option_6,
      data.custom_item_option_7,
      data.custom_item_option_8,
      data.custom_item_option_9,
      data.custom_item_option_10,
    ];
    const [activeParam, setActiveParam] = useState(0);

    const regexTitles = /{"(.*?)":"/gim;
    const regexContent = /{"(.*?)":"(.*)/gim;
    const titlesArray =
      customItemParameters &&
      customItemParameters
        .map((item) => item && regexTitles.exec(item.match(regexTitles)))
        .map((item) => item && item[1].replace("/", ""));
    const contentsArray =
      customItemParameters &&
      customItemParameters
        .map((item) => item && regexContent.exec(item.match(regexContent)))
        .map((item) => item && item[2])
        .map(
          (item) =>
            item &&
            item
              .replace(/\\\/watt/g, "/watt")
              .replace(/&lt;\\\/li&gt;/g, "")
              .replace(/\\\//g, "/")
              .replace('"}', "")
        );
    const content = contentsArray[activeParam];
    return (
      <>
        <MDBRow className="no-gutters parameters-section-wrapper">
          <MDBCol md="6" className="left-section">
            {titlesArray.map((title, index) => {
              return (
                <p
                  style={{ fontSize: "1.6rem" }}
                  className={activeParam === index ? "active" : "not-active"}
                  onClick={() => {
                    setActiveParam(index);
                  }}
                >
                  {title}
                </p>
              );
            })}
          </MDBCol>
          <MDBCol md="6" className="right-section">
            <ul
              dangerouslySetInnerHTML={{
                __html: unescape(content),
              }}
            />
          </MDBCol>
        </MDBRow>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ParametersSection;
