import React, { useState } from "react";
import { fragmentContentById } from "utils/fragmentContentById";
import { CmsDataFragment } from "./forgot-password-query-container";
import {
  MDBContainer,
  MDBBox,
  MDBRow,
  MDBTypography,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBInputGroup,
} from "mdbreact";
import "./style.scss";
import recaptcha from "./recaptcha.png";
import { Redirect } from "react-router-dom";
import PrimaSpinner from "app/layout/primaSpinner";

const ForgotPassword = ({ data, contentMap, ...props }) => {
  const [email, setEmail] = useState("");
  const [hasEmailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const onEmailChangeHandler = (e) => setEmail(e.target.value);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;
    if (!email) {
      setEmailError(true);
      setEmailErrorMsg("This field is required");
    } else if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailErrorMsg("Invalid email");
    } else {
      setEmailError(false);
      setEmailErrorMsg("");
      setEmail("");
      setLoading(true);
      props.lock({
        always: () => {},
        success: () => {
          setLoading(false);
          setTimeout(() => setShouldRedirect(true), 3000);
        },
        fail: () => {
          setLoading(false);
        },
      });
      props.onForgotPassword(email);
    }
  };

  const content = {
    title: fragmentContentById("forgot_pass_title", data, contentMap),
    text: fragmentContentById("forgot_pass_text", data, contentMap),
    buttonText: fragmentContentById(
      "forgot_pass_button_text",
      data,
      contentMap
    ),
    image: fragmentContentById("forgot_pass_image", data, contentMap),
  };

  if (shouldRedirect) return <Redirect to="/" />;

  return (
    <MDBContainer fluid className="container-forgot-password">
      <MDBBox tag="div" className="forgot-password">
        <CmsDataFragment content={content.image} />
        <form onSubmit={handleSubmit} id="PL-forgot-password-form">
          <MDBTypography
            tag="h1"
            variant="h1"
            className="pb-4 main-title-forgot-pass"
          >
            <CmsDataFragment content={content.title} />
          </MDBTypography>
          <MDBTypography tag="h3" className="pb-4">
            <CmsDataFragment content={content.text} />
          </MDBTypography>
          <MDBRow center>
            <MDBCol
              size="12"
              xl="6"
              lg="6"
              md="10"
              sm="12"
              className="input-field-email"
            >
              <MDBInputGroup
                value={email}
                onChange={onEmailChangeHandler}
                required={true}
                type="email"
                material
                hint="Enter Email * "
                label="Email"
                labelClassName={
                  hasEmailError
                    ? `input-label-email input-label  error-label`
                    : `input-label-email input-label `
                }
                className={
                  hasEmailError ? "error-input email-input" : "email-input"
                }
              />
              {hasEmailError && (
                <MDBTypography colorText="red">{emailErrorMsg}</MDBTypography>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow center className="pt-5 pera">
            <MDBCol center md="5" className="pt-1 pb-1 left">
              <MDBInput
                required={true}
                label="I am not a robot"
                type="checkbox"
                id="checkbox1"
              />
              <span className="recaptcha">
                <img src={recaptcha} alt="" />
              </span>
            </MDBCol>
            <MDBCol center md="5" className="resset-btn">
              {loading ? (
                <PrimaSpinner style={{ marginLeft: "2rem" }} />
              ) : (
                <MDBBtn className="right-button" type="submit">
                  <CmsDataFragment content={content.buttonText} />
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
        </form>
      </MDBBox>
    </MDBContainer>
  );
};

export default ForgotPassword;
