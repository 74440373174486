import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import QuoteRequestItemStateContainer from "./state-container";
import { customerActions } from "core/state/redux/data/customer";

const QuoteRequestItemReduxContainer = (props) => {
  return <QuoteRequestItemStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  productOptions:
    state.queries !== undefined
      ? state.queries.data !== undefined
        ? typeof state.queries.data.attributes_metadata_information !==
          "undefined"
          ? state.queries.data.attributes_metadata_information.data
              .customAttributeMetadata.items[0]
          : []
        : []
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestItemReduxContainer);
