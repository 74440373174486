import React from "react";
import { MDBBox } from "mdbreact";
import { useQuery } from "@apollo/react-hooks";
import ReactLoader from "app/layout/loader";
import Component from "./dynamicGraphQlWrapper";
import ErrorPage from "app/pages/error";
import apolloClient, { apolloClientMutation } from "app/graphql/apolloClient";
import useCache from "app/state/hooks/cacheHooks/useCache";

const DynamicGraphQlWrapperQueryFetchContainer = (props) => {
  //console.log("DynamicGraphQlWrapperQueryFetchContainer", props);
  const { saveNewEntry } = useCache();
  let LoadingComponent =
    typeof props.children.props.loadingComponent !== "undefined"
      ? props.children.props.loadingComponent
      : ReactLoader;

  const { loading, error, data } = useQuery(props.query, {
    variables: props.variables,
    client: props.request
      ? props.request === "POST"
        ? apolloClientMutation
        : apolloClient
      : apolloClient,
  });

  if (loading)
    return (
      <MDBBox>
        {typeof LoadingComponent !== "undefined" ? (
          <LoadingComponent
            {...(typeof props.children.props.loadingComponent !== "undefined"
              ? props.children.props
              : {})}
          />
        ) : (
          <></>
        )}
      </MDBBox>
    );

  if (error) return <MDBBox>Error: {error.message}</MDBBox>;

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, { ...props, data: data });
  });
  //console.log("DynamicGraphQlWrapperQueryFetchContainer+++++++++++++++++DATA", data);
  if (!props.isCached && typeof data !== "undefined") {
    //console.log('prvi if')
    if ("urlResolver" in data) {
     // console.log('drugi if');
      if (data.urlResolver === null) {
    //    console.log("ata.urlResolver.type === CMS_PAGE");
        return <ErrorPage {...props} />;
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CMS_PAGE" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] === "undefined"
      ) {
     //   console.log("ata.urlResolver.type === CMS_PAGE");
        props.addQueryData(data, props.variables.url, data.urlResolver.id);
      } else if (

        data.urlResolver.id &&
        data.urlResolver.type === "CATEGORY" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] !== "undefined"
      ) {
      //  console.log("in elese where !urlResolver and CmsPage 9 ");
        props.addQueryData(
          { url_key: data.urlResolver.relative_url },
          props.variables.url,
          data.urlResolver.id
        );
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "PRODUCT" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] === "undefined"
      ) {
        console.log("ata.urlResolver.type === PRODUCT");
        props.addQueryData(data, props.variables.url, data.urlResolver.id);
      }
    } else if ("cmsPage" in data) {
     // console.log("in elese where !urlResolver and CmsPage10");
      if (typeof data.cmsPage !== "undefined") {
        saveNewEntry(
          data,
          typeof props.variables !== "undefined"
            ? props.variables.url
            : window.location.pathname
        );
      }
    }else{
    //  console.log("nowhere")
    }
  } else {
  //  console.log("in elese where !urlResolver and CmsPage");
    if (typeof data.cmsPage !== "undefined") {
      saveNewEntry(data, props.variables.url);
    }
  }
  //console.log("before Component log");
  return <Component {...props}>{children}</Component>;
};

export default DynamicGraphQlWrapperQueryFetchContainer;
