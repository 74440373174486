// Action Creators
import types from "./types";

const getCacheVersion = (cache, flag) => {
  return {
    type: types.GET_CACHE_VERSION,
    version: cache,
    flag: flag,
  };
};

const setCacheVersion = (cache) => {
  return {
    type: types.SET_CACHE_VERSION,
    version: cache,
  };
};

const setFlag = (flag) => {
  return {
    type: types.SET_FLAG,
    flag: flag,
  };
};

const addDataToCache = (payload) => {
  return {
    type: types.ADD_DATA_TO_CACHE,
    payload: payload,
  };
};

const clearDataFromCache = () => {
  return {
    type: types.CLEAR_DATA_FROM_CACHE,
  };
};

export default {
  getCacheVersion,
  setCacheVersion,
  setFlag,
  addDataToCache,
  clearDataFromCache,
};
