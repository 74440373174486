import React from "react";
import { MDBNavbarBrand, MDBLink } from "mdbreact";
import PrimaLogo from "app/assets/images/logo-black.png";
import PrimaLogoMobile from "app/assets/icon/primaLogoPdf.svg";

const Logo = ({ mobileLogo }) => {
  return (
    <MDBNavbarBrand className="logo">
      <MDBLink to={"/"} className="p-0">
        <img src={mobileLogo ? PrimaLogoMobile : PrimaLogo} alt="" />
      </MDBLink>
    </MDBNavbarBrand>
  );
};

export default Logo;
