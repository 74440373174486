import {
  SET_EMAIL,
  SET_PASSWORD,
  SET_NAME,
  SET_LAST_NAME,
  SET_COMPANY,
  SET_PHONE,
  SET_ADDRESS,
  SET_COUNTRY,
  SET_CITY,
  SET_STATE,
  SET_POSTAL_CODE,
  SET_LOADING,
  SET_EMAIL_ERROR,
  SET_PASSWORD_ERROR,
  SET_NAME_ERROR,
  SET_LAST_NAME_ERROR,
  SET_COMPANY_ERROR,
  SET_PHONE_ERROR,
  SET_ADDRESS_ERROR,
  SET_COUNTRY_ERROR,
  SET_CITY_ERROR,
  SET_STATE_ERROR,
  SET_POSTAL_CODE_ERROR,
  SET_EMAIL_ERROR_MSG,
  SET_PASSWORD_ERROR_MSG,
  SET_NAME_ERROR_MSG,
  SET_LAST_NAME_ERROR_MSG,
  SET_COMPANY_ERROR_MSG,
  SET_PHONE_ERROR_MSG,
  SET_ADDRESS_ERROR_MSG,
  SET_COUNTRY_ERROR_MSG,
  SET_CITY_ERROR_MSG,
  SET_STATE_ERROR_MSG,
  SET_POSTAL_CODE_ERROR_MSG,
} from "./actions";

export const initialState = {
  email: "",
  password: "",
  name: "",
  lastName: "",
  company: "",
  phone: "",
  address: "",
  country: "",
  city: "",
  state: "",
  postalCode: "",

  loading: false,
  emailError: false,
  passwordError: false,
  nameError: false,
  lastNameError: false,
  companyError: false,
  phoneError: false,
  addressError: false,
  countryError: false,
  cityError: false,
  stateError: false,
  postalCodeError: false,

  emailErrorMsg: "",
  passwordErrorMsg: "",
  nameErrorMsg: "",
  lastNameErrorMsg: "",
  companyErrorMsg: "",
  phoneErrorMsg: "",
  addressErrorMsg: "",
  countryErrorMsg: "",
  cityErrorMsg: "",
  stateErrorMsg: "",
  postalCodeErrorMsg: "",
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL:
      return { ...state, email: payload };
    case SET_PASSWORD:
      return { ...state, password: payload };
    case SET_NAME:
      return { ...state, name: payload };
    case SET_LAST_NAME:
      return { ...state, lastName: payload };
    case SET_COMPANY:
      return { ...state, company: payload };
    case SET_PHONE:
      return { ...state, phone: payload };
    case SET_ADDRESS:
      return { ...state, address: payload };
    case SET_COUNTRY:
      return { ...state, country: payload };
    case SET_CITY:
      return { ...state, city: payload };
    case SET_STATE:
      return { ...state, state: payload };
    case SET_POSTAL_CODE:
      return { ...state, postalCode: payload };
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_EMAIL_ERROR:
      return { ...state, emailError: payload };
    case SET_PASSWORD_ERROR:
      return { ...state, passwordError: payload };
    case SET_NAME_ERROR:
      return { ...state, nameError: payload };
    case SET_LAST_NAME_ERROR:
      return { ...state, lastNameError: payload };
    case SET_COMPANY_ERROR:
      return { ...state, companyError: payload };
    case SET_PHONE_ERROR:
      return { ...state, phoneError: payload };
    case SET_ADDRESS_ERROR:
      return { ...state, addressError: payload };
    case SET_COUNTRY_ERROR:
      return { ...state, countryError: payload };
    case SET_CITY_ERROR:
      return { ...state, cityError: payload };
    case SET_STATE_ERROR:
      return { ...state, stateError: payload };
    case SET_POSTAL_CODE_ERROR:
      return { ...state, postalCodeError: payload };

    case SET_EMAIL_ERROR_MSG:
      return { ...state, emailErrorMsg: payload };
    case SET_PASSWORD_ERROR_MSG:
      return { ...state, passwordErrorMsg: payload };
    case SET_NAME_ERROR_MSG:
      return { ...state, nameErrorMsg: payload };
    case SET_LAST_NAME_ERROR_MSG:
      return { ...state, lastNameErrorMsg: payload };
    case SET_COMPANY_ERROR_MSG:
      return { ...state, companyErrorMsg: payload };
    case SET_PHONE_ERROR_MSG:
      return { ...state, phoneErrorMsg: payload };
    case SET_ADDRESS_ERROR_MSG:
      return { ...state, addressErrorMsg: payload };
    case SET_COUNTRY_ERROR_MSG:
      return { ...state, countryErrorMsg: payload };
    case SET_CITY_ERROR_MSG:
      return { ...state, cityErrorMsg: payload };
    case SET_STATE_ERROR_MSG:
      return { ...state, stateErrorMsg: payload };
    case SET_POSTAL_CODE_ERROR_MSG:
      return { ...state, postalCodeErrorMsg: payload };

    default:
      return state;
  }
};
