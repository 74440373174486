export const togglePdfBox = (setVisiblePdfBox, visiblePdfBox) => {
  setVisiblePdfBox(!visiblePdfBox);
};
export const toggleModal = (setModal, modal) => {
  setModal(!modal);
};
export const toggleDesc = (setDescOpen, descOpen) => {
  setDescOpen(!descOpen);
};
export const toggleParams = (setParamsOpen, paramsOpen) => {
  setParamsOpen(!paramsOpen);
};
export const toggleSizes = (setSizesOpen, sizesOpen) => {
  setSizesOpen(!sizesOpen);
};
