import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import FindARepComponentContainer from "./find-a-rep-component-container";
import CmsBlock from "app/layout/cmsBlock";
import Form from "./form/index";

const FindRep = (props) => {
  props.repLocators.sort((a, b) => {
    return a.position - b.position;
  });

  return (
    <MDBRow
      fluid
      className={"find-rep-container w-100 d-flex justify-content-center"}
    >
      <MDBCol xl="8" lg="12" className="image-and-toolbar-wrapper">
        <CmsBlock id={"find_a_rep_image"} />
        <FindARepComponentContainer {...props} />
      </MDBCol>
      <MDBCol xl="4" lg="12">
        <Form formId={"bdf021a2-1a69-4885-a4b5-6c51d077c399"}/>
      </MDBCol>
    </MDBRow>
  );
};

export default FindRep;
