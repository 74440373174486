import React, { memo, useEffect, useState } from "react";
import SearchResults from "./searchResults";
import { useQuery } from "react-apollo-hooks";
// import { PRODUCTS } from "./queries";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import { klevuSearch } from "../klevu/klevuSearch";
import queryLoader from "app/graphql/queryLoader";

const query = queryLoader("klevuConfig");

const SearchResultsQueryContainer = memo(
  ({
    search,
    allCategories,
    closeSearch,
    mobileonclick,
    changeSearch,
    setDisplaySearch,
    tmpSearch,
  }) => {
    const mobileOrTablet = useMediaQuery({ maxWidth: 1024 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const { data: klevuConfigData, error: klevuConfigError } = useQuery(query, {
      fetchPolicy: "cache-first",
    });

    useEffect(() => {
      if (!klevuConfigData || tmpSearch?.length < 3) return;
      setLoading(true);
      const fetchData = async () => {
        let data = await klevuSearch(tmpSearch, klevuConfigData?.klevuConfig);
        try {
          setLoading(false);
          const { queryResults } = data;
          setData(
            queryResults?.find((data) => data.id === "productSearch")
              ?.records ?? []
          );
        } catch (error) {
          setLoading(false);
          setError(error);
        }
      };
      fetchData();
    }, [tmpSearch, klevuConfigData]);

    // const { data, loading, error } = useQuery(PRODUCTS, {
    //   variables: {
    //     search: `${tmpSearch}`,
    //     pageSize: mobileOrTablet ? "3" : "5",
    //   },
    //   fetchPolicy: "cache-first",
    // });

    if (loading || error)
      return (
        <SearchResults
          data={{ products: { items: [] } }}
          search={search}
          allCategories={allCategories}
          closeSearch={closeSearch}
          loading={loading}
          setDisplaySearch={setDisplaySearch}
          tmpSearch={tmpSearch}
        />
      );

    return (
      <SearchResults
        data={data}
        search={search}
        allCategories={allCategories}
        closeSearch={closeSearch}
        loading={loading}
        mobileonclick={mobileonclick}
        changeSearch={changeSearch}
        setDisplaySearch={setDisplaySearch}
        tmpSearch={tmpSearch}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default SearchResultsQueryContainer;
