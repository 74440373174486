import React from "react";
import Video from "./video";
import ReactHtmlParser from "react-html-parser";

const VideoDataContainer = (props) => {
  let videoUrl = props?.data?.cmsBlocks?.items[0].content;
  let finalUrl = ReactHtmlParser(videoUrl)[0]?.props?.children;


  return (
    <>
      <Video videoUrl={finalUrl} />
    </>
  );
};

export default VideoDataContainer;
