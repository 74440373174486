import React, { useMemo } from "react";
import { MDBCol, Link, MDBTypography, MDBBox } from "mdbreact";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import placeholder from "app/assets/images/placeholder.png";
import queryLoader from "app/graphql/queryLoader";
import { useQuery } from "react-apollo";

const query = queryLoader("klevuSearchProductUrl");

const SingleProduct = ({
  image,
  name,
  sku,
  url,
  price,
  categories,
  id,
  imageUrl,
  custitem_closeout,
  ark_closeout
}) => {
  const { isLoggedIn } = useCustomer();
  const { data, error } = useQuery(query, {
    fetchPolicy: "cache-first",
    variables: { product_id: id },
    skip: !imageUrl,
  });



  // let full_url = "";
  // categories.forEach((element) => {
  //   full_url = full_url + element.url_key + "/";
  // });
  // full_url = full_url + url;

  const finalUrl = useMemo(() => {
    if (data?.klevuSearchProductUrl?.product_url) {
      let url = data?.klevuSearchProductUrl?.product_url.split("/");
      return `/${url[url.length - 1]}`;
    }
    return "/";
  }, [data]);

  return (
    <MDBCol className="single-product justify-content-between text-center p-0 pb-4">
      <MDBBox className={`flex-1`}>
        {custitem_closeout  ? (
          <div className={"closeout-item"}>Limited Inventory</div>
        ) : null}
        {ark_closeout  ? (
            <div className={"closeout-item"}>Clearance</div>
        ) : null}
        <Link to={!imageUrl ? `/${url}` : finalUrl}>
          <img
              style={{width: '200px',maxWidth:"200px", height:"200px", minHeight:"200px"} }
            src={
              (image?.url
                ? image?.url
                : imageUrl?.includes("placeholder")
                ? "/public/placeholder.png"
                : imageUrl?.replace("needtochange/", "")) || placeholder
            }
            className={`img-fluid`}
          />
        </Link>
      </MDBBox>
      <Link
        to={!imageUrl ? `/${url}` : finalUrl}
        className={`flex-1 d-flex justify-content-between flex-column`}
      >
        <MDBCol
          className={`d-flex flex-1 p-0 justify-content-center align-items-center`}
        >
          <MDBTypography tag="h2" className="mt-3 mb-4 d-inline">
            {name}
          </MDBTypography>
        </MDBCol>
        <MDBCol
          className={`flex-1 p-0 justify-content-center d-flex align-items-center`}
        >
          <MDBTypography tag="p">SKU {sku}</MDBTypography>
          {isLoggedIn && price && (
            <MDBTypography tag="p">${price}</MDBTypography>
          )}
        </MDBCol>
      </Link>
    </MDBCol>
  );
};

export default SingleProduct;
