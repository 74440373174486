import React, { useState, createContext, useCallback } from "react";

const MiniCartContext = createContext({
  showMiniCart: false,
  setShowMiniCart: () => {},
  toggleMiniCart: () => {},
  displayMiniCart: () => {},
  hideMiniCart: () => {},
  showMiniCartFn: () => {},
});

function MiniCartProvider(props) {
  const [showMiniCart, setShowMiniCart] = useState(false);

  const toggleMiniCart = () => {
    setShowMiniCart(!showMiniCart);
  };

  const displayMiniCart = useCallback(() => {
    setShowMiniCart(!showMiniCart);
  }, [setShowMiniCart, showMiniCart]);

  const hideMiniCart = useCallback(() => {
    if (showMiniCart) {
      setShowMiniCart(false);
    }
  }, [setShowMiniCart, showMiniCart]);

  const showMiniCartFn = useCallback(() => {
    if (!showMiniCart) {
      setShowMiniCart(true);
    }
  }, [setShowMiniCart, showMiniCart]);

  return (
    <MiniCartContext.Provider
      value={{
        showMiniCart,
        toggleMiniCart,
        displayMiniCart,
        hideMiniCart,
        showMiniCartFn,
      }}
    >
      {props.children}
    </MiniCartContext.Provider>
  );
}

export { MiniCartContext, MiniCartProvider };
