import React from "react";
import { MDBRow, MDBCol, MDBTypography } from "mdbreact";
import { Link } from "react-router-dom";

export const CatWidget = ({ cat, size = 12, lg = 6, titleTag = "h4" }) => {
  return (
    <MDBCol size={size} lg={lg} className="pb-md-5 cat-widget">
      <Link to={"/" + cat?.url_path}>
        <MDBRow className="flex-grow">
          <MDBCol className="flex-center">
            <img className="img-fluid" src={cat?.image} alt={cat.name} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-5">
          <MDBCol>
            <MDBTypography tag={titleTag} className={titleTag}>
              {cat.name}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </Link>
    </MDBCol>
  );
};
