import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const ContactLargeImage = () => {
  return <CmsBlock id={"contact_large_image"} />;
};

export const ContactSmallImage = () => {
  return <CmsBlock id={"contact_small_image"} />;
};

export const ContactAddress = () => {
  return <CmsBlock id={"contact_address"} />;
};

export const ContactPhone = () => {
  return <CmsBlock id={"contact_phone"} />;
};

export const ContactEmail = () => {
  return <CmsBlock id={"contact_email"} />;
};

export const ContactBusinessHours = () => {
  return <CmsBlock id={"contact_business_hours"} />;
};

export const ContactFacebook = () => {
  return <CmsBlock id={"contact_facebook"} />;
};

export const ContactInstagram = () => {
  return <CmsBlock id={"contact_instagram"} />;
};

export const ContactPinterest = () => {
  return <CmsBlock id={"contact_pinterest"} />;
};

export const ContactYoutube = () => {
  return <CmsBlock id={"contact_youtube"} />;
};
