import React, { useState } from "react";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { MDBBox } from "mdbreact";
import ModalWrapper from "app/layout/login/modalWrapper";
import MobileMenuBottom from "./mobileMenuBottom";
import MobileMenuTop from "./mobileMenuTop";
import "./style.scss";

const MobileNav = ({ toggleMobileMenu, menuItems, closeBurger }) => {
  const { isLoggedIn } = useCustomer();
  const [activeCollapse, setActiveCollapse] = useState(-1);
  const [activeItem, setActiveItem] = useState("1");
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  const items =
    menuItems && menuItems.children.filter((item) => item.include_in_menu);

  return (
    <>
      <MDBBox fluid className="m-0 p-0 mobile-menu-container">
        <MobileMenuTop
          menuItems={items}
          activeCollapse={activeCollapse}
          setActiveCollapse={setActiveCollapse}
          toggleMobileMenu={toggleMobileMenu}
          closeBurger={closeBurger}
        />
        <MobileMenuBottom
          isLoggedIn={isLoggedIn}
          toggleModal={toggleModal}
          setActiveItem={setActiveItem}
          toggleMobileMenu={toggleMobileMenu}
        />
      </MDBBox>
      <ModalWrapper
        toggleModal={toggleModal}
        modal={!isLoggedIn && modal}
        activeItem={activeItem}
        toggleTabs={toggleTabs}
      />
    </>
  );
};

export default MobileNav;
