import React from "react";
import CmsBlock from "app/layout/cmsBlock";

export const UpcomingMainTitle = () => {
  return <CmsBlock id={"upcoming_main_title"} />;
};
export const UpcomingImage = () => {
  return <CmsBlock id={"upcoming_image"} />;
};
export const UpcomingDateTitle = () => {
  return <CmsBlock id={"upcoming_date_title"} />;
};
export const UpcomingContent = () => {
  return <CmsBlock id={"upcoming_content"} />;
};
