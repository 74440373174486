import React, { useState, memo } from "react";
import Config from "config/themeConfiguration";
import SearchQueryContainer from "./query-container";

const SearchStateContainer = memo((props) => {
  let [pageSize, setPageSize] = useState(Config.pageSizeOptions[0].value);
  let [currentPage, setCurrentPage] = useState(1);
  let [currentSort, setCurrentSort] = useState(Config.pageSortOptions[0].value);
  const [sortSelected, setSortSelected] = useState({
    custitemsort_date: "",
  });

  const queryProps = {
    currentSort,
    pageSize,
    currentPage,
    sort: currentSort,
    categoryId: props?.category?.id || 2,
  };

  const handleSetPageSize = (pageSize) => {
    setPageSize(pageSize);
  };
  const handleCurrentSort = (pageSort) => {
    setCurrentSort(pageSort);
  };

  const toolbarProps = {
    name: props?.category?.name || "",
    pageSize,
    setPageSize: handleSetPageSize,
    currentPage,
    setCurrentPage,
    pageSizeOptions: Config.pageSizeOptions,
    currentSort,
    setCurrentSort: handleCurrentSort,
    sortSelected,
    setSortSelected,
    pageSortOptions: Config.pageSortOptions,
  };

  const listingProps = { items: [] };

  const displayProps = {
    queryProps,
    toolbarProps,
    listingProps,
  };
  return <SearchQueryContainer {...displayProps} sortSelected={sortSelected} />;
});

export default SearchStateContainer;
