import React from "react";
import { useQuery } from "react-apollo-hooks";
import FaqPage from "./faqPage";
import { GET_FAQ } from "./query";

const FaqPageQueryContainer = (props) => {
  const { data, loading, error } = useQuery(GET_FAQ);
  let getFaq = data?.getFAQ ? data.getFAQ : [];

  return <FaqPage getFaq={getFaq} {...props} loading={loading} error={error} />;
};

export default FaqPageQueryContainer;
