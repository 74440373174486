import React, { useState, memo } from "react";
import { unescape } from "lodash";
import {
  MDBRow,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from "mdbreact";

const ParametersSectionMobile = memo(({ data }) => {
  const customItemParameters = [
    data.custom_item_option_1,
    data.custom_item_option_2,
    data.custom_item_option_3,
    data.custom_item_option_4,
    data.custom_item_option_5,
    data.custom_item_option_6,
    data.custom_item_option_7,
    data.custom_item_option_8,
    data.custom_item_option_9,
    data.custom_item_option_10,
  ];
  const [activeParam, setActiveParam] = useState(0);

  const regexTitles = /{"(.*?)":"/gim;
  const regexContent = /{"(.*?)":"(.*)/gim;
  const titlesArray =
    customItemParameters &&
    customItemParameters
      .map((item) => item && regexTitles.exec(item.match(regexTitles)))
      .map((item) => item && item[1].replace("/", ""));

  const contentsArray =
    customItemParameters &&
    customItemParameters
      .map((item) => item && regexContent.exec(item.match(regexContent)))
      .map((item) => item && item[2])
      .map(
        (item) =>
          item &&
          item
            .replace(/\\\/watt/g, "/watt")
            .replace(/&lt;\\\/li&gt;/g, "")
            .replace(/\\\//g, "/")
            .replace('"}', "")
      );
  const content = contentsArray[activeParam];

  const [activeItem, setActiveItem] = useState({});
  const [parametersHeading, setParametersHeading] = useState(false);

  const toggle = (id) =>
    setActiveItem((prevState) => ({
      activeItem: prevState.activeItem !== id ? id : "",
    }));

  const toggleParametersHeading = () => {
    setParametersHeading(!parametersHeading);
  };

  return (
    <MDBRow className="no-gutters parameters-section-wrapper-mobile">
      {titlesArray && (
        <MDBCard>
          <MDBCollapseHeader
            onClick={() => {
              toggleParametersHeading();
            }}
          >
            Parameters
            <MDBIcon icon={parametersHeading ? "minus" : "plus"} />
          </MDBCollapseHeader>
          <MDBCollapse isOpen={parametersHeading}>
            <MDBCardBody>
              {titlesArray.map((title, index) => {
                if (title === "") return;

                return (
                  <MDBCard className="mt-3 parameters-sub-category">
                    <MDBCollapseHeader
                      onClick={() => {
                        toggle(index);
                      }}
                    >
                      {title}
                      <MDBIcon
                        icon={
                          activeItem.activeItem === index ? "minus" : "plus"
                        }
                      />
                    </MDBCollapseHeader>

                    <MDBCollapse id={index} isOpen={activeItem.activeItem}>
                      <MDBCardBody>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: unescape(content),
                          }}
                        />
                      </MDBCardBody>
                    </MDBCollapse>
                  </MDBCard>
                );
              })}
            </MDBCardBody>
          </MDBCollapse>
        </MDBCard>
      )}
    </MDBRow>
  );
});

export default ParametersSectionMobile;
