import gql from "graphql-tag";

export const GET_FAQ = gql`
  query getFAQ {
    getFAQ {
      question_id
      title
      mainCategoryInfo {
        category_title
        category_id
        position
      }
      items {
        answer_id
        title
        pdf {
          title
          url
        }
      }
    }
  }
`;
