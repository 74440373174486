import React from "react";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdbreact";

const AdditionalEntries = ({ additionalEntries }) => {
  return (
    <>
      <div className="description-text-address">
        <h6 className="main-title-account h6">Additional Address Entries</h6>
        {(additionalEntries &&
          additionalEntries.map((address) => {
            return (
              <div className="mb-3 pt-3">
                <p className="mb-2">
                  <Link
                    className="p-0 text-uppercase"
                    to={{
                      pathname: `/customer/address-book/edit/${address?.id}`,
                      state: { address: additionalEntries },
                    }}
                  >
                    <b>
                      <u>Edit</u>
                    </b>
                  </Link>
                </p>
                <p className="mb-1">
                  {address?.firstname + " " + address?.lastname}
                </p>
                <p className="mb-1">{address?.address_email}</p>
                <p className="mb-1">{address?.company}</p>
                <p className="mb-1">{address?.street[0]}</p>
                <p className="mb-1">
                  {address?.city +
                    ", " +
                    address?.country_code +
                    ", " +
                    address?.postcode}
                </p>
                <p className="mb-1">{address?.full_name_english}</p>
                <p className="mb-1">
                  <b>t:</b> {address?.telephone}
                </p>
              </div>
            );
          })) || (
          <p className="mb-1">
            You have no address entries in your address book.
          </p>
        )}
        <Link
          to="/customer/address-book/add"
          className="mt-0 p-0"
          style={{ color: "white" }}
        >
          <MDBBtn className="mt-5 new-address-button mb-5">
            Add New Address
          </MDBBtn>
        </Link>
      </div>
    </>
  );
};

export default AdditionalEntries;
