import React from "react";
import {
  GET_ALL_VERENIA_IMPORT_DATA,
  STORE_CONFIGURATION_QUERY,
} from "./query";
import GraphQlWrapper from "core/components/graphQlWrapper";

const ConfigurationLoader = (props) => {
  return (
    <>
      <GraphQlWrapper
        query={STORE_CONFIGURATION_QUERY}
        variables={{ id: `store_configuration_data` }}
        saveQuery={true}
        noWrapper={true}
      >
        <></>
      </GraphQlWrapper>
    </>
  );
};

export default ConfigurationLoader;
