import React from "react";
import { MDBCol, MDBRow, MDBLink } from "mdbreact";

const Right = ({ menuItems }) => {
  // TODO: refactor footer, make it pull link from CMS block so client can change it all
  const firstColItems = menuItems?.children.slice(0, 3);
  const secondColItems = menuItems?.children.slice(3, 6);
  const thirdColItems = menuItems?.children.slice(6);

  return (
    <MDBRow className="right-part">
      <MDBCol md="4" className="d-flex footer-column">
        {firstColItems.map((item) => (
          <MDBLink key={item.url_key} to={`/${item.url_path}`}>
            {item.name}
          </MDBLink>
        ))}
      </MDBCol>
      <MDBCol md="4" className="d-flex footer-column">
        {secondColItems.map((item) => (
          <MDBLink key={item.url_key} to={`/${item.url_path}`}>
            {item.name}
          </MDBLink>
        ))}
      </MDBCol>
      <MDBCol md="4" className="d-flex footer-column">
        {thirdColItems.map((item) => (
          <MDBLink key={item.url_key} to={`/${item.url_path}`}>
            {item.name}
          </MDBLink>
        ))}
      </MDBCol>
    </MDBRow>
  );
};

export default Right;
