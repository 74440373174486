import React from "react";
import { MDBTypography, MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import Slider from "react-slick";
import Hotspot from "app/layout/hotspot";
import { settings } from "./utils";

const PortfolioCarousel = ({ images, index, hideModal }) => {
  return (
    <Slider
      {...settings}
      currentSlide={index}
      className={`portfolio-fullscreen w-100`}
    >
      {typeof images !== "undefined" &&
        images.map((item, index) => (
          <MDBContainer>
            <MDBRow key={`image-` + index}>
              <MDBCol size="12">
                <MDBBox className={`portfolio-slider-inner `}>
                  <Hotspot
                    contentArray={item.hotspot[0].content}
                    isHome={true}
                    hotspotImage={item.image}
                    hideModal={hideModal}
                  />
                  <MDBTypography
                    tag="h4"
                    className="portfolio-image-name-enlarge-view text-white"
                  >
                    {item?.name || item?.description}
                  </MDBTypography>
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        ))}
    </Slider>
  );
};

export default PortfolioCarousel;
