import React, { memo } from "react";
import { MDBContainer, MDBTypography, MDBRow, MDBCol } from "mdbreact";
import Pagination from "app/layout/Pagination";
import SortBy from "app/layout/SortBy";

const Toolbar = memo(
  ({
       searchResultString,
    name,
    currentPage,
    pageSize,
    setCurrentPage,
    setPageSize,
    totalPages,
    pageSizeOptions,
    pageSortOptions,
    showSorting = true,
    showPageSize = true,
    showName = true,
    totalNumOfItems,
    sortSelected,
    setSortSelected ,
  }) => {


    return (
      <MDBContainer>
        <MDBRow
          className={`products-header-inner d-flex align-items-center ${
            showName === false
              ? "justify-content-end"
              : "justify-content-between"
          }`.trim()}
        >
          <MDBCol
            size="12"
            xl="6"
            lg="6"
            md="12"
            className="main-title-products"
          >
            {showName && (
              <MDBTypography tag="h4" variant="h4">
                  Search results for:"{searchResultString}"

              </MDBTypography>
            )}
          </MDBCol>

          <MDBCol size="7" xl="3" lg="3" md="6" className="drop-down-sort">
            {showSorting &&
                <SortBy
                    pageSortOptions={pageSortOptions}
                    sortSelected={sortSelected}
                    setSortSelected={setSortSelected}
                />}
          </MDBCol>
          <MDBCol
            size="5"
            xl="3"
            lg="3"
            md="6"
            className="m-0 p-md-0 paginate-mobile"
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              showPagesize={showPageSize}
              totalNumOfItems={totalNumOfItems}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
);

export default Toolbar;
