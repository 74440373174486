import React from "react";
// import { isUndefined } from "../../state/helper/functions";
import { MDBInput } from "mdbreact";

const CheckButton = (props) => {
  return (
    <div onClick={props.onChange} className={`${props.className}`}>
      <MDBInput {...props} className={`check ${props.wrapperClass}`}>
        <span className={props.wrapperClass}></span>
        {props.children}
      </MDBInput>
    </div>
  );
};

export default CheckButton;
