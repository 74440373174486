import React from "react";
import {MDBBtn} from "mdbreact";

const Button = (props) => {

    return (
        <>
            <MDBBtn
                type={props.type}
                onClick={props.onClick}
                className={props.className}>
                {props.children}
            </MDBBtn>
        </>
    );
};

export default Button;
