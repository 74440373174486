import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import { CatWidget } from "./catWidget";

export const Landing = (props) => {
  let { children } = props.catProps;
  return (
    <>
      <MDBContainer fluid className="header-padding-top">
        <MDBRow>
          <MDBCol>
            <MDBContainer>
              <MDBRow>
                <MDBCol className="py-5">
                  <MDBTypography tag="h4" className="h4">
                    {props?.toolbarProps?.name || ""}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="pt-md-5 landing-wrapper">
        <MDBRow>
          <MDBCol>
            <MDBContainer>
              <MDBRow>
                {children.map((childCat) => {
                  return <CatWidget cat={childCat} />;
                })}
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
