import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Configuration
import { storage } from "config/themeConfiguration";

// local storage and persisting state
import { loadState } from "./localStorage";
import subscribeDataPersistor, { allStates } from "./persistedState";

// Global reducers and middleware
import globalReducers from "./reducers";
import globalMiddleware from "./middleware";

// logic changed due to initialState not being set on load
let persistedState = loadState(storage.local_storage_key);
let allStateData = allStates();

const middleware = globalMiddleware;

const enchancements = compose(
  applyMiddleware(thunk),
  middleware,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (noop) => noop
);

persistedState =
  persistedState === undefined || Object.keys(persistedState).length === 0
    ? allStateData
    : persistedState;

const store = createStore(globalReducers, persistedState, enchancements);

export default store;

subscribeDataPersistor(store);
