import gql from "graphql-tag";

export const STORE_CONFIGURATION_QUERY = gql`
  query StoreConfiguration {
    storeConfig {
      # promotional_section {
      #   category_id
      #   max_num_products
      # }
      absolute_footer
      base_currency_code
      base_link_url
      base_media_url
      base_static_url
      base_url
      catalog_default_sort_by
      category_fixed_product_tax_display_setting
      category_url_suffix
      cms_home_page
      cms_no_cookies
      cms_no_route
      code
      copyright
      default_description
      default_display_currency_code
      default_keywords
      default_title
      demonotice
      front
      grid_per_page
      grid_per_page_values
      head_includes
      head_shortcut_icon
      header_logo_src
      id
      list_mode
      list_per_page
      list_per_page_values
      locale
      logo_alt
      logo_height
      logo_width
      no_route
      product_fixed_product_tax_display_setting
      product_url_suffix
      root_category_id
      sales_fixed_product_tax_display_setting
      secure_base_link_url
      secure_base_media_url
      secure_base_static_url
      secure_base_url
      show_cms_breadcrumbs
      store_name
      timezone
      title_prefix
      title_separator
      title_suffix
      website_id
      weight_unit
      welcome
    }
  }
`;

export const GET_ALL_VERENIA_IMPORT_DATA = gql`
  query {
    getAllVereniaImportData {
      name: question_name
      rule: question_hide_rule
      code: question_code
      seq
      answers {
        name
        rule: hide_rule
        sku
        seq
        price: std_price
        product_price: product_info {
          name
          price
        }
      }
    }
  }
`;

export const GET_ATTRIBUTE_ASSETS_QUERY = gql`
  query getAttributeAssets {
    getAttributeAssets {
      asset_group_id
      attribute_code
      options {
        attribute_asset_option_id
        hexadecimal
        image
        option_id
      }
    }
  }
`;

export const GET_ATTRIBUTE_INFORMATION = gql`
  query customAttributeMetadata($attributeInput: [AttributeInput!]!) {
    customAttributeMetadata(attributes: $attributeInput) {
      items {
        attribute_code
        attribute_options {
          label
          value
        }
        attribute_type
        entity_type
        input_type
      }
    }
  }
`;

export default STORE_CONFIGURATION_QUERY;
