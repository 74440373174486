import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import Data from "./text-part-data";
import { CmsDataFragment } from "utils/cmsDataFragment";

const TextPart = ({
  firstHeading,
  textLeftFirst,
  textRightFirst,
  highlightedTextFirst,
}) => {
  return (
    <MDBBox
      className="about-text-part-prima"
      style={{
        background: "url(" + Data.background + ") center center no-repeat",
      }}
    >
      <MDBTypography tag="h2" variant="h1">
        <CmsDataFragment content={firstHeading} />
      </MDBTypography>

      <MDBRow className="about-text-prima-columns no-gutters">
        <MDBCol md="5">
          <MDBTypography tag="p" variant="p">
            <CmsDataFragment content={textLeftFirst} />
          </MDBTypography>
        </MDBCol>
        <MDBCol md="6">
          <MDBTypography tag="p" variant="p">
            <CmsDataFragment content={textRightFirst} />
          </MDBTypography>
          <MDBTypography tag="h6" variant="h6">
            <CmsDataFragment content={highlightedTextFirst} />
          </MDBTypography>
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default TextPart;
