import gql from "graphql-tag";

export const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 1,
  variableWidth: false,
  speed: 1250,
  ease: "linear",
  slidesToScroll: 1,
};

export const GET_ALL_GALLERY_DATA = gql`
  {
    getAllGalleryData {
      category
      description
      hotspot {
        content {
          addInfo
          display_type
          left
          product {
            image
            name
            sku
          }
          top
        }
        hotspot_id
        image
        name
      }
      id
      image
      name
      skuList
    }
  }
`;
