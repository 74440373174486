import React, { memo } from "react";
import { MDBBox, MDBContainer, MDBRow } from "mdbreact";
import SingleProduct from "./singleProduct";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import {
  skeletonWidth,
  skeletonMobileHeight,
  skeletonTabletHeight,
  skeletonDesktopHeight,
} from "./plpResponsiveConfig";
import { NoResult } from "../NoResult";

const Listing = memo(
  ({
    items,
    filteringQtyGrouped,
    availabilityGroup,
    handleClearFilter,
    loading,
  }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
    const isDesktop = useMediaQuery({
      query: `(min-width: 1024px)`,
    });

    /**************looking for children of grouped products******************/
    /***************       passed to <SimpleProduct>         ***************/
    /**************end of looking for children of grouped products******************/
    return (
      <MDBBox>
        <MDBContainer fluid className={`pt-4 pt-md-0`}>
          {loading && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100%",
                marginTop: "5rem",
                marginBottom: "5rem",
                rowGap: "5rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <Skeleton width={"22%"} height={"40rem"} count={2} />
                <Skeleton width={"22%"} height={"40rem"} count={2} />
                <Skeleton width={"22%"} height={"40rem"} count={2} />
                <Skeleton width={"22%"} height={"40rem"} count={2} />
              </div>
            </div>
          )}
          <MDBRow className={items?.length === 0 ? "" : "products-list"}>
            {items?.length === 0 ? (
              <NoResult handleClearFilter={handleClearFilter} />
            ) : (
              items?.map((item) => {
                return (
                  <SingleProduct
                    image={item?.small_image || item?.image}
                    name={item?.name}
                    sku={item?.sku}
                    url={item?.url_key}
                    product={item}
                    filteringQtyGrouped={filteringQtyGrouped}
                    availabilityGroup={availabilityGroup}
                    basics_flag={item?.basics_flag}
                  />
                );
              })
            )}
          </MDBRow>
        </MDBContainer>
      </MDBBox>
    );
  }
);

export default Listing;
