import React from "react";
import QuoteRequestStateContainer from "./state-container";

const QuoteRequestDataContainer = (props) => {
  const headers = [
    {
      title: "item",
      size: 2,
    },
    {
      title: "",
      size: 5,
    },
    {
      title: "price",
      size: 4,
    },
    {
      title: "qty",
      size: 4,
    },
    {
      title: "subtotal",
      size: 4,
    },
  ];

  return <QuoteRequestStateContainer {...props} headers={headers} />;
};

export default QuoteRequestDataContainer;
