import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBox,
  MDBTypography,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import { useWatch } from "react-hook-form";

import "./style.scss";
import {
  EmailErrorMessage,
  FirstNameErrorMessage,
  LastNameErrorMessage,
  CurrentPasswordErrorMessage,
  NewPasswordErrorMessage,
  PasswordErrorMessage,
} from "./errorMessages";

const ChangePasswordForm = ({
  changePasswordCheckbox,
  setChangeEmail,
  setChangePasswordCheckbox,
  register,
  errors,
  getValues,
  loading,
  customer,
  control,
  changeEmail,
}) => {
  const firstName = useWatch({
    control,
    name: "firstName",
    defaultValue: customer?.firstname,
  });
  const lastName = useWatch({
    control,
    name: "lastName",
    defaultValue: customer?.lastname,
  });
  const email = useWatch({
    control,
    name: "email",
    defaultValue: customer?.email,
  });

  useEffect(() => {
    if (changePasswordCheckbox && changeEmail) {
      setLabel("Change email and password");
    } else if (changeEmail && !changePasswordCheckbox) {
      setLabel("Change email");
    } else if (!changeEmail && changePasswordCheckbox) {
      setLabel("Change Password");
    } else {
      setLabel("");
    }
  }, [changeEmail, changePasswordCheckbox]);
  const [label, setLabel] = useState("");

  return (
    <MDBRow className="m-0 d-flex align-items-stretch justify-content-center customer-information-main-row change-password-form">
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <MDBBox tag="div" className="mb-5 first-input-margin">
          <MDBTypography
            variant="h6"
            tag="h6"
            className="mt-5 mb-5 main-title-account-mobile"
          >
            Account information
          </MDBTypography>

          <MDBInput
            value={firstName}
            name="firstName"
            type="text"
            inputRef={register({ required: false })}
            className={`mb-5 account-input ${
              errors.firstname && `account-input-error`
            }`}
            placeholder="Enter Name *"
            label="First Name"
            labelClass={`account-input-label ${
              errors.firstname && `account-error-label`
            }`}
          />
          <FirstNameErrorMessage errors={errors} />
          <MDBInput
            value={lastName}
            name="lastName"
            type="text"
            inputRef={register({ required: false })}
            className={`mb-5 account-input ${
              errors.lastname && `account-input-error`
            }`}
            placeholder="Enter Last Name *"
            label="Last Name"
            labelClass={`account-input-label ${
              errors.lastname && `account-error-label`
            }`}
          />
          <LastNameErrorMessage errors={errors} />

          <MDBCol style={{ marginTop: "20px", paddingLeft: 0 }}>
            <MDBInput
              name="changeEmail"
              type="checkbox"
              label="change email"
              id="changeEmail"
              inputRef={register({ required: false })}
              onChange={() => setChangeEmail(getValues("changeEmail"))}
            />
          </MDBCol>
          <MDBCol style={{ marginTop: "20px", paddingLeft: 0 }}>
            <MDBInput
              name="changePasswordCheckbox"
              type="checkbox"
              label="change password"
              id="changePasswordCheckbox"
              inputRef={register({ required: false })}
              onChange={() =>
                setChangePasswordCheckbox(getValues("changePasswordCheckbox"))
              }
            />
          </MDBCol>
        </MDBBox>
        <MDBBtn type="submit" className="mt-5 save-button" disabled={loading}>
          {loading ? "Saving.." : "Save "}
        </MDBBtn>
      </MDBCol>
      <MDBCol xl="6" lg="6" md="6" sm="6" xs="6">
        <MDBBox tag="div" className="mb-5 pb-5">
          <h6 className="mt-5 mb-5 main-title-account h6">{label}</h6>
          {changeEmail && (
            <>
              <MDBInput
                value={email}
                name="email"
                type="email"
                inputRef={register({
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                className={`mb-5 account-input ${
                  errors.email && `account-input-error`
                }`}
                placeholder="Enter Email *"
                label="Email"
                labelClass={`account-input-label ${
                  errors.email && `account-error-label`
                }`}
              />
              <EmailErrorMessage errors={errors} />
              <MDBInput
                name="currentPassword"
                type="password"
                inputRef={register({
                  required: true,
                  min: 8,
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
                })}
                className={`mb-5 account-input ${
                  errors.currentPassword && `account-input-error`
                }`}
                placeholder="Enter Current Password *"
                label="Current Password"
                labelClass={`account-input-label ${
                  errors.currentPassword && `account-error-label`
                }`}
              />
            </>
          )}
          <CurrentPasswordErrorMessage errors={errors} />
          {changePasswordCheckbox && (
            <>
              {changePasswordCheckbox && !changeEmail ? (
                <MDBInput
                  name="currentPassword"
                  type="password"
                  inputRef={register({
                    required: true,
                    min: 8,
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
                  })}
                  className={`mb-5 account-input ${
                    errors.currentPassword && `account-input-error`
                  }`}
                  placeholder="Enter Current Password *"
                  label="Current Password"
                  labelClass={`account-input-label ${
                    errors.currentPassword && `account-error-label`
                  }`}
                />
              ) : (
                <></>
              )}
              <MDBInput
                name="newPassword"
                type="password"
                inputRef={register({
                  validate: (val) => val !== getValues("currentPassword"),
                  required: true,
                  min: 8,
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
                })}
                className={`mb-5 account-input ${
                  errors.newPassword && `account-input-error`
                }`}
                placeholder="Enter New Password *"
                label="New Password"
                labelClass={`account-input-label ${
                  errors.newPassword && `account-error-label`
                }`}
              />

              <NewPasswordErrorMessage errors={errors} />
              <MDBInput
                type="password"
                name="confirm_password"
                inputRef={register({
                  validate: (value) =>
                    value === getValues("newPassword") ||
                    "The passwords do not match",
                  required: true,
                  min: 8,
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,50}$/u,
                })}
                className={`mb-5 account-input ${
                  errors.confirm_password && `account-input-error`
                }`}
                placeholder="Confirm New Password *"
                label="Confirm Password"
                labelClass={`account-input-label ${
                  errors.confirm_password && `account-error-label`
                }`}
              />
              {errors.confirm_password &&
                errors.confirm_password.type === "required" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                      marginTop: "-3rem",
                      fontWeight: "300",
                    }}
                  >
                    This field is required
                  </p>
                )}

              {errors.confirm_password &&
                errors.confirm_password.type === "validate" && (
                  <MDBTypography tag="p" className="register-error-msg">
                    <p
                      style={{
                        color: "red",
                        fontSize: "1.2rem",
                        marginTop: "-3rem",
                        fontWeight: "300",
                      }}
                    >
                      The passwords do not match
                    </p>
                  </MDBTypography>
                )}

              {errors.confirm_password &&
                errors.confirm_password.type === "pattern" && (
                  <MDBTypography tag="p" className="register-error-msg">
                    <p
                      style={{
                        color: "red",
                        fontSize: "1.2rem",
                        marginTop: "-3rem",
                        fontWeight: "300",
                      }}
                    >
                      The passwords do not match
                    </p>
                  </MDBTypography>
                )}
              <PasswordErrorMessage errors={errors} />
            </>
          )}
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};

export default ChangePasswordForm;
