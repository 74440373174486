import React from "react";
import { MDBBox } from "mdbreact";
import { CmsDataFragment } from "utils/cmsDataFragment";
import { useMediaQuery } from "react-responsive";

const Banner = ({
  productImage,
  bannerDesktop,
  bannerMobile,
  bannerTablet,
  title,
}) => {
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 992px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 471px)",
  });


  return (
    <MDBBox className="about-banner">
      {isBigScreen ? (
        <MDBBox className="about-banner-desktop">
          <CmsDataFragment content={bannerDesktop} />
        </MDBBox>
      ) : isTabletOrMobileDevice ? (
        <MDBBox className="about-banner-mob">
          <CmsDataFragment content={bannerMobile} />
        </MDBBox>
      ) : (
        <MDBBox className="about-banner-tab">
          <CmsDataFragment content={bannerTablet} />
        </MDBBox>
      )}
    </MDBBox>

    // This jsx is for banner where every part is separate

    // <MDBBox
    //   className="about-banner"
    //   style={{
    //     background: "url(" + Data.background + ") center center no-repeat",
    //   }}
    // >
    //   <MDBContainer>
    //     <MDBRow className="about-banner no-gutters">
    //       <MDBCol xl="5" className="banner-image-column">
    //         <CmsDataFragment content={productImage} />
    //       </MDBCol>
    //       <MDBCol
    //         xl="7"
    //         className="d-flex align-items-center banner-title-column"
    //       >
    //         <MDBTypography tag="h1" variant="h1">
    //           <CmsDataFragment content={title} />
    //         </MDBTypography>
    //         <img className="line" src={Data.lineImage} alt="" />
    //       </MDBCol>
    //     </MDBRow>
    //   </MDBContainer>
    // </MDBBox>
  );
};

export default Banner;
