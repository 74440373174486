import React from "react";

const Newsletter = ({ customer }) => {
  return (
    <>
      <div className="mb-5 pb-sm-5">
        <h6 className="mt-5 mb-5 h6">Newsletter</h6>
        <p>
          {(customer?.is_subscribed &&
            "You are subscribed to ‘GENERAL SUBSCRIPTION’") ||
            "You are not subscribed"}
        </p>
      </div>
    </>
  );
};

export default Newsletter;
