import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  MDBCol,
  MDBTypography,
  MDBContainer,
  toast,
  ToastContainer,
} from "mdbreact";
import EditAddressBookForm from "./form";
import { useMutation } from "react-apollo-hooks";
import { UPDATE_CUSTOMER_ADDRESS } from "utils/mutations";
import { Redirect, useHistory } from "react-router-dom";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Countries from "config/data/countries";

const EditAddressBookContent = ({ address }) => {
  const {
    getCustomerAddressesById,
    getCountryById,
    getCustomerInformation,
    getTelephoneMaskByCountry,
    getZipMaskByCountry,
  } = useCustomer();

  const { countries } = Countries;
  const { location } = useHistory();
  let editAddress = address;

  if (editAddress === undefined) {
    let explodedUrl = location.pathname.split(/\//g);
    let id = explodedUrl[explodedUrl.length - 1];

    editAddress = getCustomerAddressesById(id);
  }
  let selectedCountry = getCountryById(editAddress.country_code);

  let defaultRegions = getCountryById(
    selectedCountry.id
  )?.available_regions?.map((region) => ({
    value: region.id,
    label: region.name,
    code: region.code,
    id: region.id,
    name: region.name,
  }));
  if (!defaultRegions) {
    defaultRegions = [];
  }
  const [country, setCountry] = useState({
    label: selectedCountry.full_name_english,
    value: selectedCountry.id,
    available_regions: [...defaultRegions],
  });
  // const [country, setCountry] = useState("");
  const [state, setState] = useState({
    value: editAddress.region.region_id,
    label: editAddress.region.region,
    code: editAddress.region.region_code,
    id: editAddress.region_id,
    name: editAddress.region.region,
  });
  const [availableStates, setAvailableStates] = useState([...defaultRegions]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [checkedBilling, setCheckedBilling] = useState(
    editAddress.default_billing
  );
  const [checkedShipping, setCheckedShipping] = useState(
    editAddress.default_shipping
  );
  const states = country && country.available_regions;

  const onStateChangeHandler = (value) => setState(value);
  const onCountryChangeHandler = (value) => {
    setNonUsState("");
    if (value.value === "CA") {
      setState({ value: 0, label: "Select Province" });
    } else if (value.value === "US") {
      setState({ value: 0, label: "Select State" });
    } else if (
      value.value !== "CA" &&
      value.value !== "US" &&
      hasStateOrProvince
    ) {
      setState({ value: 0, label: "Select State/Region" });
    } else {
      setState("");
    }

    let _availableStates = value.available_regions
      ? value.available_regions.map((region) => {
          return {
            value: region.id,
            label: region.name,
            code: region.code,
            id: region.id,
            name: region.name,
          };
        })
      : [];
    setCountry(value);
    setAvailableStates(_availableStates);
  };

  const handleCheckedBilling = () => {
    setCheckedBilling(!checkedBilling);
  };

  const handleCheckedShipping = () => {
    setCheckedShipping(!checkedShipping);
  };
  let telephoneMask = getTelephoneMaskByCountry(country.value);
  let zipMask = getZipMaskByCountry(country.value);
  let defaultValues = {
    city: editAddress?.city,
    company: editAddress?.company,
    country: {
      label: selectedCountry.full_name_english,
      value: selectedCountry.id,
      available_regions: [...defaultRegions],
    },
    address_email: editAddress?.address_email ? editAddress.address_email : "",
    country_code: editAddress?.country_code,
    default_billing: editAddress?.default_billing,
    billing: editAddress?.default_billing,
    default_shipping: editAddress?.default_shipping,
    firstname: editAddress?.firstname,
    lastname: editAddress?.lastname,
    postcode: editAddress?.postcode,
    region: { ...editAddress?.region },
    street: [...editAddress?.street],
    phone: editAddress?.telephone,
    address1: editAddress.street[0],
    address2:
      typeof editAddress.street[1] !== "undefined" ? editAddress.street[1] : "",
  };
  const { register, handleSubmit, errors, getValues, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      ...defaultValues,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [updateCustomerAddress, { loading }] = useMutation(
    UPDATE_CUSTOMER_ADDRESS
  );

  const [nonUsState, setNonUsState] = useState(editAddress?.region?.region);

  let hasStateOrProvince = availableStates?.length > 0;

  let explodedUrl = location?.pathname?.split(/\//g);
  let addressID = explodedUrl[explodedUrl.length - 1];

  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (state.value === 0 && hasStateOrProvince) {
      setStateError(true);
    }
  }, [state]);
  if (shouldRedirect) return <Redirect to="/customer" />;
  let values = getValues();

  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol size={12} className="dashboard-title d-flex align-items-center">
        <MDBTypography
          tag="h3"
          variant="h3"
          className="main-title-account address-main-title-margins"
        >
          Edit Address
        </MDBTypography>
      </MDBCol>
      <form
        onSubmit={handleSubmit(async (formData) => {
          let regionInput;
          if (hasStateOrProvince) {
            regionInput = {
              region: state.name ? state.name : formData.state,
              region_code: state.code ? state.code : "",
              region_id: state.id ? state.id : 0,
            };
          } else {
            regionInput = { region: nonUsState };
          }
          if (!stateError) {
            try {
              updateCustomerAddress({
                variables: {
                  id: addressID,
                  city: formData.city,
                  company: formData.company,
                  country_code: country?.value,
                  default_billing: checkedBilling,
                  default_shipping: checkedShipping,
                  firstname: formData.firstname,
                  lastname: formData.lastname,
                  postcode: formData.postcode,
                  region: regionInput,
                  street: [formData.address1, formData.address2],
                  telephone: formData.phone,
                  address_email: formData.address_email,
                },
              }).then(() => {
                getCustomerInformation();
              });
              toast(
                <MDBTypography style={{ margin: "auto" }}>
                  You successfully changed Account Information
                </MDBTypography>
              );
              setTimeout(() => setShouldRedirect(true), 3000);
            } catch (error) {
              setShouldRedirect(false);
            }
          }
        })}
      >
        <ToastContainer autoClose={3000} hideProgressBar />
        <EditAddressBookForm
          nonUsState={nonUsState}
          setNonUsState={setNonUsState}
          register={register}
          errors={errors}
          countries={countries}
          states={states}
          onCountryChangeHandler={onCountryChangeHandler}
          onStateChangeHandler={onStateChangeHandler}
          handleCheckedBilling={handleCheckedBilling}
          handleCheckedShipping={handleCheckedShipping}
          loading={loading}
          control={control}
          values={values}
          checkedShipping={checkedShipping}
          checkedBilling={checkedBilling}
          country={country}
          availableStates={availableStates}
          state={state}
          telephoneMask={telephoneMask}
          zipMask={zipMask}
          stateError={stateError}
          setStateError={setStateError}
        />
      </form>
    </MDBContainer>
  );
};

export default EditAddressBookContent;
