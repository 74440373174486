import React from "react";
import { useProductLayout } from "./hooks";

const ProductItem = (props) => {
  const [
    GetGridItemView,
    getListItemView,
    GetSliderItemView,
  ] = useProductLayout();

  return (
    <>
      {props.view === "grid" ? (
        GetGridItemView(props)
      ) : props.view === "list" ? (
        getListItemView(props)
      ) : props.view === "slider" ? (
        GetSliderItemView(props)
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductItem;
