import React from "react";
import { convertNodeToElement } from "react-html-parser";
import Image from "core/components/image";
import themeConfiguration from "config/themeConfiguration";
import { isUndefined } from "lodash";
import { MDBLink, MDBBox } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
// import SubscribeForm from "app/pages/static/customer-service/dealer-tools/subscribeForm";

export function transform(node, index, token, history) {
  let stylesArray = [];
  if (node.type === "tag" && node.name === "a") {
    if (typeof node.attribs.style === "undefined") {
      node.attribs.style = {};
    } else {
      let parsedStyle = [];
      stylesArray = node.attribs.style.split(";");

      stylesArray.forEach((element) => {
        let miniArray = element.split(":");
        let newSanitizedArray = [];
        miniArray.forEach((piece, index) => {
          let sanitizedPiece = piece.replace(/^ /, "");
          sanitizedPiece =
            "'" +
            sanitizedPiece
              .replace("border-style", "borderStyle")
              .replace("border-color", "borderColor") +
            "'";
          newSanitizedArray[index] = sanitizedPiece;
        });

        if (typeof newSanitizedArray[1] !== "undefined") {
          parsedStyle[newSanitizedArray[0]] = newSanitizedArray[1].replace(
            /"/g,
            ""
          );
        }
      });
      parsedStyle.join(",");
      let finalStyle = JSON.parse(
        JSON.stringify({ ...parsedStyle })
          .replace(/'/g, '"')
          .replace(/""/g, '"')
      );

      node.attribs.style = { ...finalStyle };
    }

    if (!isUndefined(node.attribs["data-user"])) {
      return (
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <MDBBox
              className="data-user-login-action"
              onClick={() => {
                showModal(() => {
                  return <Login hideModal={hideModal} />;
                });
              }}
            >
              <MDBBox
                onClick={(e) => {
                  e.preventDefault();
                  if (token) {
                    history.push({ pathname: `${node.attribs.href}` });
                  }
                }}
              >
                {convertNodeToElement(node, index, (node, index) =>
                  transform(node, index, token, history)
                )}
              </MDBBox>
            </MDBBox>
          )}
        </ModalConsumer>
      );
    }

    if (!isUndefined(node.attribs["data-form"])) {
      if (node.attribs["data-form"] === "subscribe") {
        return convertNodeToElement(node, index, (node, index) =>
          transform(node, index, token, history)
        );
      }
    }

    if (!isUndefined(node.attribs.href) && node.attribs.href.includes("http")) {
      return convertNodeToElement(node, index, (node, index) =>
        transform(node, index, token, history)
      );
    }

    if (typeof node.attribs.class !== "undefined") {
      node.attribs.className = node.attribs.class;
      delete node.attribs.class;
    }
    if (
      typeof node.attribs.href !== "undefined" &&
      (node.attribs.href.includes("tel") ||
        node.attribs.href.includes("mailto"))
    ) {
      return (
        <a {...node.attribs} key={`anchor-${{ ...node.attribs }}`}>
          {node.children.map((item) => {
            return convertNodeToElement(item, index, (node, index) =>
              transform(node, index, token, history)
            );
          })}
        </a>
      );
    }

    let linkComponent = (
      <MDBLink
        key={`parsed-link-${index}`}
        to={`${node.attribs.href}`}
        {...node.attribs}
      >
        {node.children.map((item) => {
          let icon = {
            type: "tag",
            name: "i",
            attribs: { class: "fas fa-arrow-right" },
            children: [],
            next: null,
          };
          if (item.name === "button") {
            if (
              item.children.length === 1 &&
              item.attribs.class.includes("show-arrow")
            ) {
              item.children.push(icon);
            }
          }
          return convertNodeToElement(item, index, (node, index) =>
            transform(node, index, token, history)
          );
        })}
      </MDBLink>
    );
    return linkComponent;
  }

  if (node.type === "tag" && node.name === "img") {
    if (
      node.attribs.src.includes("https") ||
      node.attribs.src.includes("http")
    ) {
      return (
        <Image
          key={`node-attribs-` + node.type + node.name + index}
          source={node.attribs.src}
          alt={node.attribs.alt}
        />
      );
    }
    let imageSource = node.attribs.src.split('"');
    return (
      <Image
        source={
          themeConfiguration.magento_url +
          themeConfiguration.media_url +
          "/" +
          imageSource[1]
        }
        alt={node.attribs.alt}
      />
    );
  }

  return convertNodeToElement(node, index, (node, index) =>
    transform(node, index, token, history)
  );
}
