import React from "react";
import { MDBBox, MDBCollapse } from "mdbreact";

const Accordion = (props) => {
  const { isActivePanel, toggleItem, title, link, ActiveClass } = props;

  return (
    <>
      <MDBBox className={ActiveClass("accordion")}>
        <MDBBox
          className="accordion-title pl-5 d-flex align-items-center justify-content-between cursor-pointer"
          onClick={toggleItem}
        >
          {link ? (
            <a className="d-block alink" href={link}>
              {title}
            </a>
          ) : (
            <span style={{ width: '95%' }}>{title}</span> 
          )}
        </MDBBox>
        <MDBCollapse className="ml-4" isOpen={isActivePanel}>
          <MDBBox className="ml-1 pt-4">{props.children}</MDBBox>
        </MDBCollapse>
      </MDBBox>
    </>
  );
};

export default Accordion;
