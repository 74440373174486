import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import RepCard from "./repCard";

const Content = (props) => {
  let { repLocators } = props;
  if (typeof repLocators === "undefined") {
    return <></>;
  } else if (repLocators.length === 0) {
    return (
      <MDBRow className={`find-rep-content no-results justify-content-center`}>
        <MDBCol className={`text-container`}>
          <p>
            We’re sorry, we couldn’t find any results. Please contact us at
            <a href="mailto:contact@primalighting.com">
              contact@primalighting.com
            </a>
            for more information.
          </p>
        </MDBCol>
      </MDBRow>
    );
  } else {
    return (
      <>
        <MDBRow>
          <MDBCol className={`find-rep-content find-rep-results`}>
            {repLocators.map((repLocators) => {
              return <RepCard repLocators={repLocators} />;
            })}
          </MDBCol>
        </MDBRow>
      </>
    );
  }
};

export default Content;
