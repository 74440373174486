import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import QuoteRequestMiniStateContainer from "./state-container";

const QuoteRequestMiniReduxContainer = (props) => {
  return <QuoteRequestMiniStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  items:
    state.customer.data !== undefined && state.customer.data.cart !== undefined
      ? state.customer.data.cart
        ? state.customer.data.cart.items
        : []
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    removeItem: (id) => {
      dispatch(customerActions.removeProductFromCart(id));
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestMiniReduxContainer);
