import React, { useState, useContext, useEffect } from "react";
import QuoteIcon from "app/assets/images/quote.png";
import QuoteRequest from "app/layout/quoteRequestMini";
import { useSelector } from "react-redux";
import { MiniCartContext } from "app/state/hooks/cartHooks/MinicartContext";

const QuotationList = () => {
  const cartItemsLength = useSelector(
    (state) => state?.customer?.data?.cart?.items?.length
  );
  const miniCartContext = useContext(MiniCartContext);
  const { showMiniCart, displayMiniCart } = miniCartContext;
  const [requestVisible, setRequestVisible] = useState(false);

  useEffect(() => {
    if (showMiniCart) {
      setRequestVisible(true);
      setTimeout(() => {
        setRequestVisible(false);
        displayMiniCart();
      }, 3000);
    } else {
      setRequestVisible(false);
    }
  }, [showMiniCart]);

  return (
    <>
      <div className="quotation-list-wrapper">
        <span>
          <img
            className="wishlist-header-icon mt-1"
            src={QuoteIcon}
            alt=""
            onClick={() => setRequestVisible(!requestVisible)}
          />
          {requestVisible && <QuoteRequest />}
        </span>
        {cartItemsLength !== 0 && !requestVisible && (
          <span
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "1rem",
              backgroundColor: "#f29823",
              position: "absolute",
              right: "2%",
            }}
          ></span>
        )}
      </div>
    </>
  );
};

export default QuotationList;
