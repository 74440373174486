import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import Button from "core/components/button";

const ErrorPage = (props) => {
  return (
    <>
      <MDBContainer className={"page-main-content error-page"}>
        <MDBRow className="error-page-text">
          <MDBCol md="12">
            <MDBBox tag="h4">WE COULD NOT FIND THE PAGE YOU REQUESTED</MDBBox>
            <ul className="custom-list">
              <li>
                - If you typed the URL directly, please make sure the spelling
                is correct.
              </li>
              <li>
                - If you clicked on a link to get here, the link is outdated.
              </li>
            </ul>
            <MDBBox tag="h4">WHAT CAN YOU DO?</MDBBox>
            <ul className="custom-list">
              <li>- Go back to the previous page.</li>
              <li>
                - Use the search bar at the top of the page to search for your
                products.
              </li>
              <li>- Follow these links to get you back on track!</li>
            </ul>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"error-page-buttons"}>
            <Button
              outline={true}
              className={"btn-with-icon home-btn"}
              direction={"mr-3"}
              leftIcon={true}
              icon={"arrow-right"}
              to={"/"}
              children={"Homepage"}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ErrorPage;
