// define project specific reducers
// import testReducer from "./data/test";
import commonReducer from "./data/common";
import filtersReducer from "./data/filters";
import customerReducer from "./data/customer";
import queriesReducer from "./data/queries";
import messagesReducer from "./data/messages";
import cacheReducer from "./data/cache";

const projectReducers = {
  // test: testReducer
  common: commonReducer,
  filters: filtersReducer,
  customer: customerReducer,
  queries: queriesReducer,
  messages: messagesReducer,
  cache: cacheReducer,
};

export default projectReducers;
