import React, {useEffect, useRef, useState} from "react";
import { MDBCol, MDBBox, MDBTypography, MDBRow, MDBTooltip } from "mdbreact";
import VideoTutorial from "./videoTutorial";
import { useMediaQuery } from "react-responsive";
import QuestionBox from "./questionBox";
import { returnUrl } from "../options-section/modal";
import { ColorConverter } from "three/examples/jsm/math/ColorConverter";
import { ColorPicker, createColor, open } from "material-ui-color";
import { SlColorPicker, SlInput } from "@shoelace-style/shoelace/dist/react";
import { SketchPicker } from "react-color";
import { Button } from "react-bootstrap";
import RalToHexConverter from "./RalToHexConverter";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";

const Options = ({
  questions,
  handleOptionClick,
  setQuestionCode,
  selectedOptionsMap,
  catalogNoMap,
  setVar,
  handleCatalogNoSelectOption,
  activeCatalogNo,
  setActiveCatalogNo,
  lengthOfQuestions,
  hasModel,
  options,
  handleSelectOption,
  setSelectedOptionsMap,
  colorPicker,
  colorPickerVal,
  colorPickerRalVal,
  setVars,
  vars,
  isWallSconce = false,
  setSkuState,
  initialSkuState,
  custitem_pdf_attachment_1 = false,
}) => {
  const [catalogNumber, setCatalogNumber] = useState(
    catalogNoMap && typeof catalogNoMap[0] !== "undefined"
      ? catalogNoMap[0].name
      : ""
  );
  const isMobile = useMediaQuery({
    query: "(max-device-width: 768px)",
  });

  const hasColorPicker = colorPicker == "1" ? true : false;
  const [colorPickerModal, setColorPickerModal] = useState(true);
  const [colorFromColorPicker, setColorFromColorPicker] = useState(
    createColor("#000")
  );
  const [colorPickerHtml, setColorPickerHtml] = useState();
  const [ralValSelectedColor, setRalValSelectedColor] = useState("");
  const testref = useRef("");
  // const handleHtmlColorPicker = (value) => {
  //   setColorFromColorPicker(value)
  //   console.log("html color picker value",value.target);
  // }
  const handleColorPickerChange = (color) => {

    testref.current.value = "";
    colorPickerRalVal("")
    setRalValSelectedColor("")
    setColorPickerHtml(color?.target?.value);
    colorPickerVal(color?.target?.value);
    window.player.setCustomColor("yellow_ceramic", color.target.value); // TODO: This only works for Magnolia right now
    window.player.setCustomColor("pickerMAT", color.target.value); // TODO: This only works for Magnolia right now
  };

  const updateModelColorWithRal = (hex, ral="") => {

    colorPickerRalVal(ral);
    setColorPickerHtml(hex);
    colorPickerVal(hex);
    setRalValSelectedColor(ral);
    window.player.setCustomColor("yellow_ceramic", hex); // TODO: This only works for Magnolia right now
    window.player.setCustomColor("pickerMAT", hex); // TODO: This only works for Magnolia right now
  };

  const [isImgError, setIsImgError] = useState(false);
  const onError = () => {
    setIsImgError(true);
  };

  let filteredQuestions = questions.filter((x) => x.parsedAnswers.length > 0);

  useEffect(() => {
    // let initialSelectionIfOptionsAre = {};
    // let newVars = { ...vars };
    // filteredQuestions.map((currentQuestion, i) => {
    //   if (
    //     currentQuestion.parsedAnswers.length === 1 ||
    //     currentQuestion.code === "CATA"
    //   ) {
    //     //handleSelectOption(currentQuestion.parsedAnswers[0], currentQuestion.code)
    //
    //   //  handleCatalogNoSelectOption()
    //
    //     let onlyAnswer = currentQuestion.parsedAnswers[0];
    //
    //     initialSelectionIfOptionsAre[currentQuestion.code] = {
    //       ...onlyAnswer,
    //       iconUrl: returnUrl(onlyAnswer.sku, currentQuestion.code),
    //     };
    //
    //     newVars = {
    //       ...newVars,
    //       [currentQuestion.code]: onlyAnswer.sku.replace(/-/g, "_"),
    //     };
    //
    //   }
    // });

    setSelectedOptionsMap(selectedOptionsMap);
    // if (vars.CATA && vars.CATA !== newVars.CATA) {
    //   setVars({ ...newVars, CATA: vars.CATA });
    //
    // } else {
    //   setVars(newVars);
    // //  setSkuState(newVars)
    // }
  }, [catalogNumber]);

  return (
    <div className="pdp-select-wrapper">
      <MDBRow className="no-gutters">
        <MDBCol>
          {lengthOfQuestions === 1 ? (
            <></>
          ) : (
            <MDBTypography
              style={{
                fontSize: "1.8rem",
                color: "#000000",
                marginBottom: "2rem",
                fontFamily: "SangBleuSunrise",
              }}
            >
              {hasModel
                ? "Visually configure your product"
                : "Configure your product"}
            </MDBTypography>
          )}
        </MDBCol>
        {lengthOfQuestions > 1 && hasModel && (
          <MDBCol>
            <VideoTutorial hasModel={hasModel} />
          </MDBCol>
        )}
      </MDBRow>
      {(!isImgError && (
        <MDBBox className="catalog-items d-flex">
          {catalogNoMap?.map((item, index) => (
            <>
              <MDBBox
                className={`mr-2`}
                onClick={() => {
                  setVar("CATA", item?.sku);
                  handleCatalogNoSelectOption(item, "CATA");
                  setCatalogNumber(item?.name);
                  setActiveCatalogNo(index);
                }}
              >
                <img
                  src={item?.iconUrl}
                  // onError={onError}
                  alt=""
                  className={
                    activeCatalogNo === index
                      ? `catalog-item-active cursor-pointer`
                      : `catalog-item cursor-pointer`
                  }
                />
              </MDBBox>
            </>
          ))}
        </MDBBox>
      )) || <></>}
      <MDBBox className={`mb-4`}>
        <div className="d-flex justify-content-between">
          <div>
            <span className="catalog text-bold">CATALOG NO: </span>
            <span className="catalog-code">
              {catalogNumber || (catalogNoMap && catalogNoMap[0]?.name)}
            </span>
          </div>
          {custitem_pdf_attachment_1 && (
            <Link
                style={{textAlign: 'right'}}
              to={{ pathname: custitem_pdf_attachment_1 }}
              target="_blank"
              className="catalog text-bold btn btn-link p-0"
            >
              SAVE OVERVIEW SPEC SHEET
            </Link>
          )}
        </div>
      </MDBBox>

      {hasColorPicker ? (
        <>
          {/*<MDBBox>*/}
          {/*    <span onClick={} className={`catalog text-bold`}>SELECT COLOR</span>*/}
          {/*</MDBBox>*/}
          <MDBBox className={`mb-4 colorpicker-wrap`}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center mb-5 mt-4">
                <Button
                  variant="link"
                  className={"catalog text-bold p-0 mr-3"}
                  onClick={() => setColorPickerModal(true)}
                >
                  SELECT A COLOR
                </Button>
                <MDBTooltip domElement tag="span" placement="top">
                  <div>
                    <Icon role={`top`} icon={`info`} />
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <span>
                      You can choose any custom color for this product.
                    </span>
                  </div>
                </MDBTooltip>
                {!colorPickerModal && colorPickerHtml && (
                  <span className={"ml-4 tooltip-wrapper"}>
                    <div
                      style={{
                        backgroundColor: colorPickerHtml,
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "inline-block",
                        verticalAlign: "bottom",
                        marginRight: "10px",
                      }}
                      className={"colorpicker-circle wrap"}
                    ></div>
                    {ralValSelectedColor!=="" ? "RAL " + ralValSelectedColor : colorPickerHtml}
                  </span>
                )}
              </div>
              {colorPickerModal && (
                <Button
                  className={"catalog text-bold p-0 mb-5 mt-4"}
                  variant="link"
                  active={false}
                  onClick={() => {
                    setColorPickerModal(false);
                  }}
                >
                  CLOSE COLOR PICKER
                </Button>
              )}
            </div>
            {colorPickerModal ? (
              <>
                <SlColorPicker
                  inline={colorPickerModal}
                  value={colorPickerHtml}
                  onSlChange={handleColorPickerChange}
                  label="Select a color"
                />
                <RalToHexConverter
                    testref={testref}
                  ralValSelectedColor={ralValSelectedColor}
                  updateModelColorWithRal={updateModelColorWithRal}
                />
              </>
            ) : (
              <></>
            )}

            {/*<SketchPicker />*/}
          </MDBBox>

          {/*<textarea></textarea>*/}
        </>
      ) : (
        <></>
      )}

      {filteredQuestions.map((q, i) => {
        let allPrevSelected = questions.slice(0, i).every((curr) => {
          return selectedOptionsMap[curr.code];
        });

        let qbProps = {
          q,
          selectedOptionsMap,
          prevSelected: allPrevSelected,
          setQuestionCode,
          handleOptionClick,
          isMobile,
          options,
          setVar,
          handleSelectOption,
        };
        if (q?.code !== "CATA" && q?.parsedAnswers?.length !== 0) {
          if (allPrevSelected) {
            return <QuestionBox {...qbProps} isDisabled={false} index={i} />;
          } else {
            return <QuestionBox {...qbProps} isDisabled={true} index={i} />;
          }
        }
      })}
    </div>
  );
};

export default Options;
