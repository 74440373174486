import { isEqual } from "lodash";
export default (state, actions) => {
  let payload = { ...actions.payload };
  let newDataState = { ...state.data };
  if (
    (typeof state.data !== "undefined" &&
      typeof state.data[payload.key] === "undefined") ||
    (typeof state.data !== "undefined" &&
      typeof state.data[payload.key] !== "undefined" &&
      !isEqual(state.data[payload.key], payload.data))
  ) {
    newDataState[payload.key] = payload.data;
  }

  return Object.assign({}, state, { ...state, data: { ...newDataState } });
};
