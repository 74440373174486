import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import { gql } from "graphql.macro";

const CREATE_WISHLIST_QUERY = gql`
  mutation CreateWishlist($name: String) {
    createWishlist(input: $name) {
      id
      name
      items
      main
    }
  }
`;
export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_WISHLIST_QUERY,
      variables: action,
    });

    let wishlists = { ...store.getState().customer.data.wishlist };
    if (data) {
      wishlists.multiwishlist_data.push(data.createWishlist);
      store.dispatch(customerActions._reduceWishlist(wishlists));
      store.dispatch(
        messagesActions.addMessage(
          `successfully created project ${action.name}`,
          "success"
        )
      );
      store.dispatch(
        messagesActions.addMessage(
          `successfully created project ${action.name}`,
          "success",
          "wishlist-popup"
        )
      );
    }
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );
    store.dispatch(
      messagesActions.addMessage(
        error.graphQLErrors[0].debugMessage,
        "danger",
        "wishlist-popup"
      )
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
