import React, { useEffect, memo, useState } from "react";
import { MDBBox, MDBTypography } from "mdbreact";
import _ from "lodash";
import Icon from "app/assets/icon/icon";
import PrimaSpinner from "app/layout/primaSpinner";

const QuoteRequestItem = memo(
  ({
    item,
    removeItem,
    quantity,
    setQuantity,
    updateCart,
    lock,
    loading,
    setLoading,
    key,
  }) => {
    useEffect(() => {
      if (item.quantity !== quantity) {
        setQuantity(item.quantity);
      }
    }, [item, quantity, setQuantity]);

    let productImage;
    if (typeof item.product.image?.url === "undefined") {
      productImage = item.product.small_image?.url;
    } else {
      productImage = item.product.image?.url;
    }

    return (
      <MDBBox
        id="mini-quote-request-wrapper"
        key={key}
        className="d-flex pb-5 align-items-center product-image-mini"
      >
        <img src={productImage} alt="product" />
        <MDBBox className="d-flex flex-column align-items-stretch pl-4 flex-grow-1  ml-5">
          <MDBBox className="name-and-sku-wrapper">
            <MDBTypography tag="p">{item.product.name}</MDBTypography>
            <MDBTypography tag="p" className="mb-3">
              SKU {item?.product?.sku}
            </MDBTypography>
          </MDBBox>
          <MDBBox className="d-flex align-items-center justify-content-end mt-4">
            <MDBBox>
              <MDBBox
                tag="span"
                className="mini-quote-item-remove cursor-pointer"
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                  }
                  removeItem(item.id);
                }}
              >
                {loading ? <PrimaSpinner /> : <Icon icon="trashWhite" />}
              </MDBBox>
            </MDBBox>
          </MDBBox>
        </MDBBox>
      </MDBBox>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default QuoteRequestItem;
