export const SET_NAME = "SET_NAME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_COMPANY = "SET_COMPANY";
export const SET_PHONE = "SET_PHONE";
export const SET_MESSAGE = "SET_MESSAGE";

export const SET_EMAIL_ERROR = "SET_EMAIL_ERROR";
export const SET_NAME_ERROR = "SET_NAME_ERROR";

export const SET_EMAIL_ERROR_MSG = "SET_EMAIL_ERROR_MSG";
export const SET_NAME_ERROR_MSG = "SET_NAME_ERROR_MSG";

export const setEmail = (dispatch, payload) =>
  dispatch({ type: SET_EMAIL, payload });

export const setName = (dispatch, payload) =>
  dispatch({ type: SET_NAME, payload });

export const setCompany = (dispatch, payload) =>
  dispatch({ type: SET_COMPANY, payload });

export const setPhone = (dispatch, payload) =>
  dispatch({ type: SET_PHONE, payload });

export const setMessage = (dispatch, payload) =>
  dispatch({ type: SET_MESSAGE, payload });

export const setEmailError = (dispatch, payload) =>
  dispatch({ type: SET_EMAIL_ERROR, payload });

export const setNameError = (dispatch, payload) =>
  dispatch({ type: SET_NAME_ERROR, payload });

export const setEmailErrorMsg = (dispatch, payload) => {
  dispatch({ type: SET_EMAIL_ERROR_MSG, payload });
};

export const setNameErrorMsg = (dispatch, payload) => {
  dispatch({ type: SET_NAME_ERROR_MSG, payload });
};
