export const klevuSearch = async (value, klevuConfig) => {
  const { cloud_search_url, js_api_key } = klevuConfig;
  const body = !value
    ? JSON.stringify({
        recordQueries: [
          {
            id: "recsTrending",
            typeOfRequest: "RECS_TRENDING",
            settings: {
              limit: 5,
              typeOfRecords: ["KLEVU_PRODUCT"],
            },
          },
          {
            id: "recsRecentlyViewed",
            typeOfRequest: "SEARCH",
            settings: {
              limit: 1,
            },
          },
        ],
        context: {
          apiKeys: [`${js_api_key}`],
        },
      })
    : JSON.stringify({
        recordQueries: [
          {
            id: "productSearch",
            typeOfRequest: "SEARCH",
            sort: "NAME_ASC",
            settings: {
              query: {
                term: value,
              },
              limit: 5,
              typeOfRecords: ["KLEVU_PRODUCT"],
            },
          },
          {
            id: "categoryProducts",
            typeOfRequest: "RECS_ALSO_VIEWED",
            sort: "NAME_ASC",
            settings: {
              limit: 3,
              query: {
                categoryPath: value,
              },
            },
          },
        ],
        suggestions: [
          {
            id: "mySuggestionsQuery1",
            typeOfQuery: "AUTO_SUGGESTIONS",
            query: value,
            limit: 6,
            hlStartElem: "<span style='font-weight: bold;'>",
            hlEndElem: "</span>",
          },
        ],
        context: {
          apiKeys: [`${js_api_key}`],
        },
      });

  const data = await fetch(`https://${cloud_search_url}/cs/v2/search/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then((data) => data);
  return data;
};
