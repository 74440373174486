import React from "react";
import {
  MDBCard,
  MDBCardImage,
  Link,
  MDBCardBody,
  MDBCardTitle,
  MDBLink,
  MDBCardText,
  MDBTypography,
  MDBContainer,
  MDBBox,
} from "mdbreact";
import "./related-products.scss";
import Scrollbar from "react-scrollbars-custom";

const RelatedProducts = ({ data }) => {
  const relatedProducts = data?.products.items;

  return (
    <MDBContainer className="related-products-row w-100">
      <MDBBox className="related-products-title d-flex align-items-center">
        <hr />
        <MDBTypography tag="h5" variant="h5">
          Related Products
        </MDBTypography>
        <hr />
      </MDBBox>
      <Scrollbar
        noScrollY={true}
        noScrollX={false}
        className="slider-related-products"
      >
        {relatedProducts?.slice(0, 8).map((product, index) => {
          return (
            <MDBCard
              key={index}
              className="text-center product-card related-products-pdp"
            >
              <Link onClick={(e)=>{
                  window.location.replace(`/${product.url_key}`)
              }}>
                <MDBCardImage
                  className="related-products-image-pdp"
                  src={product?.small_image.url}
                  waves
                />
                <MDBCardBody className="card-body-pdp-page">
                  <MDBCardTitle>
                    <MDBLink to={product.url_key}>{product.name}</MDBLink>
                  </MDBCardTitle>
                  <MDBCardText>{product.sku}</MDBCardText>
                </MDBCardBody>
                {/* <i className="far fa-heart fa-2x"></i> */}
              </Link>
            </MDBCard>
          );
        })}
      </Scrollbar>
    </MDBContainer>
  );
};

export default RelatedProducts;
