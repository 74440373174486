import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdbreact";
import Data from "./tabs-data";
import Restaurants from "./restaurants";
import PublicSpaces from "./publicSpaces";
import Retail from "./retail";
import Residential from "./residential";
import Government from "./government";
import Adult from "./adult";

const Tabs = ({
  restaurantsTitle,
  restaurantsImage,
  restaurantsText,
  restaurantsBottomLeft,
  restaurantsBottomImageRight,
  publicSpacesTitle,
  publicSpacesImage,
  publicSpacesText,
  publicSpacesBottomLeft,
  publicSpacesBottomImageRight,
  retailTitle,
  retailImage,
  retailText,
  retailBottomLeft,
  retailBottomImageRight,
  residentialTitle,
  residentialImage,
  residentialText,
  residentialBottomLeft,
  residentialBottomImageRight,
  governmentTitle,
  governmentImage,
  governmentText,
  governmentBottomLeft,
  governmentBottomImageRight,
  adultTitle,
  adultImage,
  adultText,
  adultBottomLeft,
  adultBottomImageRight,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  let TabContent = null;

  const TabList = [
    { title: "Restaurants" },
    { title: "Public Spaces" },
    { title: "Retail" },
    { title: "Residential and Multi Family" },
    { title: "Government" },
    { title: "Active Adult" },
  ];
  switch (activeTab) {
    case 0:
      TabContent = (
        <Restaurants
          restaurantsTitle={restaurantsTitle}
          restaurantsImage={restaurantsImage}
          restaurantsText={restaurantsText}
          restaurantsBottomLeft={restaurantsBottomLeft}
          restaurantsBottomImageRight={restaurantsBottomImageRight}
        />
      );
      break;
    case 1:
      TabContent = (
        <PublicSpaces
          publicSpacesTitle={publicSpacesTitle}
          publicSpacesImage={publicSpacesImage}
          publicSpacesText={publicSpacesText}
          publicSpacesBottomLeft={publicSpacesBottomLeft}
          publicSpacesBottomImageRight={publicSpacesBottomImageRight}
        />
      );
      break;
    case 2:
      TabContent = (
        <Retail
          retailTitle={retailTitle}
          retailImage={retailImage}
          retailText={retailText}
          retailBottomLeft={retailBottomLeft}
          retailBottomImageRight={retailBottomImageRight}
        />
      );
      break;
    case 3:
      TabContent = (
        <Residential
          residentialTitle={residentialTitle}
          residentialImage={residentialImage}
          residentialText={residentialText}
          residentialBottomLeft={residentialBottomLeft}
          residentialBottomImageRight={residentialBottomImageRight}
        />
      );
      break;
    case 4:
      TabContent = (
        <Government
          governmentTitle={governmentTitle}
          governmentImage={governmentImage}
          governmentText={governmentText}
          governmentBottomLeft={governmentBottomLeft}
          governmentBottomImageRight={governmentBottomImageRight}
        />
      );
      break;
    case 5:
      TabContent = (
        <Adult
          adultTitle={adultTitle}
          adultImage={adultImage}
          adultText={adultText}
          adultBottomLeft={adultBottomLeft}
          adultBottomImageRight={adultBottomImageRight}
        />
      );
      break;
    default:
      TabContent = (
        <Restaurants
          restaurantsTitle={restaurantsTitle}
          restaurantsImage={restaurantsImage}
          restaurantsText={restaurantsText}
          restaurantsBottomLeft={residentialBottomLeft}
          residentialBottomImageRight={restaurantsBottomImageRight}
        />
      );
  }

  return (
    <>
      <MDBContainer fluid className="tabs-section">
        <MDBRow className="justify-content-between align-items-stretch tabs-header">
          <MDBCol
            size="12"
            xl="2"
            lg="3"
            md="3"
            className="d-flex align-items-center tabs-inner"
          >
            <MDBTypography tag="h3" variant="h5">
              {Data.title}
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12" xl="10" lg="9" md="9" className="tabs">
            <ul className="flex-column-navigation-mobile-about">
              {TabList.map((tab, i) => (
                <li
                  onClick={() => setActiveTab(i)}
                  key={i}
                  className={activeTab === i && `active-tab`}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </MDBCol>
        </MDBRow>
        <MDBRow className="tabs-container no-gutters">{TabContent}</MDBRow>
        {/* <MDBTypography tag="h6" variant="h6">
          0{activeTab + 1}/<span>06</span>
        </MDBTypography> */}
      </MDBContainer>
    </>
  );
};

export default Tabs;
