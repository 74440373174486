import React from "react";
import { fragmentContentById } from "utils/fragmentContentById";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";
import Banner from "./baner/baner";
import TextPart from "./text-part/text-part";
import TextPartWithImage from "./text-part-with-image/text-part-with-image";
import CatalogSection from "./catalog-section/catalog";
import TabsSection from "./tab-section/tabs";
import Skeleton from "react-loading-skeleton";
import SeoHelmet from "app/layout/seoHelmet";

export const About = ({ data, contentMap }) => {
  if (!data)
    return (
      <React.Fragment>
        <SeoHelmet title={"About us"} url={"/about-us"} />
        <MDBBox className="about-us">
          <Skeleton height={`50rem`} />
          <MDBBox className={`row about-text-part-prima flex-row`}>
            <MDBRow className={`about-text-prima-columns no-gutters`}>
              <MDBCol size={`5`} lg={`5`}>
                <MDBBox>
                  <Skeleton height={`50rem`} width={`100%`} />
                </MDBBox>
              </MDBCol>
              <MDBCol size={`6`} lg={`6`}>
                <Skeleton height={`50rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBBox>
          <Skeleton height={`50rem`} />
        </MDBBox>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <SeoHelmet title={"About us"} url={"/about-us"} />
      <MDBBox className="about-us">
        <Banner
          title={fragmentContentById("about_us_title", data, contentMap)}
          // productImage={fragmentContentById(
          //   "about_us_product_image",
          //   data,
          //   contentMap
          // )}
          bannerDesktop={fragmentContentById(
            "about_us_banner_desktop",
            data,
            contentMap
          )}
          bannerMobile={fragmentContentById(
            "about_us_banner_mobile",
            data,
            contentMap
          )}
          bannerTablet={fragmentContentById(
            "about_us_banner_tablet",
            data,
            contentMap
          )}
        />
        <TextPart
          firstHeading={fragmentContentById(
            "about_us_first_heading",
            data,
            contentMap
          )}
          textLeftFirst={fragmentContentById(
            "about_us_text_left_first",
            data,
            contentMap
          )}
          textRightFirst={fragmentContentById(
            "about_us_text_right_first",
            data,
            contentMap
          )}
          highlightedTextFirst={fragmentContentById(
            "about_us_highlighted_first",
            data,
            contentMap
          )}
        />
        <TabsSection
          productName={fragmentContentById(
            "about_us_product_name",
            data,
            contentMap
          )}
          productSku={fragmentContentById(
            "about_us_product_sku",
            data,
            contentMap
          )}
          tabsImage={fragmentContentById(
            "about_us_tabs_image",
            data,
            contentMap
          )}
          restaurantsTitle={fragmentContentById(
            "about_us_restaurants_title",
            data,
            contentMap
          )}
          restaurantsImage={fragmentContentById(
            "about_us_restaurants_image",
            data,
            contentMap
          )}
          restaurantsText={fragmentContentById(
            "about_us_restaurants_text",
            data,
            contentMap
          )}
          restaurantsBottomLeft={fragmentContentById(
            "about_us_restaurants_bottom_left",
            data,
            contentMap
          )}
          restaurantsBottomImageRight={fragmentContentById(
            "about_us_restaurants_bottom_image_right",
            data,
            contentMap
          )}
          publicSpacesTitle={fragmentContentById(
            "about_us_public_spaces_title",
            data,
            contentMap
          )}
          publicSpacesImage={fragmentContentById(
            "about_us_public_spaces_image",
            data,
            contentMap
          )}
          publicSpacesText={fragmentContentById(
            "about_us_public_spaces_text",
            data,
            contentMap
          )}
          publicSpacesBottomLeft={fragmentContentById(
            "about_us_public_spaces_bottom_left",
            data,
            contentMap
          )}
          publicSpacesBottomImageRight={fragmentContentById(
            "about_us_public_spaces_bottom_image_right",
            data,
            contentMap
          )}
          retailTitle={fragmentContentById(
            "about_us_retail_title",
            data,
            contentMap
          )}
          retailImage={fragmentContentById(
            "about_us_retail_image",
            data,
            contentMap
          )}
          retailText={fragmentContentById(
            "about_us_retail_text",
            data,
            contentMap
          )}
          retailBottomLeft={fragmentContentById(
            "about_us_retail_bottom_left",
            data,
            contentMap
          )}
          retailBottomImageRight={fragmentContentById(
            "about_us_retail_bottom_image_right",
            data,
            contentMap
          )}
          residentialTitle={fragmentContentById(
            "about_us_residential_title",
            data,
            contentMap
          )}
          residentialImage={fragmentContentById(
            "about_us_residential_image",
            data,
            contentMap
          )}
          residentialText={fragmentContentById(
            "about_us_residential_text",
            data,
            contentMap
          )}
          residentialBottomLeft={fragmentContentById(
            "about_us_residential_bottom_left",
            data,
            contentMap
          )}
          residentialBottomImageRight={fragmentContentById(
            "about_us_residential_bottom_image_right",
            data,
            contentMap
          )}
          governmentTitle={fragmentContentById(
            "about_us_government_title",
            data,
            contentMap
          )}
          governmentImage={fragmentContentById(
            "about_us_government_image",
            data,
            contentMap
          )}
          governmentText={fragmentContentById(
            "about_us_government_text",
            data,
            contentMap
          )}
          governmentBottomLeft={fragmentContentById(
            "about_us_government_bottom_left",
            data,
            contentMap
          )}
          governmentBottomImageRight={fragmentContentById(
            "about_us_government_bottom_image_right",
            data,
            contentMap
          )}
          adultImage={fragmentContentById(
            "about_us_adult_image",
            data,
            contentMap
          )}
          adultTitle={fragmentContentById(
            "about_us_adult_title",
            data,
            contentMap
          )}
          adultText={fragmentContentById(
            "about_us_adult_text",
            data,
            contentMap
          )}
          adultBottomLeft={fragmentContentById(
            "about_us_adult_bottom_left",
            data,
            contentMap
          )}
          adultBottomImageRight={fragmentContentById(
            "about_us_adult_bottom_image_right",
            data,
            contentMap
          )}
        />
        <CatalogSection
          catalogImage={fragmentContentById(
            "about_us_catalog_image",
            data,
            contentMap
          )}
          catalogText={fragmentContentById(
            "about_us_catalog_text",
            data,
            contentMap
          )}
          catalogButton={fragmentContentById(
            "about_us_catalog_button",
            data,
            contentMap
          )}
        />
        <div className="proud-member-section">
          <TextPartWithImage
            secondHeading={fragmentContentById(
              "about_us_second_heading",
              data,
              contentMap
            )}
            textLeftSecond={fragmentContentById(
              "about_us_text_left_second",
              data,
              contentMap
            )}
            textRightSecond={fragmentContentById(
              "about_us_text_right_second",
              data,
              contentMap
            )}
            highlightedTextSecond={fragmentContentById(
              "about_us_highlighted_second",
              data,
              contentMap
            )}
            largeImage={fragmentContentById(
              "about_us_large_image",
              data,
              contentMap
            )}
            link={fragmentContentById("about_us_link", data, contentMap)}
          />
        </div>
      </MDBBox>
    </React.Fragment>
  );
};
