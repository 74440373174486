/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-apollo-hooks";
import { LIFESTYLE_IMAGES } from "./queries";
import GraphQlQueryWrapper from "../core/components/graphQlWrapper/graphQlWrapper-redux-container";
import {About} from "../app/pages/static/about-us/about";

import React from "react";
import CmsBlock from "app/layout/cmsBlock";

// const ids = ["pdp_left_lifestyle_image", "pdp_right_lifestyle_image"];
//
// export const getLifestyleImages = () => {
//
//   const { data, error, loading } = useQuery(LIFESTYLE_IMAGES, {
//     variables: { ids },
//   });
//
//   if (error || loading) return null;
//   return data?.cmsBlocks?.items;
//
// };

export const GetLifestyleImagesLeft = () => {
  return <CmsBlock id={"pdp_left_lifestyle_image"} />;
}

export const GetLifestyleImagesRight = () => {
  return <CmsBlock id={"pdp_right_lifestyle_image"} />;
}
