import React, { useState } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBCol,
  MDBRow,
  MDBBox,
  MDBModalHeader,
  MDBLink,
} from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { validate, shouldMarkError } from "./validation";
import SessionMessages from "core/components/sessionMessages";
import Icon from "app/assets/icon/icon";
const Login = (props) => {
  let { customer, state, login, component } = props;
  const [toggleView, setToggleView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const toggle = () => {
    setToggleView(false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      _handleLogin(e);
    }
  };

  const _handleLogin = (e) => {
    e.preventDefault();
    let isTrue = true;
    setTouched({ email: true, password: true });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (isTrue) {
      setLoading(true);
      props.lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          if (component === undefined) {
            toggle();
          }
        },
        fail: (e) => {
          setLoading(false);
        },
      });
      login(state.username, state.password, customer.cartToken);
    }
  };

  let errors = validate(state.username, state.password);

  return (
    <>
      {!customer.token ? (
        <MDBContainer className={"login-modal-container"}>
          {toggleView && (
            <MDBModal
              className="modal-wrapper"
              isOpen={toggleView}
              toggle={() => {
                toggle();
                props.hideModal();
              }}
              centered
            >
              <MDBModalHeader>
                <div className="close-btn">
                  <button
                    type="button"
                    onClick={() => {
                      toggle();
                      props.hideModal();
                    }}
                  >
                    <Icon icon="closeIcon" />
                  </button>
                </div>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBContainer fluid>
                  <MDBRow className="modal-title-wrapper">
                    <MDBCol md="12">
                      <MDBBox tag="h2" className="text-center">
                        login
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <InputField
                        className={`custom-input ${
                          shouldMarkError("email", errors, touched)
                            ? "validation-error"
                            : ""
                        }`}
                        label={`${
                          shouldMarkError("email", errors, touched)
                            ? "Required Field"
                            : "email*"
                        }`}
                        hint="Enter Your Email"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={(e) => {
                          state.setUsername(e.target.value);
                        }}
                        onBlur={(e) => {
                          setTouched({ ...touched, email: true });
                        }}
                        value={state.username}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="grey-text text-center">
                        <InputField
                          className={`custom-input ${
                            shouldMarkError("password", errors, touched)
                              ? "validation-error"
                              : ""
                          }`}
                          label={`${
                            shouldMarkError("password", errors, touched)
                              ? "Required Field"
                              : "password*"
                          }`}
                          hint="Enter Your Password"
                          group
                          type="password"
                          validate
                          error="wrong"
                          success="right"
                          onChange={(e) => {
                            state.setPassword(e.target.value);
                          }}
                          onBlur={(e) => {
                            setTouched({ ...touched, password: true });
                          }}
                          onKeyDown={(e) => _handleKeyDown(e)}
                          value={state.password}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    {/* <MDBCol className={"custom-modal-btn"}>
                <CheckButton
                  containerClass={"switch-container check-button"}
                  wrapperClass={"slider"}
                  label={" remember me"}
                  value="I have read the Terms + Conditions"
                  checked={rememberMe}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitches"
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <span>*= required</span>
                <div className="custom-control custom-switch grey-text text-center">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitches"
                    defaultChecked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitches"
                  >
                    remember me
                    <span className="ml-5">* = required</span>
                  </label>
                </div>
              </MDBCol> */}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="grey-text text-center">
                        <SessionMessages target={`login`} dismissible={false} />

                        <Button
                          className={"w-100 login-button btn-with-icon"}
                          direction={"mr-2"}
                          leftIcon={true}
                          icon={"arrow-right"}
                          disabled={loading}
                          text={
                            <span>{`${
                              loading ? "Logging in..." : "Login"
                            }`}</span>
                          }
                          onClick={(e) => {
                            _handleLogin(e);
                          }}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="row-forgot-link">
                    <MDBCol md="12">
                      <div className="grey-text text-left">
                        <MDBBox tag="a" className="forgot-link">
                          <MDBLink
                            className={"link-elaine"}
                            to={`/forgot-password`}
                            onClick={(e) => {
                              toggle();
                            }}
                          >
                            <MDBBox tag="span">forgot your password?</MDBBox>
                          </MDBLink>
                        </MDBBox>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="row-or-text">
                    <MDBCol className="or-text-wrapper">
                      <div className="or-text text-center">
                        <MDBBox tag="span">or</MDBBox>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="row-trade-program-message">
                    <MDBCol>
                      <div className="text-center text-bottom">
                        <MDBBox tag="span" className={"trade-program-message"}>
                          Are you a Designer or Wholesaler and don't have an
                          account?
                        </MDBBox>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="grey-text text-center btn-create-account">
                        <Button
                          outline={true}
                          className={"w-100 btn-with-icon"}
                          text={<span>Create a new account</span>}
                          direction={"mr-2"}
                          leftIcon={true}
                          icon={"arrow-right"}
                          to={`/register/trade`}
                          onClick={(e) => {
                            if (typeof props.closeBurger !== "undefined")
                              props.closeBurger();
                            toggle();
                          }}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {/* <MDBRow className="row-trade-program">
                    <MDBCol>
                      <div className="text-center text-bottom trade-link">
                        <MDBBox className={"trade"} tag="span">
                          <MDBLink
                            className={"link-elaine"}
                            to={`/register/trade`}
                            onClick={(e) => {
                              if (typeof props.closeBurger !== "undefined")
                                props.closeBurger();
                              toggle();
                            }}
                          >
                            <MDBBox tag="span">Trade program</MDBBox>
                          </MDBLink>
                        </MDBBox>
                      </div>
                    </MDBCol>
                  </MDBRow> */}
                </MDBContainer>
              </MDBModalBody>
            </MDBModal>
          )}
        </MDBContainer>
      ) : (
        <>{component}</>
      )}
    </>
  );
};

export default Login;
