import React from "react";
import { MDBContainer, MDBBox, MDBTypography, MDBRow, MDBCol } from "mdbreact";
import {
  CustomizationSectionTitle,
  CustomizationSectionHeading,
  CustomizationSectionText,
  CustomizationSectionButton,
} from "./customization-section-query-container";
import Video from "./video";
const CustomizationSection = () => {
  return (
    <MDBContainer className="customization-section">
      <MDBBox className="section-title d-flex align-items-center mb-4">
        <hr />
        <MDBTypography tag="h5" variant="h5">
          <CustomizationSectionTitle />
        </MDBTypography>
        <hr />
      </MDBBox>
      <MDBBox className="inner-container">
        <MDBRow>
          <MDBCol xl="6" md="6">
            <MDBBox className="d-flex justify-content-center align-items-center image-customization">
              <Video />
            </MDBBox>
          </MDBCol>
          <MDBCol xl="6" md="5" className="offset-md-1 offset-lg-0">
            <MDBBox className="d-flex justify-content-center align-items-center text-part">
              <MDBBox>
                <MDBTypography tag="h2" variant="h1">
                  <CustomizationSectionHeading />
                </MDBTypography>
                <MDBTypography tag="p" className="paragraph">
                  <CustomizationSectionText />
                </MDBTypography>
                <MDBTypography tag="p" className="paragraph mt-5">
                  <MDBTypography
                    tag="p"
                    className="paragraph mt-5"
                    style={{
                      fontWeight: 700,
                      fontSize: "1.7rem",
                    }}
                  >
                    Model Shown: Carmell
                  </MDBTypography>
                </MDBTypography>
                <CustomizationSectionButton />
              </MDBBox>
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </MDBBox>
    </MDBContainer>
  );
};

export default CustomizationSection;
