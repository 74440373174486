import React from "react";
import Top from "./top";
import Bottom from "./bottom";

const Header = (props) => {
  const { data } = props;
  return (
    <header style={{ zIndex: "999" }}>
      {/*<Top />*/}
      <Bottom menuItems={data} />
    </header>
  );
};

export default Header;
