import React, { useState } from "react";
import QuoteRequestItem from "./quoteRequestItem";

const QuoteRequestItemStateContainer = (props) => {
  let { productSize } = props;
  let [detailsExpanded, setDetailsExpanded] = useState(false);
  let [additionalOptionsExpanded, setAdditionalOptionsExpanded] = useState(
    false
  );

  const [quantity, setQuantity] = useState(props.item.quantity);
  let stateProps = {
    detailsExpanded,
    setDetailsExpanded,
    additionalOptionsExpanded,
    setAdditionalOptionsExpanded,
  };
  //console.log("Good help us all", props);
  return (
    <QuoteRequestItem
      {...props}
      {...stateProps}
      productSize={productSize}
      quantity={quantity}
      setQuantity={setQuantity}
    />
  );
};

export default QuoteRequestItemStateContainer;
