import React from "react";
import { MDBBox, MDBLink } from "mdbreact";
const Breadcrumbs = (props) => {
  let links = props.links;
  if (typeof props.links === "undefined") {
    links = props.backupLinks;
  }

  return (
    <MDBBox
      className={`breadcrumbs-title ${
        (props.className && props.className) || "breadcrumbs-wrapper"
      }`}
    >
      <MDBBox className="breadcrumbs-paths d-flex flex-wrap text-left mb-3">
        {links.map((link, index) => {
          if (links.length === index + 1) {
            return (
              <span className={"current-breadcrumb-item"}>{link?.title}</span>
            );
          }
          return link.url ? (
            <MDBLink key={`breadcrumb-${link.url}-${index}`} to={`${link.url}`}>
              <span>{link?.title}</span>
            </MDBLink>
          ) : (
            <React.Fragment key={`breadcrumb-fragment-${link.url}`} />
          );
        })}
      </MDBBox>
    </MDBBox>
  );
};

export default Breadcrumbs;
