import React from "react";
import { MDBCol, MDBBox, MDBTypography } from "mdbreact";
import { CmsDataFragment } from "utils/cmsDataFragment";

const Retail = ({
  retailTitle,
  retailImage,
  retailText,
  retailBottomLeft,
  retailBottomImageRight,
}) => {
  return (
    <>
      <MDBCol lg="6" className="main-img">
        <CmsDataFragment content={retailImage} />
      </MDBCol>
      <MDBCol lg="6" className="d-flex align-items-center">
        <MDBBox className="text-part d-flex flex-column">
          <MDBTypography tag="h2" variant="h3">
            <CmsDataFragment content={retailTitle} />
          </MDBTypography>
          <MDBTypography tag="p">
            <CmsDataFragment content={retailText} />
          </MDBTypography>
        </MDBBox>
      </MDBCol>
      <MDBBox className="d-flex main-block-tabs">
        <MDBCol size="5" md="7" sm="6" className="product-image-left">
          <MDBBox className="text-center image-left-wrapper">
            <CmsDataFragment content={retailBottomLeft} />
          </MDBBox>
        </MDBCol>
        <MDBCol size="7" md="5" sm="6" className="product-image-right">
          <CmsDataFragment content={retailBottomImageRight} />
        </MDBCol>
      </MDBBox>
    </>
  );
};

export default Retail;
